import React, { useCallback } from "react";
import FilterPopper from "components/FilterPopper";
import CostFilter from "./CostFilter";
import InStateCostFilter from "./InStateCostFilter";
import FilterPopperField from "./FilterPopperField";

function CostFilterPopper({ filter, onChange }) {
  const onResetFilter = useCallback(() => {
    onChange({
      ...filter,
      costMin: null,
      costMax: null,
      useInStateCost: null,
    });
  }, [onChange, filter]);

  return (
    <FilterPopper
      anchor="Cost"
      filterTitle="Filter by Cost"
      resetFilter={onResetFilter}
      testId="cost-filter"
    >
      <CostFilter filter={filter} onChange={onChange} />
      <FilterPopperField label="Use In-state Cost:" mobileColumnSize={6}>
        <InStateCostFilter filter={filter} onChange={onChange} />
      </FilterPopperField>
    </FilterPopper>
  );
}

export default CostFilterPopper;
