import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";

import { StyledParentTreeItem, StyledUnreadMessages } from "./style";

function ParentTreeItem({
  nodeId,
  name,
  icon,
  children,
  selected = false,
  unredMessagesCount,
}) {
  const theme = useTheme();

  const style = {
    background: selected ? theme.palette.common.fern : "inherit",
  };

  return (
    <StyledParentTreeItem
      style={style}
      nodeId={nodeId}
      label={
        <Box display="flex" position="relative">
          {icon}
          <Box>
            {name}
            {Boolean(unredMessagesCount) && (
              <StyledUnreadMessages>{unredMessagesCount}</StyledUnreadMessages>
            )}
          </Box>
        </Box>
      }
    >
      {children}
    </StyledParentTreeItem>
  );
}

ParentTreeItem.propTypes = {
  nodeId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.any,
  children: PropTypes.any,
};

export default ParentTreeItem;
