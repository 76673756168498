import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as yup from "yup";
import CustomTextField from "components/CustomTextField";
import { Add as AddIcon } from "components/MaterialIcons";
import { Delete as DeleteIcon } from "components/MaterialIcons";
import { Box } from "components/MaterialComponents";
import * as validationHelper from "helpers/validationHelper";
import {
  CreateFieldsContainer,
  CreateFormContainer,
  CreateButtonGroup,
  StyledIconButton,
} from "./style";

const validationSchema = yup.object().shape({
  email: validationHelper.email,
});

function AddChildrenForm({ addChildren, closeForm }) {
  const handleFormSubmit = useCallback(
    (values) => {
      addChildren(values.email);
    },
    [addChildren],
  );

  return (
    <CreateFormContainer>
      <Formik
        validateOnChange
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {() => (
          <Form>
            <CreateFieldsContainer>
              <Box width="50%">
                <CustomTextField label="Profile email" name="email" />
              </Box>
              <CreateButtonGroup>
                <StyledIconButton color="primary" type="submit">
                  <AddIcon />
                </StyledIconButton>
                <StyledIconButton color="secondary" onClick={closeForm}>
                  <DeleteIcon />
                </StyledIconButton>
              </CreateButtonGroup>
            </CreateFieldsContainer>
          </Form>
        )}
      </Formik>
    </CreateFormContainer>
  );
}

AddChildrenForm.propTypes = {
  addChildren: PropTypes.func,
  closeForm: PropTypes.func,
};

export default AddChildrenForm;
