import React from "react";
import PropTypes from "prop-types";
import { useRouteMatch } from "react-router-dom";

import { StyledTabLink, StyledTabButton, TabsContainer } from "./style";

function AdminTabs({ url, path }) {
  const coachesMatch = useRouteMatch(`${path}/coaches-verification`);
  const sportsMatch = useRouteMatch(`${path}/sports`);
  const collegesMatch = useRouteMatch(`${path}/colleges`);
  const collegeCoachesMatch = useRouteMatch(`${path}/college-coaches`);

  return (
    <TabsContainer>
      <StyledTabLink to={`${url}/coaches-verification`}>
        <StyledTabButton $active={coachesMatch}>
          Coaches verification
        </StyledTabButton>
      </StyledTabLink>
      <StyledTabLink to={`${url}/sports`}>
        <StyledTabButton $active={sportsMatch}>Sports</StyledTabButton>
      </StyledTabLink>
      <StyledTabLink to={`${url}/colleges`}>
        <StyledTabButton $active={collegesMatch}>Colleges</StyledTabButton>
      </StyledTabLink>
      <StyledTabLink to={`${url}/college-coaches`}>
        <StyledTabButton $active={collegeCoachesMatch}>
          College Coaches
        </StyledTabButton>
      </StyledTabLink>
    </TabsContainer>
  );
}

AdminTabs.props = {
  url: PropTypes.string,
  path: PropTypes.string,
};

export default AdminTabs;
