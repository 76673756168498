import React from "react";
import PropTypes from "prop-types";
import { InputLabel } from "components/MaterialComponents";
import { Select } from "components/MaterialComponents";
import { MenuItem } from "components/MaterialComponents";
import { useField } from "formik";

import {
  StyledFormControl,
  ErrorMessage,
  ChipsContainer,
  StyledChip,
} from "./style";

function SelectMultiple({
  order,
  label,
  itemsList,
  handleChange,
  disabled,
  name,
}) {
  const [field, meta] = useField(name);
  const { value, ...restField } = field;
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <StyledFormControl>
      <InputLabel id={order}>{label}</InputLabel>
      <Select
        labelId={order}
        value={value}
        onChange={handleChange}
        label={label}
        error={Boolean(errorText)}
        disabled={disabled}
        multiple
        renderValue={(selected) => (
          <ChipsContainer>
            {selected.map((value) => (
              <StyledChip key={value} label={value} />
            ))}
          </ChipsContainer>
        )}
      >
        {itemsList.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      {errorText ? <ErrorMessage>{errorText}</ErrorMessage> : null}
    </StyledFormControl>
  );
}

SelectMultiple.propTypes = {
  order: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  itemsList: PropTypes.array,
  handleChange: PropTypes.func,
};

export default SelectMultiple;
