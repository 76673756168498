import React, { useCallback, useState, useEffect } from "react";
import { InputAdornment } from "components/MaterialComponents";
import debounce from "lodash.debounce";

import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { StyledTextField } from "./style";

function NameFilter({ filter = {}, onChange, placeholder }) {
  const { query = "" } = filter;
  const [searchValue, setSearchValue] = useState(query);

  useEffect(() => {
    return () => {
      onChange({ query: "" });
    };
  }, []);

  const onQueryChange = useCallback(
    debounce((text) => {
      onChange({ ...filter, query: text });
    }, 250),
    [onChange, filter],
  );

  const handleSearchValueChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    onQueryChange(value);
  };

  return (
    <StyledTextField
      variant="outlined"
      placeholder={placeholder}
      size="small"
      value={searchValue}
      onChange={handleSearchValueChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        "data-testid": "text-field",
      }}
    />
  );
}

export default React.memo(NameFilter);
