import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { verifyCoachProfile, getCoachesProfiles } from "api/domains/user";
import NameFilter from "components/NameFilter";

import {
  CoacheItemContainer,
  SportItem,
  CoachesContainer,
  CoachesFilterContainer,
} from "./style";

const FILTER_VALUES = {
  ALL: "all",
  VERIFIED: "verified",
  NOT_VERIFIED: "not_verified",
};

function getCoachFullName(coach) {
  return `${coach.firstName} ${coach.lastName}`;
}
function CoachesList() {
  const [coaches, setCoaches] = useState();
  const [filteredCoaches, setFilteredCoaches] = useState();
  const [filterValue, setFilterValue] = useState(FILTER_VALUES.ALL);
  const [queryString, setQueryString] = useState("");

  useEffect(() => {
    const getCoaches = async () => {
      const data = await getCoachesProfiles();
      setCoaches(data);
    };
    getCoaches();
  }, []);

  useEffect(() => {
    if (!coaches) {
      return;
    }

    let filtered;
    switch (filterValue) {
      case FILTER_VALUES.VERIFIED:
        filtered = coaches.filter(
          (coach) =>
            coach.verified &&
            getCoachFullName(coach).toLocaleLowerCase().includes(queryString),
        );
        break;
      case FILTER_VALUES.NOT_VERIFIED:
        filtered = coaches.filter(
          (coach) =>
            !coach.verified &&
            getCoachFullName(coach).toLocaleLowerCase().includes(queryString),
        );
        break;
      default:
        filtered = coaches.filter((coach) =>
          getCoachFullName(coach).toLocaleLowerCase().includes(queryString),
        );
    }

    setFilteredCoaches(filtered);
  }, [filterValue, coaches, queryString]);

  const onVerify = useCallback(async (id) => {
    const coachesData = await verifyCoachProfile(id);
    setCoaches(coachesData);
  }, []);

  const handleNameStringChange = useCallback((value) => {
    setQueryString(value.query);
  }, []);

  return (
    <>
      <Box width="100%">
        <CoachesFilterContainer>
          <Box width="50%">
            <NameFilter
              onChange={handleNameStringChange}
              placeholder="Name search"
            />
          </Box>
          <Box marginLeft="10px" height="40px">
            <Button
              variant={
                filterValue === FILTER_VALUES.VERIFIED
                  ? "contained"
                  : "outlined"
              }
              color="primary"
              onClick={() => setFilterValue(FILTER_VALUES.VERIFIED)}
            >
              Verified
            </Button>
          </Box>
          <Box marginLeft="10px" height="40px">
            <Button
              variant={
                filterValue === FILTER_VALUES.NOT_VERIFIED
                  ? "contained"
                  : "outlined"
              }
              color="primary"
              onClick={() => setFilterValue(FILTER_VALUES.NOT_VERIFIED)}
            >
              Not verified
            </Button>
          </Box>
          <Box marginLeft="10px" height="40px">
            <Button
              variant={
                filterValue === FILTER_VALUES.ALL ? "contained" : "outlined"
              }
              color="primary"
              onClick={() => setFilterValue(FILTER_VALUES.ALL)}
            >
              All
            </Button>
          </Box>
        </CoachesFilterContainer>
      </Box>
      <CoachesContainer>
        {filteredCoaches?.map((coach) => (
          <CoacheItemContainer key={coach.userId}>
            <Box width="10%">
              <Typography variant="h5">{`${coach.firstName} ${coach.lastName}`}</Typography>
            </Box>
            <Box width="10%">
              <Typography variant="h6">{coach.college?.schoolName}</Typography>
            </Box>
            <Box width="10%">
              <Typography variant="h6">{coach.coachTitle}</Typography>
            </Box>
            <Box width="15%">
              {coach.sports.map((sport) => (
                <SportItem
                  key={sport.id}
                  variant="caption"
                >{`${sport.name} (${sport.gender})`}</SportItem>
              ))}
            </Box>
            <Box width="15%">
              <Typography variant="h6">{coach.email}</Typography>
            </Box>
            <Box width="10%">
              <Typography variant="h6">{coach.phoneNumber}</Typography>
            </Box>
            <Box width="10%">
              {coach.verified ? (
                <Box marginRight="10px">
                  <Typography variant="caption">Verified</Typography>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onVerify(coach.userId)}
                >
                  Verify
                </Button>
              )}
            </Box>
          </CoacheItemContainer>
        ))}
      </CoachesContainer>
    </>
  );
}

CoachesList.propTypes = {
  coaches: PropTypes.array,
  onVerify: PropTypes.func,
};

export default CoachesList;
