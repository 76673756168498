import styled from "styled-components";
import { Box } from "components/MaterialComponents";

export const AcademicInfoFormContainer = styled(Box)`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
  margin: 2em;
  width: 75%;
  ${theme.breakpoints.down("sm")} {
    width: inherit;
  }
  `}
`;

export const AcademicInfoInputContainer = styled(Box)`
  ${({ theme }) => `
  display: flex;
  ${theme.breakpoints.down("sm")} {
    flex-direction: column;
  }
  `}
`;

export const SmallTextFieldContainer = styled(Box)`
  ${({ theme }) => `
  width: 20%;
  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
  `}
`;

export const MediumTextFieldContainer = styled(Box)`
  ${({ theme }) => `
  width: 34%;
  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
  `}
`;

export const SchoolSearchContainer = styled(Box)`
  ${({ theme }) => `
  width: 40%;
  margin-bottom: 2em;
  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
  `}
`;
