import { createRegexForBusinessEmail } from "helpers/createRegexForBusinessEmail";
import { emailsBlackList } from "./emailsBlackList";

export const YOUTUBE_REG_EXP = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;
export const FACEBOOK_REG_EXP = /(?:http:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w-]*\/)*([\w-]*)/;
export const TWITTER_REG_EXP = /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w-]*\/)*([\w-]*)/;
export const PASSWORD_REG_EXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*])(?=.{8,})/;
export const HUDL_REG_EXP = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:hudl\.com))(\/video\/|\/v\/)/;
export const INSTAGRAM_REG_EXP = /(https?)?:?(www)?instagram\.com\/[a-z].{3}/;
export const PHONE_REG_EXP = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const ZIP_REG_EXP = /^\d{5}$/;
export const BUSiNESS_EMAIL_REGEX = createRegexForBusinessEmail(
  emailsBlackList,
);

//format: [feet]-[inches], inches less than 12
export const DISTANCE_REGEX = /^(?<feet>0|[1-9][0-9]*)(?:-(?<inches>[0-9]|1[0-1])?)?$/;
//format: mm:ss.ms, mm < 60, ss < 60
export const TIME_MIN_SEC_MS_REGEX = /^(?<minutes>0?[0-9]|[0-5][0-9])(?::|:(?<seconds>0?[0-9]|[1-5][0-9])(?:\.?|\.(?<milliseconds>[0-9]{0,2}))?)?$/;
//only one digit after decimal point
export const DECIMAL_1_REGEX = /^(0|[1-9][0-9]*)(\.[0-9]?)?$/;
//positive integers
export const INTEGER_REGEX = /^[1-9]\d*$/;
//format: ss.ms
export const TIME_SEC_MS_REGEX = /^(?<seconds>0?[0-9]|[1-5][0-9])(?:\.?|\.(?<milliseconds>[0-9]{0,2})?)?$/;
//format: [win]-[lose]-[tie]
export const RECORD_REGEX = /^(?<win>0|[1-9][0-9]*)(?:-(?<lose>0|[1-9][0-9]*)?)?(?:-(?<tie>0|[1-9][0-9]*)?)?$/;
//up to two digits after decimal point
export const DECIMAL_2_REGEX = /^(0|[1-9][0-9]*)(\.[0-9]?[0-9]?)?$/;
//up to three digits after decimal point
export const DECIMAL_3_REGEX = /^(0|[1-9][0-9]*)(\.[0-9]?[0-9]?[0-9]?)?$/;
//up to four digits after decimal point
export const DECIMAL_4_REGEX = /^(0|[1-9][0-9]*)(\.[0-9]?[0-9]?[0-9]?[0-9]?)?$/;
