import styled from "styled-components";
import { Box } from "components/MaterialComponents";

export const SaveBtnContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
`;

export const InputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled(Box)`
  ${({ theme }) => `
    width: 800px;
  ${theme.breakpoints.down("sm")} {
    width: 90%;
    margin-top: 20px;
  }
  `}
`;

export const SubjectContainer = styled(Box)`
  ${({ theme }) => `
      width: 60%;
    ${theme.breakpoints.down("sm")} {
      width: 100%;
    }
  `}
`;
