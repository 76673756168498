import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { AlternateEmail as AlternateEmailIcon } from "components/MaterialIcons";
import CustomTextField from "components/CustomTextField";

function SocialMediaInput({ label, name }) {
  return (
    <Box display="flex">
      <Box mt="7px" mr="5px">
        <AlternateEmailIcon color="primary" />
      </Box>
      <CustomTextField label={label} name={name} />
    </Box>
  );
}

SocialMediaInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default SocialMediaInput;
