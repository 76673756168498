import React, { useCallback, useState, useEffect } from "react";
import FilterPopperField from "./FilterPopperField";
import {
  StyledSlider,
  StyledFilterValuesBlue,
  StyledFilterValuesGrey,
} from "./style";

const min = 0;
const max = 35000;
/*const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 5000,
    label: "5K",
  },
  {
    value: 10000,
    label: "10K",
  },
  {
    value: 20000,
    label: "20K",
  },
  {
    value: 35000,
    label: "35K+",
  },
];*/

function valueLabelFormat(value) {
  return `${value / 1000}K${value >= 35000 ? "+" : ""}`;
}

function EnrollmentFilter({ filter, onChange }) {
  const { enrollmentMin, enrollmentMax } = filter;
  const [value, setValue] = useState([
    parseInt(enrollmentMin) || min,
    parseInt(enrollmentMax) >= 0 ? parseInt(enrollmentMax) : min,
  ]);

  useEffect(() => {
    setValue([
      parseInt(enrollmentMin) || min,
      parseInt(enrollmentMax) >= 0 ? parseInt(enrollmentMax) : min,
    ]);
  }, [enrollmentMin, enrollmentMax]);

  const onEnrollmentChange = useCallback(
    (e, newValue) => {
      setValue(newValue);
    },
    [setValue],
  );
  const onEnrollmentChangeCommitted = useCallback(
    (e, [enrollmentMin, enrollmentMax]) => {
      if (enrollmentMax === min) {
        onChange({
          ...filter,
          enrollmentMin: null,
          enrollmentMax: null,
        });
        return;
      }
      onChange({
        ...filter,
        enrollmentMin: enrollmentMin <= min ? min : enrollmentMin,
        enrollmentMax: enrollmentMax >= max ? max : enrollmentMax,
      });
    },
    [onChange, filter],
  );
  return (
    <FilterPopperField label="Enrollment:">
      {(value[0] === min) & (value[1] === min) ? (
        <StyledFilterValuesGrey variant="h5">
          Select the range
        </StyledFilterValuesGrey>
      ) : (
        <StyledFilterValuesBlue variant="h5">{`${value[0]} - ${value[1]}`}</StyledFilterValuesBlue>
      )}

      <StyledSlider
        value={value}
        min={min}
        max={max}
        step={1000}
        onChange={onEnrollmentChange}
        onChangeCommitted={onEnrollmentChangeCommitted}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        //marks={marks}
        valueLabelFormat={valueLabelFormat}
      />
    </FilterPopperField>
  );
}

export default EnrollmentFilter;
