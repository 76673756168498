import * as yup from "yup";

import { userExists } from "api/domains/user";
import {
  PHONE_REG_EXP,
  PASSWORD_REG_EXP,
  INSTAGRAM_REG_EXP,
  FACEBOOK_REG_EXP,
  TWITTER_REG_EXP,
  YOUTUBE_REG_EXP,
  HUDL_REG_EXP,
  BUSiNESS_EMAIL_REGEX,
} from "constants/regExps";
import {
  ACT_MAX_SCORE,
  ACT_MIN_SCORE,
  CLASS_MAX_RANK,
  CLASS_MIN_RANK,
  FACEBOOK_HANDLE_MAX_LENGTH,
  FACEBOOK_HANDLE_MIN_LENGTH,
  INSTAGRAM_HANDLE_MAX_LENGTH,
  INSTAGRAM_HANDLE_MIN_LENGTH,
  TWITTER_HANDLE_MAX_LENGTH,
  TWITTER_HANDLE_MIN_LENGTH,
  PSAT_SECTION_MAX_SCORE,
  PSAT_SECTION_MIN_SCORE,
  SAT_SECTION_MAX_SCORE,
  SAT_SECTION_MIN_SCORE,
} from "../constants/allowedFormValues";

export const to = yup.string().required("Field is required");

export const subject = yup.string().required("Field is required");

export const body = yup.string().required("Field is required");

export const title = yup.string().required("Field is required");

export const firstName = yup
  .string()
  .min(1, "Must be at least 1 characters")
  .nullable();

export const lastName = yup
  .string()
  .min(1, "Must be at least 1 characters")
  .nullable();

export const heightInInches = yup
  .number()
  .min(50, "Must be in between 50 and 90")
  .max(90, "Must be in between 50 and 90")
  .nullable();

export const weightInPounds = yup
  .number()
  .min(70, "Must be in between 70 and 400")
  .max(400, "Must be in between 80 and 400")
  .nullable();

export const instagramHandle = yup
  .string()
  .min(
    INSTAGRAM_HANDLE_MIN_LENGTH,
    `Must be at least ${INSTAGRAM_HANDLE_MIN_LENGTH} characters`,
  )
  .max(
    INSTAGRAM_HANDLE_MAX_LENGTH,
    `Cannot be longer than ${INSTAGRAM_HANDLE_MAX_LENGTH} characters`,
  )
  .nullable();

export const facebookHandle = yup
  .string()
  .min(
    FACEBOOK_HANDLE_MIN_LENGTH,
    `Must be at least ${FACEBOOK_HANDLE_MIN_LENGTH} characters`,
  )
  .max(
    FACEBOOK_HANDLE_MAX_LENGTH,
    `Cannot be longer than ${FACEBOOK_HANDLE_MAX_LENGTH} characters`,
  )
  .nullable();

export const twitterHandle = yup
  .string()
  .min(
    TWITTER_HANDLE_MIN_LENGTH,
    `Must be at least ${TWITTER_HANDLE_MIN_LENGTH} characters`,
  )
  .max(
    TWITTER_HANDLE_MAX_LENGTH,
    `Cannot be longer than ${TWITTER_HANDLE_MAX_LENGTH} characters`,
  )
  .nullable();

export const email = yup.string().email("Please enter a valid email");

export const gpa = yup
  .number()
  .min(0, "Must be in between 0 and 5.0")
  .max(5, "Must be in between 0 and 5.0")
  .nullable();

export const satSection = yup
  .number()
  .min(
    SAT_SECTION_MIN_SCORE,
    `Must be between ${SAT_SECTION_MIN_SCORE} and ${SAT_SECTION_MAX_SCORE}`,
  )
  .max(
    SAT_SECTION_MAX_SCORE,
    `Must be between ${SAT_SECTION_MIN_SCORE} and ${SAT_SECTION_MAX_SCORE}`,
  )
  .nullable();

export const psatSection = yup
  .number()
  .min(
    PSAT_SECTION_MIN_SCORE,
    `Must be between ${PSAT_SECTION_MIN_SCORE} and ${PSAT_SECTION_MAX_SCORE}`,
  )
  .max(
    PSAT_SECTION_MIN_SCORE,
    `Must be between ${PSAT_SECTION_MIN_SCORE} and ${PSAT_SECTION_MAX_SCORE}`,
  )
  .nullable();

export const act = yup
  .number()
  .min(ACT_MIN_SCORE, `Must be between ${ACT_MIN_SCORE} and ${ACT_MAX_SCORE}`)
  .max(ACT_MAX_SCORE, `Must be between ${ACT_MIN_SCORE} and ${ACT_MAX_SCORE}`)
  .nullable();

export const videoDescription = yup
  .string()
  .min(10, "Must be between 10 and 150 characters")
  .max(150, "Must be between 10 and 150 characters");

export const classRank = yup
  .number()
  .min(
    CLASS_MIN_RANK,
    `Must be between ${CLASS_MIN_RANK} and ${CLASS_MAX_RANK}`,
  )
  .when("classRankOutOf", (classRankOutOf, schema) => {
    return schema.max(
      classRankOutOf,
      `Must be between ${CLASS_MIN_RANK} and ${classRankOutOf}`,
    );
  })
  .nullable();

export const maxRank = yup
  .number()
  .min(
    CLASS_MIN_RANK,
    `Must be between ${CLASS_MIN_RANK} and ${CLASS_MAX_RANK}`,
  )
  .max(
    CLASS_MAX_RANK,
    `Must be between ${CLASS_MIN_RANK} and ${CLASS_MAX_RANK}`,
  )
  .nullable();

export const password = yup
  .string()
  .required(
    "Required - Looks like you forgot about this field. Please enter your password here.",
  )
  .matches(
    PASSWORD_REG_EXP,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, and one Number or Special Character (!, @, #, %, &, *)",
  );

export const instagramUrl = yup
  .string()
  .matches(INSTAGRAM_REG_EXP, "Must be a valid Instagram link")
  .nullable();

export const facebookUrl = yup
  .string()
  .matches(FACEBOOK_REG_EXP, "Must be a valid Facebook link")
  .nullable();

export const twitterUrl = yup
  .string()
  .matches(TWITTER_REG_EXP, "Must be a valid Twitter link")
  .nullable();

export const youTubeUrl = yup
  .string()
  .matches(YOUTUBE_REG_EXP, "Must be a valid YouTube video link")
  .required("Field is required");

export const hudlUrl = yup
  .string()
  .matches(HUDL_REG_EXP, "Must be a valid Hudl video link")
  .required("Field is required");

export const phone = yup
  .string()
  .matches(PHONE_REG_EXP, "Phone number is not valid")
  .nullable();

export const emailCheckCoach = yup
  .string()
  .matches(BUSiNESS_EMAIL_REGEX, "Please enter a valid business email address")
  .test("Unique Email", "Email already in use", isemailExist)
  .required(
    "Required - Looks like you forgot about this field. Please enter your email here.",
  );

export const emailCheckAthlete = yup
  .string()
  .email("Please enter a valid email")
  .test("Unique Email", "Email already in use", isemailExist)
  .required(
    "Required - Looks like you forgot about this field. Please enter your email here.",
  );

export const startDate = yup.mixed().required("Field is required");

export const endDate = yup.mixed().when("current", {
  is: false,
  then: yup.mixed().required("Field is required"),
});

async function isemailExist(value) {
  try {
    let res = await userExists({ email: value });
    if (res.message && res.message === "not found") {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    //404 is a valid response, meaning user does not exist
    return true;
  }
}

export const checkUpdateCoachEmail = yup
  .string()
  .matches(BUSiNESS_EMAIL_REGEX, "Please enter a valid business email address")
  .required(
    "Required - Looks like you forgot about this field. Please enter your email here.",
  );

export const college = yup
  .mixed()
  .required("This field is required. Please select your college");
export const sports = yup
  .array()
  .required("This field is required. Please select your sports");
export const sport = yup
  .mixed()
  .required("This field is required. Please select a sport");
