import React from "react";
import PropTypes from "prop-types";

import { StyledPopover as Popover } from "./style";

function CustomPopover({ id, open, anchorEl, handleClose, children }) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      {children}
    </Popover>
  );
}

CustomPopover.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
};

export default CustomPopover;
