import React from "react";
import { StylesProvider } from "components/MaterialComponents";

import Theme from "theme";
import { SportsProvider } from "components/useSports";
import { ProfileProvider } from "components/useProfile";
import { MajorsProvider } from "components/useMajors";
import { RecruitingProfileProvider } from "components/useRecruitingProfile";
import { MessagesProvider } from "components/useMessages";
import { AthleticHistoryProvider } from "components/useAthleticHistory";
import { NotesProvider } from "components/useNotes";
import Routing from "containers/Routing";

function App() {
  return (
    <StylesProvider injectFirst>
      <Theme>
        <AthleticHistoryProvider>
          <NotesProvider>
            <MessagesProvider>
              <SportsProvider>
                <ProfileProvider>
                  <RecruitingProfileProvider>
                    <MajorsProvider>
                      <Routing />
                    </MajorsProvider>
                  </RecruitingProfileProvider>
                </ProfileProvider>
              </SportsProvider>
            </MessagesProvider>
          </NotesProvider>
        </AthleticHistoryProvider>
      </Theme>
    </StylesProvider>
  );
}

export default App;
