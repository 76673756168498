import React from "react";
import PropTypes from "prop-types";
import { Container, MiddleLine, TopLine, DownLine } from "./style";

function HumburgerButton({ isOpen, toggleMenu }) {
  return (
    <Container onClick={toggleMenu}>
      <TopLine $isOpen={isOpen} />
      <MiddleLine $isOpen={isOpen} />
      <DownLine $isOpen={isOpen} />
    </Container>
  );
}

HumburgerButton.propTypes = {
  isOpen: PropTypes.bool,
  toggleMenu: PropTypes.func,
};

export default HumburgerButton;
