import styled from "styled-components";
import * as colors from "theme/colors";

export const Bar = styled.div`
  background: ${colors.Allports};
`;

export const LogoRegion = styled.div`
  position: relative;
  padding: 1px 0 7px 0;
  margin-left: 30px;
`;

export const HallowImage = styled.img`
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
  width: 250%;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  height: 72px;
`;

export const ProfileRegion = styled.div`
  margin-left: auto;
`;
