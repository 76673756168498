import React from "react";
import PropTypes from "prop-types";

import { CoachSummaryContainer, CoachSummaryItem } from "./style";

function CoachSummary({ summary }) {
  return (
    <CoachSummaryContainer>
      <CoachSummaryItem>Sends: {summary?.sends}</CoachSummaryItem>
      <CoachSummaryItem>Opens: {summary?.opens}</CoachSummaryItem>
      <CoachSummaryItem>Visits: {summary?.visits}</CoachSummaryItem>
      <CoachSummaryItem>Replies: {summary?.replies}</CoachSummaryItem>
    </CoachSummaryContainer>
  );
}

CoachSummary.propTypes = {
  summary: PropTypes.object,
};

export default CoachSummary;
