import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { getValInt, getSSfromTime } from "helpers/getSportStatIntValue";
import { StyledSportStatTextField } from "./style";
import { TIME_MIN_SEC_MS } from "../../../constants/sports";
function SportStatTextField({
  label,
  inputType,
  min,
  max,
  value,
  index,
  prompt,
  setFieldValue,
}) {
  const placeholder = prompt && prompt.toString();

  const convertSS = useCallback((duration) => {
    let hs = parseInt(duration % 100),
      seconds = parseInt((duration / 100) % 60),
      minutes = parseInt((duration / (100 * 60)) % 60);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    hs = hs < 10 ? "0" + hs : hs;

    return minutes + ":" + seconds + "." + hs;
  }, []);

  const handleBlur = useCallback(
    ({ target: { value } }) => {
      if (inputType === TIME_MIN_SEC_MS) {
        const ss = getSSfromTime(value);
        if (ss === "") {
          setFieldValue(
            `sportStatsWithAnswers.${index}.answer.valueText`,
            null,
          );
          setFieldValue(`sportStatsWithAnswers.${index}.answer.valueInt`, null);
        } else {
          setFieldValue(
            `sportStatsWithAnswers.${index}.answer.valueText`,
            convertSS(ss),
          );
        }
      }
    },
    [index, setFieldValue, inputType, convertSS],
  );

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const valInt = value && getValInt(value, inputType);

      if (value && valInt === null) return;

      setFieldValue(`sportStatsWithAnswers.${index}.answer.valueText`, value);
      setFieldValue(`sportStatsWithAnswers.${index}.answer.valueInt`, valInt);
    },
    [index, setFieldValue, inputType],
  );

  return (
    <StyledSportStatTextField
      size="small"
      variant="outlined"
      placeholder={placeholder}
      label={label}
      type="text"
      value={value || ""}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}

SportStatTextField.propTypes = {
  label: PropTypes.string,
  inputType: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number,
  setFieldValue: PropTypes.func,
  prompt: PropTypes.any,
  inputUnits: PropTypes.string,
};

export default SportStatTextField;
