import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal";
import { Formik, Form } from "formik";
import * as yup from "yup";
import CustomTextField from "components/CustomTextField";
import { Typography } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";

import { FormContainer, FormModalHeader, ButtonsBlock } from "../../style";

const validationSchema = yup.object().shape({
  name: yup.string().required("Field is required"),
});

function SportsCreateEditePopUp({
  open,
  onClose,
  item,
  onCreatePosition,
  onEditePosition,
}) {
  const handleFormSubmit = useCallback(
    async (values) => {
      !item && (await onCreatePosition(values));
      item && (await onEditePosition(values, item.id));
      onClose();
    },
    [item, onCreatePosition, onEditePosition, onClose],
  );

  const headerText = item ? "EDIT POSITION DATA" : "ADD NEW POSITION";

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <FormModalHeader>
          <Typography variant="h4">{headerText}</Typography>
        </FormModalHeader>
        <Formik
          validateOnChange
          initialValues={{
            name: item ? item.name : "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ submitForm }) => (
            <Form>
              <FormContainer>
                <CustomTextField label="Name" name="name" type="text" />
                <ButtonsBlock>
                  <Button
                    onClick={submitForm}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={onClose}
                    variant="outlined"
                    color="secondary"
                    size="large"
                  >
                    Cancel
                  </Button>
                </ButtonsBlock>
              </FormContainer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

SportsCreateEditePopUp.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCreatePosition: PropTypes.func,
  onEditePosition: PropTypes.func,
};

export default SportsCreateEditePopUp;
