import styled from "styled-components";
import { Modal } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { IconButton } from "components/MaterialComponents";
import * as colors from "theme/colors";

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalBody = styled(Box)`
  ${({ theme, $withScroll }) => `
    position: relative;
    max-width: 80%;
    min-width: 480px;
    max-height: 80%;
    border-top: 0.7em solid ${colors.Allports};
    padding: 24px 48px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px 15px 30px rgba(0, 22, 77, 0.15);
    outline: none;
    overflow-y: ${$withScroll ? "auto" : "none"};
    overflow-x: ${$withScroll ? "hidden" : "none"};
    ${theme.breakpoints.down("xs")} {
      min-width: 330px;
    }
`}
`;

export const StyledIconBtn = styled(IconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
`;

export const ModalBodyFluid = styled(Box)`
  ${({ theme }) => `
    position: relative;
    width: 100%;
    height: 100%;
    border-top: 0.7em solid ${colors.Allports};
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px 15px 30px rgba(0, 22, 77, 0.15);
    outline: none;
    overflow-y: auto;
`}
`;

export const StyledIconBtnFluid = styled(IconButton)`
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 10;
`;
