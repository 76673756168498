import { useContext } from "react";
import Context from "./Context";
import MajorsProvider from "./MajorsProvider";

function useMajors() {
  const { data, loading } = useContext(Context);

  return {
    loading,
    majors: data,
  };
}

export default useMajors;

export { MajorsProvider };
