import { createGlobalStyle } from "styled-components";
import { Whitesmoke, TroutLight } from "theme/colors";

export default createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }
  body {
    font-family: "Open Sans", sans-serif;
    color: ${TroutLight};
    font-size: 14px;
    background: ${Whitesmoke};
  }
`;
