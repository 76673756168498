var HtmlToReactParser = require("html-to-react").Parser;
var htmlToReactParser = new HtmlToReactParser();

export function parseEmailBodyHtml(message) {
  return htmlToReactParser.parse(message);
}

export function parseEmailBodyText(message) {
  const string = message.toString().replace(/<(“[^”]*”|'[^’]*’|[^'”>])*>/, "");
  let paragraphArray = string.split("\n\n");
  let res = "";
  paragraphArray.forEach((element) => {
    res += `<p>${element}</p>`;
  });

  return htmlToReactParser.parse(res);
}

export function parseEmailBody(message) {
  if (message.match(/(?:<\/[^<]+>)|(?:<[^<]+\/>)/)) {
    return parseEmailBodyHtml(message);
  }

  return parseEmailBodyText(message);
}
