import { useContext } from "react";

import ProfileProvider from "./ProfileProvider";
import { ProfileStateContext, ProfileDispatchContext } from "./Context";
import {
  loadCurrentUser,
  logout,
  updateEmail,
  sendVerificationEmail,
  verifyEmail,
  sendChangePasswordLinkToUserEmail,
  changePassword,
} from "./actions";

function useProfileState() {
  const context = useContext(ProfileStateContext);
  if (context === undefined) {
    throw new Error("useProfileState must be used within a ProfileProvider");
  }
  return context;
}

function useProfileDispatch() {
  const context = useContext(ProfileDispatchContext);
  if (context === undefined) {
    throw new Error("useProfileDispatch must be used within a ProfileProvider");
  }
  return context;
}

function useProfile() {
  return [useProfileState(), useProfileDispatch()];
}

export default useProfile;

export {
  ProfileProvider,
  useProfileState,
  useProfileDispatch,
  loadCurrentUser,
  logout,
  updateEmail,
  sendVerificationEmail,
  verifyEmail,
  ProfileStateContext,
  ProfileDispatchContext,
  sendChangePasswordLinkToUserEmail,
  changePassword,
};
