import React from "react";
import styled from "styled-components";
import { Card } from "components/MaterialComponents";
import { Grid } from "components/MaterialComponents";
import { IconButton } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";

import background from "assets/background.svg";
import * as colors from "theme/colors";
import { ReactComponent as SchoolIcon } from "assets/icons/school.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/phone.svg";
import { ReactComponent as LocationIcon } from "assets/icons/location-grey.svg";

export const StyledCard = styled(Card)`
  ${({ theme }) => `
  padding: 61px 0;
  background-image: url(${background});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0;
  color: ${theme.palette.common.white};
`}
`;

export const CardGridContainer = styled(Grid)`
  ${({ theme }) => `
    justify-content: space-evenly;
    ${theme.breakpoints.down("md")} {
      flex-direction: column;
      align-content: center;
    }
  `}
`;

export const LogoGridItem = styled(Grid)`
  ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      align-self: center;
    }
  `}
`;

export const StyledIconButton = styled(IconButton)`
  ${({ theme, selected }) => `
  background: ${
    selected ? theme.palette.common.white : theme.palette.common.allports
  };
  padding: 11px;
  & .MuiIconButton-label {
    & .MuiSvgIcon-root {
      font-size: 1.2rem;
      color: ${
        selected ? theme.palette.common.allports : theme.palette.common.white
      };
    }
  }
  &:hover {
    background: ${theme.palette.common.white};
    & .MuiIconButton-label {
      & .MuiSvgIcon-root {
          color: ${theme.palette.common.allports};
      }
    }
  }
`}
`;

export const StudentInfoGridItem = styled(Grid)`
  ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      text-align: center;
    }
  `}
`;

export const StudentName = styled((props) => (
  <Typography variant="h2" {...props} />
))`
  margin-bottom: 0.5em;
`;

export const StudentSport = styled((props) => (
  <Typography variant="h5" {...props} />
))`
  margin-bottom: 1em;
`;

export const StudentStatsGridContainer = styled(Grid)`
  ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      flex-direction: column;
    }
  `}
`;

export const StudentStatsGridItem = styled(Grid)`
  &:not(:last-child) {
    margin-right: 5em;
  }
`;

export const StudentStatContainer = styled(Box)`
  display: flex;
  margin-bottom: 0.5em;
  align-items: baseline;
`;

export const StudentStatName = styled((props) => (
  <Typography variant="body2" {...props} />
))`
  margin-right: 0.5em;
`;

export const IconGridItem = styled(Grid)`
  align-self: flex-end;
`;

export const CardsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CallToAction = styled(Box)`
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #000;
`;

export const StatsCardContainer = styled(Box)`
  ${({ theme }) => `
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  width: 60%;
    ${theme.breakpoints.down("md")} {
      width: 100%;
    }
    ${theme.breakpoints.down("sm")} {
      flex-direction: column;
    }
  `}
`;

export const AcademicInfoStat = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 15em;
  margin-bottom: 1em;
`;

export const StyledSchoolIcon = styled(SchoolIcon)`
  margin-right: 5px;
`;

export const StyledMailIcon = styled(MailIcon)`
  margin-right: 5px;
  vertical-align: middle;
`;

export const StyledPhoneIcon = styled(PhoneIcon)`
  margin-right: 5px;
  vertical-align: middle;
`;

export const StyledLocationIcon = styled(LocationIcon)`
  margin-right: 5px;
  vertical-align: middle;
`;

export const SportCardContainer = styled.div`
  margin-top: 2em;
`;

export const SportPositionTitle = styled.div`
  ${({ theme }) => `
    font-size: 14px;
    font-weight: 600;
    font-family: 'Open Sans',Arial,sans-serif;
    margin-right: 20px;
    color: ${theme.palette.common.brightGray};
  `}
`;

export const SportStatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const SportStatValue = styled.div`
  width: 40px;
  font-size: 16px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  line-height: 24px;
`;

export const ActivateMessageContainer = styled.div`
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: ${colors.Allports};
  color: white;
`;
