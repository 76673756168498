import React, { useCallback, useMemo } from "react";
import { FormControlLabel } from "components/MaterialComponents";
import { Checkbox } from "components/MaterialComponents";
import { FormGroup } from "components/MaterialComponents";
import ItemSkeleton from "./ItemSkeleton";

function CheckboxOptionsList({ value = [], onChange, options }) {
  const optionsIds = useMemo(() => Object.keys(options), [options]);
  const handleOnChange = useCallback(
    (e) => {
      let newValue = [];
      const checkedName = e.target.name;
      if (value.includes(checkedName))
        newValue = value.filter((v) => v !== checkedName);
      else newValue = [...value, checkedName];
      onChange(newValue);
    },
    [value, onChange],
  );
  return (
    <FormGroup>
      {optionsIds.map((id) => (
        <FormControlLabel
          key={id}
          control={
            <Checkbox
              name={id}
              checked={value.includes(id)}
              onChange={handleOnChange}
            />
          }
          label={options[id]}
        />
      ))}
    </FormGroup>
  );
}

export default React.memo(CheckboxOptionsList);

export { ItemSkeleton };
