import React from "react";
import PropTypes from "prop-types";
import { Backdrop } from "components/MaterialComponents";
import { Fade } from "components/MaterialComponents";
import { Close as CloseIcon } from "components/MaterialIcons";

import {
  StyledModal,
  ModalBody,
  StyledIconBtn,
  ModalBodyFluid,
  StyledIconBtnFluid,
} from "./style";

function Modal({
  children,
  open,
  onClose,
  fluid,
  showCloseIcon = true,
  setRef,
  withScroll = true,
}) {
  return (
    <StyledModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        {fluid ? (
          <ModalBodyFluid ref={setRef} data-testid="mobile-body">
            {showCloseIcon && (
              <StyledIconBtnFluid onClick={() => onClose()}>
                <CloseIcon />
              </StyledIconBtnFluid>
            )}
            {children}
          </ModalBodyFluid>
        ) : (
          <ModalBody $withScroll={withScroll} data-testid="descktop-body">
            <StyledIconBtn onClick={() => onClose()}>
              <CloseIcon />
            </StyledIconBtn>
            {children}
          </ModalBody>
        )}
      </Fade>
    </StyledModal>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  fluid: PropTypes.bool,
};

export default Modal;
