import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";

import CircularProgressWithLabel from "components/CircularProgressWithLabel";
import { StyledChildrenTreeItem, StyledNavLink } from "./style";

function ChildrenTreeItem({ to, nodeId, label, progress }) {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledNavLink to={to}>
      <StyledChildrenTreeItem
        endIcon={!matchesSm && <CircularProgressWithLabel value={progress} />}
        nodeId={nodeId}
        label={label}
      />
    </StyledNavLink>
  );
}

ChildrenTreeItem.propTypes = {
  to: PropTypes.string.isRequired,
  nodeId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  progress: PropTypes.number,
};

export default ChildrenTreeItem;
