import React from "react";
import styled from "styled-components";
import { Grid } from "components/MaterialComponents";
import { Card } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import { IconButton } from "components/MaterialComponents";
import { SportsSoccer as SportsSoccerIcon } from "components/MaterialIcons";
import { Link } from "react-router-dom";

import { ReactComponent as LocationIcon } from "assets/icons/location-white.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info-white.svg";
import { ReactComponent as BankCardIcon } from "assets/icons/bank-card-white.svg";
import { ReactComponent as CollegeIcon } from "assets/icons/college.svg";
import background from "assets/background.svg";

export const StyledCard = styled(Card)`
  ${({ theme }) => `
  padding-top: 61px;
  background-image: url(${background});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0;
  color: ${theme.palette.common.white};
`}
`;

export const StyledCollegeIcon = styled(CollegeIcon)`
  margin-right: 0.5em;
  vertical-align: middle;
`;

export const StyledSportIcon = styled(SportsSoccerIcon)`
  font-size: 1rem;
  margin-right: 0.5em;
  vertical-align: middle;
`;

export const CardGridContainer = styled(Grid)`
  ${({ theme }) => `
    justify-content: space-around;
    ${theme.breakpoints.down("md")} {
      flex-direction: column;
      align-content: center;
    }
  `}
`;

export const LogoGridItem = styled(Grid)`
  ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      align-self: center;
    }
  `}
`;

export const MainSectionGridItem = styled(Grid)`
  ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      text-align: center;
    }
  `}
`;

export const LinksGridContainer = styled(Grid)`
  ${({ theme }) => `
    margin-top: 1.5em;
    ${theme.breakpoints.down("md")} {
      justify-content: center;
    }
  `}
`;

export const IconsGridItem = styled(Grid)`
  ${({ theme }) => `
    align-self: flex-end;
    display: flex;
    ${theme.breakpoints.down("md")} {
      align-self: center;
      margin-top: 1.5em;
    }
  `}
`;

export const CollegeName = styled((props) => (
  <Typography variant="h2" {...props} />
))`
  margin-bottom: 0.4rem;
`;

export const CollegeLocation = styled((props) => (
  <Typography variant="body1" {...props} />
))`
  ${({ theme }) => `
  color: ${theme.palette.common.white};
`}
`;

export const CollegeInfo = styled((props) => (
  <Typography variant="body1" {...props} />
))`
  ${({ theme }) => `
  color: ${theme.palette.common.white};
`}
`;

export const CollegeMoney = styled((props) => (
  <Typography variant="body1" {...props} />
))`
  ${({ theme }) => `
  color: ${theme.palette.common.white};
`}
`;

export const StyledLocationIcon = styled(LocationIcon)`
  vertical-align: middle;
  margin-right: 9px;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  vertical-align: middle;
  margin-right: 9px;
`;

export const StyledBankCardIcon = styled(BankCardIcon)`
  vertical-align: middle;
  margin-right: 9px;
`;

export const StyledIconButton = styled(IconButton)`
  ${({ theme, $selected }) => `
  &:not(:last-child) {
    margin-right: 8px;
  }
  background: ${
    $selected ? theme.palette.common.white : theme.palette.common.allports
  };
  padding: 7px;
  & .MuiIconButton-label {
    & .MuiSvgIcon-root {
      font-size: 1.2rem;
      color: ${
        $selected ? theme.palette.common.allports : theme.palette.common.white
      };
    }
  }
  &:hover {
    background: ${theme.palette.common.white};
    & .MuiIconButton-label {
      & .MuiSvgIcon-root {
          color: ${theme.palette.common.allports};
      }
    }
  }
`}
`;

export const NewsProfileContainer = styled(Box)`
  display: inline-block;
  margin-right: 1.5rem;
`;

export const CollegeLinkGridItem = styled(Grid)`
  margin-right: 2em;
`;

export const NavigationGridItem = styled(Grid)`
  text-align: center;
`;

export const ContactCoachesLink = styled(Link)`
  margin-right: 8px;
`;
