import React from "react";
import styled from "styled-components";
import { Tooltip } from "components/MaterialComponents";
import { CatskillWhite, TroutLight } from "theme/colors";

export const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background: ${CatskillWhite};
    font-size: 14px;
    color: ${TroutLight};
    padding: 10px;
  }

  & .MuiTooltip-arrow {
    color: ${CatskillWhite};
  }
`;
