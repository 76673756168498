import * as userDomain from "api/domains/user";
import * as actionTypes from "./actionTypes";
import { recruitingProfileFeedbacks } from "./recruitingProfileFeedbacks";

async function loadRecruitingProfile(dispatch) {
  try {
    const res = await userDomain.loadRecruitingProfile();
    dispatch({ type: actionTypes.SUCCESS, data: res });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.errorCode });
  }
}

async function createRecruitingProfile(dispatch, data, userType) {
  try {
    dispatch({ type: actionTypes.FETCHING });
    const res = await userDomain.createRecruitingProfile(data, userType);
    dispatch({ type: actionTypes.SUCCESS, data: res });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: recruitingProfileFeedbacks.Created,
    });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.errorCode });
  }
}

async function resetRecruitingProfile(dispatch) {
  dispatch({ type: actionTypes.RESET_RECRUITING_PROFILE });
}

async function updateRecruitingProfile(dispatch, profileId, data) {
  try {
    dispatch({ type: actionTypes.UPDATING });
    const res = await userDomain.updateRecruitingProfile(profileId, data);
    dispatch({ type: actionTypes.SUCCESS, data: res });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: recruitingProfileFeedbacks.Updated,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_ERROR,
      error: error.errorCode,
      message: error.message,
    });
  }
}

async function refreshRecruitingProfile(dispatch, data) {
  try {
    dispatch({ type: actionTypes.UPDATING });
    dispatch({ type: actionTypes.SUCCESS, data });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: recruitingProfileFeedbacks.Updated,
    });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.errorCode });
  }
}

async function uploadProfilePhoto(dispatch, profileData, image) {
  try {
    dispatch({ type: actionTypes.UPDATING });
    const url = await userDomain.uploadProfilePhoto(image);
    dispatch({
      type: actionTypes.SUCCESS,
      data: { ...profileData, profilePhotoMediaItem: url },
    });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: recruitingProfileFeedbacks.Updated,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_ERROR,
      error: error.errorCode,
      message: error.message,
    });
  }
}

async function deleteProfilePhoto(dispatch, profileData) {
  try {
    dispatch({ type: actionTypes.UPDATING });
    await userDomain.deleteProfilePhoto();
    dispatch({
      type: actionTypes.SUCCESS,
      data: { ...profileData, profilePhotoMediaItem: null },
    });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: recruitingProfileFeedbacks.Updated,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_ERROR,
      error: error.errorCode,
      message: error.message,
    });
  }
}

export {
  loadRecruitingProfile,
  createRecruitingProfile,
  updateRecruitingProfile,
  refreshRecruitingProfile,
  uploadProfilePhoto,
  deleteProfilePhoto,
  resetRecruitingProfile,
};
