import client from "../adapter";

function login(body) {
  return client("/user-login", { body });
}

function logout() {
  return client("/user-logout");
}

function signUp(body) {
  const send = {
    ...body,
    redirectUrl: `${window.location.origin}/signup/email/verify`,
  };
  return client("/users", { body: send });
}

function userExists(body) {
  return client("/users/email", { body, method: "POST" });
}

function loadCurrentUser() {
  return client("/user/profile");
}

function sendResetPasswordLink(data) {
  const body = {
    ...data,
    redirectUrl: window.location.origin,
  };

  return client("/user/password/request-reset", { body, method: "POST" });
}

function changePassword(body) {
  return client("/user/password", { body, method: "POST" });
}

function loadRecruitingProfile() {
  return client(`/profile`);
}

function createRecruitingProfile(body) {
  return client(`/profile`, { body });
}

function updateRecruitingProfile(profileId, body) {
  return client(`/profile/${profileId}`, { body, method: "PATCH" });
}

function loadFavorites() {
  return client(`/profile/favorites`);
}

function saveFavorites(groupName, collegeId) {
  return client(`/profile/favorites`, {
    body: { groupName, collegeId },
    method: "POST",
  });
}

function updateFavorites(groupName, favoriteId) {
  return client(`/profile/favorites/${favoriteId}`, {
    body: { groupName },
    method: "PATCH",
  });
}

function removeFavorites(favoriteId) {
  return client(`/profile/favorites/${favoriteId}`, {
    method: "DELETE",
  });
}

function uploadProfilePhoto(image) {
  return client(`/profile/photo`, {
    body: { image },
  });
}

function deleteProfilePhoto() {
  return client(`/profile/photo`, {
    method: "DELETE",
  });
}

function updateEmail(data) {
  return client("/user/change-email", {
    body: { ...data },
    method: "POST",
  });
}

function sendVerificationEmail() {
  return client("/user/verification/send", {
    method: "POST",
    body: { redirectUrl: `${window.location.origin}/user/email/verify` },
  });
}

function verifyEmail(token) {
  return client(`/user/activation`, { method: "POST", body: { token } });
}

function loadSchools(state, city) {
  return client(`/profile/school`, {
    method: "POST",
    body: { state: state, city: city },
  });
}

function loadCitiesByState(state, search) {
  return client(`/profile/city`, {
    method: "POST",
    body: { state: state, text: search },
  });
}

function verifyCoachProfile(id) {
  return client(`/coach/profile/check/${id}`, {
    method: "PATCH",
    body: { redirectUrl: `${window.location.origin}/signup/email/verify` },
  });
}

function getCoachesProfiles() {
  return client(`/coach/profile/all`);
}

export {
  login,
  logout,
  loadCurrentUser,
  loadRecruitingProfile,
  createRecruitingProfile,
  updateRecruitingProfile,
  loadFavorites,
  saveFavorites,
  removeFavorites,
  signUp,
  userExists,
  uploadProfilePhoto,
  deleteProfilePhoto,
  updateEmail,
  sendVerificationEmail,
  verifyEmail,
  loadSchools,
  loadCitiesByState,
  updateFavorites,
  verifyCoachProfile,
  getCoachesProfiles,
  sendResetPasswordLink,
  changePassword,
};
