import React, { useState, useEffect, useCallback } from "react";
import NotesList from "./NotesList";
import CollegeItem from "./CollegeItem";
import { useNotesState } from "components/useNotes";
import HorizontalLine from "components/HorizontalLine";
import { Typography } from "components/MaterialComponents";
import { Headline } from "components/Typography";
import { Box } from "components/MaterialComponents";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import NotesPopUp from "./NotesPopUp";
import NameFilter from "containers/FindColleges/NameFilter";
import isempty from "lodash.isempty";
import { getColleges } from "api/domains/colleges";
import {
  NotesContainer,
  CollegesContainer,
  NotesListContaner,
  HeadersContainer,
  SearchContainer,
} from "./style";

const getCollegeList = (notes) => {
  return Object.keys(notes).map((key) => ({
    unitId: key,
    schoolName: notes[key][0]["school_name"],
    logoUrl: notes[key][0]["logo_url"],
  }));
};

function Notes() {
  const { notes = {} } = useNotesState();
  const collegeList = getCollegeList(notes);
  const [collegeSearchList, setCollegeSearchList] = useState(null);
  const defaultCollege = !isempty(collegeList) ? collegeList[0] : null;
  const [currentCollege, setCurrentCollege] = useState(defaultCollege);
  const [isOpenNoteModal, setIsOpenNoteModal] = useState(false);

  const calculatedCollegeList = collegeSearchList || collegeList;

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    if (!notes[currentCollege?.unitId]) {
      setCurrentCollege(defaultCollege);
    }
  }, [notes]);

  const searchColleges = useCallback(
    async (search) => {
      const colleges = await getColleges({ collegeName: search.query });
      if (search.query) {
        setCollegeSearchList(colleges.data);
        setCurrentCollege(null);
        return;
      }
      setCollegeSearchList(null);
      setCurrentCollege(defaultCollege);
    },
    [defaultCollege],
  );

  const handleCloseModal = useCallback(() => setIsOpenNoteModal(false), []);

  return (
    <>
      <HeadersContainer>
        <Box marginRight="15px">
          <Headline>Notes</Headline>
        </Box>
        {!matchesXs && (
          <Typography variant="h4">
            Document your thoughts about potential schools
          </Typography>
        )}
      </HeadersContainer>
      <HorizontalLine />
      <NotesContainer>
        <CollegesContainer>
          <SearchContainer>
            <NameFilter
              placeholder="Search by name"
              onChange={searchColleges}
            />
          </SearchContainer>
          {!isempty(calculatedCollegeList) ? (
            <>
              {calculatedCollegeList.map((college) => (
                <CollegeItem
                  key={college.unitId}
                  college={college}
                  onSelect={setCurrentCollege}
                  openMobileModal={setIsOpenNoteModal}
                  selected={college.unitId === currentCollege?.unitId}
                />
              ))}
            </>
          ) : (
            <Box
              marginTop="40px"
              marginLeft="20px"
              data-testid="no-notes-screen"
            >
              <Typography variant="h3">No notes were made yet</Typography>
            </Box>
          )}
        </CollegesContainer>
        {matchesXs ? null : (
          <NotesListContaner>
            {currentCollege && (
              <NotesList
                collegeNotes={notes[currentCollege.unitId]}
                collegeId={currentCollege.unitId}
              />
            )}
          </NotesListContaner>
        )}
      </NotesContainer>
      {matchesXs && (
        <NotesPopUp
          open={isOpenNoteModal}
          onClose={handleCloseModal}
          collegeId={currentCollege?.unitId}
          collegeNotes={notes[currentCollege?.unitId]}
          name={currentCollege?.schoolName}
          logo={currentCollege?.logoUrl}
        />
      )}
    </>
  );
}

export default Notes;
