import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { TextField } from "components/MaterialComponents";
import { Formik, Form, useField } from "formik";
import * as yup from "yup";

import {
  DrawerHeader,
  StyledCloseIcon,
  HelperText,
  EmailButton,
  LoginContainer,
  ForgotPasswordLink,
} from "./style";
import { INVALID_EMAIL_OR_USERNAME, INCORRECT_PASSWORD } from "./backendErrors";
import { useProfileDispatch } from "components/useProfile";
import { drawerBodyEnum } from "./drawerBodyEnum";
import * as userDomain from "api/domains/user";
import * as validationHelper from "helpers/validationHelper";

const validationSchema = yup.object().shape({
  email: validationHelper.email.required(
    "Required - Looks like you forgot about this field. Please enter your email here.",
  ),
  password: validationHelper.password,
});

function SignInWithEmail({ onDrawerClose, setDrawerBody }) {
  const dispatch = useProfileDispatch();

  const CustomTextField = ({ label, type, autoFocus = false, ...props }) => {
    const [field, meta] = useField(props);
    const { value, ...restField } = field;
    const errorText = meta.error && meta.touched ? meta.error : "";
    return (
      <TextField
        style={{ marginBottom: "4em" }}
        variant="outlined"
        label={label}
        type={type}
        helperText={errorText}
        error={!!errorText}
        autoFocus={autoFocus}
        value={value || ""}
        {...restField}
      />
    );
  };

  const handleFormSubmit = async (data, { setFieldError }) => {
    try {
      await userDomain.login(data);
      // opening a material ui modal/popover adds overflow: hidden, padding-right: 15px styles to prevent scroll,
      // which should be removed after closing the modal/popover, however if a modal/popover is opened right after login
      // without a page refresh, the styles are not removed, a page reload fixes that
      // todo: change if better solution found
      window.location.reload();
      // dispatch({ type: "success", data: resData });
    } catch (err) {
      handleBackEndError(err, setFieldError);
    }
  };

  const handleBackEndError = (err, setFieldError) => {
    switch (err.errorCode) {
      case INVALID_EMAIL_OR_USERNAME:
        setFieldError("email", err.message);
        break;
      case INCORRECT_PASSWORD:
        setFieldError("password", err.message);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <DrawerHeader>Sign in with your email</DrawerHeader>
        <StyledCloseIcon onClick={onDrawerClose} />
      </Box>
      <HelperText>Enter the email address you used to sign up.</HelperText>
      <Formik
        validateOnChange
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <LoginContainer>
              <CustomTextField
                label="Email"
                name="email"
                type="email"
                autoFocus={true}
              />
              <CustomTextField
                label="Password"
                name="password"
                type="password"
              />
              <EmailButton disabled={isSubmitting} type="submit">
                Next
              </EmailButton>
            </LoginContainer>
          </Form>
        )}
      </Formik>
      <ForgotPasswordLink
        onClick={() => setDrawerBody(drawerBodyEnum.SendResetLinkToEmail)}
        data-testid="forgot-passwod-btn"
      >
        I forgot my password
      </ForgotPasswordLink>
    </>
  );
}

SignInWithEmail.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
};

export default SignInWithEmail;
