import React, { useCallback } from "react";
import PropTypes from "prop-types";
import CheckboxOptionsList from "components/CheckboxOptionsList";

const transformToOptions = (optionsArray) => {
  const result = {};
  optionsArray.forEach((option) => (result[option.id] = option.name));
  return result;
};

function SportPositionsFilter({ filter, onChange, positions }) {
  const { sportPositions = "" } = filter;
  const positionsArray = sportPositions ? sportPositions.split(",") : [];

  const handleOnChange = useCallback(
    (newPositionsArray) => {
      onChange({
        ...filter,
        sportPositions: newPositionsArray.join(","),
      });
    },
    [onChange, filter],
  );

  return (
    <CheckboxOptionsList
      value={positionsArray}
      onChange={handleOnChange}
      options={transformToOptions(positions)}
    />
  );
}

SportPositionsFilter.propTypes = {
  filter: PropTypes.object,
  onChange: PropTypes.func,
  positions: PropTypes.array,
};

export default SportPositionsFilter;
