import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";

import VideoItem from "./VideoItem";
import defaultHudlLogo from "assets/default-hudl-bg.jpg";
import { getVideoPreviewImg, getVideoType } from "helpers/videoUrlHelper";

function VideosContainer({
  updating,
  provided,
  videos,
  handleVideoPreviewClick,
  handleOpenDeleteModal,
  handleIncludeSport,
}) {
  const style = {
    opacity: updating ? "0.2" : "1",
    pointerEvents: updating ? "none" : "all",
  };
  const getImg = (url) => getVideoPreviewImg(url) || defaultHudlLogo;

  return (
    <Box style={style} ref={provided.innerRef} {...provided.droppableProps}>
      {videos
        .sort((a, b) => a.ordinal - b.ordinal)
        .map(({ id, url, title, description, sportIds }, index) => (
          <VideoItem
            img={getImg(url)}
            updating={updating}
            key={id}
            id={id}
            sportIds={sportIds}
            index={index}
            title={title}
            description={description}
            type={getVideoType(url)}
            handleVideoPreviewClick={() => handleVideoPreviewClick(url)}
            handleOpenDeleteModal={() => handleOpenDeleteModal(id)}
            handleIncludeSport={handleIncludeSport}
          />
        ))}
      {provided.placeholder}
    </Box>
  );
}

VideosContainer.propTypes = {
  updating: PropTypes.bool,
  provided: PropTypes.object,
  videos: PropTypes.array,
  handleVideoPreviewClick: PropTypes.func,
  handleOpenDeleteModal: PropTypes.func,
  handleIncludeSport: PropTypes.func,
};

export default VideosContainer;
