import React, { useCallback } from "react";
import PropTypes from "prop-types";
import CollegeLogo from "components/CollegeLogo";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import { Name, CollegeItemContainer } from "./style";

function CollegeItem({ college, onSelect, selected, openMobileModal }) {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));

  const handleSelect = useCallback(() => {
    onSelect(college);

    if (matchesXs) {
      openMobileModal(true);
    }
  }, [college, onSelect, openMobileModal, matchesXs]);

  return (
    <CollegeItemContainer onClick={handleSelect} selected={selected}>
      <CollegeLogo
        name={college.schoolName}
        image={college.logoUrl}
        inverted={true}
      />
      <Name>{college.schoolName}</Name>
    </CollegeItemContainer>
  );
}

CollegeItem.propTypes = {
  college: PropTypes.object,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  openMobileModal: PropTypes.func,
};

export default CollegeItem;
