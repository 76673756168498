import { useContext } from "react";

import NotesProvider from "./NotesProvider";
import { NotesStateContext, NotesDispatchContext } from "./Context";
import { getUserNotes, createNote, updateNote, deleteNote } from "./actions";

function useNotesState() {
  const context = useContext(NotesStateContext);
  if (context === undefined) {
    throw new Error("useNotesState must be used within a NotesProvider");
  }
  return context;
}

function useNotesDispatch() {
  const context = useContext(NotesDispatchContext);
  if (context === undefined) {
    throw new Error("useNotesDispatch must be used within a NotesProvider");
  }
  return context;
}

function useNotes() {
  return [useNotesState(), useNotesDispatch()];
}

export default useNotes;

export {
  NotesProvider,
  useNotesState,
  useNotesDispatch,
  getUserNotes,
  createNote,
  updateNote,
  deleteNote,
  NotesStateContext,
  NotesDispatchContext,
};
