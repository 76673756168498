import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import NoteField from "./NoteField";
import CreateNoteField from "./CreateNoteField";
import {
  useNotesDispatch,
  createNote,
  updateNote,
  deleteNote,
} from "components/useNotes";
import { AddButton } from "components/Buttons";
import { Add as AddIcon } from "components/MaterialIcons";
import { Typography } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import isempty from "lodash.isempty";
import { ListContainer } from "./style";

function NotesList({ collegeNotes = [], collegeId }) {
  const [createMode, setCreateMode] = useState(false);
  const dispatch = useNotesDispatch();

  const handleCreateNote = useCallback(
    (body) => {
      createNote(dispatch, body);
      setCreateMode(false);
    },
    [dispatch],
  );

  const handleUpdateNote = useCallback(
    (id, body) => {
      updateNote(dispatch, id, body);
    },
    [dispatch],
  );

  const handleDeleteNote = useCallback(
    (id) => {
      deleteNote(dispatch, id);
    },
    [dispatch],
  );

  const toggleCreateMode = useCallback(() => {
    setCreateMode(!createMode);
  }, [createMode]);

  return (
    <ListContainer>
      {isempty(collegeNotes) ? (
        <Box marginLeft="15px">
          <Typography variant="h4">Add your first note</Typography>
        </Box>
      ) : (
        <>
          {collegeNotes.map((note) => (
            <NoteField
              key={note.id}
              note={note}
              updateNote={handleUpdateNote}
              deleteNote={handleDeleteNote}
              collegeId={collegeId}
            />
          ))}
        </>
      )}
      {createMode ? (
        <CreateNoteField
          collegeId={collegeId}
          createNote={handleCreateNote}
          closeForm={toggleCreateMode}
        />
      ) : (
        <Box marginTop="50px">
          <AddButton onClick={toggleCreateMode}>
            <AddIcon fontSize="large" />
          </AddButton>
        </Box>
      )}
    </ListContainer>
  );
}

NotesList.propTypes = {
  collegeNotes: PropTypes.array,
  collegeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default NotesList;
