// ACADEMIC INFORMATION
export const CLASS_MIN_RANK = 1;
export const CLASS_MAX_RANK = 5000;
export const SAT_SECTION_MIN_SCORE = 200;
export const SAT_SECTION_MAX_SCORE = 800;
export const PSAT_SECTION_MIN_SCORE = 160;
export const PSAT_SECTION_MAX_SCORE = 760;
export const ACT_MIN_SCORE = 1;
export const ACT_MAX_SCORE = 36;

// PERSONAL INFORMATION
export const TWITTER_HANDLE_MIN_LENGTH = 4;
export const TWITTER_HANDLE_MAX_LENGTH = 15;
export const INSTAGRAM_HANDLE_MIN_LENGTH = 1;
export const INSTAGRAM_HANDLE_MAX_LENGTH = 30;
export const FACEBOOK_HANDLE_MIN_LENGTH = 5;
export const FACEBOOK_HANDLE_MAX_LENGTH = 50;
