import React from "react";
import { Select } from "components/MaterialComponents";
import { MenuItem } from "components/MaterialComponents";
import PropTypes from "prop-types";

import { StyledFormControl } from "./style";

function CoachesSelect({ label, value, selectOptions, setFieldValue }) {
  return (
    <StyledFormControl>
      <Select
        label={label}
        value={value}
        renderValue={(item) => `${item.name} - ${item.title}`}
        onChange={(e) => setFieldValue("to", e.target.value)}
      >
        {selectOptions.map((option) => (
          <MenuItem key={option.id} value={option}>
            {option.name} - {option.title}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}

CoachesSelect.propTypes = {
  value: PropTypes.object,
  selectOptions: PropTypes.array,
  setFieldValue: PropTypes.func,
};

export default CoachesSelect;
