import React, { useReducer } from "react";

import { NotesStateContext, NotesDispatchContext } from "./Context";
import notesReducer, { initialState } from "./reducer";

function NotesProvider({ children, testState }) {
  const [state, dispatch] = useReducer(notesReducer, initialState);

  return (
    <NotesStateContext.Provider value={testState ? testState : state}>
      <NotesDispatchContext.Provider value={dispatch}>
        {children}
      </NotesDispatchContext.Provider>
    </NotesStateContext.Provider>
  );
}

export default NotesProvider;
