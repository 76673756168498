import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { MailOutline as MailOutlineIcon } from "components/MaterialIcons";
import { ThumbUpAltOutlined as ThumbUpAltOutlinedIcon } from "components/MaterialIcons";
import { ContactMail as ContactMailIcon } from "components/MaterialIcons";
import { Typography } from "components/MaterialComponents";
import { capitalizeEachWord } from "helpers/capitalizeStrings";
import isempty from "lodash.isempty";
import Tooltip from "components/Tooltip";
import ContactAthletesPopover from "components/ContactAthletesPopover";
import getShortName from "helpers/getShortName";
import * as coachDomain from "api/domains/coach";
import useRecruitingProfile, {
  refreshRecruitingProfile,
} from "components/useRecruitingProfile";
import { useProfileState } from "components/useProfile";
import { useMessagesState } from "components/useMessages";
import ContactAthletePopUp from "containers/ContactAthlete";
import ContactParentsPopUp from "containers/ContactParents";
import CoachFavoritePopover from "components/CoachFavoritePopover";
import { userTypesEnum } from "enums";
import AthleteSports from "./AthleteSports";
import {
  CardInfo,
  InfoBlock,
  Details,
  ActionsIconContainer,
  StyledIconButton,
  StyledCard,
  CardContainer,
  LogoContainer,
  StyledAvatar,
  Name,
  InfoContainer,
  StyledLocationIcon,
  StyledSchoolIcon,
} from "./style";

function AthleteCard({
  userId,
  firstName,
  lastName,
  profilePhotoMediaItem,
  city,
  state,
  sports,
  school,
  parents,
  onItemRemoved,
  onItemModified,
}) {
  const [favorite, setFavorite] = useState(null);
  const [mailUserType, setMailUserType] = useState(null);
  const routeParams = useParams();
  const [selectedSport, setSelectedSport] = useState(null);
  const [anchorElMail, setanchorElMail] = useState(null);
  const [isFavoriteModalOpen, setFavoriteModalOpen] = useState(false);
  const [{ profile: recruitingProfile }, dispatch] = useRecruitingProfile();
  const { profile } = useProfileState();

  const isProfileVerified = Boolean(profile?.verified);

  const [isContactModalOpen, setContactModalOpen] = useState(false);
  const [isParentContactModalOpen, setParentContactModalOpen] = useState(false);
  const { coachCurrentThread } = useMessagesState();
  const hasActiveMessages = Object.keys(coachCurrentThread).includes(
    String(userId),
  );

  const coachSports = recruitingProfile?.sports || [];
  const coachFavoriteGroups = recruitingProfile?.favorite_groups || [];

  useEffect(
    function () {
      setFavorite(
        recruitingProfile?.athlete_favorites?.find(
          (favorite) => favorite.athleteId === userId,
        ),
      );
    },
    [recruitingProfile, userId],
  );

  const handleMailPopoverOpen = useCallback((event) => {
    setanchorElMail(event.currentTarget);
    setMailUserType(userTypesEnum.ATHLETE);
  }, []);

  const handleMailPopoverOpenForParent = useCallback((event) => {
    setanchorElMail(event.currentTarget);
    setMailUserType(userTypesEnum.PARENT);
  }, []);

  const handleMailPopoverClose = useCallback(() => {
    setanchorElMail(null);
  }, []);

  const toggleContactModal = useCallback(() => {
    setContactModalOpen(!isContactModalOpen);
  }, [isContactModalOpen]);

  const toggleParentContactModal = useCallback(() => {
    setParentContactModalOpen(!isParentContactModalOpen);
  }, [isParentContactModalOpen]);

  const toggleFaforiteModal = useCallback(() => {
    setFavoriteModalOpen(!isFavoriteModalOpen);
  }, [isFavoriteModalOpen]);

  const handleSelectSport = useCallback(
    (sport) => {
      setSelectedSport(sport);
      handleMailPopoverClose();
      mailUserType === userTypesEnum.ATHLETE && toggleContactModal();
      mailUserType === userTypesEnum.PARENT && toggleParentContactModal();
    },
    [
      handleMailPopoverClose,
      mailUserType,
      toggleContactModal,
      toggleParentContactModal,
    ],
  );

  const id = !!anchorElMail ? "athlete-card-popover" : undefined;

  const handleLike = useCallback(
    async (body) => {
      try {
        const data = {
          ...body,
          athleteId: userId,
        };
        const res = await coachDomain.saveFavorites(data);

        const { athlete_favorites, ...rest } = recruitingProfile;

        await refreshRecruitingProfile(dispatch, {
          ...rest,
          athlete_favorites: [...athlete_favorites, res.favorite],
          favorite_groups: res.groups,
        });

        toggleFaforiteModal();
      } catch (error) {
        dispatch({ type: "update_error", error: error.errorCode });
      }
    },
    [dispatch, recruitingProfile, toggleFaforiteModal, userId],
  );

  const handleModifyLike = useCallback(
    async (body) => {
      try {
        const res = await coachDomain.updateFavorites(body, favorite.id);

        const { athlete_favorites, ...rest } = recruitingProfile;

        await refreshRecruitingProfile(dispatch, {
          ...rest,
          athlete_favorites: res.favorites,
          favorite_groups: res.groups,
        });

        Object.keys(routeParams).includes("groupId") &&
          onItemModified(Date.now());

        toggleFaforiteModal();
      } catch (error) {
        dispatch({ type: "update_error", error: error.errorCode });
      }
    },
    [
      dispatch,
      favorite,
      onItemModified,
      recruitingProfile,
      routeParams,
      toggleFaforiteModal,
    ],
  );

  const unlikeAthlete = useCallback(async () => {
    try {
      const { athlete_favorites, ...rest } = recruitingProfile;

      const favorites = athlete_favorites.filter(
        (el) => Number(el.id) !== Number(favorite.id),
      );

      await coachDomain.removeFavorites(favorite.id);

      Object.keys(routeParams).includes("groupId") &&
        onItemRemoved(favorite.id);

      await refreshRecruitingProfile(dispatch, {
        ...rest,
        athlete_favorites: favorites,
      });
      toggleFaforiteModal();
    } catch (error) {
      dispatch({ type: "update_error", error: error.errorCode });
    }
  }, [
    dispatch,
    favorite,
    routeParams,
    recruitingProfile,
    onItemRemoved,
    toggleFaforiteModal,
  ]);

  const hasMoreThanOneSport = coachSports.length > 1;

  const parentsMailTooltipMessage = isProfileVerified
    ? "Message Parents"
    : "Activate account to send message";
  const mailTooltipMessage = isProfileVerified
    ? "Message Athlete"
    : "Activate account to send message";
  const likeTooltipMessage = isProfileVerified
    ? favorite
      ? "Modify favorites"
      : "Add to favorites"
    : "Activate account to add to favorites";

  const renderLocation = (city = "", state = "") => {
    if (city || state) {
      return (
        <>
          <StyledLocationIcon />
          {capitalizeEachWord(city)}
          {city ? ", " : ""}
          {state.toUpperCase()}
        </>
      );
    }
  };

  const renderSchool = (school) => {
    if (school) {
      return (
        <>
          <StyledSchoolIcon />
          {school.name}
        </>
      );
    }
  };
  return (
    <>
      <StyledCard>
        <CardContainer>
          <CardInfo>
            <LogoContainer>
              <StyledAvatar src={profilePhotoMediaItem}>
                <Typography>
                  {getShortName(`${firstName} ${lastName}`)}
                </Typography>
              </StyledAvatar>
            </LogoContainer>
            <InfoContainer>
              <Name
                to={`/coaches-profile-view/${userId}`}
              >{`${firstName} ${lastName}`}</Name>
              <InfoBlock>{renderLocation(city, state)}</InfoBlock>
              <InfoBlock>{renderSchool(school)}</InfoBlock>
              <Details>
                <AthleteSports sports={sports} />
              </Details>
            </InfoContainer>
          </CardInfo>
        </CardContainer>
        <ActionsIconContainer>
          <Box marginRight="10px" marginBottom="10px">
            {!isempty(parents) && (
              <Tooltip
                title={parentsMailTooltipMessage}
                arrow={true}
                placement="top"
              >
                {isProfileVerified ? (
                  <StyledIconButton
                    onClick={
                      hasMoreThanOneSport
                        ? handleMailPopoverOpenForParent
                        : toggleParentContactModal
                    }
                    $selected={hasActiveMessages}
                    data-testid="email-parent-btn"
                  >
                    <ContactMailIcon color="primary" />
                  </StyledIconButton>
                ) : (
                  <StyledIconButton>
                    <ContactMailIcon color="primary" />
                  </StyledIconButton>
                )}
              </Tooltip>
            )}
            <Tooltip title={mailTooltipMessage} arrow={true} placement="top">
              {isProfileVerified ? (
                <StyledIconButton
                  onClick={
                    hasMoreThanOneSport
                      ? handleMailPopoverOpen
                      : toggleContactModal
                  }
                  $selected={hasActiveMessages}
                  data-testid="email-athlete-btn"
                >
                  <MailOutlineIcon color="primary" />
                </StyledIconButton>
              ) : (
                <StyledIconButton>
                  <MailOutlineIcon color="primary" />
                </StyledIconButton>
              )}
            </Tooltip>
            <Tooltip title={likeTooltipMessage} arrow={true} placement="top">
              {isProfileVerified ? (
                <StyledIconButton
                  onClick={toggleFaforiteModal}
                  $selected={favorite}
                  data-testid="favorite-btn"
                >
                  <ThumbUpAltOutlinedIcon color="primary" />
                </StyledIconButton>
              ) : (
                <StyledIconButton>
                  <ThumbUpAltOutlinedIcon color="primary" />
                </StyledIconButton>
              )}
            </Tooltip>
          </Box>
        </ActionsIconContainer>
      </StyledCard>
      <ContactAthletesPopover
        id={id}
        sports={coachSports}
        open={!!anchorElMail}
        anchorEl={anchorElMail}
        handleClose={handleMailPopoverClose}
        onSportSelect={handleSelectSport}
      />
      <CoachFavoritePopover
        open={isFavoriteModalOpen}
        handleClose={toggleFaforiteModal}
        handleLike={favorite ? handleModifyLike : handleLike}
        handleUnlike={unlikeAthlete}
        favoriteGroups={coachFavoriteGroups}
        favorite={favorite}
      />
      <ContactAthletePopUp
        open={isContactModalOpen}
        onClose={toggleContactModal}
        athleteId={userId}
        athleteName={`${firstName} ${lastName}`}
        sport={selectedSport || coachSports[0]}
      />
      <ContactParentsPopUp
        open={isParentContactModalOpen}
        onClose={toggleParentContactModal}
        parents={parents}
        sport={selectedSport || coachSports[0]}
      />
    </>
  );
}

AthleteCard.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  profilePhotoMediaItem: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  onItemRemoved: PropTypes.func,
  onItemModified: PropTypes.func,
};

export default React.memo(AthleteCard);
