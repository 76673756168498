function Currency({ children, unit = "$" }) {
  if (!children) return null;
  let result = "";
  if (unit) result += unit;

  result += parseFloat(children).toLocaleString();
  return result;
}

export default Currency;
