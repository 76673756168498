import React from "react";
import { HelperText } from "./style";

import { StyledUnorderedList, StyledListItem } from "./style";

function PasswordDescription() {
  return (
    <>
      <HelperText>
        In order to protect your account, make sure your password:
      </HelperText>
      <StyledUnorderedList>
        <StyledListItem>is longer than 7 characters</StyledListItem>
        <StyledListItem>contains one uppercase character</StyledListItem>
        <StyledListItem>one lowercase character</StyledListItem>
        <StyledListItem>
          one number or spesial character (!, @, #, %, &, *)
        </StyledListItem>
      </StyledUnorderedList>
    </>
  );
}

export default PasswordDescription;
