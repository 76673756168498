import React from "react";
import PropTypes from "prop-types";
import YouTube from "react-youtube";
import { Backdrop } from "components/MaterialComponents";
import { Fade } from "components/MaterialComponents";
import { Close as CloseIcon } from "components/MaterialIcons";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";

import videoFormStateEnum from "./videoFormStateEnum";
import { StyledModal, ModalBody, StyledIconBtn } from "./style";

function VideoModal({ currentVideo, open, onClose }) {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const opts = {
    height: matchesSm ? "240" : "480",
    width: matchesSm ? "462" : "854",
  };

  const youtubeState = currentVideo?.type === videoFormStateEnum.youtube;
  const hudlState = currentVideo?.type === videoFormStateEnum.hudl;
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalBody>
          <StyledIconBtn onClick={() => onClose()}>
            <CloseIcon fontSize="large" />
          </StyledIconBtn>
          {youtubeState && (
            <YouTube videoId={currentVideo.playableString} opts={opts} />
          )}
          {hudlState && (
            <iframe
              title="hudl"
              src={currentVideo.playableString}
              frameBorder="0"
              allowFullScreen
              {...opts}
            />
          )}
        </ModalBody>
      </Fade>
    </StyledModal>
  );
}

VideoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentVideo: PropTypes.object,
};

export default VideoModal;
