import React, { useMemo } from "react";
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from "components/MaterialComponents";
import { ThemeProvider } from "styled-components";

import * as colors from "./colors";

function Theme({ children }) {
  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          common: {
            allports: colors.Allports,
            blumine: colors.Blumine,
            trout: colors.Trout,
            jumbo: colors.Jumbo,
            yellowOrange: colors.YellowOrange,
            fern: colors.Fern,
            pictionBlue: colors.PictionBlue, // tmp
            white: colors.White,
            athensGray: colors.AthensGray,
            catskillWhite: colors.CatskillWhite,
            geyser: colors.Geyser,
            brightGrey: colors.BrightGray,
            whisper: colors.Whisper,
            whiteSmoke: colors.Whitesmoke,
            mandysPink: colors.MandysPink,
          },
          primary: {
            main: colors.Allports,
          },
          secondary: {
            main: colors.Red,
          },
          error: {
            main: colors.Red,
          },
          text: {
            primary: colors.TroutLight,
            secondary: colors.Manatee,
          },
        },
        typography: {
          fontFamily: ["'Open Sans'", "Arial", "sans-serif"].join(","),
          h1: {
            fontWeight: "600",
            fontSize: "44px",
            lineHeight: "64px",
          },
          h2: {
            fontWeight: "600",
            fontSize: "32px",
            lineHeight: "48px",
          },
          h3: {
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "32px",
          },
          h4: {
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
          },
          h5: {
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "24px",
          },
          h6: {
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "24px",
          },
          body1: {
            fontWeight: "normal",
            fontSize: "16px",
            lineHeight: "32px",
          },
          body2: {
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "24px",
          },
          caption: {
            fontWeight: "normal",
            fontSize: "12px",
            lineHeight: "16px",
          },
        },
        overrides: {
          MuiButton: {
            root: {
              textTransform: "none",
            },
            text: {
              "& .MuiButton-endIcon": {
                marginLeft: "8px",
              },
            },
          },
          MuiSkeleton: {
            root: {
              borderRadius: "4px",
            },
          },
          MuiAvatar: {
            colorDefault: {
              backgroundColor: colors.Allports,
            },
          },
          MuiCircularProgress: {
            static: {
              color: colors.YellowOrange,
            },
          },
          MuiTextField: {
            root: {
              background: colors.White,
            },
          },
          MuiOutlinedInput: {
            root: {
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "24px",
            },
            input: {
              "&::placeholder": {
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "24px",
              },
            },
            notchedOutline: {
              borderColor: colors.Geyser,
            },
            inputMarginDense: {
              paddingTop: "15px",
              paddingBottom: "15px",
            },
            adornedStart: {
              paddingLeft: "18px",
            },
          },
          MuiPaginationItem: {
            root: {
              background: colors.White,
            },
          },
          MuiSelect: {
            outlined: {
              paddingTop: "15px",
              paddingBottom: "15px",
            },
            icon: {
              color: colors.Allports,
            },
          },
          MuiListItem: {
            button: {
              "&:hover": {
                backgroundColor: colors.Fern,
                color: colors.White,
              },
              "&.Mui-selected:hover": {
                color: "inherit",
              },
            },
          },
        },
        props: {
          MuiSkeleton: {
            animation: "wave",
          },
        },
      }),
    [],
  );
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  );
}

export default Theme;
