import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography } from "components/MaterialComponents";
import {
  ChildrenListContainer,
  ChildrenItemContainer,
  StyledButton,
  StyledDeleteIcon,
} from "./style";

function ChildrenList({ athletes, removeChildren }) {
  const [hoverState, setHoverState] = useState(false);

  return (
    <ChildrenListContainer>
      {athletes.map((athlete) => (
        <ChildrenItemContainer
          key={athlete.userId}
          onMouseEnter={() => setHoverState(true)}
          onMouseLeave={() => setHoverState(false)}
        >
          <Typography variant="h5">{`${athlete.firstName} ${athlete.lastName}`}</Typography>
          {hoverState ? (
            <StyledButton onClick={() => removeChildren(athlete.userId)}>
              <StyledDeleteIcon />
            </StyledButton>
          ) : null}
        </ChildrenItemContainer>
      ))}
    </ChildrenListContainer>
  );
}

ChildrenList.propTypes = {
  removeChildren: PropTypes.func,
  athletes: PropTypes.array,
};

export default ChildrenList;
