function getSportTitle(sportName) {
  switch (sportName) {
    case "Track and Field - Indoor":
    case "Track and Field - Outdoor":
      return "What's your event(s)?";
    default:
      return "What's your position?";
  }
}

function getSportPrimaryInputLabel(sportName) {
  switch (sportName) {
    case "Track and Field - Indoor":
    case "Track and Field - Outdoor":
      return "Primary Event";
    default:
      return "Primary Position";
  }
}

function getSportSecondaryInputLabel(sportName) {
  switch (sportName) {
    case "Track and Field - Indoor":
    case "Track and Field - Outdoor":
      return "Secondary Event";
    default:
      return "Secondary Position";
  }
}

function getSportOtherInputLabel(sportName) {
  switch (sportName) {
    case "Track and Field - Indoor":
    case "Track and Field - Outdoor":
      return "Other Event";
    default:
      return "Other Position";
  }
}

function getSportButtonText(sportName) {
  switch (sportName) {
    case "Track and Field - Indoor":
    case "Track and Field - Outdoor":
      return "event";
    default:
      return "position";
  }
}

export {
  getSportTitle,
  getSportPrimaryInputLabel,
  getSportSecondaryInputLabel,
  getSportOtherInputLabel,
  getSportButtonText,
};
