import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types";
import { MuiPickersUtilsProvider } from "components/MaterialComponents";
import { useField } from "formik";

import { StyledDatePicker } from "./style";

function DatePicker({
  date,
  handleChange,
  label,
  index = 0,
  disabled,
  ...rest
}) {
  const [field, meta] = useField(rest);
  const { value, ...restField } = field;
  const errorText = meta.error && meta.touched ? meta.error : "";

  const error = typeof errorText === "string" ? errorText : null;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StyledDatePicker
        id={`date-picker-dialog${index}`}
        data-testid="date-picker"
        label={label}
        inputVariant="outlined"
        size="small"
        format="MM/dd/yyyy"
        value={date}
        helperText={error}
        disableFuture
        error={Boolean(error)}
        disabled={disabled}
        onChange={handleChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

DatePicker.propTypes = {
  date: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  index: PropTypes.number,
  disabled: PropTypes.bool,
};

export default DatePicker;
