/*
  There are 2 types of sidebar links - with children and without.
  ParentId and nodeId should not be the same.
*/

export const parentRoutes = [
  {
    link: "/profile/personal-information",
    nodeId: "2",
    parentId: "1",
    label: "Personal Information",
  },
  {
    link: "/profile/children-information",
    nodeId: "3",
    parentId: "1",
    label: "Children Information",
  },
  //
  {
    link: "/colleges",
    nodeId: "5",
    parentId: "4",
    label: "All Schools",
  },
  {
    link: "/colleges/reach",
    nodeId: "6",
    parentId: "4",
    label: "Reach Schools",
  },
  {
    link: "/colleges/target",
    nodeId: "7",
    parentId: "4",
    label: "Target Schools",
  },
  {
    link: "/colleges/safety",
    nodeId: "8",
    parentId: "4",
    label: "Safety Schools",
  },
  //
  {
    link: "/contact-activity",
    nodeId: "9",
  },
  {
    link: "/notes",
    nodeId: "10",
  },
];
