import React from "react";
import styled from "styled-components";
import { Typography } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Edit as EditIcon } from "components/MaterialIcons";
import { Delete as DeleteIcon } from "components/MaterialIcons";
import { Link } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import * as colors from "theme/colors";

export const FormContainer = styled(Box)`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 600px;
    margin-top: 60px;

    ${theme.breakpoints.down("sm")} {
      max-width: 400px;
      height: 100%;
      width: 100%;
      margin-top: 70px;
    }
  `}
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const ListItemContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 40px;

    ${theme.breakpoints.down("sm")} {
      padding: 10px;
    }
  `}
`;

export const GeneralCard = styled.div`
  ${({ theme }) => `
    width: 80%;
    margin: 20px auto;
    border-color: white;
    border-radius: 15px;
    border: 1px solid ${colors.AthensGray};
    background-color: white;

    ${theme.breakpoints.down("sm")} {
      width: 100%;
    }
  `}
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListItemCard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 2px solid ${colors.AthensGray};
`;

export const ButtonsGroup = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: flex-start;
    justify-content: center;

    ${theme.breakpoints.down("sm")} {
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
    }
  `}
`;

export const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 5%;
  background-color: ${(props) =>
    props.$isHover ? colors.Fern : colors.Allports};
  transition: all 0.1s;
`;

export const StyledLogoSmall = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 5%;
  background-color: ${(props) =>
    props.$isHover ? colors.Fern : colors.Allports};
  transition: all 0.3s;
`;

export const LogoContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    width: 20%;

    ${theme.breakpoints.down("sm")} {
      flex-direction: column;
      justify-content: space-between;
    }
  `}
`;

export const StyledTitle = styled(Link)`
  font-size: 16px;
  text-transform: uppercase;
  color: ${colors.Allports};
  line-height: 24px;
  text-decoration: none;
`;

export const StyledSubtitle1 = styled(Typography)`
  line-height: 24px;
  color: ${colors.BrightGray};
`;

export const SubtitleSportPositions = styled(Typography)`
  margin-right: 20px;
  line-height: 24px;
  color: ${colors.BrightGray};
`;

export const StyledSubtitle2 = styled(Typography)`
  color: ${colors.Manatee};
  line-height: 24px;
`;

export const StyledSubtitleStats = styled(Typography)`
  text-transform: uppercase;
  color: ${colors.Manatee};
  line-height: 24px;
`;

export const StyledCaption = styled(Typography)`
  color: ${colors.BrightGray};
  text-transform: capitalize;
  margin-top: 20px;
  font-size: 14px;
`;

export const CardHeader = styled.div`
  padding: 10px 30px;
`;

export const AddButton = styled(Button)`
  text-transform: uppercase;
  color: ${colors.Allports};
  font-size: 18px;
`;

export const StyledButton = styled.div`
  margin-right: 10px;
  cursor: pointer;
`;

export const StyledEditeIcon = styled(EditIcon)`
  font-size: 30px;
  :hover {
    color: ${colors.Allports};
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  font-size: 30px;
  :hover {
    color: ${colors.Red};
  }
`;

export const StyledTextFieldContainer = styled.div`
  width: 100%;
`;

export const FormModalHeader = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: 0;
    width: 100%;
    padding: 15px 40px;
    box-sizing: border-box;
    border-bottom: 2px solid ${colors.AthensGray};

    ${theme.breakpoints.down("sm")} {
      position: fixed;
      top: 7px;
      padding-top: 20px;
      background-color: white;
      z-index: 5;
    }
  `}
`;

export const DatesBlock = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    ${theme.breakpoints.down("sm")} {
      flex-direction: column;
    }
  `}
`;

export const FieldsBlock = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;

    ${theme.breakpoints.down("sm")} {
      flex-direction: column;
    }
  `}
`;

export const FieldContainer = styled.div`
  ${({ theme }) => `
    width: 48%;

    ${theme.breakpoints.down("sm")} {
      width: 100%
    }
  `}
`;

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const StyledHeader = styled((props) => (
  <Typography variant="h3" {...props} />
))`
  ${({ theme }) => `
    margin: 20px 10%;
    color: ${colors.BrightGray};

    ${theme.breakpoints.down("sm")} {
      text-align: center;
  }
  `}
`;

export const IconContainer = styled.div`
  width: 70%;
  height: 70%;
`;
