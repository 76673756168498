import React, { useCallback } from "react";
import { Checkbox } from "components/MaterialComponents";

function InStateCostFilter({ filter, onChange }) {
  const { useInStateCost } = filter;
  const handleOnChange = useCallback(
    () => onChange({ ...filter, useInStateCost: !useInStateCost }),
    [onChange, filter, useInStateCost],
  );
  return (
    <Checkbox checked={Boolean(useInStateCost)} onChange={handleOnChange} />
  );
}

export default InStateCostFilter;
