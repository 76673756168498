import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import GeneralCard from "components/GeneralCard";

import {
  StatsCardContainer,
  StudentStatContainer,
  StudentStatName,
  SportCardContainer,
  SportPositionTitle,
  SportStatContainer,
  SportStatValue,
} from "./style";

function Stats({ data, inchesToFtInches }) {
  return (
    <GeneralCard header="Stats">
      <StatsCardContainer>
        <StudentStatContainer>
          <StudentStatName>Height:</StudentStatName>
          <Typography variant="h5">
            {inchesToFtInches(data.heightInInches)}
          </Typography>
        </StudentStatContainer>
        <StudentStatContainer>
          <StudentStatName>Weight:</StudentStatName>
          <Typography variant="h5">{data.weightInPounds}lbs</Typography>
        </StudentStatContainer>
      </StatsCardContainer>

      {data.sports &&
        Object.values(data.sports).map((sport) => (
          <SportCardContainer key={sport.id}>
            <Typography variant="h4">{sport.name}</Typography>
            <Box display="flex" marginTop="10px">
              {sport.sportPositions?.map((position) => (
                <SportPositionTitle key={position.id}>
                  {position.name}
                </SportPositionTitle>
              ))}
            </Box>
            <Box>
              {sport.sportStatsDefinitions?.map((stat) => {
                return (
                  <StatsCardContainer key={stat.id}>
                    <SportStatContainer>
                      <StudentStatName>{stat.name}:</StudentStatName>
                      <SportStatValue>
                        {stat.sportStatsAnswers.valueText}
                      </SportStatValue>
                    </SportStatContainer>
                  </StatsCardContainer>
                );
              })}
            </Box>
          </SportCardContainer>
        ))}
    </GeneralCard>
  );
}

Stats.propTypes = {
  data: PropTypes.array,
  inchesToFtInches: PropTypes.func,
};

export default Stats;
