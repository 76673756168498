import { useContext } from "react";

import RecruitingProfileProvider from "./RecruitingProfileProvider";
import { recruitingProfileFeedbacks } from "./recruitingProfileFeedbacks";
import {
  RecruitingProfileStateContext,
  RecruitingProfileDispatchContext,
} from "./Context";
import {
  loadRecruitingProfile,
  createRecruitingProfile,
  updateRecruitingProfile,
  refreshRecruitingProfile,
  uploadProfilePhoto,
  deleteProfilePhoto,
  resetRecruitingProfile,
} from "./athleteActions";

import {
  createCoachProfile,
  loadCoachProfile,
  updateCoachProfile,
  uploadCoachProfilePhoto,
  deleteCoachProfilePhoto,
} from "./coachActions";

import {
  loadParentProfile,
  createParentProfile,
  updateParentProfile,
  uploadParentProfilePhoto,
  deleteParentProfilePhoto,
  addChildrenToParentProfile,
  removeChildrenFromParentProfile,
} from "./parentActions";

function useRecruitingProfileState() {
  const context = useContext(RecruitingProfileStateContext);
  if (context === undefined) {
    throw new Error(
      "useRecruitingProfileState must be used within a RecruitingProfileProvider",
    );
  }
  return context;
}
function useRecruitingProfileDispatch() {
  const context = useContext(RecruitingProfileDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useRecruitingProfileDispatch must be used within a RecruitingProfileProvider",
    );
  }
  return context;
}

function useRecruitingProfile() {
  return [useRecruitingProfileState(), useRecruitingProfileDispatch()];
}

export default useRecruitingProfile;

export {
  RecruitingProfileProvider,
  useRecruitingProfileState,
  useRecruitingProfileDispatch,
  loadRecruitingProfile,
  createRecruitingProfile,
  uploadProfilePhoto,
  deleteProfilePhoto,
  updateRecruitingProfile,
  refreshRecruitingProfile,
  resetRecruitingProfile,
  recruitingProfileFeedbacks,
  createCoachProfile,
  loadCoachProfile,
  updateCoachProfile,
  uploadCoachProfilePhoto,
  deleteCoachProfilePhoto,
  loadParentProfile,
  createParentProfile,
  updateParentProfile,
  uploadParentProfilePhoto,
  deleteParentProfilePhoto,
  addChildrenToParentProfile,
  removeChildrenFromParentProfile,
  RecruitingProfileStateContext,
  RecruitingProfileDispatchContext,
};
