import genderType from "types/gender";

const getSportListByGender = (gender, sportList = []) => {
  if (!gender) return [];

  if (gender === genderType.MALE) {
    return sportList.filter((sport) => sport.gender === "Men");
  }
  if (gender === genderType.FEMALE) {
    return sportList.filter((sport) => sport.gender === "Women");
  }
};

export default getSportListByGender;
