import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { StyledSportStatTextField } from "./style";
import {
  DECIMAL_1,
  DISTANCE,
  INT,
  TIME_MIN_SEC_MS,
  TIME_SEC_MS,
  DECIMAL_3,
  DECIMAL_4,
} from "constants/sports";
import {
  DECIMAL_1_REGEX,
  DISTANCE_REGEX,
  INTEGER_REGEX,
  TIME_MIN_SEC_MS_REGEX,
  TIME_SEC_MS_REGEX,
  DECIMAL_3_REGEX,
  DECIMAL_4_REGEX,
} from "constants/regExps";

function SportStatTextField({
  inputType,
  value,
  prompt,
  setFieldValue,
  isDisabled,
}) {
  const placeholder = prompt && prompt.toString();

  const convertSS = (duration) => {
    let hs = parseInt(duration % 100),
      seconds = parseInt((duration / 100) % 60),
      minutes = parseInt((duration / (100 * 60)) % 60);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    hs = hs < 10 ? "0" + hs : hs;

    return minutes + ":" + seconds + "." + hs;
  };

  const getSSfromTime = useCallback(
    (timeString) => {
      if (timeString === "") {
        return "";
      }
      let min = 0;
      let secSS = 0;

      const minArray = timeString.split(":");

      //if only Min are entered
      if (minArray.length === 1) {
        if (timeString.includes(".")) {
          //e.g. 78.77 -- assume 78sec 77ss
          secSS = Number(value) * 100;
        } else {
          //e.g. 5 -- assume 5 mins
          min = Number(value);
        }
      } else {
        min = Number(minArray[0]);
        secSS = Number(minArray[1]) * 100;
      }
      return min * 60 * 100 + secSS;
    },
    [value],
  );

  const getValInt = useCallback(
    (value) => {
      switch (inputType) {
        case DISTANCE:
          const { groups: { feet, inches } = {} } =
            value.match(DISTANCE_REGEX) ?? {};
          return feet || inches
            ? 12 * (parseInt(feet) || 0) + (parseInt(inches) || 0)
            : null;

        case TIME_SEC_MS:
          return TIME_SEC_MS_REGEX.test(value) ? Number(value) * 100 : null;

        case TIME_MIN_SEC_MS:
          return TIME_MIN_SEC_MS_REGEX.test(value)
            ? getSSfromTime(value)
            : null;

        case DECIMAL_1:
          return DECIMAL_1_REGEX.test(value) ? Number(value) * 10 : null;

        case DECIMAL_3:
          return DECIMAL_3_REGEX.test(value) ? Number(value) * 1000 : null;

        case DECIMAL_4:
          return DECIMAL_4_REGEX.test(value) ? Number(value) * 10000 : null;

        case INT:
          return INTEGER_REGEX.test(value) ? parseInt(value) : null;

        default:
          return null;
      }
    },
    [inputType, getSSfromTime],
  );

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const valInt = value && getValInt(value);

      if (value && valInt === null) {
        return;
      }

      setFieldValue(valInt, value);
    },
    [getValInt, setFieldValue],
  );

  const handleBlur = useCallback(
    ({ target: { value } }) => {
      if (inputType === TIME_MIN_SEC_MS) {
        const ss = getSSfromTime(value);
        if (ss === "") {
          setFieldValue(null, null);
        } else {
          setFieldValue(getValInt(value), convertSS(ss));
        }
      }
    },
    [getSSfromTime, getValInt, inputType, setFieldValue],
  );

  return (
    <StyledSportStatTextField
      size="medium"
      placeholder={placeholder}
      type="text"
      value={value || ""}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={isDisabled}
    />
  );
}

SportStatTextField.propTypes = {
  label: PropTypes.string,
  inputType: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setFieldValue: PropTypes.func,
  prompt: PropTypes.any,
  isDisabled: PropTypes.bool,
};

export default SportStatTextField;
