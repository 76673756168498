import React from "react";
import PropTypes from "prop-types";
import { InputLabel } from "components/MaterialComponents";
import { Select } from "components/MaterialComponents";
import { MenuItem } from "components/MaterialComponents";

import getFutureYears from "helpers/getFutureYears";
import { StyledFormControl } from "./style";

function GraduationSelect({ hsGradYear, handleChange }) {
  const fiveFutureYears = getFutureYears(5);

  return (
    <StyledFormControl>
      <InputLabel id="graduation-labe">Graduation Year</InputLabel>
      <Select
        labelId="graduation-label"
        value={hsGradYear}
        onChange={handleChange}
        label="Graduation Year"
      >
        {fiveFutureYears.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}

GraduationSelect.propTypes = {
  hsGradYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChange: PropTypes.func.isRequired,
};

export default GraduationSelect;
