import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Formik, Form, FieldArray } from "formik";
import Modal from "components/Modal";
import CustomTextField from "components/CustomTextField";

import { ModalHeader, ModalFooter } from "./style";

const allowedFields = [
  "name",
  "title",
  "phoneNumber",
  "email",
  "selector",
  "pageUrl",
  "schoolName",
  "sport",
  "gender",
  "unitSport",
];

function CreateMultipleCoachesPopup({
  open,
  onClose,
  csvData,
  onCreate,
  loadingState,
}) {
  const fileHeader = csvData[0] ? Object.keys(csvData[0]) : [];

  const handleFormSubmit = useCallback(
    async (values) => {
      await onCreate(values);
      onClose();
    },
    [onClose, onCreate],
  );

  return (
    <Modal open={open} onClose={onClose} fluid={true} showCloseIcon={false}>
      <Box width="95%">
        <>
          <ModalHeader>
            {fileHeader.map((field) => (
              <>
                {allowedFields.includes(field) && (
                  <Box width="10%" padding="20px 0">
                    {field}
                  </Box>
                )}
              </>
            ))}
          </ModalHeader>
        </>
        <>
          <Formik
            validateOnChange
            initialValues={{
              fields: csvData,
            }}
            onSubmit={handleFormSubmit}
          >
            {({ values }) => (
              <Form>
                <Box display="flex" flexDirection="column">
                  <FieldArray name="fields">
                    {() => (
                      <Box display="flex" flexDirection="column">
                        {values.fields.map((row, index) => (
                          <Box display="flex">
                            {Object.keys(row).map((fieldKey) => (
                              <>
                                {allowedFields.includes(fieldKey) && (
                                  <CustomTextField
                                    name={`fields[${index}][${fieldKey}]`}
                                  />
                                )}
                              </>
                            ))}
                          </Box>
                        ))}
                      </Box>
                    )}
                  </FieldArray>
                  <ModalFooter>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={loadingState}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={onClose}
                      variant="outlined"
                      color="secondary"
                      size="large"
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      </Box>
    </Modal>
  );
}

CreateMultipleCoachesPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  csvData: PropTypes.array,
  onCreate: PropTypes.func.isRequired,
  loadingState: PropTypes.bool,
};

export default CreateMultipleCoachesPopup;
