import React from "react";
import { Skeleton } from "components/MaterialComponents";
import { Form, Formik } from "formik";
import * as yup from "yup";

import ContactMethodSelect from "./ContactMethodSelect";
import GeneralCard from "components/GeneralCard";
import CustomTextField from "components/CustomTextField";
import Feedbacker from "components/Feedbacker";
import { Title } from "components/Typography/";
import { SuccessButton } from "components/Buttons";
import useRecruitingProfile, {
  recruitingProfileFeedbacks,
  updateRecruitingProfile,
} from "components/useRecruitingProfile";
import * as validationHelper from "helpers/validationHelper";
import * as actionTypes from "components/useRecruitingProfile/actionTypes";

import { ContactInfoFormContainer } from "./style";
import { CardContainer } from "../style";

const validationSchema = yup.object().shape({
  firstParentFirstName: validationHelper.firstName.required(
    "Field is required",
  ),
  firstParentLastName: validationHelper.lastName.required("Field is required"),
  phone: validationHelper.phone,
  firstParentPhone: validationHelper.phone,
  secondParentPhone: validationHelper.phone,
  firstParentEmail: validationHelper.email.nullable(),
  secondParentEmail: validationHelper.email.nullable(),
});

function ContactInformation() {
  const [{ profile, feedback, loading }, dispatch] = useRecruitingProfile();

  if (loading) {
    return (
      <Skeleton variant="text" width="100%" style={{ marginTop: "2em" }} />
    );
  }

  const {
    firstParentFirstName,
    firstParentLastName,
    firstParentIsAdmin,
    firstParentContactBy,
    firstParentEmail,
    firstParentPhone,
    secondParentFirstName,
    secondParentLastName,
    secondParentIsAdmin,
    secondParentContactBy,
    secondParentEmail,
    secondParentPhone,
    phone,
    address1,
    address2,
    city,
    state,
    zip,
    ...restProfile
  } = profile;

  const handleFormSubmit = async (data, { setSubmitting }) => {
    const sportPositions = Object.values(profile.sports)
      .flatMap((sport) => sport.sportPositions)
      .filter(Boolean);

    const {
      firstParentFirstName,
      firstParentLastName,
      firstParentIsAdmin,
      firstParentContactBy,
      firstParentEmail,
      firstParentPhone,
      secondParentFirstName,
      secondParentLastName,
      secondParentIsAdmin,
      secondParentContactBy,
      secondParentEmail,
      secondParentPhone,
      phone,
      address1,
      address2,
      city,
      state,
      zip,
      ...restData
    } = data;

    const dataToSend = {
      firstParentFirstName,
      firstParentLastName,
      firstParentIsAdmin,
      firstParentContactBy,
      firstParentEmail,
      firstParentPhone,
      secondParentFirstName,
      secondParentLastName,
      secondParentIsAdmin,
      secondParentContactBy,
      secondParentEmail,
      secondParentPhone,
      phone,
      address1,
      address2,
      city,
      state,
      zip,
      ...restData,
      ...restProfile,
      sportPositions,
    };

    await updateRecruitingProfile(dispatch, profile.userId, dataToSend);
    setSubmitting(false);
  };

  return (
    <CardContainer>
      <GeneralCard header="Contact Information">
        <Formik
          validateOnChange
          validateOnBlur
          initialValues={{
            phone,
            address1,
            address2,
            city,
            state,
            zip,
            firstParentFirstName,
            firstParentLastName,
            firstParentIsAdmin,
            firstParentContactBy,
            firstParentEmail,
            firstParentPhone,
            secondParentFirstName,
            secondParentLastName,
            secondParentIsAdmin,
            secondParentContactBy,
            secondParentEmail,
            secondParentPhone,
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <ContactInfoFormContainer>
                <CustomTextField label="Phone" name="phone" type="text" />
                <CustomTextField
                  label="Street Address"
                  name="address1"
                  type="text"
                />
                <CustomTextField
                  label="Street Address 2"
                  name="address2"
                  type="text"
                />
                <CustomTextField label="City" name="city" type="text" />
                <CustomTextField label="State Abbr" name="state" type="text" />
                <CustomTextField label="Zip" name="zip" type="text" />

                <Title mb="1.5em">Parent/Guardian #1</Title>
                <CustomTextField
                  label="First Name"
                  name="firstParentFirstName"
                  type="text"
                />
                <CustomTextField
                  label="Last Name"
                  name="firstParentLastName"
                  type="text"
                />
                <CustomTextField
                  label="Phone"
                  name="firstParentPhone"
                  type="text"
                  placeholder="555-123-4567"
                />
                <CustomTextField
                  label="Email"
                  name="firstParentEmail"
                  type="text"
                />
                <ContactMethodSelect
                  labelId="contact-first-parent"
                  label="Contact method"
                  name="firstParentContactBy"
                  value={values.firstParentContactBy}
                  setFieldValue={setFieldValue}
                />

                <Title mb="1.5em">Parent/Guardian #2</Title>
                <CustomTextField
                  label="First Name"
                  name="secondParentFirstName"
                  type="text"
                />
                <CustomTextField
                  label="Last Name"
                  name="secondParentLastName"
                  type="text"
                />
                <CustomTextField
                  label="Phone"
                  name="secondParentPhone"
                  type="text"
                  placeholder="555-123-4567"
                />
                <CustomTextField
                  label="Email"
                  name="secondParentEmail"
                  type="text"
                />
                <ContactMethodSelect
                  labelId="contact-second-parent"
                  label="Contact method"
                  name="secondParentContactBy"
                  value={values.secondParentContactBy}
                  setFieldValue={setFieldValue}
                />

                <SuccessButton disabled={isSubmitting} type="submit">
                  Save
                </SuccessButton>
              </ContactInfoFormContainer>
            </Form>
          )}
        </Formik>
      </GeneralCard>
      <Feedbacker
        open={feedback === recruitingProfileFeedbacks.Updated}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage={feedback}
        clearFeedback={() => dispatch({ type: actionTypes.CLEAR_FEEDBACK })}
      />
    </CardContainer>
  );
}

export default ContactInformation;
