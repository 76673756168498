import videoFormStateEnum from "containers/Profile/HighlightReel/videoFormStateEnum";

const getVideoPreviewImg = (url) => {
  const id = extractYoutubeVideoId(url);
  return id && `https://img.youtube.com/vi/${id}/sddefault.jpg`;
};

const extractYoutubeVideoId = (url) => {
  if (url.includes('youtube.com')) {
    const queryParamsIndex = url.indexOf("?");
    const searchParams = url.substring(queryParamsIndex + 1);
    const urlParams = new URLSearchParams(searchParams);
    return urlParams.get("v");
  } else if (url.includes('youtu.be')) {
    const idIndex = url.lastIndexOf("/");
    return url.substr(idIndex+1, url.length);
  }
};

//https://www.hudl.com/video/3/6128109/5f620824bee1d00480c51a03
//http://www.hudl.com/v/2EDeJF

const getHudlPlayableUrl = (url) => {
  const videoId = url.split("video")[1];
  return `//www.hudl.com/embed/video${videoId}`;
};

const getPlayableString = (url, type) => {
  switch (type) {
    case videoFormStateEnum.youtube:
      return extractYoutubeVideoId(url);
    case videoFormStateEnum.hudl:
      return getHudlPlayableUrl(url);
    default:
      return "";
  }
};

const getVideoType = (url) => {
  return (url.includes("youtube") || url.includes("youtu.be"))
    ? videoFormStateEnum.youtube
    : videoFormStateEnum.hudl;
};

export {
  getVideoPreviewImg,
  extractYoutubeVideoId,
  getPlayableString,
  getVideoType,
};
