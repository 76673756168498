import React from "react";
import PropTypes from "prop-types";
import { InputLabel } from "components/MaterialComponents";
import { Select } from "components/MaterialComponents";
import { MenuItem } from "components/MaterialComponents";

import { ContactMethodFormControl } from "./style";

function ContactMethodSelect({ labelId, label, value, setFieldValue, name }) {
  return (
    <ContactMethodFormControl>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        label={label}
        value={value || ""}
        onChange={(e) => setFieldValue(name, e.target.value)}
      >
        {["Email", "Phone"].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </ContactMethodFormControl>
  );
}

ContactMethodSelect.propTypes = {
  labelId: PropTypes.string,
  label: PropTypes.string,
  index: PropTypes.number,
  value: PropTypes.string,
  name: PropTypes.string,
  setFieldValue: PropTypes.func,
};

export default ContactMethodSelect;
