/*
  There are 2 types of sidebar links - with children and without.
  ParentId and nodeId should not be the same.
*/

export const coachRoutes = [
  {
    link: "/profile/personal-information",
    nodeId: "2",
  },
  {
    link: "/athletes",
    nodeId: "3",
  },
  {
    link: "/athletes/favorite",
    nodeId: "4",
  },
  {
    link: "/contact-activity",
    nodeId: "5",
  },
];
