import React from "react";
import PropTypes from "prop-types";
import useSports from "components/useSports";
import { Formik } from "formik";
import { getOptionsFromSports } from "helpers/coachProfileSelectHelpers";
import SelectInput from "components/Select";
import { SportFormContainer, SportFilterContainer } from "./style";

function SportSelectForm({ onGenderChange, onSportChange, filter }) {
  const { data: sportList, loading: sportsLoading } = useSports();
  const { sportId = "" } = filter;
  const selectedSport = sportList?.find(
    (el) => Number(el.id) === Number(sportId),
  );

  const sportValue = selectedSport
    ? {
        value: selectedSport.id,
        label: `${selectedSport.name}(${selectedSport.gender})`,
      }
    : null;

  return (
    <SportFilterContainer>
      <Formik>
        <SportFormContainer>
          <SelectInput
            placeholder="Select sport"
            name="sport"
            isDisabled={sportsLoading}
            options={getOptionsFromSports(sportList)}
            onChange={onSportChange}
            value={sportValue}
          />
        </SportFormContainer>
      </Formik>
    </SportFilterContainer>
  );
}

SportSelectForm.propTypes = {
  onGenderChange: PropTypes.func,
  onSportChange: PropTypes.func,
  filter: PropTypes.object,
};

export default SportSelectForm;
