import { useContext } from "react";
import Context from "./Context";
import SportsProvider from "./SportsProvider";

function useSports() {
  const { mensSports, womensSports, data, loading } = useContext(Context);

  return {
    loading,
    mensSports,
    womensSports,
    data,
  };
}

export default useSports;

export { SportsProvider };
