import React from "react";
import PropTypes from "prop-types";
import { Typography } from "components/MaterialComponents";
import Tooltip from "components/Tooltip";
import LargeProfileAvatar from "components/LargeProfileAvatar";
import { MailOutline as MailOutlineIcon } from "components/MaterialIcons";

import {
  StudentStatsGridContainer,
  StudentStatsGridItem,
  StudentInfoGridItem,
  StyledCard,
  CardGridContainer,
  LogoGridItem,
  StyledIconButton,
  StudentName,
  StudentSport,
  StudentStatContainer,
  StudentStatName,
  IconGridItem,
} from "./style";

function Profilecard({
  data,
  inchesToFtInches,
  calcAgeFromDOB,
  isCoach,
  hasMoreThanOneSport,
  handleMailPopoverOpen,
  toggleContactModal,
  hasActiveMessages,
  isProfileVerified,
}) {
  const mailTooltipMessage = isProfileVerified
    ? "Message Athlete"
    : "Activate account to send message";
  return (
    <StyledCard>
      <CardGridContainer container>
        <LogoGridItem item>
          <LargeProfileAvatar
            withBorder
            name={data.firstName + " " + data.lastName}
            image={data.profileImg}
          />
        </LogoGridItem>
        <StudentInfoGridItem item>
          <StudentName>
            {data.firstName} {data.lastName} - {data.hsGradYear}
          </StudentName>
          <StudentSport>
            {data.sports &&
              Object.values(data.sports).map((row) => {
                return row.sportPositions ? (
                  <div key={row.id}>
                    {row.name} &#40;
                    {row.sportPositions.map((pos, index, arr) => (
                      <span key={index}>
                        {pos.name}
                        {index === arr.length - 1 ? "" : ", "}
                      </span>
                    ))}
                    &#41;
                  </div>
                ) : (
                  <div key={row.id}>{row.name}</div>
                );
              })}
          </StudentSport>

          <StudentStatsGridContainer container>
            <StudentStatsGridItem item>
              <StudentStatContainer>
                <StudentStatName>Height:</StudentStatName>
                <Typography variant="h5">
                  {inchesToFtInches(data.heightInInches)}
                </Typography>
              </StudentStatContainer>
              <StudentStatContainer>
                <StudentStatName>Weight:</StudentStatName>
                <Typography variant="h5">{data.weightInPounds}lbs</Typography>
              </StudentStatContainer>
            </StudentStatsGridItem>
            <StudentStatsGridItem item>
              <StudentStatContainer>
                <StudentStatName>Age:</StudentStatName>
                <Typography variant="h5">
                  {data.dateOfBirth ? calcAgeFromDOB(data.dateOfBirth) : ""}
                </Typography>
              </StudentStatContainer>
              <StudentStatContainer>
                <StudentStatName>Home town:</StudentStatName>
                <Typography variant="h5">
                  {data.city}, {data.state}
                </Typography>
              </StudentStatContainer>
            </StudentStatsGridItem>
          </StudentStatsGridContainer>
        </StudentInfoGridItem>
        {isCoach && (
          <IconGridItem item>
            <Tooltip title={mailTooltipMessage} arrow={true} placement="top">
              {isProfileVerified ? (
                <StyledIconButton
                  onClick={
                    hasMoreThanOneSport
                      ? handleMailPopoverOpen
                      : toggleContactModal
                  }
                  selected={hasActiveMessages}
                >
                  <MailOutlineIcon color="primary" />
                </StyledIconButton>
              ) : (
                <StyledIconButton>
                  <MailOutlineIcon color="primary" />
                </StyledIconButton>
              )}
            </Tooltip>
          </IconGridItem>
        )}
      </CardGridContainer>
    </StyledCard>
  );
}

Profilecard.propTypes = {
  data: PropTypes.array,
  inchesToFtInches: PropTypes.func,
  calcAgeFromDOB: PropTypes.func,
  isCoach: PropTypes.bool,
  hasMoreThanOneSport: PropTypes.bool,
  handleMailPopoverOpen: PropTypes.func,
  toggleContactModal: PropTypes.func,
  hasActiveMessages: PropTypes.bool,
  isProfileVerified: PropTypes.bool,
};

export default Profilecard;
