import React, { useEffect, useState, useCallback } from "react";
import { Skeleton } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { Grid } from "components/MaterialComponents";
import { Formik, Form } from "formik";
import * as yup from "yup";
import debounce from "lodash.debounce";

import Feedbacker from "components/Feedbacker";
import GeneralCard from "components/GeneralCard";
import CustomTextField from "components/CustomTextField";
import FormikSwitch from "components/Formik/Switch";
import { SuccessButton } from "components/Buttons";
import SelectInput from "components/Select";
import useClearFeedback from "customHooks/useClearFeedback";
import * as validationHelper from "helpers/validationHelper";
import useRecruitingProfile, {
  updateRecruitingProfile,
  recruitingProfileFeedbacks,
} from "components/useRecruitingProfile";
import AcademicTextArea from "./AcademicTextArea";
import { Search } from "components/MaterialIcons";
import * as actionTypes from "components/useRecruitingProfile/actionTypes";
import { CardContainer } from "../style";
import {
  AcademicInfoFormContainer,
  AcademicInfoInputContainer,
  SmallTextFieldContainer,
  MediumTextFieldContainer,
} from "./style";
import {
  ACT_MIN_SCORE,
  ACT_MAX_SCORE,
  CLASS_MIN_RANK,
  CLASS_MAX_RANK,
  PSAT_SECTION_MIN_SCORE,
  PSAT_SECTION_MAX_SCORE,
  SAT_SECTION_MIN_SCORE,
  SAT_SECTION_MAX_SCORE,
} from "../../../constants/allowedFormValues";
import { loadCitiesByState } from "api/domains/user";
import useStates from "components/useStates";
import useSchools from "./useSchools";
import {
  getOptionsFromStates,
  getSchoolValues,
  getOptionsFromSearchCity,
} from "./selectHelpers";

const validationSchema = yup.object().shape({
  gpa: validationHelper.gpa,
  gpaWeighted: yup.boolean().nullable(),
  satMath: validationHelper.satSection,
  satVerbal: validationHelper.satSection,
  psatMath: validationHelper.satSection,
  psatVerbal: validationHelper.satSection,
  actMath: validationHelper.act,
  classRank: validationHelper.classRank,
  classRankOutOf: validationHelper.maxRank,
  school: yup.object().nullable(),
});

function AcademicInformation() {
  const [
    { profile, feedback, loading, error },
    dispatch,
  ] = useRecruitingProfile();

  const {
    gpa,
    gpaWeighted,
    satMath,
    satVerbal,
    psatMath,
    psatVerbal,
    actMath,
    personalStatement,
    classRank,
    classRankOutOf,
    schoolId,
    school,
    ...restProfile
  } = profile || {};

  const [stateValue, setStateValue] = useState();
  const [city, setCity] = useState();
  const schoolOptions = useSchools(stateValue?.value, city?.value);
  useClearFeedback(dispatch);

  const { states } = useStates();
  const stateOptions = getOptionsFromStates(states);

  const handleFormSubmit = useCallback(
    async (data, { setSubmitting }) => {
      const sportPositions = Object.values(profile.sports)
        .flatMap((sport) => sport.sportPositions)
        .filter(Boolean);

      const { gpaOutOf, school, ...restData } = data;

      const dataToSend = {
        schoolId: school ? school.value : "",
        ...restData,
        ...restProfile,
        sportPositions,
      };
      await updateRecruitingProfile(dispatch, profile.userId, dataToSend);
      setSubmitting(false);
    },
    [dispatch, profile, restProfile],
  );

  useEffect(() => {
    setStateValue(getSchoolValues(school).state);
    setCity(getSchoolValues(school).city);
  }, [school]);

  const loadOptionsCity = debounce((value, callback) => {
    loadCitiesByState(stateValue.value, value)
      .then((res) => getOptionsFromSearchCity(res))
      .then((res) => callback(res))
      .catch((e) => callback([]));
  }, 250);

  if (loading) {
    return (
      <Skeleton variant="text" width="100%" style={{ marginTop: "2em" }} />
    );
  }

  return (
    <CardContainer>
      <GeneralCard header="Academic Information">
        <Formik
          validateOnChange
          initialValues={{
            gpa,
            gpaWeighted: !!gpaWeighted,
            satMath,
            satVerbal,
            psatMath,
            psatVerbal,
            actMath,
            personalStatement,
            gpaOutOf: "",
            classRank,
            classRankOutOf,
            school: getSchoolValues(school).school,
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, setFieldValue, handleChange, isSubmitting }) => (
            <Form>
              <AcademicInfoFormContainer>
                <AcademicInfoInputContainer>
                  <SmallTextFieldContainer mr="2em">
                    <CustomTextField
                      label="GPA"
                      name="gpa"
                      type="number"
                      inputProps={{ step: 0.1, min: 0, max: 5 }}
                    />
                  </SmallTextFieldContainer>
                  <Box ml="1em" mb="2em">
                    <FormikSwitch
                      checked={values.gpaWeighted}
                      setFieldValue={setFieldValue}
                      name="gpaWeighted"
                      label="Weighted"
                    />
                  </Box>
                </AcademicInfoInputContainer>
                <AcademicInfoInputContainer>
                  <MediumTextFieldContainer mr="2em">
                    <CustomTextField
                      label="Class Rank"
                      name="classRank"
                      type="number"
                      inputProps={{
                        step: 1,
                        min: CLASS_MIN_RANK,
                        max: classRankOutOf,
                      }}
                    />
                  </MediumTextFieldContainer>
                  <MediumTextFieldContainer>
                    <CustomTextField
                      label="Out Of"
                      name="classRankOutOf"
                      type="number"
                      inputProps={{
                        step: 1,
                        min: CLASS_MIN_RANK,
                        max: CLASS_MAX_RANK,
                      }}
                    />
                  </MediumTextFieldContainer>
                </AcademicInfoInputContainer>
                <AcademicInfoInputContainer>
                  <MediumTextFieldContainer mr="2em">
                    <CustomTextField
                      label="SAT Math"
                      name="satMath"
                      type="number"
                      inputProps={{
                        step: 1,
                        min: SAT_SECTION_MIN_SCORE,
                        max: SAT_SECTION_MAX_SCORE,
                      }}
                    />
                  </MediumTextFieldContainer>
                  <MediumTextFieldContainer>
                    <CustomTextField
                      label="SAT Reading/Writing"
                      name="satVerbal"
                      type="number"
                      inputProps={{
                        step: 1,
                        min: SAT_SECTION_MIN_SCORE,
                        max: SAT_SECTION_MAX_SCORE,
                      }}
                    />
                  </MediumTextFieldContainer>
                </AcademicInfoInputContainer>
                <AcademicInfoInputContainer>
                  <MediumTextFieldContainer mr="2em">
                    <CustomTextField
                      label="PSAT Math"
                      name="psatMath"
                      type="number"
                      inputProps={{
                        step: 1,
                        min: PSAT_SECTION_MIN_SCORE,
                        max: PSAT_SECTION_MAX_SCORE,
                      }}
                    />
                  </MediumTextFieldContainer>
                  <MediumTextFieldContainer>
                    <CustomTextField
                      label="PSAT Reading/Writing"
                      name="psatVerbal"
                      type="number"
                      inputProps={{
                        step: 1,
                        min: PSAT_SECTION_MIN_SCORE,
                        max: PSAT_SECTION_MAX_SCORE,
                      }}
                    />
                  </MediumTextFieldContainer>
                </AcademicInfoInputContainer>
                <MediumTextFieldContainer>
                  <CustomTextField
                    label="ACT"
                    name="actMath"
                    type="number"
                    inputProps={{
                      step: 1,
                      min: ACT_MIN_SCORE,
                      max: ACT_MAX_SCORE,
                    }}
                  />
                </MediumTextFieldContainer>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <SelectInput
                      label="State"
                      placeholder="Select state..."
                      options={stateOptions}
                      value={stateValue}
                      onChange={setStateValue}
                      isDisabled={!stateOptions}
                      name="state"
                    >
                      <Search />
                    </SelectInput>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectInput
                      placeholder="Type city name..."
                      label="City"
                      loadOptions={loadOptionsCity}
                      async={true}
                      value={city}
                      onChange={setCity}
                      isDisabled={!stateValue & !city}
                      name="city"
                    >
                      <Search />
                    </SelectInput>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectInput
                      values={values}
                      setFieldValue={setFieldValue}
                      name="school"
                      options={schoolOptions}
                      placeholder="Select school..."
                      label="High school"
                      isDisabled={!schoolOptions || !values["school"] & !city}
                    >
                      <Search />
                    </SelectInput>
                  </Grid>
                </Grid>
                <AcademicTextArea
                  value={values.personalStatement}
                  handleChange={handleChange}
                />
                <SuccessButton disabled={isSubmitting} type="submit">
                  Save
                </SuccessButton>
              </AcademicInfoFormContainer>
            </Form>
          )}
        </Formik>
      </GeneralCard>
      <Feedbacker
        open={feedback === recruitingProfileFeedbacks.Updated}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage={feedback}
        clearFeedback={() => dispatch({ type: actionTypes.CLEAR_FEEDBACK })}
      />
      <Feedbacker
        open={Boolean(error)}
        autoHideDuration={3000}
        severity="error"
        feedbackMessage={feedback}
        clearFeedback={() => dispatch({ type: actionTypes.CLEAR_FEEDBACK })}
      />
    </CardContainer>
  );
}

export default AcademicInformation;
