import React, { useState, useEffect } from "react";
import { ExpandMore as ExpandMoreIcon } from "components/MaterialIcons";
import { ChevronRight as ChevronRightIcon } from "components/MaterialIcons";

import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import { useLocation } from "react-router-dom";
import { useProfileState } from "components/useProfile";

import TabsBar from "./TabsBar";
import { athleteRoutes } from "./athleteRoutes";
import { coachRoutes } from "./coachRoutes";
import { parentRoutes } from "./parentRoutes";
import AthletesTree from "./AthletesTree";
import CoachesTree from "./CoachesTree";
import ParentsTree from "./ParentsTree";
import { userTypesEnum } from "enums";
import { StyledTreeView } from "./style";

function Sidebar({ messagesSummary }) {
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState("");
  const { pathname } = useLocation();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMobile = useMediaQuery("(max-width:600px)");

  const { profile } = useProfileState();
  const isAdmin = profile.isAdmin;
  const isAthlete = profile.type === userTypesEnum.ATHLETE;
  const isCoach = profile.type === userTypesEnum.COACH;
  const isParent = profile.type === userTypesEnum.PARENT;

  const unredMessagesCount = isCoach
    ? messagesSummary.unread
    : messagesSummary.reduce((sum, item) => sum + item.unread, 0);

  const renderRoutes = () => {
    switch (profile.type) {
      case userTypesEnum.COACH:
        return coachRoutes;
      case userTypesEnum.PARENT:
        return parentRoutes;
      default:
        return athleteRoutes;
    }
  };

  const routes = renderRoutes();

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (!routes.some(({ link }) => currentPath.includes(link))) {
      setExpanded([]);
      setSelected("");
    } else {
      routes.forEach(({ link, nodeId, parentId }) => {
        const routeMatch = currentPath.includes(link);
        if (routeMatch) {
          if (parentId) {
            setExpanded([parentId]);
            setSelected(nodeId);
          } else {
            setExpanded([]);
            setSelected(nodeId);
          }
        }
      });
    }
  }, [pathname]);

  const handleToggle = (e, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (e, nodeId) => {
    setSelected(nodeId);
  };

  if (matchesMobile) {
    return <TabsBar routes={routes} />;
  }

  return (
    <StyledTreeView
      defaultCollapseIcon={!matchesSm && <ExpandMoreIcon />}
      defaultExpandIcon={!matchesSm && <ChevronRightIcon />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
      {isAthlete && (
        <AthletesTree
          selected={selected}
          isAdmin={isAdmin}
          unredMessagesCount={unredMessagesCount}
        />
      )}
      {isCoach && (
        <CoachesTree
          selected={selected}
          unredMessagesCount={unredMessagesCount}
        />
      )}
      {isParent && (
        <ParentsTree
          selected={selected}
          unredMessagesCount={unredMessagesCount}
        />
      )}
    </StyledTreeView>
  );
}

export default Sidebar;
