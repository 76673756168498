import React from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Box } from "components/MaterialComponents";
import { Formik, Form } from "formik";
import Select from "components/Select";
import { Button } from "components/MaterialComponents";
import Modal from "components/Modal";
import { InputLabel } from "components/Typography";

function getAthletesOptions(athletes = []) {
  return athletes.map((athlete) => ({
    value: athlete.userId,
    label: `${athlete.firstName} ${athlete.lastName}`,
    sports: athlete.sports,
  }));
}

function getSportOptions(sports = [], sportIds = []) {
  const userSportsInCollegeList = sports
    .map((sport) =>
      sportIds.find((collegeSport) => collegeSport.id === sport.id),
    )
    .filter((sport) => Boolean(sport) === true);

  return userSportsInCollegeList.map((sport) => ({
    value: sport.id,
    label: sport.name,
  }));
}

const validationSchema = yup.object().shape({
  athlete: yup.mixed().required("Please select child"),
  sport: yup.mixed().required("Please select sport"),
});

function ParentContactCoachesPopover({
  collegeId,
  open,
  handleClose,
  athletes,
  sportIds,
}) {
  const history = useHistory();

  const handleFormSubmit = (values) => {
    history.push({
      pathname: `/contact-coaches/${collegeId}-${values.sport.value}`,
      state: { childData: values.athlete },
    });
  };

  const athletesOptions = getAthletesOptions(athletes);

  return (
    <Modal open={open} onClose={handleClose} withScroll={false}>
      <Formik
        validateOnChange
        initialValues={{
          athlete: "",
          sport: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form data-testid="form">
            <Box display="flex" flexDirection="column">
              <Box width="320px" marginBottom="10px">
                <Box marginBottom="5px">
                  <InputLabel>Message regarding</InputLabel>
                </Box>
                <Select
                  name="athlete"
                  placeholder="Select child"
                  options={athletesOptions}
                  setFieldValue={setFieldValue}
                  value={values.athlete}
                />
              </Box>
              <Box width="320px" marginBottom="20px">
                <Box marginBottom="5px">
                  <InputLabel>Sport</InputLabel>
                </Box>
                <Select
                  name="sport"
                  placeholder="Select sport"
                  options={getSportOptions(values.athlete.sports, sportIds)}
                  setFieldValue={setFieldValue}
                  value={values.sport}
                />
              </Box>
              <Box>
                <Button type="submit" color="primary" variant="outlined">
                  Write
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

ParentContactCoachesPopover.propTypes = {
  collegeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  athletes: PropTypes.array,
  sportIds: PropTypes.array,
};

export default ParentContactCoachesPopover;
