import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import InputComponent from "components/InputComponent";
import { FormContainer, SaveButton, CategoryFieldContainer } from "./style";

const validationSchema = yup.object().shape({
  text: yup.string().required(),
});

function CoachCategoryField({ updateGroup, setEditedGroupId, group }) {
  const handleFormSubmit = useCallback(
    (values) => {
      if (group.groupName === values.text) {
        setEditedGroupId(null);
        return;
      }

      const body = {
        groupName: values.text,
      };
      updateGroup(group.id, body);
    },
    [group, updateGroup, setEditedGroupId],
  );

  return (
    <CategoryFieldContainer>
      <Formik
        validateOnChange
        initialValues={{
          text: group.groupName,
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {() => (
          <Form>
            <FormContainer>
              <Field name="text" charLimit={20} component={InputComponent} />
              <SaveButton variant="outlined" color="primary" type="submit">
                Save
              </SaveButton>
            </FormContainer>
          </Form>
        )}
      </Formik>
    </CategoryFieldContainer>
  );
}

CoachCategoryField.propTypes = {
  updateGroup: PropTypes.func,
  setEditedGroupId: PropTypes.func,
  group: PropTypes.object,
};

export default CoachCategoryField;
