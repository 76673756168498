import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { TextField } from "components/MaterialComponents";

import { RegularText } from "components/Typography";

function AcademicTextArea({ value, handleChange }) {
  return (
    <Box mb="2em" mt="3em" fontFamily="'Open Sans', Arial, sans-serif">
      <RegularText>Academic Highlights/Accomplishments/Goals</RegularText>
      <TextField
        fullWidth
        multiline
        rows={6}
        variant="outlined"
        name="personalStatement"
        type="text"
        value={value || ""}
        onChange={handleChange}
      />
    </Box>
  );
}

AcademicTextArea.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
};

export default AcademicTextArea;
