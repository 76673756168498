import React, { useMemo } from "react";
import { Box } from "components/MaterialComponents";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import Select from "components/Select";
import { Add as AddIcon } from "components/MaterialIcons";

import { StyledIconButton, SelectContainer } from "./style";

function getSelectOptionsFromGroups(groups) {
  return groups.map((group) => ({ value: group.id, label: group.groupName }));
}

function SelectForm({ favoriteGroups, selectGroup, favorite }) {
  const handleFormSubmit = (value) => {
    selectGroup(value.group.label);
  };

  const defaultGroup = useMemo(
    () =>
      favoriteGroups.find(
        (group) => Number(group.id) === Number(favorite?.groupId),
      ),
    [favoriteGroups, favorite],
  );

  const groupOptions = useMemo(
    () => getSelectOptionsFromGroups(favoriteGroups),
    [favoriteGroups],
  );

  return (
    <Formik
      validateOnChange
      initialValues={{
        group: defaultGroup
          ? { value: defaultGroup.id, label: defaultGroup.groupName }
          : "",
      }}
      onSubmit={handleFormSubmit}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Box display="flex" alignItems="center">
            <SelectContainer>
              <Select
                name="group"
                placeholder="Select group"
                options={groupOptions}
                setFieldValue={setFieldValue}
                value={values.group}
              />
            </SelectContainer>
            <Box>
              <StyledIconButton color="primary" type="submit">
                <AddIcon />
              </StyledIconButton>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

SelectForm.propTypes = {
  favoriteGroups: PropTypes.array,
  selectGroup: PropTypes.func,
};

export default SelectForm;
