import React from "react";
import { Box } from "components/MaterialComponents";
import PropTypes from "prop-types";

import { ContactCoachesPopover as Popover, SportLink } from "./style";

function ContactCoachesPopover({
  id,
  collegeId,
  open,
  anchorEl,
  primarySport,
  secondarySport,
  tertiarySport,
  handleClose,
}) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <Box data-testid="contact-coaches-popover">
        {primarySport && (
          <SportLink to={`/contact-coaches/${collegeId}-${primarySport.id}`}>
            <Box>Regarding {primarySport.name}</Box>
          </SportLink>
        )}
        {secondarySport && (
          <SportLink to={`/contact-coaches/${collegeId}-${secondarySport.id}`}>
            <Box>Regarding {secondarySport.name}</Box>
          </SportLink>
        )}
        {tertiarySport && (
          <SportLink to={`/contact-coaches/${collegeId}-${tertiarySport.id}`}>
            <Box>Regarding {tertiarySport.name}</Box>
          </SportLink>
        )}
      </Box>
    </Popover>
  );
}

ContactCoachesPopover.propTypes = {
  id: PropTypes.string,
  collegeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  primarySport: PropTypes.object,
  secondarySport: PropTypes.object,
  tertiarySport: PropTypes.object,
};

export default ContactCoachesPopover;
