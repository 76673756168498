import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal";
import { Formik, Form } from "formik";
import * as yup from "yup";
import CustomTextField from "components/CustomTextField";
import { Typography } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";

import { FormContainer, FormModalHeader, ButtonsBlock } from "../../style";

const validationSchema = yup.object().shape({
  label: yup.string().required("Field is required"),
  value: yup.string().required("Field is required"),
});

function SelectOptionsCreateEditePopUp({
  open,
  onClose,
  item,
  onCreateOption,
  onEditeOption,
}) {
  const handleFormSubmit = useCallback(
    async (values) => {
      !item && (await onCreateOption(values));
      item && (await onEditeOption(values, item.id));
      onClose();
    },
    [item, onCreateOption, onEditeOption, onClose],
  );

  const headerText = item ? "EDIT OPTION DATA" : "ADD NEW SELECT OPTION";

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <FormModalHeader>
          <Typography variant="h4">{headerText}</Typography>
        </FormModalHeader>
        <Formik
          validateOnChange
          initialValues={{
            label: item ? item.optionLabel : "",
            value: item ? item.optionValue : "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ submitForm }) => (
            <Form>
              <FormContainer>
                <CustomTextField label="Label" name="label" type="text" />
                <CustomTextField label="Value" name="value" type="text" />
                <ButtonsBlock>
                  <Button
                    onClick={submitForm}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={onClose}
                    variant="outlined"
                    color="secondary"
                    size="large"
                  >
                    Cancel
                  </Button>
                </ButtonsBlock>
              </FormContainer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

SelectOptionsCreateEditePopUp.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCreateOption: PropTypes.func,
  onEditeOption: PropTypes.func,
};

export default SelectOptionsCreateEditePopUp;
