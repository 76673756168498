import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal";
import { Formik, Form } from "formik";
import * as yup from "yup";
import CustomTextField from "components/CustomTextField";
import TextFieldInput from "components/TextFieldInput";
import { Typography } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import SelectInput from "components/Select";
import { Search } from "components/MaterialIcons";
import useStates from "components/useStates";
import { InputLabel } from "components/Typography";
import {
  getOptionsFromStates,
  getOptionsFromSearchCity,
} from "containers/Profile/AcademicInformation/selectHelpers";
import debounce from "lodash.debounce";
import { loadCitiesByState } from "api/domains/user";
import {
  campusSizeOptions,
  campusTypeOptions,
  sanctionOptions,
} from "./collegeFormOptions";

import { FormContainer, FormModalHeader, ButtonsBlock } from "../style";

const validationSchema = yup.object().shape({
  schoolName: yup.string().required("Field is required"),
  city: yup.mixed().required("Field is required"),
  state: yup.mixed().required("Field is required"),
  zip: yup.string().required("Field is required"),
  search: yup.string().required("Field is required"),
});

function CreateEditCollegePopup({
  open,
  onClose,
  item,
  onCreateCollege,
  onEditCollege,
}) {
  const { states } = useStates();
  const stateOptions = getOptionsFromStates(states);
  const [stateValue, setStateValue] = useState();

  const loadOptionsCity = debounce((value, callback) => {
    loadCitiesByState(stateValue.value, value)
      .then((res) => getOptionsFromSearchCity(res))
      .then((res) => callback(res))
      .catch((e) => callback([]));
  }, 250);

  useEffect(() => {
    item && setStateValue({ value: item.state, label: states[item.state] });
  }, []);

  const handleFormSubmit = useCallback(
    async (values) => {
      const body = {
        ...values,
        state: values.state.value,
        city: values.city.value,
        campusSettingSize: values.campusSettingSize.value,
        campusSettingType: values.campusSettingType.value,
        actRange: `${values.actRange.from}-${values.actRange.to}`,
        satRange: `${values.satRange.from}-${values.satRange.to}`,
        sanktionId: values.sanction.value,
        sanctionName:
          values.sanction.label === "other"
            ? values.sanctionOther
            : values.sanction.label,
      };

      !item && (await onCreateCollege(body));
      item && (await onEditCollege(item.unitId, body));
      onClose();
    },
    [item, onCreateCollege, onEditCollege, onClose],
  );

  const headerText = item ? "EDIT COLLEGE DATA" : "ADD NEW COLLEGE";

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <FormModalHeader>
          <Typography variant="h4">{headerText}</Typography>
        </FormModalHeader>
        <Formik
          validateOnChange
          initialValues={{
            schoolName: item ? item.schoolName : "",
            state: stateValue,
            city: item ? { value: item.city, label: item.city } : "",
            zip: item ? item.zip : "",
            campusSettingSize: item
              ? { value: item.campusSettingSize, label: item.campusSettingSize }
              : "",
            campusSettingType: item
              ? { value: item.campusSettingType, label: item.campusSettingType }
              : "",

            logoUrl: item ? item.logoUrl : "",
            schoolUrl: item ? item.schoolUrl : "",
            sportsUrl: item ? item.sportsUrl : "",
            usnewsUrl: item ? item.usnewsUrl : "",

            acceptanceRate: item ? item.acceptanceRate : "",
            act: item ? item.act : "",
            actRange: {
              from: item ? item.actRange?.split("-")[0] : "",
              to: item ? item.actRange?.split("-")[1] : "",
            },
            graduationRate: item ? item.graduationRate : "",
            hsGPA: item ? item.hsGPA : "",
            netPrice: item ? item.netPrice : "",
            publicOrPrivate: item ? item.publicOrPrivate : "",
            sat: item ? item.sat : "",
            satRange: {
              from: item ? item.satRange?.split("-")[0] : "",
              to: item ? item.satRange?.split("-")[1] : "",
            },
            totalEnrollment: item ? item.totalEnrollment : "",
            undergradEnrollment: item ? item.undergradEnrollment : "",

            sanction: item
              ? { value: item.sanktionId, label: item.sanctionName }
              : "",
            sanctionOther: item ? item.sanctionName : "",
            division: item ? item.division : "",
            divisionAbbr: item ? item.divisionAbbr : "",

            search: item ? item.search : "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ submitForm, setFieldValue, values }) => (
            <Form>
              <FormContainer>
                <CustomTextField
                  label="School Name"
                  name="schoolName"
                  type="text"
                />
                <Box display="flex" justifyContent="space-between">
                  <Box width="48%">
                    <CustomTextField
                      label="Public or Private"
                      name="publicOrPrivate"
                      type="text"
                    />
                  </Box>
                  <Box width="48%">
                    <CustomTextField label="Zip code" name="zip" type="text" />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  marginBottom="2em"
                >
                  <Box width="48%">
                    <SelectInput
                      label="State"
                      placeholder="Select state..."
                      options={stateOptions}
                      value={stateValue}
                      onChange={(value) => {
                        setStateValue(value);
                        setFieldValue("state", value);
                      }}
                      isDisabled={!stateOptions}
                      name="state"
                    >
                      <Search />
                    </SelectInput>
                  </Box>
                  <Box width="48%">
                    <SelectInput
                      placeholder="Type city name..."
                      label="City"
                      loadOptions={loadOptionsCity}
                      async={true}
                      values={values}
                      setFieldValue={setFieldValue}
                      isDisabled={!stateValue}
                      name="city"
                    >
                      <Search />
                    </SelectInput>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  marginBottom="2em"
                >
                  <Box width="48%">
                    <SelectInput
                      label="Campus Size"
                      placeholder="Select ..."
                      options={campusSizeOptions}
                      values={values}
                      setFieldValue={setFieldValue}
                      name="campusSettingSize"
                    >
                      <Search />
                    </SelectInput>
                  </Box>
                  <Box width="48%">
                    <SelectInput
                      label="Campus Type"
                      placeholder="Select ..."
                      options={campusTypeOptions}
                      values={values}
                      setFieldValue={setFieldValue}
                      name="campusSettingType"
                    >
                      <Search />
                    </SelectInput>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box width="48%">
                    <CustomTextField
                      label="Logo url"
                      name="logoUrl"
                      type="text"
                    />
                  </Box>
                  <Box width="48%">
                    <CustomTextField
                      label="School url"
                      name="schoolUrl"
                      type="text"
                    />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box width="48%">
                    <CustomTextField
                      label="Sports url"
                      name="sportsUrl"
                      type="text"
                    />
                  </Box>
                  <Box width="48%">
                    <CustomTextField
                      label="US News url"
                      name="usnewsUrl"
                      type="text"
                    />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box width="48%">
                    <CustomTextField
                      label="Acceptance Rate"
                      name="acceptanceRate"
                      type="text"
                    />
                  </Box>
                  <Box width="48%">
                    <CustomTextField label="ACT" name="act" type="text" />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box marginBottom="10px">
                    <InputLabel>ACT Range</InputLabel>
                  </Box>
                  <Box display="flex">
                    <Box marginRight="10px">
                      <CustomTextField
                        label="from"
                        name="actRange.from"
                        type="text"
                      />
                    </Box>
                    <Box>
                      <CustomTextField
                        label="to"
                        name="actRange.to"
                        type="text"
                      />
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Box width="48%">
                    <CustomTextField
                      label="Graduation Rate"
                      name="graduationRate"
                      type="text"
                    />
                  </Box>
                  <Box width="48%">
                    <CustomTextField label="hsGPA" name="hsGPA" type="text" />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box width="48%">
                    <CustomTextField
                      label="Net Price"
                      name="netPrice"
                      type="text"
                    />
                  </Box>
                  <Box width="48%">
                    <CustomTextField label="SAT" name="sat" type="text" />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box marginBottom="10px">
                    <InputLabel>SAT Range</InputLabel>
                  </Box>
                  <Box display="flex">
                    <Box marginRight="10px">
                      <CustomTextField
                        label="from"
                        name="satRange.from"
                        type="text"
                      />
                    </Box>
                    <Box>
                      <CustomTextField
                        label="to"
                        name="satRange.to"
                        type="text"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box width="48%">
                    <CustomTextField
                      label="Total Enrollment"
                      name="totalEnrollment"
                      type="text"
                    />
                  </Box>
                  <Box width="48%">
                    <CustomTextField
                      label="Undergrad Enrollment"
                      name="undergradEnrollment"
                      type="text"
                    />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box width="48%">
                    <CustomTextField
                      label="Division"
                      name="division"
                      type="text"
                    />
                  </Box>
                  <Box width="48%">
                    <CustomTextField
                      label="Division Abbr"
                      name="divisionAbbr"
                      type="text"
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  marginBottom="2em"
                >
                  <Box width="48%">
                    <SelectInput
                      label="Sanction"
                      placeholder="Select ..."
                      options={sanctionOptions}
                      values={values}
                      setFieldValue={setFieldValue}
                      name="sanction"
                    >
                      <Search />
                    </SelectInput>
                  </Box>
                  <Box width="48%">
                    {values.sanction?.label === "other" && (
                      <TextFieldInput
                        label="Sanction name"
                        name="sanctionOther"
                        type="text"
                      />
                    )}
                  </Box>
                </Box>
                <CustomTextField label="Search" name="search" type="text" />
                <ButtonsBlock>
                  <Button
                    onClick={submitForm}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={onClose}
                    variant="outlined"
                    color="secondary"
                    size="large"
                  >
                    Cancel
                  </Button>
                </ButtonsBlock>
              </FormContainer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

CreateEditCollegePopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  item: PropTypes.object,
  onCreateCollege: PropTypes.func,
  onEditCollege: PropTypes.func,
};

export default CreateEditCollegePopup;
