import React from "react";
import styled from "styled-components";
import { Button } from "components/MaterialComponents";
import { Modal } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { IconButton } from "components/MaterialComponents";
import { Avatar } from "components/MaterialComponents";
import { Chip } from "components/MaterialComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faBars, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import hudlLogo from "assets/hudl-logo.png";

export const VideoProviders = styled(Box)`
  ${({ theme }) => `
	display: flex;
	padding: 2em;
  `}
`;

export const AddFromYoutube = styled(Box)`
  ${({ theme }) => `
	text-align: center;
	margin-right: 3em;
	cursor: pointer;

	&:hover {
		.svg-inline--fa.fa-w-18 {
			color: red;
		}
	}
  `}
`;

export const AddFromHudle = styled(Box)`
  ${({ theme }) => `
	text-align: center;
	cursor: pointer;

	&:hover {
		.svg-inline--fa.fa-w-16 {
			color: orange;
		}
	}
  `}
`;

export const TitleLinkContainer = styled(Box)`
  ${({ theme }) => `
	display: flex;
	justify-content: space-between;
	${theme.breakpoints.down("sm")} {
    flex-direction: column;
  }
  `}
`;

export const TextFieldContainer = styled(Box)`
  ${({ theme }) => `
  width: 45%;
  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
  `}
`;

export const VideoFormBtnContainer = styled(Box)`
  ${({ theme }) => `
  ${theme.breakpoints.down("sm")} {
    display: flex;
    justify-content: space-between;
  }
  `}
`;

export const VideoFormBtn = styled((props) => (
  <Button variant="contained" {...props} />
))`
  ${({ theme }) => `
  width: 20%;
  &:first-child {
    margin-right: 1em;
  }
  ${theme.breakpoints.down("sm")} {
    width: 45%;
    &:first-child {
      margin-right: 0.5em;
    }
  }
  `}
`;

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
`;

export const ModalBody = styled(Box)`
  position: relative;
  background-color: #000;
  outline: none;
`;

export const StyledIconBtn = styled(IconButton)`
  position: absolute;
  right: 0;
  transform: translate(33px, -33px);

  & .MuiSvgIcon-root {
    font-size: 2.8rem;
    color: #fff;
  }
`;

export const VideoItemContainer = styled(Box)`
  ${({ theme }) => `
	display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #f6f6f6;
  padding: 10px 20px;
    ${theme.breakpoints.down("sm")} {
      flex-direction: column;
      align-items: center;
      margin-bottom: 2em;
    }
  `}
`;

export const VideoPreviewImg = styled((props) => (
  <Avatar variant="square" {...props} />
))`
  ${({ theme }) => `
  margin-bottom: 1em;
  width: 250px;
  height: 200px;
  cursor: pointer;
  position: relative;
  `}
`;

export const StyledYoutubeIcon = styled((props) => (
  <FontAwesomeIcon icon={faYoutube} color="red" size="2x" {...props} />
))`
  position: absolute;
`;

export const StyledHudlIcon = styled((props) => (
  <img src={hudlLogo} width="31.5" height="28" alt="hudlLogo" {...props} />
))`
  position: absolute;
`;

export const VideoItemActionsContainer = styled(Box)`
  ${({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 16%;
  ${theme.breakpoints.down("sm")} {
      width: 30%;
    }
  `}
`;

export const VideoDescription = styled(Box)`
  ${({ theme }) => `
    width: 20em;
    ${theme.breakpoints.down("sm")} {
      display: none;
  }
  `}
`;

export const DragAndDropIcon = styled((props) => (
  <FontAwesomeIcon
    cursor="pointer"
    color="grey"
    icon={faBars}
    size="2x"
    {...props}
  />
))`
  ${({ theme }) => `
    & :hover {
      color: ${theme.palette.primary.main}
    }
  }
  `}
`;

export const DeleteIcon = styled((props) => (
  <FontAwesomeIcon
    cursor="pointer"
    color="grey"
    icon={faTrashAlt}
    size="2x"
    {...props}
  />
))`
  ${({ theme }) => `
    & :hover {
      color: ${theme.palette.error.main}
    }
  }
  `}
`;

export const IncludeSportChip = styled((props) => (
  <Chip size="small" {...props} />
))`
  ${({ theme }) => `
  cursor: pointer;
  margin-right: .5em;
  `}
`;

export const ChipsContainer = styled(Box)`
  ${({ theme }) => `
    display: flex;
    margin-top: 1em;
    ${theme.breakpoints.down("sm")} {
      margin-bottom: 1em;
  }
  `}
`;
