import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 12px;
`;

export const SearchMenuContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 250px;
`;
