import React from "react";
import ParentTreeItem from "./ParentTreeItem";
import ChildrenTreeItem from "./ChildrenTreeItem";
import {
  StyledProfileIcon,
  StyledCollegeIcon,
  StyledContactIcon,
  StyledNavLink,
  StyledNoteIcon,
  StyledAdminIcon,
} from "./style";

function AthletesTree({ isAdmin, selected, unredMessagesCount }) {
  return (
    <>
      <ParentTreeItem nodeId="1" name="Profile" icon={<StyledProfileIcon />}>
        <ChildrenTreeItem
          to="/profile/personal-information"
          nodeId="2"
          label="Personal Information"
        />

        <ChildrenTreeItem
          to="/profile/athletic-performance"
          nodeId="3"
          label="Athletic Performance"
        />

        <ChildrenTreeItem
          to="/profile/athletic-history"
          nodeId="4"
          label="Athletic History"
        />

        <ChildrenTreeItem
          to="/profile/highlight-reel"
          nodeId="5"
          label="Highlight Reel"
        />

        <ChildrenTreeItem
          to="/profile/contact-information"
          nodeId="6"
          label="Contact Information"
        />
        <ChildrenTreeItem
          to="/profile/academic-information"
          nodeId="7"
          label="Academic Information"
        />
      </ParentTreeItem>

      <ParentTreeItem
        nodeId="8"
        name="Find Colleges"
        icon={<StyledCollegeIcon />}
      >
        <ChildrenTreeItem to="/" nodeId="9" label="All Schools" />
        <ChildrenTreeItem
          to="/colleges/reach"
          nodeId="10"
          label="Reach Schools"
        />
        <ChildrenTreeItem
          to="/colleges/target"
          nodeId="11"
          label="Target Schools"
        />
        <ChildrenTreeItem
          to="/colleges/safety"
          nodeId="12"
          label="Safety Schools"
        />
      </ParentTreeItem>

      <StyledNavLink to="/contact-activity">
        <ParentTreeItem
          selected={"13" === selected}
          nodeId="13"
          name="Contact Activity"
          icon={<StyledContactIcon />}
          unredMessagesCount={unredMessagesCount}
        />
      </StyledNavLink>

      <StyledNavLink to="/notes">
        <ParentTreeItem
          selected={"14" === selected}
          nodeId="14"
          name="Notes"
          icon={<StyledNoteIcon />}
        />
      </StyledNavLink>
      {isAdmin && (
        <StyledNavLink to="/admin">
          <ParentTreeItem
            selected={"15" === selected}
            nodeId="15"
            name="Admin"
            icon={<StyledAdminIcon />}
          />
        </StyledNavLink>
      )}
    </>
  );
}

export default AthletesTree;
