import styled from "styled-components";
import { TableRow } from "components/MaterialComponents";
import { TableHead } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import * as colors from "theme/colors";

export const StyledTableRowSports = styled(TableRow)`
  background-color: ${colors.LightYellow};
`;

export const TableHeadContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
`;

export const StyledTableHead = styled(TableHead)``;

export const StyledHeaderTableCell = styled(TableCell)`
  background-color: ${colors.Allports};
  color: white;
`;

export const ModalHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid grey;
  background: white;
  margin-bottom: 5px;
  display: flex;
  font-weight: bold;
`;

export const ModalFooter = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 10;
  background: white;
  display: flex;
  justify-content: space-around;
  padding: 10px;
`;

export const ErrorValueContainer = styled.div`
  margin-left: 5px;
  width: 35px;
  height: 35px;
  background: ${colors.Pink};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
