import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import CreateEditePopUp from "./CreateEditePopUp";
import {
  useHistoryDispatch,
  deleteHistory,
} from "components/useAthleticHistory";
import formatDateTime from "helpers/formatDateTime";
import TeamLogo from "./TeamLogo";
import Tooltip from "components/Tooltip";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";

import {
  ListItemContainer,
  InfoContainer,
  ButtonsGroup,
  ListItemCard,
  LogoContainer,
  StyledTitle,
  StyledSubtitle2,
  StyledSubtitleStats,
  StyledCaption,
  StyledButton,
  StyledDeleteIcon,
  StyledEditeIcon,
  SubtitleSportPositions,
} from "./style";

function HistoryListItem({ item, allowedToChange = true }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoverState, setHoverState] = useState(false);
  const dispatch = useHistoryDispatch();

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));

  const toggleModalOpen = useCallback(() => {
    setIsModalOpen(!isModalOpen);
    setHoverState(false);
  }, [isModalOpen]);

  const handleDeletHistory = useCallback(() => {
    deleteHistory(dispatch, item.id);
  }, [dispatch, item]);

  const getDatesRange = useCallback(() => {
    const startDate = formatDateTime(item.startDate, "MM-DD-YYYY");

    if (item.current) {
      return startDate;
    }

    const endDate = formatDateTime(item.endDate, "MM-DD-YYYY");

    return `${startDate} - ${endDate}`;
  }, [item]);

  const buttonsCondition = allowedToChange & hoverState & !matchesXs;
  const buttonsConditionMobile = allowedToChange & hoverState & matchesXs;

  return (
    <ListItemContainer
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
    >
      <LogoContainer>
        <TeamLogo isHover={hoverState} sport={item.sport.name} />

        {buttonsConditionMobile ? (
          <ButtonsGroup>
            <StyledButton onClick={toggleModalOpen}>
              <StyledEditeIcon />
            </StyledButton>
            <StyledButton onClick={handleDeletHistory}>
              <StyledDeleteIcon />
            </StyledButton>
          </ButtonsGroup>
        ) : null}
      </LogoContainer>
      <ListItemCard>
        <InfoContainer>
          <Tooltip
            title={
              item.team_link
                ? "Visit team's web page"
                : "Link to team's web page was not provided"
            }
            arrow={true}
            placement="top"
          >
            <StyledTitle
              href={item.team_link ? item.team_link : null}
              target="_blank"
              rel="noopener"
            >
              {item.program}
            </StyledTitle>
          </Tooltip>
          <Box display="flex" width="300px" flexWrap="wrap">
            {item.sport_positions.map((position) => (
              <SubtitleSportPositions key={position.id}>
                {position.name}
              </SubtitleSportPositions>
            ))}
          </Box>
          {item.startDate && (
            <StyledSubtitle2>{getDatesRange()}</StyledSubtitle2>
          )}
          {item.location && <StyledSubtitle2>{item.location}</StyledSubtitle2>}
          {item.statLine && (
            <StyledSubtitleStats>{item.statLine}</StyledSubtitleStats>
          )}
          {item.coach && (
            <StyledSubtitle2>{`Coach ${item.coach} `}</StyledSubtitle2>
          )}
          {item.coachContact && (
            <StyledSubtitle2>{item.coachContact}</StyledSubtitle2>
          )}
          {item.achivements && (
            <StyledCaption>{item.achivements}</StyledCaption>
          )}
        </InfoContainer>
        {buttonsCondition ? (
          <ButtonsGroup>
            <StyledButton onClick={toggleModalOpen} data-testid="edit-btn">
              <StyledEditeIcon />
            </StyledButton>
            <StyledButton onClick={handleDeletHistory}>
              <StyledDeleteIcon />
            </StyledButton>
          </ButtonsGroup>
        ) : null}
      </ListItemCard>
      <CreateEditePopUp
        open={isModalOpen}
        onClose={toggleModalOpen}
        item={item}
      />
    </ListItemContainer>
  );
}

TeamLogo.propTypes = {
  item: PropTypes.object,
  allowedToChange: PropTypes.bool,
};

export default HistoryListItem;
