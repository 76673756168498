import { useEffect } from "react";

function useClearFeedback(dispatch) {
  useEffect(() => {
    return () => {
      dispatch({ type: "clear_feedback" });
    };
  }, [dispatch]);
}

export default useClearFeedback;
