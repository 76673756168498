import React, { useCallback } from "react";
import PropTypes from "prop-types";
import StatesFilter from "components/StatesFilter";
import FilterPopper from "components/FilterPopper";
import { Box } from "components/MaterialComponents";

function LocationFilterPopper({ filter, onChange }) {
  const onResetFilter = useCallback(() => {
    onChange({
      ...filter,
      states: null,
    });
  }, [onChange, filter]);

  return (
    <FilterPopper
      anchor="Location"
      filterTitle="Filter by Location"
      resetFilter={onResetFilter}
      testId="location-filter-coach"
    >
      <Box paddingLeft="30px" paddingRight="30px">
        <StatesFilter filter={filter} onChange={onChange} />
      </Box>
    </FilterPopper>
  );
}

LocationFilterPopper.propTypes = {
  filter: PropTypes.object,
  onChange: PropTypes.func,
};

export default LocationFilterPopper;
