import React, { useCallback } from "react";
import { Grid } from "components/MaterialComponents";
import FilterPopper from "components/FilterPopper";
import DivisionFilter from "./DivisionFilter";
import MensSportsFilter from "./MensSportsFilter";
import WomensSportsFilter from "./WomensSportsFilter";
import { FilterSubTitle } from "./style";
import FilterPopperField from "./FilterPopperField";

function AthleticsFilterPopper({ filter, onChange }) {
  const onResetFilter = useCallback(() => {
    onChange({
      ...filter,
      division: null,
      mensSports: null,
      womensSports: null,
    });
  }, [onChange, filter]);

  return (
    <FilterPopper
      anchor="Athletics"
      filterTitle="Filter by Athletics"
      resetFilter={onResetFilter}
      testId="athletics-filter"
    >
      <FilterPopperField label="Division:" labelColumnSize={3}>
        <DivisionFilter filter={filter} onChange={onChange} />
      </FilterPopperField>
      <FilterPopperField
        label="Sports:"
        labelColumnSize={3}
        childrenColumnSize={9}
      >
        <Grid container spacing={1}>
          <Grid item sm={6} xs={12}>
            <FilterSubTitle>Mens</FilterSubTitle>
            <MensSportsFilter filter={filter} onChange={onChange} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FilterSubTitle>Womens</FilterSubTitle>
            <WomensSportsFilter filter={filter} onChange={onChange} />
          </Grid>
        </Grid>
      </FilterPopperField>
    </FilterPopper>
  );
}

export default AthleticsFilterPopper;
