import React, { useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "components/MaterialComponents";
import { Table } from "components/MaterialComponents";
import { TableBody } from "components/MaterialComponents";
import { TableContainer } from "components/MaterialComponents";
import { TableRow } from "components/MaterialComponents";
import { TablePagination } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import CollegeTableRow from "./CollegeTableRow";
import CreateEditCollegePopup from "./CreateEditCollegePopup";
import FilterBar from "containers/FindColleges/FilterBar";
import * as collegesDomain from "api/domains/colleges";
import useFilter from "customHooks/useFilter";
import useSearchResult from "containers/FindColleges/useSearchResult";

import {
  TableHeadContainer,
  StyledTableHead,
  StyledHeaderTableCell,
} from "./style";

const useStyles = makeStyles({
  table: {
    width: "95%",
    height: "63vh",
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "white",
    overflowY: "auto",
  },
});

function CollegesList() {
  const [filter, setFilter] = useFilter();
  const params = useParams();
  const [lastModified, setLastModified] = useState(null);
  const [lastRemoved, setLastRemoved] = useState(null);
  const [{ data: items, total, majors } = {}, loading] = useSearchResult(
    filter,
    params,
    {
      modified: lastModified,
      removed: lastRemoved,
    },
  );
  const onFilterChange = (filter) => setFilter({ ...filter, page: 1 });
  const parsedPage = parseInt(filter.page) || 1;
  const parsedRowsPerPage = parseInt(filter.pageSize) || 30;

  const [isCreateModalOpen, setCreateModalOpen] = useState(false);

  const tableContainerRef = useRef(null);

  const scrollTop = useCallback(() => {
    if (!tableContainerRef.current) {
      return;
    }
    tableContainerRef.current.scrollTop = 0;
    tableContainerRef.current.scrollLeft = 0;
  }, [tableContainerRef]);

  const handleChangePage = useCallback(
    (_, page) => {
      setFilter({ ...filter, page: page + 1 });
      scrollTop();
    },
    [filter, setFilter, scrollTop],
  );

  const handleChangeRowsPerPage = useCallback(
    (event) =>
      setFilter({
        ...filter,
        page: 1,
        pageSize: parseInt(event.target.value, 10),
      }),
    [filter, setFilter],
  );

  const handleCreateCollege = useCallback(async (body) => {
    await collegesDomain.createCollege(body);
    setLastModified("new college");
  }, []);

  const handleDeleteCollege = useCallback(async (id) => {
    await collegesDomain.deleteCollege(id);
    setLastRemoved(`collegeId-${id}`);
  }, []);

  const handleEditCollege = useCallback(async (id, body) => {
    await collegesDomain.editCollege(id, body);
    setLastModified(`collegeId-${id}`);
  }, []);

  const handleAddSportsToCollege = useCallback(async (id, body) => {
    await collegesDomain.addSportsToCollege(id, body);
    setLastModified(`collegeId-${id}-sport`);
  }, []);

  const handleDeleteCollegeSport = useCallback(async (unitSport) => {
    await collegesDomain.deleteCollegeSport(unitSport);
    setLastRemoved(unitSport);
  }, []);

  const toggleCreateModal = useCallback(() => {
    setCreateModalOpen(!isCreateModalOpen);
  }, [isCreateModalOpen]);

  const classes = useStyles();

  return (
    <>
      <TableHeadContainer>
        <Box marginRight="10px" marginLeft="10px">
          <Typography variant="h4">Colleges</Typography>
        </Box>
        <Button color="primary" variant="outlined" onClick={toggleCreateModal}>
          Add new
        </Button>
        <Box marginLeft="30px">
          <FilterBar
            filter={filter}
            majors={majors}
            onChange={onFilterChange}
          />
        </Box>
      </TableHeadContainer>
      <TableContainer className={classes.table} ref={tableContainerRef}>
        <Table stickyHeader>
          <StyledTableHead>
            <TableRow>
              <StyledHeaderTableCell>Name</StyledHeaderTableCell>
              <StyledHeaderTableCell>City</StyledHeaderTableCell>
              <StyledHeaderTableCell>Acceptance Rate</StyledHeaderTableCell>
              <StyledHeaderTableCell>ACT</StyledHeaderTableCell>
              <StyledHeaderTableCell>Act Range</StyledHeaderTableCell>
              <StyledHeaderTableCell>Campus Size</StyledHeaderTableCell>
              <StyledHeaderTableCell>Campus Type</StyledHeaderTableCell>
              <StyledHeaderTableCell>Division</StyledHeaderTableCell>
              <StyledHeaderTableCell>Division Abbr</StyledHeaderTableCell>
              <StyledHeaderTableCell>Graduation Rate</StyledHeaderTableCell>
              <StyledHeaderTableCell>hsGPA</StyledHeaderTableCell>
              <StyledHeaderTableCell>Logo Url</StyledHeaderTableCell>
              <StyledHeaderTableCell>Net Price</StyledHeaderTableCell>
              <StyledHeaderTableCell>Public or Private</StyledHeaderTableCell>
              <StyledHeaderTableCell>Sanction</StyledHeaderTableCell>
              <StyledHeaderTableCell>SAT</StyledHeaderTableCell>
              <StyledHeaderTableCell>SAT Range</StyledHeaderTableCell>
              <StyledHeaderTableCell>School Url</StyledHeaderTableCell>
              <StyledHeaderTableCell>Sports Url</StyledHeaderTableCell>
              <StyledHeaderTableCell>State</StyledHeaderTableCell>
              <StyledHeaderTableCell>Total Enrollment</StyledHeaderTableCell>
              <StyledHeaderTableCell>
                Undergrad Enrollment
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>US News Url</StyledHeaderTableCell>
              <StyledHeaderTableCell>Search</StyledHeaderTableCell>
              <StyledHeaderTableCell>Sports</StyledHeaderTableCell>
              <StyledHeaderTableCell>EDIT</StyledHeaderTableCell>
              <StyledHeaderTableCell>DELETE</StyledHeaderTableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {items?.map((college) => (
              <CollegeTableRow
                key={college.unitId}
                college={college}
                onDelete={handleDeleteCollege}
                onEdit={handleEditCollege}
                onAddSports={handleAddSportsToCollege}
                onDeleteSport={handleDeleteCollegeSport}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total || 0}
        rowsPerPage={parsedRowsPerPage}
        page={parsedPage - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <CreateEditCollegePopup
        open={isCreateModalOpen}
        onClose={toggleCreateModal}
        onCreateCollege={handleCreateCollege}
      />
    </>
  );
}

export default CollegesList;
