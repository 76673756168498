import React from "react";
import PropTypes from "prop-types";
import { Snackbar } from "components/MaterialComponents";
import { Alert as MuiAlert } from "components/MaterialComponents";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function Feedbacker({
  open,
  autoHideDuration,
  severity = "success",
  feedbackMessage,
  clearFeedback,
}) {
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    clearFeedback();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleAlertClose}
    >
      <Alert onClose={handleAlertClose} severity={severity}>
        {feedbackMessage}
      </Alert>
    </Snackbar>
  );
}

Feedbacker.propTypes = {
  open: PropTypes.bool,
  autoHideDuration: PropTypes.number.isRequired,
  severity: PropTypes.oneOf(["error", "info", "success", "warning"]),
  feedbackMessage: PropTypes.any,
  clearFeedback: PropTypes.func.isRequired,
};

export default Feedbacker;
