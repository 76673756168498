import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";

import Modal from "components/Modal";
import { ModalHeader, ModalButton } from "../style";

function DeleteVideoModal({ open, handleClose, handleDeleteVideo, updating }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader mb="1em">
        Deleting this video will remove it from your recruiting profile. Are you
        sure you want to continue?
      </ModalHeader>
      <Box display="flex" justifyContent="space-around">
        <ModalButton
          onClick={handleDeleteVideo}
          color="secondary"
          variant="contained"
          disabled={updating}
        >
          Delete
        </ModalButton>
        <ModalButton onClick={handleClose} variant="outlined">
          Cancel
        </ModalButton>
      </Box>
    </Modal>
  );
}

DeleteVideoModal.propTypes = {
  open: PropTypes.bool,
  updating: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDeleteVideo: PropTypes.func,
};

export default DeleteVideoModal;
