import client from "../adapter";

function getColleges(body) {
  return client(`/colleges/search`, { body, method: "POST" });
}

function createCollege(body) {
  return client(`/colleges/create/college`, { body, method: "POST" });
}

function deleteCollege(id) {
  return client(`/colleges/delete/college/${id}`, { method: "DELETE" });
}

function editCollege(id, body) {
  return client(`/colleges/edit/college/${id}`, { body, method: "PATCH" });
}

function addSportsToCollege(id, body) {
  return client(`/colleges/add/sports/${id}`, { body, method: "PATCH" });
}

function deleteCollegeSport(unitSport) {
  return client(`/colleges/delete/sport/${unitSport}`, { method: "DELETE" });
}

export {
  getColleges,
  createCollege,
  deleteCollege,
  editCollege,
  addSportsToCollege,
  deleteCollegeSport,
};
