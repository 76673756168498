import client from "../adapter";

function getAllsports() {
  return client(`/colleges/sports/all`);
}

function createSport(body) {
  return client(`/colleges/sports`, { body });
}

function updateSport(body, id) {
  return client(`/colleges/sports/${id}`, { body, method: "PATCH" });
}

function deleteSport(id) {
  return client(`/colleges/sports/${id}`, { method: "DELETE" });
}

function createSportPosition(body) {
  return client(`/colleges/sports/positions`, { body });
}

function updateSportPosition(body, id) {
  return client(`/colleges/sports/positions/${id}`, { body, method: "PATCH" });
}

function deleteSportPosition(id) {
  return client(`/colleges/sports/positions/${id}`, { method: "DELETE" });
}

function createSportStat(body) {
  return client(`/colleges/sports/stats`, { body });
}

function updateSportStat(body, id) {
  return client(`/colleges/sports/stats/${id}`, { body, method: "PATCH" });
}

function updateSportStatGroup(body) {
  return client(`/colleges/sports/stats/update-group`, {
    body,
    method: "PATCH",
  });
}

function deleteSportStat(id) {
  return client(`/colleges/sports/stats/${id}`, { method: "DELETE" });
}

function createSportStatSelectOption(body) {
  return client(`/colleges/sports/option`, { body });
}

function updateSportStatSelectOption(body, id) {
  return client(`/colleges/sports/option/${id}`, { body, method: "PATCH" });
}

function deleteSportStatSelectOption(id) {
  return client(`/colleges/sports/option/${id}`, { method: "DELETE" });
}

export {
  getAllsports,
  createSport,
  updateSport,
  deleteSport,
  createSportPosition,
  updateSportPosition,
  deleteSportPosition,
  createSportStat,
  updateSportStat,
  updateSportStatGroup,
  deleteSportStat,
  createSportStatSelectOption,
  updateSportStatSelectOption,
  deleteSportStatSelectOption,
};
