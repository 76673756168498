import React, { useCallback, useState, useEffect } from "react";
import FilterPopperField from "./FilterPopperField";
import {
  StyledSlider,
  StyledFilterValuesBlue,
  StyledFilterValuesGrey,
} from "./style";

const min = 0;
const max = 4.0;
/*const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 4.5,
    label: "4.5",
  },
];*/
function GPAFilter({ filter, onChange }) {
  const { gpaMax } = filter;
  const [value, setValue] = useState(parseFloat(gpaMax) || min);

  useEffect(() => {
    setValue(parseFloat(gpaMax) || min);
  }, [gpaMax]);

  const onValueChange = useCallback(
    (e, newValue) => {
      setValue(newValue);
    },
    [setValue],
  );
  const onChangeCommitted = useCallback(
    (e, gpaMax) => {
      if (gpaMax === min) {
        onChange({
          ...filter,
          gpaMin: null,
          gpaMax: null,
        });
        return;
      }
      onChange({ ...filter, gpaMin: min, gpaMax });
    },
    [onChange, filter],
  );
  return (
    <FilterPopperField label="GPA (unweighted):">
      {value === min ? (
        <StyledFilterValuesGrey variant="h5">
          Select the value
        </StyledFilterValuesGrey>
      ) : (
        <StyledFilterValuesBlue variant="h5">{value}</StyledFilterValuesBlue>
      )}
      <StyledSlider
        value={value}
        min={min}
        max={max}
        step={0.1}
        onChange={onValueChange}
        onChangeCommitted={onChangeCommitted}
        valueLabelDisplay="auto"
        aria-labelledby="continuous-slider"
        //marks={marks}
      />
    </FilterPopperField>
  );
}

export default GPAFilter;
