import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import TabHeader from "./TabHeader";
import { Collapse } from "components/MaterialComponents";
import { Fade } from "components/MaterialComponents";
import { StyledTabsContainer, StyledItemsContainer, MobileLink } from "./style";
import { useHistory } from "react-router";
import transformRoutes from "helpers/transformRoutesMobileNav";
import { tabHeadersAthlete } from "./tabHeadersAthlete";
import { tabHeadersCoach } from "./tabHeadersCoach";
import { tabHeadersParent } from "./tabHeadersParent";
import { useProfileState } from "components/useProfile";
import { userTypesEnum } from "enums";

function TabsBar({ routes }) {
  const [selected, setSelected] = useState(null);
  const history = useHistory();
  const { profile } = useProfileState();

  const reducedRoutes = useMemo(() => transformRoutes(routes), [routes]);

  const onSelect = (link) => {
    history.push(link);
    setSelected(null);
  };

  const renderTabheaders = () => {
    switch (profile.type) {
      case userTypesEnum.COACH: {
        return tabHeadersCoach;
      }
      case userTypesEnum.PARENT: {
        return tabHeadersParent;
      }
      default: {
        return tabHeadersAthlete;
      }
    }
  };

  return (
    <>
      <StyledTabsContainer>
        {renderTabheaders().map((header) => (
          <TabHeader
            key={header.id}
            header={header}
            onSelect={setSelected}
            selected={Number(header.id) === Number(selected)}
          />
        ))}
      </StyledTabsContainer>
      {Object.keys(reducedRoutes).map((parent, index) => (
        <Collapse
          key={index}
          in={Number(parent) === Number(selected)}
          timeout={300}
        >
          <StyledItemsContainer>
            {reducedRoutes[parent].map((route, index) => (
              <Fade
                key={index}
                in={Number(parent) === Number(selected)}
                style={{
                  transitionDelay:
                    Number(parent) === Number(selected) ? "200ms" : "0ms",
                }}
              >
                <MobileLink onClick={() => onSelect(route.link)}>
                  {route.label}
                </MobileLink>
              </Fade>
            ))}
          </StyledItemsContainer>
        </Collapse>
      ))}
    </>
  );
}

TabsBar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default TabsBar;
