import React from "react";
import PropTypes from "prop-types";
import { Switch } from "components/MaterialComponents";
import { FormControlLabel } from "components/MaterialComponents";
import { Field } from "formik";

function FormikSwitch({
  name,
  checked,
  label,
  setFieldValue,
  labelPlacement = "end",
}) {
  return (
    <FormControlLabel
      control={
        <Field
          checked={Boolean(checked)}
          component={Switch}
          name={name}
          color="primary"
          onChange={(e) => setFieldValue(name, e.target.checked)}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  );
}

FormikSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

export default FormikSwitch;
