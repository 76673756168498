import React, { useMemo } from "react";
import Context from "./Context";
import { useGet } from "../useFetch";

function SportsProvider({ children, testData }) {
  const [data, loading, error] = useGet("/colleges/sports");
  const mensSports = useMemo(() => {
    if (!data) return;
    return data
      .filter((s) => s.gender === "Men")
      .reduce((map, sport) => {
        map[sport.id] = sport.name;
        return map;
      }, {});
  }, [data]);
  const womensSports = useMemo(() => {
    if (!data) return;
    return data
      .filter((s) => s.gender === "Women")
      .reduce((map, sport) => {
        map[sport.id] = sport.name;
        return map;
      }, {});
  }, [data]);
  const value = useMemo(
    () => ({
      data,
      loading,
      error,
      mensSports,
      womensSports,
    }),
    [data, loading, error, mensSports, womensSports],
  );
  return (
    <Context.Provider value={testData ? testData : value}>
      {children}
    </Context.Provider>
  );
}

export default SportsProvider;
