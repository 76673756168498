import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { Formik, Form, useField } from "formik";
import { TextField } from "components/MaterialComponents";
import * as yup from "yup";
import PasswordDescription from "./PasswordDescription";

import {
  DrawerHeader,
  StyledCloseIcon,
  EmailButton,
  HelperText,
  LoginContainer,
} from "./style";
import NewTabLink from "components/NewTabLink";

import * as validationHelper from "helpers/validationHelper";
import * as userDomain from "api/domains/user";
import { useProfileDispatch } from "components/useProfile";
import { INCORRECT_PASSWORD, INVALID_EMAIL_OR_USERNAME } from "./backendErrors";

const validationSchemaAthlete = yup.object().shape({
  email: validationHelper.emailCheckAthlete,
  password: validationHelper.password,
});

function SignUpBody({ onDrawerClose, setDrawerBody, userType }) {
  const dispatch = useProfileDispatch();

  const handleFormSubmit = async (data, { setFieldError }) => {
    try {
      const body = {
        ...data,
        type: userType,
      };
      const resData = await userDomain.signUp(body);
      onDrawerClose();
      dispatch({ type: "success", data: resData });
      dispatch({
        type: "set_feedback",
        data: {
          feedback:
            "Please click on the link that has been sent to your email to verify your email address",
        },
      });
    } catch (err) {
      handleBackEndError(err, setFieldError);
    }
  };

  const handleBackEndError = (err, setFieldError) => {
    switch (err.errorCode) {
      case INVALID_EMAIL_OR_USERNAME:
        setFieldError("email", err.message);
        break;
      case INCORRECT_PASSWORD:
        setFieldError("password", err.message);
        break;
      default:
        break;
    }
  };

  ///api/v2x/users/email to check valid email

  //if find email, then switch to Login option

  //else post to /api/v2x/users

  const CustomTextField = ({ label, type, autoFocus = false, ...props }) => {
    const [field, meta] = useField(props);
    const { value, ...restField } = field;
    const errorText = meta.error && meta.touched ? meta.error : "";
    return (
      <TextField
        style={{ marginBottom: "2em" }}
        variant="outlined"
        label={label}
        type={type}
        helperText={errorText}
        error={!!errorText}
        autoFocus={autoFocus}
        value={value || ""}
        {...restField}
      />
    );
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        data-testid="sign-up-form"
      >
        <DrawerHeader>Sign up now</DrawerHeader>
        <StyledCloseIcon onClick={onDrawerClose} />
      </Box>
      <HelperText>
        By clicking "Sign Up with email" you confirm you are at least 13 years
        of age and agree to our{" "}
        <NewTabLink
          name={"Terms of Use"}
          href={"https://pages.fanangel.com/terms"}
        />{" "}
        and{" "}
        <NewTabLink
          name={"Privacy Policy"}
          href={"https://pages.fanangel.com/privacy/"}
        />
        .
      </HelperText>
      <PasswordDescription />
      <Formik
        validateOnChange
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchemaAthlete}
        onSubmit={handleFormSubmit}
      >
        {({ values, setFieldValue, handleChange, isSubmitting }) => (
          <Form>
            <LoginContainer>
              <CustomTextField
                label="Name"
                name="displayName"
                type="text"
                autoFocus={true}
              />
              <CustomTextField label="Email" name="email" type="email" />
              <CustomTextField
                label="Password"
                name="password"
                type="password"
              />
              <EmailButton disabled={isSubmitting} type="submit">
                Sign Up With Email
              </EmailButton>
            </LoginContainer>
          </Form>
        )}
      </Formik>
    </>
  );
}

SignUpBody.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
  setDrawerBody: PropTypes.func.isRequired,
};

export default SignUpBody;
