// PROFILE SPORTS ORDER
const ORDER_PRIMARY = "primary";
const ORDER_SECONDARY = "secondary";

// SPORT STATS DEFINITIONS INPUT TYPES
const INT = "int";
const TIME_SEC_MS = "TIME_SEC_MS";
const TIME_MIN_SEC_MS = "TIME_MIN_SEC_MS";

const DECIMAL_1 = "DECIMAL_1";
const DECIMAL_2 = "DECIMAL_2";
const DECIMAL_3 = "DECIMAL_3";
const DECIMAL_4 = "DECIMAL_4";
const DISTANCE = "DISTANCE";
const RECORD = "RECORD";
const SELECT = "SELECT";

const FLOAT_TYPES = [TIME_SEC_MS, DECIMAL_1, DECIMAL_3, DECIMAL_4, DECIMAL_2];
const NUM_TYPES = [INT, TIME_SEC_MS, DECIMAL_1];
const INPUT_TYPES = [
  INT,
  TIME_SEC_MS,
  TIME_MIN_SEC_MS,
  DECIMAL_1,
  DECIMAL_2,
  DISTANCE,
  RECORD,
  DECIMAL_3,
  DECIMAL_4,
  SELECT,
];

export {
  ORDER_PRIMARY,
  ORDER_SECONDARY,
  INT,
  TIME_SEC_MS,
  TIME_MIN_SEC_MS,
  DECIMAL_1,
  DECIMAL_2,
  DISTANCE,
  RECORD,
  DECIMAL_3,
  DECIMAL_4,
  FLOAT_TYPES,
  NUM_TYPES,
  INPUT_TYPES,
};
