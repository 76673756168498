export const recruitingProfileFeedbacks = {
  Created: "Successfully created -",
  Updated: "Successfully updated",
  FavoritesUpdated: "Favorites updated",
  ActivationEmailSent:
    "Successfully created - Profile. Please click on the link that has been sent to your email to verify your email address",
  ActivationEmailNotSent:
    "Successfully created - Profile. Your data is going through verification process, as soon as it will be finished you will receive an activation email for your account.",
  NotFoundUser: "User with this email is not exist",
};
