import React from "react";
import {
  getNamesFromOptionsArray,
  getNamesFromOptions,
  removeItemFromField,
} from "helpers/filterPreviewHelpers";
import {
  transformToArray,
  transformToString,
  transformToArraySelect,
  transformToStringSelect,
} from "helpers/athletsFilterTransformers";
import useStates from "components/useStates";
import {
  StyledItem,
  StyledItemsContainer,
  FilterItemCloseButton,
} from "containers/FindColleges/style";

function getStatName(sportData, stat) {
  if (!sportData) {
    return;
  }
  const definition = sportData.sportStatsDefinitions.find(
    (el) => Number(el.id) === Number(stat.id),
  );

  return definition ? definition.name : null;
}

function getNewValuesForStats(transformFn, stats, value) {
  return transformFn(stats).filter((el) => Number(el.id) !== Number(value.id));
}

function FiltersPreview({ filter, onChange, sportData }) {
  const {
    sportId,
    states,
    sportPositions,
    sportStats,
    sportStatsSelect,
  } = filter;
  const { states: stateOptions } = useStates();

  const mapFilterToPreviwItems = {
    sportId: {
      name: sportData ? `${sportData.name} (${sportData.gender})` : null,
      value: sportId,
      filterField: "sportId",
    },
    sportPositions: getNamesFromOptionsArray(
      sportPositions,
      sportData?.sportPositions,
      "sportPositions",
    ),
    sportStats: transformToArray(sportStats).map((stat) => ({
      name: getStatName(sportData, stat)
        ? `${getStatName(sportData, stat)}: ${stat.minText || stat.maxText}`
        : null,
      value: stat,
      filterField: "sportStats",
    })),
    sportStatsSelect: transformToArraySelect(sportStatsSelect).map((stat) => ({
      name: getStatName(sportData, stat)
        ? `${getStatName(sportData, stat)}: ${stat.select}`
        : null,
      value: stat,
      filterField: "sportStatsSelect",
    })),
    states: getNamesFromOptions(states, stateOptions, "states"),
  };

  const calculatedFilters = Object.keys(filter)
    .map((key) => (filter[key] ? mapFilterToPreviwItems[key] : null))
    .flat()
    .filter((el) => Boolean(el) !== false);

  const removeFilter = (target) => {
    const { value, filterField } = target;

    if (filterField === "sportStats") {
      const newfilterValues = getNewValuesForStats(
        transformToArray,
        filter.sportStats,
        value,
      );
      onChange({
        ...filter,
        sportStats: transformToString(newfilterValues),
      });
      return;
    }

    if (filterField === "sportStatsSelect") {
      const newfilterValues = getNewValuesForStats(
        transformToArraySelect,
        filter.sportStatsSelect,
        value,
      );
      onChange({
        ...filter,
        sportStatsSelect: transformToStringSelect(newfilterValues),
      });
      return;
    }

    if (filterField === "sportId") {
      onChange({
        ...filter,
        sportId: null,
        sportPositions: null,
        sportStats: null,
        sportStatsSelect: null,
      });
      return;
    }

    onChange({
      ...filter,
      [filterField]: removeItemFromField(filter[filterField], value),
    });
  };

  return (
    <StyledItemsContainer>
      {calculatedFilters.map((filter, index) =>
        Boolean(filter && filter.name) ? (
          <StyledItem key={index}>
            {filter.name}
            <FilterItemCloseButton onClick={() => removeFilter(filter)} />
          </StyledItem>
        ) : null,
      )}
    </StyledItemsContainer>
  );
}

export default FiltersPreview;
