import React from "react";
import PropTypes from "prop-types";

import { StyledTooltip } from "./style";

function Tooltip({ title, arrow, placement, children }) {
  return (
    <StyledTooltip title={title} placement={placement} arrow={arrow}>
      {children}
    </StyledTooltip>
  );
}

Tooltip.propTypes = {
  title: PropTypes.string,
  arrow: PropTypes.bool,
  placement: PropTypes.string,
};

export default Tooltip;
