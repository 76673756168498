import styled from "styled-components";
import { Button } from "components/MaterialComponents";
import { IconButton } from "components/MaterialComponents";
import * as colors from "theme/colors";

export const SportButtonRemove = styled(Button)`
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 23px;
  color: ${colors.Red};
`;

export const SportButtonNew = styled(Button)`
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  height: 50px;
  color: inherit;

  &:hover {
    background-color: none;
    color: ${colors.Allports};
  }
`;

export const MainContainer = styled.div`
  ${({ theme }) => `{
    display: flex;
    flex-direction: column;
    width: 100%;

    ${theme.breakpoints.down("sm")} {
      width: 90%;
      padding-top: 50px;
  `}
`;

export const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 15px;
`;

export const CreateFormContainer = styled.div`
  width: 100%;
`;

export const CreateFieldsContainer = styled.div`
  display: flex;
`;

export const CreateButtonGroup = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  margin-right: 5px;

  &:hover {
    background-color: transparent;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

export const SelectContainer = styled.div`
  ${({ theme }) => `{
    width: 315px;
    margin-right: 10px;
    ${theme.breakpoints.down("sm")} {
      width: 280px;
  `}
`;
