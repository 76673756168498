import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import FilterPopper from "components/FilterPopper";
import SportPositionsFilter from "./SportPositionsFilter";

function PositionsFilterPopper({ filter, onChange, positions, sportId }) {
  const onResetFilter = useCallback(() => {
    onChange({
      ...filter,
      sportPositions: null,
    });
  }, [onChange, filter]);

  return (
    <FilterPopper
      anchor="Sport Positions"
      filterTitle="Filter by Sport Positions"
      resetFilter={onResetFilter}
      isData={Boolean(positions && sportId)}
      testId="positions-filter-coach"
    >
      <Box paddingLeft="30px" paddingRight="30px">
        <SportPositionsFilter
          filter={filter}
          onChange={onChange}
          positions={positions}
        />
      </Box>
    </FilterPopper>
  );
}

PositionsFilterPopper.propTypes = {
  filter: PropTypes.object,
  onChange: PropTypes.func,
  positions: PropTypes.array,
  sportId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PositionsFilterPopper;
