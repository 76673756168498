import React from "react";
import styled from "styled-components";
import { FormControl } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import { Chip } from "components/MaterialComponents";

export const StyledFormControl = styled((props) => (
  <FormControl variant="outlined" {...props} />
))`
  margin-bottom: 2.5em;
  width: 100%;
`;

export const ErrorMessage = styled(Typography)`
  color: red;
  font-size: 12px;
  margin: 0 14px;
`;

export const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledChip = styled(Chip)`
  margin-top: 3px;
  margin-right: 3px;
`;
