import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import SelectInput from "components/Select";
import {
  transformToArraySelect,
  transformToStringSelect,
} from "helpers/athletsFilterTransformers";
import { Formik } from "formik";
import { SportStatField, NameField } from "./style";

function StatPositionSelect({ option, filter, onChange }) {
  const { sportStatsSelect = "" } = filter;

  const { sportStatsSelectOptions } = option;

  const statsArraySelect = sportStatsSelect
    ? transformToArraySelect(sportStatsSelect)
    : [];

  const checked = statsArraySelect.find(
    (el) => Number(el.id) === Number(option.id),
  );

  const defaultSelectVal = checked?.select
    ? { value: checked.id, label: checked.select }
    : "";

  const [select, setSelect] = useState(defaultSelectVal);

  const options = useMemo(
    () =>
      sportStatsSelectOptions.map((option) => ({
        value: option.id,
        label: option.optionLabel,
      })),
    [sportStatsSelectOptions],
  );

  const onSubmitSelect = useCallback(
    (e) => {
      if (!select) {
        return;
      }

      if (!e.target.checked) {
        onChange({
          ...filter,
          sportStatsSelect: transformToStringSelect(
            statsArraySelect.filter(
              (el) => Number(el.id) !== Number(option.id),
            ),
          ),
        });
        return;
      }

      const position = {
        id: option.id,
        select: select.label,
      };

      onChange({
        ...filter,
        sportStatsSelect: transformToStringSelect([
          ...statsArraySelect,
          position,
        ]),
      });
    },
    [filter, onChange, option, select, statsArraySelect],
  );

  return (
    <SportStatField>
      <NameField>
        <Typography variant="h5">{option.name}</Typography>
      </NameField>
      <Box width="250px">
        <Formik>
          <SelectInput
            value={select}
            onChange={setSelect}
            options={options}
            isDisabled={Boolean(checked)}
            name="statSelect"
          />
        </Formik>
      </Box>
      <Box width="40px" marginLeft="30px">
        <Checkbox checked={Boolean(checked)} onChange={onSubmitSelect} />
      </Box>
    </SportStatField>
  );
}

StatPositionSelect.propTypes = {
  option: PropTypes.object,
  filter: PropTypes.object,
  onChange: PropTypes.func,
};

export default StatPositionSelect;
