import React from "react";
import SportSelectForm from "./SportSelectForm";
import LocationFilterPopper from "./LocationFilterPopper";
import PositionsFilterPopper from "./PositionsFilterPopper";
import StatsFilterPopper from "./StatsFilterPopper";

function FilterMenu({
  filter,
  onChange,
  positions,
  stats,
  onGenderChange,
  onSportChange,
  sportId,
}) {
  return (
    <>
      <SportSelectForm
        filter={filter}
        onGenderChange={onGenderChange}
        onSportChange={onSportChange}
      />
      <LocationFilterPopper filter={filter} onChange={onChange} />
      <PositionsFilterPopper
        filter={filter}
        onChange={onChange}
        positions={positions}
        sportId={sportId}
      />
      <StatsFilterPopper
        filter={filter}
        onChange={onChange}
        stats={stats}
        sportId={sportId}
      />
    </>
  );
}

export default React.memo(FilterMenu);
