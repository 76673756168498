import React from "react";
import { Box } from "components/MaterialComponents";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as yup from "yup";

import CustomTextField from "components/CustomTextField";
import { RegularText } from "components/Typography";
import videoFormStateEnum from "./videoFormStateEnum";
import * as validationHelper from "helpers/validationHelper";
import {
  TitleLinkContainer,
  TextFieldContainer,
  VideoFormBtnContainer,
  VideoFormBtn,
} from "./style";

const validationSchema = (type) =>
  yup.object().shape({
    title: validationHelper.title,
    description: validationHelper.videoDescription,
    url:
      type === videoFormStateEnum.youtube
        ? validationHelper.youTubeUrl
        : validationHelper.hudlUrl,
  });

function VideoForm({ type, icon, setClose, handleFormSubmit }) {
  const iconColor = type === videoFormStateEnum.youtube ? "red" : "orange";
  return (
    <Box p="2em">
      <Formik
        validateOnChange
        initialValues={{
          title: "",
          url: "",
          description: "",
        }}
        validationSchema={validationSchema(type)}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Box>
              <RegularText mb="1.5em">
                <FontAwesomeIcon color={iconColor} icon={icon} /> Add from{" "}
                {type}
              </RegularText>
              <TitleLinkContainer>
                <TextFieldContainer>
                  <CustomTextField label="Title" name="title" type="text" />
                </TextFieldContainer>
                <TextFieldContainer>
                  <CustomTextField
                    label={`${type} Link`}
                    name="url"
                    type="text"
                  />
                </TextFieldContainer>
              </TitleLinkContainer>
              <Box>
                <CustomTextField
                  label="Description"
                  name="description"
                  type="text"
                  multiline
                  rowsMax={2}
                />
                <VideoFormBtnContainer>
                  <VideoFormBtn
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Add Video
                  </VideoFormBtn>
                  <VideoFormBtn onClick={setClose}>Cancel</VideoFormBtn>
                </VideoFormBtnContainer>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

VideoForm.propTypes = {
  type: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  setClose: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
};

export default VideoForm;
