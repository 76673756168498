import debounce from "lodash.debounce";
import { getColleges } from "api/domains/colleges";

export const getOptionsFromColleges = (colleges = []) => {
  return colleges.map((college) => ({
    value: college.unitId,
    label: college.schoolName,
  }));
};

export const getOptionsFromSports = (sportList = []) => {
  return sportList.map((sport) => ({
    value: sport.id,
    label: `${sport.name} (${sport.gender})`,
  }));
};

export const loadOptionsCollege = debounce((value, callback) => {
  getColleges({ collegeName: value })
    .then((res) => getOptionsFromColleges(res.data))
    .then((res) => callback(res))
    .catch((e) => callback([]));
}, 250);
