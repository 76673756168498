import styled from "styled-components";
import { Box } from "components/MaterialComponents";
import { Popper } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Close as CloseIcon } from "components/MaterialIcons";

export const Container = styled.div``;

export const MobileContainer = styled.div`
  width: 90%;
`;

export const FilterPopperContent = styled(Box)`
  ${({ theme }) => `
    max-height: 600px;
    overflow: auto;
    width: 600px;
    ${theme.breakpoints.down("xs")} {
      width: 90vw;
    }

    & .MuiCheckbox-root {
      padding: 2px 5px 2px 0;
    }
  `}
`;

export const FilterCloseButton = styled(CloseIcon)`
  cursor: pointer;
`;

export const FilterHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

export const FilterTitle = styled.div`
  text-transform: uppercase;
  font-weight: bold;
`;

export const StyledPopper = styled(Popper)`
  z-index: 10;
`;

export const FilterButton = styled(Button)`
  ${({ theme }) => `{
    margin-left: 30px;
    padding: 11px 11px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;

    ${theme.breakpoints.down("sm")} {
      margin-left: 0;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      border: 1px solid ${theme.palette.common.catskillWhite};
      background: ${theme.palette.common.whisper};

    &:hover {
      background: ${theme.palette.common.catskillWhite};
      color: ${theme.palette.common.allports};
    }
  `}
`;

export const MobileHeaderContainer = styled.div`
  ${({ theme }) => `{
    position: fixed;
    top: 9px;
    left: 0;
    background: ${theme.palette.common.whisper};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9;
    padding: 20px 10px;
  `}
`;

export const MobileContentContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
`;

export const MobileFooterContainer = styled.div`
  ${({ theme }) => `{
    position: fixed;
    bottom: 0;
    left: 0;
    background: ${theme.palette.common.whisper};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    padding: 15px;
  `}
`;
