import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { drawerBodyEnum } from "./drawerBodyEnum";
import { userTypesEnum } from "enums/userTypesEnum";
import { Typography } from "components/MaterialComponents";
import {
  DrawerHeader,
  StyledCloseIcon,
  EmailButton,
  NavigationButton,
  GoogleButton,
} from "./style";

function SignInBody({ onDrawerClose, setDrawerBody, setUserType }) {
  const switchToGoogleUrl = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_API}/api/v2x/user-login/get-google-url`;
  };
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <DrawerHeader>Login now</DrawerHeader>
        <StyledCloseIcon onClick={onDrawerClose} data-testid="close-btn" />
      </Box>
      <Box display="flex" flexDirection="column">
        {/*LOCAL TESTING -- please change*/}
        {/*<OutlookButton*/}
        {/*  startIcon={<FontAwesomeIcon icon={faMicrosoft} />}*/}
        {/*  onClick={notYetImplemented}*/}
        {/*>*/}
        {/*  Login with Outlook*/}
        {/*</OutlookButton>*/}
        <EmailButton
          startIcon={<FontAwesomeIcon icon={faEnvelope} />}
          onClick={() => setDrawerBody(drawerBodyEnum.SignInWithEmail)}
          data-testid="sign-in-btn"
        >
          Login with Email
        </EmailButton>
        <Typography>* Athlete or Parent</Typography>
        <GoogleButton
          startIcon={<FontAwesomeIcon icon={faGoogle} />}
          onClick={switchToGoogleUrl}
        >
          Login with Google
        </GoogleButton>
        <DrawerHeader>Don't have an account yet?</DrawerHeader>
        <NavigationButton
          onClick={() => {
            setDrawerBody(drawerBodyEnum.SignUp);
            setUserType(userTypesEnum.ATHLETE);
          }}
          data-testid="join-athlete-btn"
        >
          Join as athlete
        </NavigationButton>
        <NavigationButton
          onClick={() => {
            setDrawerBody(drawerBodyEnum.SignUp);
            setUserType(userTypesEnum.COACH);
          }}
          data-testid="join-coach-btn"
        >
          Join as coach
        </NavigationButton>
        <NavigationButton
          onClick={() => {
            setDrawerBody(drawerBodyEnum.SignUp);
            setUserType(userTypesEnum.PARENT);
          }}
          data-testid="join-parent-btn"
        >
          Join as parent
        </NavigationButton>
      </Box>
    </>
  );
}

SignInBody.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
  setDrawerBody: PropTypes.func.isRequired,
};

export default SignInBody;
