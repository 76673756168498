import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import sportIconsMap from "maps/sporticons";
import { snakeCase } from "helpers/stringToSnakeCase";
import { StyledLogo, StyledLogoSmall, IconContainer } from "./style";

function TeamLogo({ isHover, sport }) {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));

  const Icon = sportIconsMap[snakeCase(sport)] ?? sportIconsMap["default"];

  return (
    <>
      {matchesXs ? (
        <StyledLogoSmall $isHover={isHover}>
          <IconContainer>
            <Icon />
          </IconContainer>
        </StyledLogoSmall>
      ) : (
        <StyledLogo $isHover={isHover}>
          <IconContainer>
            <Icon />
          </IconContainer>
        </StyledLogo>
      )}
    </>
  );
}

TeamLogo.propTypes = {
  isHover: PropTypes.bool,
  sport: PropTypes.string,
};

export default TeamLogo;
