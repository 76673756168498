import React, { useCallback, useState, useEffect } from "react";
import FilterPopperField from "./FilterPopperField";
import {
  StyledSlider,
  StyledFilterValuesBlue,
  StyledFilterValuesGrey,
} from "./style";

const min = 1000;
const max = 40000;
/*const marks = [
  {
    value: 3000,
    label: "3k",
  },
  {
    value: 10000,
    label: "10k",
  },
  {
    value: 20000,
    label: "20k",
  },
  {
    value: 30000,
    label: "30k",
  },
];*/

function valueLabelFormat(value) {
  return `$${value / 1000}K${value >= 35000 ? "+" : ""}`;
}

function CostFilter({ filter, onChange }) {
  const { costMin, costMax } = filter;
  const [value, setValue] = useState([
    parseInt(costMin) || min,
    parseInt(costMax) >= 0 ? parseInt(costMax) : min,
  ]);

  useEffect(() => {
    setValue([
      parseInt(costMin) || min,
      parseInt(costMax) >= 0 ? parseInt(costMax) : min,
    ]);
  }, [costMax, costMin]);

  const onValueChange = useCallback(
    (e, newValue) => {
      setValue(newValue);
    },
    [setValue],
  );
  const onChangeCommitted = useCallback(
    (e, [costMin, costMax]) => {
      if (costMax === min) {
        onChange({
          ...filter,
          costMin: null,
          costMax: null,
        });
        return;
      }
      onChange({
        ...filter,
        costMin,
        costMax,
      });
    },
    [onChange, filter],
  );
  return (
    <FilterPopperField label="Total Cost:">
      {(value[0] === min) & (value[1] === min) ? (
        <StyledFilterValuesGrey variant="h5">
          Select the range
        </StyledFilterValuesGrey>
      ) : (
        <StyledFilterValuesBlue variant="h5">{`${value[0]} - ${value[1]}`}</StyledFilterValuesBlue>
      )}
      <StyledSlider
        value={value}
        min={min}
        max={max}
        step={1000}
        onChange={onValueChange}
        onChangeCommitted={onChangeCommitted}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        //marks={marks}
        valueLabelFormat={valueLabelFormat}
      />
    </FilterPopperField>
  );
}

export default CostFilter;
