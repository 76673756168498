import React from "react";
import { Box } from "components/MaterialComponents";
import PropTypes from "prop-types";

import { StyledPopover as Popover, SportButton } from "./style";

const groupNames = {
  REACH: "Reach",
  TARGET: "Target",
  SAFETY: "Safety",
};

function BookmarkCollegePopover({
  id,
  collegeId,
  open,
  anchorEl,
  handleClose,
  handleLike,
  handleUnlike,
  favorite,
}) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <Box data-testid="favorites-popover">
        {favorite?.groupName !== groupNames.REACH && (
          <SportButton
            onClick={() =>
              handleLike({ groupId: 1, groupName: groupNames.REACH }, collegeId)
            }
          >
            <Box>Reach School</Box>
          </SportButton>
        )}
        {favorite?.groupName !== groupNames.TARGET && (
          <SportButton
            onClick={() =>
              handleLike(
                { groupId: 2, groupName: groupNames.TARGET },
                collegeId,
              )
            }
            data-testid="target-button"
          >
            <Box>Target School</Box>
          </SportButton>
        )}
        {favorite?.groupName !== groupNames.SAFETY && (
          <SportButton
            onClick={() =>
              handleLike(
                { groupId: 3, groupName: groupNames.SAFETY },
                collegeId,
              )
            }
            data-testid="safety-button"
          >
            <Box>Safety School</Box>
          </SportButton>
        )}
        {favorite && (
          <SportButton onClick={handleUnlike} data-testid="remove-button">
            <Box>Remove from likes</Box>
          </SportButton>
        )}
      </Box>
    </Popover>
  );
}

BookmarkCollegePopover.propTypes = {
  id: PropTypes.string,
  collegeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  handleLike: PropTypes.func,
  handleUnlike: PropTypes.func,
  favorite: PropTypes.object,
};

export default BookmarkCollegePopover;
