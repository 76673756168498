import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";

import GeneralCard from "components/GeneralCard";
import useSports from "components/useSports";
import { useRecruitingProfileState } from "components/useRecruitingProfile";
import Tooltip from "components/Tooltip";
import {
  SportHeaderContainer,
  CardRegularText,
  CardBoldText,
  CardSelectedSport,
  LinkContainer,
} from "./style";

const sportsMapper = (sportids, sports) => {
  if (!sports) return [];
  return sportids
    .reduce(
      (acc, { sportId }) => [...acc, { id: sportId, name: sports[sportId] }],
      [],
    )
    .filter((sport) => Boolean(sport.name));
};

const renderSports = ({ primary, secondary, tertiary, sport, collegeId }) => {
  const primarySport = primary?.id === sport.id ? primary : null;
  const secondarySport = secondary?.id === sport.id ? secondary : null;
  const tertiarySport = tertiary?.id === sport.id ? tertiary : null;
  const activeSport = primarySport || secondarySport || tertiarySport;
  if (activeSport) {
    return (
      <LinkContainer key={sport.id}>
        <Tooltip
          title={`Message about ${activeSport?.name}`}
          arrow={true}
          placement="top"
        >
          <CardSelectedSport
            to={`/contact-coaches/${collegeId}-${activeSport?.id}`}
          >
            {sport.name}
          </CardSelectedSport>
        </Tooltip>
      </LinkContainer>
    );
  }
  return <CardRegularText key={sport.id}>{sport.name}</CardRegularText>;
};

function SportsCard({ sportids, refProp, collegeId }) {
  const { mensSports, womensSports } = useSports();

  const { profile: recruitingProfile = {} } = useRecruitingProfileState();

  const { sports } = recruitingProfile || {};
  const {
    primary: primarySport,
    secondary: secondarySport,
    tertiary: tertiarySport,
  } = sports || {};

  const currentMensSports = sportsMapper(sportids, mensSports);
  const currentWomensSports = sportsMapper(sportids, womensSports);

  return (
    <GeneralCard header="Sports" refProp={refProp}>
      <Box display="flex" mt={2}>
        <Box mr={20}>
          <SportHeaderContainer>
            <CardBoldText>Men</CardBoldText>
          </SportHeaderContainer>
          {currentMensSports.map((sport) =>
            renderSports({
              primary: primarySport,
              secondary: secondarySport,
              tertiary: tertiarySport,
              sport,
              collegeId,
            }),
          )}
        </Box>
        <Box>
          <SportHeaderContainer>
            <CardBoldText>Women</CardBoldText>
          </SportHeaderContainer>
          {currentWomensSports.map((sport) =>
            renderSports({
              primary: primarySport,
              secondary: secondarySport,
              tertiary: tertiarySport,
              sport,
              collegeId,
            }),
          )}
        </Box>
      </Box>
    </GeneralCard>
  );
}

SportsCard.propTypes = {
  sportids: PropTypes.arrayOf(
    PropTypes.shape({
      unitId: PropTypes.number,
      sportId: PropTypes.number,
    }),
  ),
  refProp: PropTypes.object,
  collegeId: PropTypes.string,
};

export default SportsCard;
