import client from "../adapter";

function sendEmail(body) {
  return client("/coaches/email", { body });
}

function sendEmailToCoachNotInDB(body) {
  return client("/coaches/email/notindb", { body });
}

function loadMessagesByCollegeId(collegeId) {
  return client(`/colleges/contact/${collegeId}`);
}

function loadMessagesGroupedByAthleteId() {
  return client(`/colleges/contact/athlete`);
}

function getContactsSummary() {
  return client(`/colleges/contact`);
}

function updateEmail(messageId, body) {
  return client(`/colleges/contact/${messageId}`, {
    body,
    method: "PATCH",
  });
}

function updateUnreadEmails(body) {
  return client("/colleges/contact/updateunread", {
    body,
    method: "POST",
  });
}

export {
  sendEmail,
  getContactsSummary,
  loadMessagesByCollegeId,
  loadMessagesGroupedByAthleteId,
  updateEmail,
  sendEmailToCoachNotInDB,
  updateUnreadEmails,
};
