import { useEffect, useCallback, useState } from "react";
import { loadSchools } from "api/domains/user";
import { getOptionsFromSearchSchool } from "./selectHelpers";

function useSchools(state, city) {
  const [schoolOptions, setSchoolOptions] = useState(null);

  const getSchools = useCallback(async () => {
    const schools = await loadSchools(state, city);
    const options = getOptionsFromSearchSchool(schools);
    setSchoolOptions(options);
  }, [state, city]);

  useEffect(() => {
    if (Boolean(state) & Boolean(city)) {
      getSchools();
    }
  }, [state, city, getSchools]);

  return schoolOptions;
}

export default useSchools;
