export const profileApiFeedbacks = {
  UPDATE_ERROR: "There was an error trying to update your email address.",
  UPDATE_SUCCESS: "Email updated successfully.",
  VERIFICATION_SEND_SUCCESS:
    "A verification email has been sent to your address.",
  VERIFICATION_SEND_FAIL:
    "There was an error sending a verification email to you address.",
  EMAIL_VERIFICATION_SUCCESS: "Your email has been successfully verified.",
  EMAIL_VERIFICATION_FAIL: "Email could not be verified.",
  SEND_RESET_PASSWORD_EMAIL_SUCCES:
    "An email has been sent. Please click the link when you get it",
  SEND_RESET_PASSWORD_EMAIL_FAILURE:
    "There was an error trying to send link to your email address",
};
