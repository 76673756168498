import React from "react";
import PropTypes from "prop-types";

import getShortName from "helpers/getShortName";
import { StyledAvatar } from "./style";

function LargeCollegeLogo({ name, image, withBorder = false }) {
  return (
    <StyledAvatar
      src={image}
      $withBorder={withBorder}
      alt="college logo"
      variant="circle"
    >
      {getShortName(name)}
    </StyledAvatar>
  );
}

LargeCollegeLogo.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  withBorder: PropTypes.bool,
};

export default LargeCollegeLogo;
