export const SUCCESS = "success";
export const EMPTY_SUCCESS = "empty_success";
export const EMPTY_ERROR = "empty_error";
export const ERROR = "error";
export const LOGOUT = "logout";
export const EMAIL_UPDATE = "email_update";
export const API_ERROR = "api_error";
export const API_SUCCESS = "api_success";
export const SET_FEEDBACK = "set_feedback";
export const CLEAR_FEEDBACK = "clear_feedback";
