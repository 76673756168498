import styled from "styled-components";
import { Box } from "components/MaterialComponents";
import { IconButton } from "components/MaterialComponents";
import { Card } from "components/MaterialComponents";
import { Avatar } from "components/MaterialComponents";
import { Link } from "react-router-dom";
import { LocationOn as LocationOnIcon } from "components/MaterialIcons";
import { School as SchoolIcon } from "components/MaterialIcons";
import { Stars as StarsIcon } from "components/MaterialIcons";
import * as colors from "theme/colors";

export const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardContainer = styled(Box)`
  padding: 20px;
  display: flex;
  flex-grow: 1;
`;

export const LogoContainer = styled(Box)`
  width: 60px;
`;

export const InfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledAvatar = styled(Avatar)`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.Whisper};
  background-color: ${colors.Allports};
`;

export const Name = styled(Link)`
  display: block;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  color: ${colors.Allports};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const InfoBlock = styled.div`
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 8px;
`;

export const Details = styled.div`
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 8px;
  width: 100%;
`;

export const CardInfo = styled(Box)`
  display: flex;
  flex-grow: 1;
  width: 100%;
`;

export const SportsContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const SportNamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SportName = styled.div`
  width: 100%;
`;

export const ActionsIconContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;

export const PositionsContainer = styled.span`
  margin-left: 10px;
`;

export const Position = styled.span`
  ${({ theme }) => `
		margin-right: 14px;
		color: ${theme.palette.common.allports}
	`}
`;

export const StatAnswer = styled.div``;

export const StyledIconButton = styled(IconButton)`
  ${({ theme, $selected }) => `
  padding: 7px;
  & .MuiIconButton-label {
    & .MuiSvgIcon-root {
      font-size: 1.2rem;
      color: ${
        $selected ? theme.palette.common.white : theme.palette.common.allports
      };
    }
  }

  background-color: ${$selected ? theme.palette.common.allports : "inherit"};

  &:hover {
    background-color: ${theme.palette.common.fern};
    & .MuiIconButton-label {
      & .MuiSvgIcon-root {
          color: ${theme.palette.common.white};
      }
    }
  }
`}
`;

export const IconContainer = styled.div`
  width: 70%;
  height: 70%;
`;

export const SportIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

export const CurrencyContainer = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
`;

export const NameSkeleton = styled.div`
  font-size: 17px;
  font-weight: 500;
`;

export const StyledLocationIcon = styled(LocationOnIcon)`
  vertical-align: middle;
  margin-right: 9px;
`;

export const StyledSchoolIcon = styled(SchoolIcon)`
  vertical-align: middle;
  margin-right: 9px;
`;

export const StyledStarsIcon = styled(StarsIcon)`
  vertical-align: middle;
  margin-right: 6px;
`;
