import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { List } from "components/MaterialComponents";
import { ListItem } from "components/MaterialComponents";

import { ProfilePopover as Popover, EmailText } from "./style";

function ProfilePopover({
  id,
  open,
  anchorEl,
  handleClose,
  email,
  recruitingProfileNotCreated,
  openModal,
  handleLogout,
}) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <List data-testid="profile-btn-menu">
        <ListItem>
          <EmailText>{email}</EmailText>
        </ListItem>
        {recruitingProfileNotCreated ? (
          <ListItem
            onClick={() => {
              handleClose();
              openModal();
            }}
            button
          >
            Go to Profile
          </ListItem>
        ) : (
          <ListItem
            component={Link}
            to="/profile/personal-information"
            onClick={handleClose}
            button
          >
            Go to Profile
          </ListItem>
        )}
        <ListItem
          onClick={() => {
            handleClose();
            handleLogout();
          }}
          button
        >
          Log Out
        </ListItem>
      </List>
    </Popover>
  );
}

ProfilePopover.propTypes = {
  handleClose: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  handleLogout: PropTypes.func.isRequired,
  id: PropTypes.string,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  recruitingProfileNotCreated: PropTypes.bool,
  openModal: PropTypes.func,
};

export default ProfilePopover;
