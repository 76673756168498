import React from "react";
import PropTypes from "prop-types";

import { StyledLink } from "./style";

function NewTabLink({ name, href, ...props }) {
  return (
    <StyledLink href={href} target="_blank" rel="noopener" {...props}>
      {name}
    </StyledLink>
  );
}

NewTabLink.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default NewTabLink;
