import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { TableRow } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import CreateEditCoachPopup from "./CreateEditCoachPopup";
import ConfirmDeleteModal from "../ConfirmDeleteModal";
import { Check as CheckIcon } from "components/MaterialIcons";
import { Clear as ClearIcon } from "components/MaterialIcons";

function CollegeCoachesTableRow({ collegeCoach, onDelete, onEdit }) {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const toggleEditModal = useCallback(() => {
    setEditModalOpen(!isEditModalOpen);
  }, [isEditModalOpen]);

  const toggleDeleteModal = useCallback(() => {
    setDeleteModalOpen(!isDeleteModalOpen);
  }, [isDeleteModalOpen]);

  const handleDeleteCoach = useCallback(async () => {
    await onDelete(collegeCoach.id);
    toggleDeleteModal();
  }, [onDelete, collegeCoach, toggleDeleteModal]);

  return (
    <>
      <TableRow key={collegeCoach.id}>
        <TableCell>{collegeCoach.id}</TableCell>
        <TableCell>{collegeCoach.unit_sport}</TableCell>
        <TableCell>{collegeCoach.name}</TableCell>
        <TableCell>{collegeCoach.title}</TableCell>
        <TableCell>{collegeCoach.college_sport.college.schoolName}</TableCell>
        <TableCell>{`${collegeCoach.college_sport.sport.name} (${collegeCoach.college_sport.sport.gender})`}</TableCell>
        <TableCell>{collegeCoach.email}</TableCell>
        <TableCell>{collegeCoach.phone}</TableCell>
        <TableCell>{collegeCoach.pageUrl}</TableCell>
        <TableCell>{collegeCoach.selector}</TableCell>

        <TableCell>
          {collegeCoach.resourceLoaded ? <CheckIcon /> : <ClearIcon />}
        </TableCell>
        <TableCell>
          {collegeCoach.selectorValid ? <CheckIcon /> : <ClearIcon />}
        </TableCell>
        <TableCell>
          {collegeCoach.emailValid ? <CheckIcon /> : <ClearIcon />}
        </TableCell>
        <TableCell>
          {collegeCoach.coachNameOnPage ? <CheckIcon /> : <ClearIcon />}
        </TableCell>
        <TableCell>
          {collegeCoach.emailOnPage ? <CheckIcon /> : <ClearIcon />}
        </TableCell>
        <TableCell>{collegeCoach.lastCheck}</TableCell>

        <TableCell>
          <Button color="primary" onClick={toggleEditModal}>
            Edit
          </Button>
        </TableCell>
        <TableCell>
          <Button color="secondary" onClick={toggleDeleteModal}>
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <CreateEditCoachPopup
        open={isEditModalOpen}
        onClose={toggleEditModal}
        onEditCoach={onEdit}
        item={collegeCoach}
      />
      <ConfirmDeleteModal
        open={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        onDelete={handleDeleteCoach}
        modalText="Are you sure you want to delete all college coach data?"
      />
    </>
  );
}

CollegeCoachesTableRow.propTypes = {
  college: PropTypes.object,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onAddSports: PropTypes.func,
  onDeleteSport: PropTypes.func,
};

export default CollegeCoachesTableRow;
