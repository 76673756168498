function getPastDateFromCurrent(years) {
  let pastDefaultDate = new Date();
  pastDefaultDate.setFullYear(pastDefaultDate.getFullYear() - years);
  pastDefaultDate.setMonth(0);
  pastDefaultDate.setDate(1);

  return pastDefaultDate;
}

export default getPastDateFromCurrent;
