import React from "react";
import PropTypes from "prop-types";
import CustomTextField from "components/CustomTextField";
import { Box } from "components/MaterialComponents";
import { Formik, Form } from "formik";
import * as yup from "yup";
import PasswordDescription from "./PasswordDescription";
import {
  useProfileDispatch,
  changePassword,
  loadCurrentUser,
} from "components/useProfile";
import * as validationHelper from "helpers/validationHelper";

import {
  DrawerHeader,
  StyledCloseIcon,
  EmailButton,
  LoginContainer,
} from "./style";

const validationSchema = yup.object().shape({
  newPassword: validationHelper.password,
  confirmNewPassword: yup
    .string()
    .when("newPassword", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref("newPassword")], "Both passwords must match"),
    })
    .required(
      "Required - Looks like you forgot about this field. Please re-enter your new password",
    ),
});

function SetNewPasswordBody({ onDrawerClose, resetPasswordToken }) {
  const profileDispatch = useProfileDispatch();

  const handleFormSubmit = async (data, { setFieldError }) => {
    const body = {
      ...data,
      token: resetPasswordToken,
    };

    await changePassword(profileDispatch, body);
    await loadCurrentUser(profileDispatch);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <DrawerHeader>Change password</DrawerHeader>
        <StyledCloseIcon onClick={onDrawerClose} />
      </Box>
      <PasswordDescription />
      <Formik
        validateOnChange
        initialValues={{
          newPassword: "",
          confirmNewPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, setFieldValue, handleChange, isSubmitting }) => (
          <Form>
            <LoginContainer>
              <CustomTextField
                label="New password"
                name="newPassword"
                type="password"
              />
              <CustomTextField
                label="Re-enter your new password"
                name="confirmNewPassword"
                type="password"
              />
              <EmailButton disabled={isSubmitting} type="submit">
                Change Password
              </EmailButton>
            </LoginContainer>
          </Form>
        )}
      </Formik>
    </>
  );
}

SetNewPasswordBody.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
};

export default SetNewPasswordBody;
