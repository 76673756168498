import { Grid } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import React from "react";

function FilterPopperField({
  children,
  label,
  labelColumnSize = 4,
  childrenColumnSize = 6,
  mobileColumnSize = 12,
}) {
  return (
    <Box pb={1} pt={1}>
      <Grid container spacing={1}>
        <Grid item sm={labelColumnSize} xs={mobileColumnSize}>
          <Typography gutterBottom variant="h5">
            {label}
          </Typography>
        </Grid>
        <Grid item sm={childrenColumnSize} xs={mobileColumnSize}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}

export default FilterPopperField;
