import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import { StyledDrawer } from "./style";
import { drawerBodyEnum } from "./drawerBodyEnum";
import SignInBody from "./SignInBody";
import SignUpBody from "./SignUpBody";
import SignUpBodyCoach from "./SignUpBodyCoach";
import SignInWithEmail from "./SignInWithEmail";
import SendResetPasswordLinkBody from "./SendResetPasswordLinkBody";
import SetNewPasswordBody from "./SetNewPasswordBody";
import { userTypesEnum } from "enums";

function AuthenticationDrawer({ open, setOpen }) {
  const [drawerBody, setDrawerBody] = useState(drawerBodyEnum.SignIn);
  const [userType, setUserType] = useState();

  const location = useLocation();
  const resetPasswordToken = location.pathname.split("/reset-password/")[1];

  const onDrawerClose = () => {
    setOpen(false);
    setDrawerBody(drawerBodyEnum.SignIn);
  };

  useEffect(() => {
    if (resetPasswordToken) {
      setDrawerBody(drawerBodyEnum.SetNewPassword);
      setOpen(true);
    }
  }, [resetPasswordToken]);

  const renderSignUpBody = () => {
    if (userType === userTypesEnum.COACH) {
      return (
        <SignUpBodyCoach
          onDrawerClose={onDrawerClose}
          setDrawerBody={setDrawerBody}
          userType={userType}
        />
      );
    }
    return (
      <SignUpBody
        onDrawerClose={onDrawerClose}
        setDrawerBody={setDrawerBody}
        userType={userType}
      />
    );
  };

  const isSignIn = drawerBody === drawerBodyEnum.SignIn;
  const isSignUp = drawerBody === drawerBodyEnum.SignUp;
  const isSignInWithEmail = drawerBody === drawerBodyEnum.SignInWithEmail;
  const isSendResetPasswordLink =
    drawerBody === drawerBodyEnum.SendResetLinkToEmail;
  const isCreateNewPassword = drawerBody === drawerBodyEnum.SetNewPassword;

  return (
    <StyledDrawer anchor="right" open={open} onClose={onDrawerClose}>
      {isSignIn && (
        <SignInBody
          onDrawerClose={onDrawerClose}
          setDrawerBody={setDrawerBody}
          setUserType={setUserType}
        />
      )}
      {isSignUp && renderSignUpBody()}
      {isSignInWithEmail && (
        <SignInWithEmail
          onDrawerClose={onDrawerClose}
          setDrawerBody={setDrawerBody}
        />
      )}
      {isSendResetPasswordLink && (
        <SendResetPasswordLinkBody onDrawerClose={onDrawerClose} />
      )}
      {isCreateNewPassword && (
        <SetNewPasswordBody
          onDrawerClose={onDrawerClose}
          resetPasswordToken={resetPasswordToken}
        />
      )}
    </StyledDrawer>
  );
}

AuthenticationDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default AuthenticationDrawer;
