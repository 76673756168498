import React, { useState, useRef, useEffect, useCallback } from "react";
import { io } from "socket.io-client";
import { makeStyles } from "components/MaterialComponents";
import { Table } from "components/MaterialComponents";
import { TableBody } from "components/MaterialComponents";
import { TableContainer } from "components/MaterialComponents";
import { TableRow } from "components/MaterialComponents";
import { TablePagination } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { LinearProgress } from "components/MaterialComponents";
import isempty from "lodash.isempty";
import * as collegeCoachesDomain from "api/domains/collegeCoach";
import CollegeCoachesTableRow from "./CollegeCoachesTableRow";
import CreateEditCoachPopup from "./CreateEditCoachPopup";
import CSVReader from "./CSVReader";
import CreateMultipleCoachesPopup from "./CreateMultipleCoachesPopup";
import ConfirmUploadBigFile from "./ConfirmUploadBigFile";
import Feedbacker from "components/Feedbacker";
import CoachErrorFilterButton from "./CoachErrorFilterButton";
import NameFilter from "components/NameFilter";
import formatDateTime from "helpers/formatDateTime";

import {
  TableHeadContainer,
  StyledTableHead,
  StyledHeaderTableCell,
} from "./style";

const filterFields = {
  1: "resource_loaded",
  2: "selector_valid",
  3: "email_valid",
  4: "coach_name_on_page",
  5: "email_on_page",
};

const useStyles = makeStyles({
  table: {
    width: "95%",
    height: "63vh",
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "white",
    overflowY: "auto",
  },
});

const requiredHeaders = [
  "name",
  "title",
  "phoneNumber",
  "email",
  "selector",
  "pageUrl",
  "unitSport",
];
function checkFileForRequiredHeaders(firstFlileRow, requiredHeaders) {
  const fileHeaders = Object.keys(firstFlileRow);
  return requiredHeaders.every((element) => fileHeaders.includes(element));
}

function CollegeCoachesList() {
  const [collegeCoachesData, setCollegeCoachesData] = useState([]);
  const [coachErrorsData, setCoachErrorsData] = useState(null);

  const [collegeCoachesDataFromCSV, setCollegeCoachesDataFromCSV] = useState(
    [],
  );
  const [isCoachesCSVModalVisible, setCoachesCSVModalVisible] = useState(false);
  const [
    isConfirmFileUploadModalVisible,
    setConfirmFileUploadModalVisible,
  ] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  const maxCoachesForPreview = 100;

  const firstPage = 0;
  const rows = 10;
  const [page, setPage] = useState(firstPage);
  const [rowsPerPage, setRowsPerPage] = useState(rows);

  const [scriptData, setScriptData] = useState({});
  const [filterId, setFilterId] = useState(null);

  const [isDisabledStopBtn, setDisabledStopBtn] = useState(false);

  const [queryString, setQueryString] = useState("");

  const [isCreateModalOpen, setCreateModalOpen] = useState(false);

  const tableContainerRef = useRef(null);

  const loadCollegeCoaches = useCallback(async () => {
    const errorFilter = filterId ? filterFields[filterId] : "";
    try {
      const data = await collegeCoachesDomain.getCollegeCoaches(
        page + 1,
        rowsPerPage,
        errorFilter,
        queryString,
      );
      setCollegeCoachesData(data);
    } catch (error) {
      setLoadError(error);
    }
  }, [page, rowsPerPage, filterId, queryString]);

  const loadCollegeCoachesErrorsSummary = useCallback(async () => {
    try {
      const data = await collegeCoachesDomain.getCollegeCoachesErrorsSummary();
      setCoachErrorsData(data[0]);
    } catch (error) {
      setLoadError(error);
    }
  }, []);

  const handleDataFromCSVFile = useCallback((data) => {
    if (!checkFileForRequiredHeaders(data[0], requiredHeaders)) {
      setLoadError({
        message:
          "Imported file should contain required headers in format: name, title, phoneNumber, email, selector, pageUrl, unitSport",
      });
      return;
    }

    setCollegeCoachesDataFromCSV(data.filter((el) => el.unitSport));
    if (data.length > maxCoachesForPreview) {
      setConfirmFileUploadModalVisible(true);
      return;
    }
    setCoachesCSVModalVisible(true);
  }, []);

  // useEffect(() => {
  //   const socket = io.connect(process.env.REACT_APP_SCRAPING_SERVER_SOCKET);

  //   socket.on(`start`, (data) => {
  //     setScriptData(data);
  //   });

  //   socket.on(`stop`, (data) => {
  //     setScriptData(data);
  //     setDisabledStopBtn(false);
  //   });

  //   socket.on(`disconnect`, (data) => {
  //     setScriptData({});
  //   });
  // }, []);

  useEffect(() => {
    loadCollegeCoaches();
  }, [loadCollegeCoaches]);

  useEffect(() => {
    loadCollegeCoachesErrorsSummary();
  }, []);

  const scrollTop = useCallback(() => {
    if (!tableContainerRef.current) {
      return;
    }
    tableContainerRef.current.scrollTop = 0;
    tableContainerRef.current.scrollLeft = 0;
  }, [tableContainerRef]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      setPage(newPage);
      scrollTop();
    },
    [scrollTop],
  );

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleCreateCollegeCoach = useCallback(
    async (body) => {
      await collegeCoachesDomain.createCollegeCoach(body);
      loadCollegeCoaches();
    },
    [loadCollegeCoaches],
  );

  const handleBulkCreateCollegeCoach = useCallback(
    async (body) => {
      try {
        setLoadingState(true);

        if (body.fields.length > maxCoachesForPreview) {
          let tempCoachesArray = [];
          for (const coach of body.fields) {
            if (tempCoachesArray.length === maxCoachesForPreview) {
              await collegeCoachesDomain.collegeCoachBulkCreation({
                fields: tempCoachesArray,
              });
              tempCoachesArray = [];
            }
            tempCoachesArray.push(coach);
          }

          await collegeCoachesDomain.collegeCoachBulkCreation({
            fields: tempCoachesArray,
          });
        } else {
          await collegeCoachesDomain.collegeCoachBulkCreation(body);
        }
      } catch (error) {
        setLoadError(error);
      }
      await loadCollegeCoaches();
      setLoadingState(false);
    },
    [loadCollegeCoaches],
  );

  const handleDeleteCollege = useCallback(
    async (id) => {
      await collegeCoachesDomain.deleteCollegeCoach(id);
      loadCollegeCoaches();
    },
    [loadCollegeCoaches],
  );

  const handleEditCollege = useCallback(
    async (id, body) => {
      await collegeCoachesDomain.editCollegeCoach(id, body);
      loadCollegeCoaches();
      loadCollegeCoachesErrorsSummary();
    },
    [loadCollegeCoaches, loadCollegeCoachesErrorsSummary],
  );

  const toggleCreateModal = useCallback(() => {
    setCreateModalOpen(!isCreateModalOpen);
  }, [isCreateModalOpen]);

  const selectFilterButton = useCallback(
    (id) => {
      if (id === filterId) {
        setFilterId(null);
        return;
      }
      setFilterId(id);
    },
    [filterId],
  );

  const stopScript = useCallback(() => {
    collegeCoachesDomain.stopScript();
    setDisabledStopBtn(true);
  }, []);

  const handleNameStringChange = useCallback((value) => {
    setQueryString(value.query);
  }, []);

  const classes = useStyles();

  return (
    <>
      <TableHeadContainer>
        <Box marginRight="10px" marginLeft="10px">
          <Typography variant="h4">College Coaches</Typography>
        </Box>
        <Button color="primary" variant="outlined" onClick={toggleCreateModal}>
          Add new
        </Button>
        <Box marginLeft="30px" marginTop="10px" width="300px">
          <CSVReader onDataReady={handleDataFromCSVFile} />
        </Box>
        <Box marginLeft="30px" display="flex" alignItems="flex-end">
          <Button
            color="primary"
            variant="outlined"
            disabled={scriptData?.isRunning}
            onClick={collegeCoachesDomain.startScript}
          >
            Start script
          </Button>
          <Box marginLeft="10px">
            <Button
              color="secondary"
              variant="outlined"
              disabled={isDisabledStopBtn}
              onClick={stopScript}
            >
              Stop script
            </Button>
          </Box>
          {scriptData.isRunning ? (
            <Box marginLeft="30px">
              Script is running... {scriptData.checked} from {scriptData.total}
              <LinearProgress
                variant="determinate"
                value={(scriptData.checked * 100) / scriptData.total}
              />
            </Box>
          ) : (
            <>
              {!isempty(scriptData) && (
                <Box marginLeft="30px">
                  Last check on{" "}
                  {formatDateTime(scriptData.lastCheckDate, "lll")} checked{" "}
                  {scriptData.checked} from {scriptData.total}
                </Box>
              )}
            </>
          )}
        </Box>
      </TableHeadContainer>
      <Box width="95%" display="flex">
        <Box width="250px" marginRight="20px">
          <NameFilter
            onChange={handleNameStringChange}
            placeholder="Name search"
          />
        </Box>
        {coachErrorsData && (
          <Box display="flex">
            <CoachErrorFilterButton
              id="1"
              label="Not loaded"
              value={coachErrorsData.notLoaded}
              onSelect={selectFilterButton}
              currentId={filterId}
            />
            <CoachErrorFilterButton
              id="2"
              label="Selector not valid"
              value={coachErrorsData.selectorNotValid}
              onSelect={selectFilterButton}
              currentId={filterId}
            />
            <CoachErrorFilterButton
              id="3"
              label="Selector content not equals email"
              value={coachErrorsData.selectorContentNotValid}
              onSelect={selectFilterButton}
              currentId={filterId}
            />
            <CoachErrorFilterButton
              id="4"
              label="Name not on page"
              value={coachErrorsData.nameNotOnPage}
              onSelect={selectFilterButton}
              currentId={filterId}
            />
            <CoachErrorFilterButton
              id="5"
              label="Email not on page"
              value={coachErrorsData.emailNotOnPage}
              onSelect={selectFilterButton}
              currentId={filterId}
            />
          </Box>
        )}
      </Box>
      <TableContainer className={classes.table} ref={tableContainerRef}>
        <Table stickyHeader>
          <StyledTableHead>
            <TableRow>
              <StyledHeaderTableCell>Id</StyledHeaderTableCell>
              <StyledHeaderTableCell>Unit sport</StyledHeaderTableCell>
              <StyledHeaderTableCell>Name</StyledHeaderTableCell>
              <StyledHeaderTableCell>Title</StyledHeaderTableCell>
              <StyledHeaderTableCell>College</StyledHeaderTableCell>
              <StyledHeaderTableCell>Sport</StyledHeaderTableCell>
              <StyledHeaderTableCell>Email</StyledHeaderTableCell>
              <StyledHeaderTableCell>Phone</StyledHeaderTableCell>
              <StyledHeaderTableCell>Page URL</StyledHeaderTableCell>
              <StyledHeaderTableCell>Selector</StyledHeaderTableCell>

              <StyledHeaderTableCell>Resource loaded</StyledHeaderTableCell>
              <StyledHeaderTableCell>Selector valid</StyledHeaderTableCell>
              <StyledHeaderTableCell>
                Selector equals email
              </StyledHeaderTableCell>
              <StyledHeaderTableCell>Name on page</StyledHeaderTableCell>
              <StyledHeaderTableCell>Email on page</StyledHeaderTableCell>
              <StyledHeaderTableCell>Last check</StyledHeaderTableCell>

              <StyledHeaderTableCell>EDIT</StyledHeaderTableCell>
              <StyledHeaderTableCell>DELETE</StyledHeaderTableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {collegeCoachesData.data?.map((coach) => (
              <CollegeCoachesTableRow
                key={coach.id}
                collegeCoach={coach}
                onDelete={handleDeleteCollege}
                onEdit={handleEditCollege}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 50, 100]}
        component="div"
        count={collegeCoachesData.total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <CreateEditCoachPopup
        open={isCreateModalOpen}
        onClose={toggleCreateModal}
        onCreateCoach={handleCreateCollegeCoach}
      />
      <CreateMultipleCoachesPopup
        open={isCoachesCSVModalVisible}
        onClose={() => setCoachesCSVModalVisible(false)}
        csvData={collegeCoachesDataFromCSV}
        onCreate={handleBulkCreateCollegeCoach}
        loadingState={loadingState}
      />
      <ConfirmUploadBigFile
        open={isConfirmFileUploadModalVisible}
        onClose={() => setConfirmFileUploadModalVisible(false)}
        onSendData={() =>
          handleBulkCreateCollegeCoach({ fields: collegeCoachesDataFromCSV })
        }
        loadingState={loadingState}
      />
      <Feedbacker
        open={Boolean(loadError)}
        severity="error"
        feedbackMessage={loadError.message}
        clearFeedback={() => setLoadError(null)}
      />
    </>
  );
}

export default CollegeCoachesList;
