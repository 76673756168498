import React, { useMemo, useCallback } from "react";
import useSports from "components/useSports";
import useStates from "components/useStates";
import useMajors from "components/useMajors";
import getListOfmajors from "helpers/getListOfMajors";
import {
  StyledItem,
  StyledItemsContainer,
  FilterItemCloseButton,
} from "./style";
import {
  getNamesFromOptions,
  removeItemFromField,
  getFieldsToRemove,
} from "helpers/filterPreviewHelpers";
import { capitalizeEachWord } from "helpers/capitalizeStrings";

function transformDivision(division = "") {
  return division.replace(/_/g, " ");
}

function transformField(field) {
  return capitalizeEachWord(field.replace(/_/g, " "));
}

function FiltersPreview({ filter, onChange }) {
  const {
    mensSports: mensSportsOptions,
    womensSports: womensSportsOptions,
  } = useSports();
  const { states: stateOptions } = useStates();
  const { majors: majorsOptions } = useMajors();
  const majorsList = useMemo(() => getListOfmajors(majorsOptions), [
    majorsOptions,
  ]);

  const {
    majors,
    states,
    mensSports,
    womensSports,
    enrollmentMax,
    enrollmentMin,
    actMax,
    costMax,
    costMin,
    gpaMax,
    satMax,
    division,
    acceptanceRate,
    campusSettings,
  } = filter;

  const mapFilterToPreviwItems = {
    useInStateCost: {
      name: "Use in state cost",
      filterField: ["useInStateCost"],
    },
    campusSettings: (Boolean(campusSettings) ? campusSettings : "")
      .split(",")
      .map((set) => ({
        name: transformField(set),
        value: set,
        filterField: "campusSettings",
      })),
    acceptanceRate: (Boolean(acceptanceRate) ? acceptanceRate : "")
      .split(",")
      .map((acc) => ({
        name: transformField(acc),
        value: acc,
        filterField: "acceptanceRate",
      })),
    division: (Boolean(division) ? division : "").split(",").map((div) => ({
      name: `Division: ${transformDivision(div)}`,
      value: div,
      filterField: "division",
    })),
    gpaMax: {
      name: `GPA: ${gpaMax}`,
      filterField: ["gpaMax", "gpaMin", "nullGPA"],
    },
    satMax: {
      name: `SAT: ${satMax}`,
      filterField: ["satMax", "satMin", "nullSAT"],
    },
    costMax: {
      name: `Cost: ${costMin}-${costMax}`,
      filterField: ["costMax", "costMin"],
    },
    actMax: {
      name: `ACT: ${actMax}`,
      filterField: ["actMax", "actMin", "nullACT"],
    },
    enrollmentMax: {
      name: `Enrollment: ${enrollmentMin}-${enrollmentMax}`,
      filterField: ["enrollmentMax", "enrollmentMin"],
    },
    majors: getNamesFromOptions(majors, majorsList, "majors"),
    mensSports: getNamesFromOptions(
      mensSports,
      mensSportsOptions,
      "mensSports",
    ),
    states: getNamesFromOptions(states, stateOptions, "states"),
    womensSports: getNamesFromOptions(
      womensSports,
      womensSportsOptions,
      "womensSports",
    ),
  };

  const calculatedFilters = Object.keys(filter)
    .map((key) => (filter[key] ? mapFilterToPreviwItems[key] : null))
    .flat()
    .filter((el) => Boolean(el) !== false);

  const removeFilter = useCallback(
    (target) => {
      const { value, filterField } = target;

      if (value) {
        onChange({
          ...filter,
          [filterField]: removeItemFromField(filter[filterField], value),
        });
      }

      if (Array.isArray(filterField)) {
        onChange({
          ...filter,
          ...getFieldsToRemove(filterField),
        });
      }
    },
    [filter, onChange],
  );

  return (
    <StyledItemsContainer>
      {calculatedFilters.map((filter, index) =>
        filter ? (
          <StyledItem key={index}>
            {filter.name}
            <FilterItemCloseButton onClick={() => removeFilter(filter)} />
          </StyledItem>
        ) : null,
      )}
    </StyledItemsContainer>
  );
}

export default FiltersPreview;
