import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import GeneralCard from "components/GeneralCard";
import videoFormStateEnum from "../HighlightReel/videoFormStateEnum";
import VideoModal from "../HighlightReel/VideoModal";
import { RegularText, Subtitle1 } from "components/Typography";
import { getVideoType } from "helpers/videoUrlHelper";
import {
  StyledHudlIcon,
  StyledYoutubeIcon,
  VideoDescription,
  VideoItemContainer,
  VideoPreviewImg,
} from "../HighlightReel/style";

function Videos({
  data,
  handleVideoPreviewClick,
  videoModalOpen,
  setVideoModalOpen,
  currentVideo,
  getImg,
}) {
  return (
    <>
      <GeneralCard header="Videos">
        {data.profileVideos?.map((video) => (
          <VideoItemContainer key={video.id}>
            <VideoPreviewImg
              variant="square"
              onClick={() => handleVideoPreviewClick(video.url)}
              src={getImg(video.url)}
            />
            {getVideoType(video.url) === videoFormStateEnum.youtube && (
              <StyledYoutubeIcon />
            )}
            {getVideoType(video.url) === videoFormStateEnum.hudl && (
              <StyledHudlIcon />
            )}
            <Box>
              <Subtitle1>{video.title}</Subtitle1>
              <VideoDescription>
                <RegularText>
                  {video.description || "(No Description)"}
                </RegularText>
              </VideoDescription>
            </Box>
          </VideoItemContainer>
        ))}
      </GeneralCard>
      <VideoModal
        open={videoModalOpen}
        onClose={() => setVideoModalOpen(false)}
        currentVideo={currentVideo}
      />
    </>
  );
}

Videos.propTypes = {
  data: PropTypes.array,
  handleVideoPreviewClick: PropTypes.func,
  videoModalOpen: PropTypes.func,
  setVideoModalOpen: PropTypes.func,
  currentVideo: PropTypes.object,
  getImg: PropTypes.func,
};

export default Videos;
