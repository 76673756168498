import client from "../adapter";

function saveFilter(body) {
  return client(`/filter`, {
    body,
    method: "POST",
  });
}

function deleteFilter(filterId) {
  return client(`/filter/${filterId}`, {
    method: "DELETE",
  });
}

export { saveFilter, deleteFilter };
