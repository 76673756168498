import React, { useState } from "react";
import { Card } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { Skeleton } from "components/MaterialComponents";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faFutbol } from "@fortawesome/free-solid-svg-icons";

import GeneralCard from "components/GeneralCard";
import { Subtitle, RegularText } from "components/Typography";
import useRecruitingProfile, {
  updateRecruitingProfile,
} from "components/useRecruitingProfile";
import VideoForm from "./VideoForm";
import VideoModal from "./VideoModal";
import DeleteVideoModal from "./DeleteVideoModal";
import VideosContainer from "./VideosContainer";
import videoFormStateEnum from "./videoFormStateEnum";
import useClearFeedback from "customHooks/useClearFeedback";
import { getPlayableString, getVideoType } from "helpers/videoUrlHelper";
import { CardContainer } from "../style";
import { VideoProviders, AddFromYoutube, AddFromHudle } from "./style";

function HiglightReel() {
  const [videoForm, setVideoForm] = useState(videoFormStateEnum.closed);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [videoIdToDelete, setVideoIdToDelete] = useState(null);
  const [{ profile, loading, updating }, dispatch] = useRecruitingProfile();
  useClearFeedback(dispatch);

  if (loading) {
    return (
      <Skeleton variant="text" width="100%" style={{ marginTop: "2em" }} />
    );
  }

  const sportPositions = Object.values(profile.sports)
    .flatMap((sport) => sport.sportPositions)
    .filter(Boolean);

  const handleVideoFormState = (type) => {
    setVideoForm(type);
  };

  const handleVideoFormClose = () => {
    handleVideoFormState(videoFormStateEnum.closed);
  };

  const handleVideoPreviewClick = (url) => {
    const type = getVideoType(url);
    const playableString = getPlayableString(url, type);

    setCurrentVideo({ playableString, type });
    setVideoModalOpen(true);
  };

  const handleOpenDeleteModal = (id) => {
    setVideoIdToDelete(id);
    setDeleteModalOpen(true);
  };

  const { profileVideos } = profile;

  const handleDeleteVideo = async () => {
    const newVideos = profileVideos.filter(
      (video) => video.id !== videoIdToDelete,
    );

    const dataToSend = {
      ...profile,
      profileVideos: newVideos,
      sportPositions,
    };

    await updateRecruitingProfile(dispatch, profile.userId, dataToSend);
    setDeleteModalOpen(false);
  };

  const handleFormSubmit = async (data, { setSubmitting }) => {
    const lastVideo = profileVideos.length - 1;
    const ordinal = profileVideos[lastVideo]?.ordinal + 1;
    const newVideo = { ...data, ordinal: ordinal || 1 };

    const dataToSend = {
      ...profile,
      profileVideos: [...profileVideos, newVideo],
      sportPositions,
    };

    await updateRecruitingProfile(dispatch, profile.userId, dataToSend);
    setSubmitting(false);
    handleVideoFormClose();
  };

  const handleDragEnd = async ({ destination, source, draggableId }) => {
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }
    const draggebleItem = profileVideos.find(({ id }) => +draggableId === id);
    let newProfileVideos = [...profileVideos];

    newProfileVideos.splice(source.index, 1);
    newProfileVideos.splice(destination.index, 0, draggebleItem);
    newProfileVideos = newProfileVideos.map((video, index) => ({
      ...video,
      ordinal: index + 1,
    }));

    const dataToSend = {
      ...profile,
      profileVideos: newProfileVideos,
    };

    dispatch({ type: "success", data: dataToSend });
    await updateRecruitingProfile(dispatch, profile.userId, dataToSend);
  };

  const handleIncludeSport = async (videoId, sportId, sportIds) => {
    const alreadyExist = sportIds.includes(sportId);
    const deleteSportId = (id) => id !== sportId;
    const createNewVideo = (video) => ({
      ...video,
      sportIds: alreadyExist
        ? sportIds.filter(deleteSportId)
        : [...sportIds, sportId],
    });
    const newProfileVideos = profileVideos.map((video) =>
      video.id === videoId ? createNewVideo(video) : video,
    );

    const dataToSend = {
      ...profile,
      profileVideos: newProfileVideos,
      sportPositions,
    };

    await updateRecruitingProfile(dispatch, profile.userId, dataToSend);
  };

  const closedState = videoForm === videoFormStateEnum.closed;
  const youtubeState = videoForm === videoFormStateEnum.youtube;
  const hudlState = videoForm === videoFormStateEnum.hudl;

  return (
    <CardContainer>
      <GeneralCard header="Highlight Reel">
        <Box p="2em">
          <Card>
            {closedState && (
              <VideoProviders>
                <AddFromYoutube
                  onClick={() =>
                    handleVideoFormState(videoFormStateEnum.youtube)
                  }
                >
                  <FontAwesomeIcon icon={faYoutube} size="6x" />
                  <RegularText>Add From Youtube</RegularText>
                </AddFromYoutube>
                <AddFromHudle
                  onClick={() => handleVideoFormState(videoFormStateEnum.hudl)}
                >
                  <FontAwesomeIcon icon={faFutbol} size="6x" />
                  <RegularText>Add From Hudl</RegularText>
                </AddFromHudle>
              </VideoProviders>
            )}
            {youtubeState && (
              <VideoForm
                setClose={handleVideoFormClose}
                type={videoFormStateEnum.youtube}
                icon={faYoutube}
                handleFormSubmit={handleFormSubmit}
              />
            )}
            {hudlState && (
              <VideoForm
                setClose={handleVideoFormClose}
                type={videoFormStateEnum.hudl}
                icon={faFutbol}
                handleFormSubmit={handleFormSubmit}
              />
            )}
          </Card>
          <Subtitle my="2em">Your Videos</Subtitle>
          <DragDropContext onDragEnd={handleDragEnd}>
            {profileVideos.length ? (
              <Droppable droppableId="video-container">
                {(provided) => (
                  <VideosContainer
                    updating={updating}
                    provided={provided}
                    videos={profileVideos}
                    handleVideoPreviewClick={handleVideoPreviewClick}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                    handleIncludeSport={handleIncludeSport}
                  />
                )}
              </Droppable>
            ) : (
              <RegularText textAlign="center">
                You don't have any videos on your profile.
              </RegularText>
            )}
          </DragDropContext>
        </Box>
      </GeneralCard>
      <VideoModal
        open={videoModalOpen}
        onClose={() => setVideoModalOpen(false)}
        currentVideo={currentVideo}
      />
      <DeleteVideoModal
        open={deleteModalOpen}
        updating={updating}
        handleDeleteVideo={handleDeleteVideo}
        handleClose={() => setDeleteModalOpen(false)}
      />
    </CardContainer>
  );
}

export default HiglightReel;
