import React from "react";
import { InputLabel } from "components/MaterialComponents";
import { MenuItem } from "components/MaterialComponents";
import PropTypes from "prop-types";

import { SportStatFormControl } from "../style";
import { StyledSelect } from "./style";

function SportStatSelect({
  labelId,
  label,
  index,
  value,
  selectOptions,
  setFieldValue,
}) {
  return (
    <SportStatFormControl>
      <InputLabel id={labelId}>{label}</InputLabel>
      <StyledSelect
        labelId={labelId}
        label={label}
        value={value}
        onChange={(e) =>
          setFieldValue(
            `sportStatsWithAnswers.${index}.answer.valueText`,
            e.target.value,
          )
        }
      >
        {selectOptions.map((option) => (
          <MenuItem key={option.id} value={option.optionValue}>
            {option.optionValue}
          </MenuItem>
        ))}
      </StyledSelect>
    </SportStatFormControl>
  );
}

SportStatSelect.propTypes = {
  labelId: PropTypes.string,
  label: PropTypes.string,
  index: PropTypes.number,
  value: PropTypes.string,
  selectOptions: PropTypes.array,
  setFieldValue: PropTypes.func,
};

export default SportStatSelect;
