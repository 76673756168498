import styled from "styled-components";
import { Container } from "components/MaterialComponents";
import { Grid } from "components/MaterialComponents";

export const MainGridContainer = styled(Grid)`
  justify-content: center;
`;

export const SidebarGridItem = styled(Grid)`
  ${({ theme }) => `
  background: ${theme.palette.common.blumine};
  padding-top: 39px;
  min-height: calc(100vh - 72px);
  ${theme.breakpoints.down("xs")} {
      min-height: unset;
      width: 100%;
      padding: 0;
    }
    @media (min-width: 1150px) {
      max-width: 23%;
      flex-basis: 23%;
    }
    @media (min-width: 1280px) {
      max-width: 20%;
      flex-basis: 20%;
    }
    @media (min-width: 1575px) {
      max-width: 16.666667%;
      flex-basis: 16.666667%;
    }
  `}
`;

export const ContentGridItem = styled(Grid)`
  @media (min-width: 1150px) {
    max-width: 77%;
    flex-basis: 77%;
  }
  @media (min-width: 1280px) {
    max-width: 80%;
    flex-basis: 80%;
  }
  @media (min-width: 1575px) {
    max-width: 83.333333%;
    flex-basis: 83.333333%;
  }
`;

export const MainContainer = styled(Container)`
  padding: 0;
`;
