import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel } from "components/MaterialComponents";
import { Checkbox } from "components/MaterialComponents";

function AthleticCheckBox({ value, handleChange }) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          name="current"
          value={value}
          onChange={handleChange}
          checked={value}
        />
      }
      label="I'm currently playing for this program"
    />
  );
}

AthleticCheckBox.propTypes = {
  value: PropTypes.bool || PropTypes.string,
  handleChange: PropTypes.func,
};

export default AthleticCheckBox;
