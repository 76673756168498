import styled from "styled-components";
import { Card } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { IconButton } from "components/MaterialComponents";
import { Link } from "react-router-dom";

import { ReactComponent as LocationIcon } from "assets/icons/location-grey.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info-grey.svg";
import { ReactComponent as BankCardIcon } from "assets/icons/bank-card-grey.svg";

export const StyledCard = styled(Card)`
  height: 100%;
`;

export const NameSkeleton = styled.div`
  font-size: 17px;
  font-weight: 500;
`;

export const Name = styled(Link)`
  ${({ theme }) => `
  display: block;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  color: ${theme.palette.common.allports};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`}
`;

export const Location = styled.div`
  ${({ theme }) => `
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 8px;
`}
`;

export const Details = styled.div`
  ${({ theme }) => `
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 8px;
`}
`;

export const CurrencyContainer = styled.div`
  ${({ theme }) => `
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
`}
`;

export const CardContainer = styled(Box)`
  padding: 35px 26px 26px 25px;
`;

export const CardInfo = styled(Box)`
  display: flex;
`;

export const CollegeLogoContainer = styled(Box)`
  margin-right: 1em;
`;

export const StyledLocationIcon = styled(LocationIcon)`
  vertical-align: middle;
  margin-right: 9px;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  vertical-align: middle;
  margin-right: 9px;
`;

export const StyledBankCardIcon = styled(BankCardIcon)`
  vertical-align: middle;
  margin-right: 9px;
`;

export const ActionsIconContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const StyledIconButton = styled(IconButton)`
  ${({ theme, $selected }) => `
  padding: 7px;
  & .MuiIconButton-label {
    & .MuiSvgIcon-root {
      font-size: 1.2rem;
      color: ${
        $selected ? theme.palette.common.white : theme.palette.common.allports
      };
    }
  }

  background-color: ${$selected ? theme.palette.common.allports : "inherit"};

  &:hover {
    background-color: ${theme.palette.common.fern};
    & .MuiIconButton-label {
      & .MuiSvgIcon-root {
          color: ${theme.palette.common.white};
      }
    }
  }
`}
`;
