import React, { useEffect, useState, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import useMessages, {
  setCurrentThread,
  setLoading,
  sendEmail,
  sendEmailToCoachNotInDB,
} from "components/useMessages";
import CoachItem from "./CochItem";
import MessagesList from "./MessagesList";
import CoachSummary from "./CoachSummary";
import isempty from "lodash.isempty";
import HorizontalLine from "components/HorizontalLine";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import { Skeleton } from "components/MaterialComponents";
import { AddButtonWithText } from "components/Buttons";
import { Add as AddIcon } from "components/MaterialIcons";
import ReplyEmailPopUp from "./ReplyEmailPopUp";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import MobileMessagesPopUp from "./MobileMessagesPopUp";
import Feedbacker from "components/Feedbacker";
import { CLEAR_FEEDBACK } from "components/useMessages/actionTypes";

import {
  ThreadContainer,
  SelectSection,
  MessagesSection,
  BackButton,
  StyledHeadline,
  StyledTitle,
  AddButtonContainer,
  MainMessagesContainer,
} from "./style";

const getCoachesList = (currentThread) => {
  return Object.keys(currentThread).map((key) => ({
    coachId: key,
    name: currentThread[key][0]["coach"],
    sport: currentThread[key][0]["sport"],
    title: currentThread[key][0]["coachTitle"],
  }));
};

const getCoachesNotInDBList = (currentThreadCoachesNotInDB) => {
  return Object.keys(currentThreadCoachesNotInDB).map((key) => ({
    coachId: key,
    name: currentThreadCoachesNotInDB[key][0]["emailFrom"],
    sport: currentThreadCoachesNotInDB[key][0]["sport"],
    sportId: currentThreadCoachesNotInDB[key][0]["sportId"],
  }));
};

function ContactDetails() {
  const [
    {
      currentThread,
      currentThreadCoachesNotInDB,
      currentSummary,
      collegesSummary,
      loading,
      error,
      feedback,
    },
    dispatch,
  ] = useMessages();
  const { collegeId } = useParams();
  const history = useHistory();
  const [containerRef, setContainerRef] = useState(null);
  const [listRef, setListref] = useState();

  const coaches = getCoachesList(currentThread);
  const coachesNotInDB = getCoachesNotInDBList(currentThreadCoachesNotInDB);
  const firstCoachId = !isempty(coaches) ? coaches[0]["coachId"] : null;
  const firstCoachIdNotInDB = !isempty(coachesNotInDB)
    ? coachesNotInDB[0]["coachId"]
    : null;

  const defaultCoachId = firstCoachId || firstCoachIdNotInDB;

  const [currentCoachId, setCurrentCoachId] = useState(defaultCoachId);

  const [isSendWindowOpen, setSendWindowOpen] = useState(false);

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [isMobilePopUpOpen, setIsMobilePopUpOpen] = useState(false);

  const toggleMobilePopUp = useCallback(() => {
    setIsMobilePopUpOpen(!isMobilePopUpOpen);
  }, [isMobilePopUpOpen]);

  const toggleSendWindow = useCallback(() => {
    setSendWindowOpen(!isSendWindowOpen);
  }, [isSendWindowOpen]);

  const summary = currentSummary.find(
    (el) => Number(el.coachId) === Number(currentCoachId),
  );

  const currentCollege = collegesSummary.find(
    (el) => Number(el.collegeId) === Number(collegeId),
  );

  const currentCoach = coaches.find(
    (el) => Number(el.coachId) === Number(currentCoachId),
  );

  const currentCoachNotInDB = coachesNotInDB.find(
    (coach) => coach.coachId === currentCoachId,
  );

  const isCoachInDB = Boolean(currentThread[currentCoachId]);
  const isCoachNotInDB = Boolean(currentThreadCoachesNotInDB[currentCoachId]);

  useEffect(() => {
    setCurrentThread(dispatch, collegeId);

    return () => {
      setLoading(dispatch);
    };
  }, [collegeId, dispatch]);

  useEffect(() => {
    setCurrentCoachId(defaultCoachId);
  }, [defaultCoachId]);

  const handleSetCurrentCoachId = (coachId) => {
    setCurrentCoachId(coachId);

    if (matchesSm) {
      toggleMobilePopUp();
    }
  };

  const handleGoBackClick = () => {
    history.push("/contact-activity");
  };

  const handleGoBottom = () => {
    listRef.scrollTop = listRef.scrollHeight;
  };

  const handleSendEmail = async (data) => {
    const { body, subject, athlete } = data;
    const recipients = [
      {
        id: currentCoachId,
        name: currentCoach.name,
      },
    ];
    const message = {
      body,
      subject,
      messageRegarding: athlete ? athlete.value : null,
    };
    await sendEmail(dispatch, { recipients, message }, collegeId);
    handleGoBottom();
  };

  const handleSendEmailForCoachNotInDB = async (data) => {
    const { body, subject } = data;
    const dataToSend = {
      body,
      subject,
      tempCoachId: currentCoachId,
      collegeId: collegeId,
      sportId: currentCoachNotInDB.sportId,
      emailTo: currentCoachNotInDB.name,
    };

    await sendEmailToCoachNotInDB(dispatch, dataToSend, collegeId);
    handleGoBottom();
  };

  return (
    <MainMessagesContainer>
      <Box margin="10px">
        <StyledHeadline>Contact Activity</StyledHeadline>
        <Box display="flex" alignItems="baseline">
          <BackButton onClick={handleGoBackClick}>&#8592; Back</BackButton>
          <StyledTitle>{currentCollege?.schoolName}</StyledTitle>
        </Box>
      </Box>
      <HorizontalLine />
      <ThreadContainer>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            marginTop="40px"
            width="100%"
          >
            <Skeleton variant="rect" width="80%" height="400px" />
          </Box>
        ) : (
          <>
            <SelectSection>
              {coaches.map((coach) => (
                <CoachItem
                  key={coach.coachId}
                  coach={coach}
                  onSelect={handleSetCurrentCoachId}
                  selected={Number(coach.coachId) === Number(currentCoachId)}
                  coachSummary={currentSummary.find(
                    (summary) =>
                      Number(summary.coachId) === Number(coach.coachId),
                  )}
                />
              ))}
              {coachesNotInDB.map((coach) => (
                <CoachItem
                  key={coach.coachId}
                  coach={coach}
                  onSelect={handleSetCurrentCoachId}
                  selected={coach.coachId === currentCoachId}
                />
              ))}
            </SelectSection>
            {!matchesSm && (
              <MessagesSection ref={setContainerRef}>
                {isCoachInDB && (
                  <>
                    <CoachSummary summary={summary} />
                    <MessagesList
                      messages={currentThread[currentCoachId]}
                      sendEmail={handleSendEmail}
                      containerRef={containerRef}
                      collegeId={collegeId}
                      currentCoachId={currentCoachId}
                      listRef={listRef}
                      setListref={setListref}
                      handleGoBottom={handleGoBottom}
                      withSummary={true}
                    />
                  </>
                )}
                {isCoachNotInDB && (
                  <MessagesList
                    messages={currentThreadCoachesNotInDB[currentCoachId]}
                    sendEmail={handleSendEmailForCoachNotInDB}
                    containerRef={containerRef}
                    collegeId={collegeId}
                    currentCoachId={currentCoachId}
                    listRef={listRef}
                    setListref={setListref}
                    handleGoBottom={handleGoBottom}
                  />
                )}
                <AddButtonContainer>
                  <AddButtonWithText onClick={toggleSendWindow}>
                    <AddIcon fontSize="large" />
                    <Typography variant="h5">New message</Typography>
                  </AddButtonWithText>
                </AddButtonContainer>
              </MessagesSection>
            )}
            <MobileMessagesPopUp
              open={isMobilePopUpOpen}
              onClose={toggleMobilePopUp}
              currentCoach={currentCoach || currentCoachNotInDB}
              messages={
                currentThread[currentCoachId] ||
                currentThreadCoachesNotInDB[currentCoachId]
              }
              sendEmail={
                isCoachInDB ? handleSendEmail : handleSendEmailForCoachNotInDB
              }
            />

            <ReplyEmailPopUp
              open={isSendWindowOpen}
              onClose={toggleSendWindow}
              containerRef={containerRef}
              onSendEmail={
                isCoachInDB ? handleSendEmail : handleSendEmailForCoachNotInDB
              }
            />
          </>
        )}
      </ThreadContainer>
      <Feedbacker
        open={feedback === "success"}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage="Your message was successfully sent!"
        clearFeedback={() => dispatch({ type: CLEAR_FEEDBACK })}
      />
      <Feedbacker
        open={error}
        autoHideDuration={3000}
        severity="error"
        feedbackMessage="Something went wrong!"
        clearFeedback={() => dispatch({ type: CLEAR_FEEDBACK })}
      />
    </MainMessagesContainer>
  );
}

export default ContactDetails;
