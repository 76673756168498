import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { CircularProgress } from "components/MaterialComponents";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Modal from "components/Modal";
import CustomTextField from "components/CustomTextField";
import * as validationHelper from "helpers/validationHelper";

import { ModalHeader, ModalButton, ModalFormContainer } from "./style";

const validationSchema = yup.object().shape({
  firstName: validationHelper.firstName.required("First Name is required"),
  lastName: validationHelper.lastName.required("Last Name is required"),
  phoneNumber: yup.string().nullable(),
});

function NoParentProfileModal({
  open,
  handleClose,
  handleCreateParentProfile,
  displayName,
  loading,
}) {
  const displayNameArray = displayName.split(" ");
  const firstName = displayNameArray[0];
  const lastName = displayNameArray[1] || null;

  const handleFormSubmit = useCallback(
    async (values) => {
      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
      };
      await handleCreateParentProfile(body);
    },
    [handleCreateParentProfile],
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader data-testid="no-parent-profile-modal-header">
        We noticed you don't have a profile yet. Press Create to continue
      </ModalHeader>
      <Box width="80%">
        <Formik
          validateOnChange
          initialValues={{
            firstName,
            lastName,
            phoneNumber: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              <ModalFormContainer>
                <CustomTextField label="First Name" name="firstName" />
                <CustomTextField label="Last Name" name="lastName" />
                <CustomTextField label="Phone Number" name="phoneNumber" />
                <Box display="flex" justifyContent="space-around">
                  <ModalButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={loading && <CircularProgress size={20} />}
                    disabled={loading}
                  >
                    Create
                  </ModalButton>
                  <ModalButton
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                  >
                    Cancel
                  </ModalButton>
                </Box>
              </ModalFormContainer>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

NoParentProfileModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreateParentProfile: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  displayName: PropTypes.string,
};

export default NoParentProfileModal;
