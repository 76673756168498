import React, { useReducer } from "react";

import { MessagesStateContext, MessagesDispatchContext } from "./Context";
import messagesReducer, { initialState } from "./reducer";

function MessagesProvider({ children, testState }) {
  const [state, dispatch] = useReducer(messagesReducer, initialState);

  return (
    <MessagesStateContext.Provider value={testState ? testState : state}>
      <MessagesDispatchContext.Provider value={dispatch}>
        {children}
      </MessagesDispatchContext.Provider>
    </MessagesStateContext.Provider>
  );
}

export default MessagesProvider;
