import {
  SET_CURRENT_THREAD,
  SET_SUMMARY,
  SET_COACH_SUMMARY,
  SET_FEEDBACK,
  CLEAR_FEEDBACK,
  LOADING,
  ERROR,
  SET_CURRENT_THREAD_FOR_COACH,
} from "./actionTypes";

const initialState = {
  currentThread: {},
  currentThreadCoachesNotInDB: {},
  coachCurrentThread: {},
  currentSummary: [],
  collegesSummary: [],
  coachSummary: {},
  unsortedEmails: [],
  loading: true,
  error: null,
  feedback: undefined,
  lastPage: null,
};

function messagesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_THREAD: {
      return {
        ...state,
        currentThread: action.data.messages,
        currentSummary: action.data.summary,
        currentThreadCoachesNotInDB: action.data.messagesFromCoachesNotInDB,
        loading: false,
        error: null,
      };
    }
    case SET_SUMMARY: {
      return {
        ...state,
        collegesSummary: action.data.summaryData,
        unsortedEmails: action.data.unsortedEmails,
      };
    }
    case SET_COACH_SUMMARY: {
      return {
        ...state,
        coachSummary: action.data,
      };
    }
    case SET_CURRENT_THREAD_FOR_COACH: {
      return {
        ...state,
        coachCurrentThread: action.data,
        loading: false,
        error: null,
      };
    }
    case ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_FEEDBACK: {
      return { ...state, feedback: action.data };
    }
    case CLEAR_FEEDBACK: {
      return { ...state, feedback: undefined, error: undefined };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default messagesReducer;

export { initialState };
