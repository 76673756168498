import * as userDomain from "api/domains/user";
import * as actionTypes from "./actionTypes";
import { profileApiFeedbacks } from "./profileApiFeedbacks";

async function loadCurrentUser(dispatch) {
  try {
    const data = await userDomain.loadCurrentUser();
    dispatch({ type: actionTypes.SUCCESS, data });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.message });
  }
}

async function logout(dispatch) {
  try {
    await userDomain.logout();
    dispatch({ type: actionTypes.LOGOUT });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.message });
  }
}

async function updateEmail(dispatch, data) {
  await userDomain.updateEmail({
    newEmail: data.email,
    password: data.password,
  });

  dispatch({
    type: actionTypes.EMAIL_UPDATE,
    data: { email: data.email, feedback: profileApiFeedbacks.UPDATE_SUCCESS },
  });
}

async function sendVerificationEmail(dispatch) {
  try {
    await userDomain.sendVerificationEmail();

    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: { feedback: profileApiFeedbacks.VERIFICATION_SEND_SUCCESS },
    });
  } catch (error) {
    dispatch({
      type: actionTypes.API_ERROR,
      data: {
        error: error.errorCode,
        feedback: profileApiFeedbacks.VERIFICATION_SEND_FAIL,
      },
    });
  }
}

async function sendChangePasswordLinkToUserEmail(dispatch, data) {
  try {
    await userDomain.sendResetPasswordLink(data);
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: { feedback: profileApiFeedbacks.SEND_RESET_PASSWORD_EMAIL_SUCCES },
    });
    dispatch({
      type: actionTypes.EMPTY_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.EMPTY_ERROR,
      data: { error: error },
    });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: { feedback: profileApiFeedbacks.SEND_RESET_PASSWORD_EMAIL_FAILURE },
    });
  }
}

async function changePassword(dispatch, data) {
  try {
    await userDomain.changePassword(data);
  } catch (error) {
    dispatch({
      type: actionTypes.API_ERROR,
      data: {
        error: error.errorCode,
        feedback: error.message,
      },
    });
  }
}

async function verifyEmail(dispatch, token) {
  try {
    await userDomain.verifyEmail(token);
    const data = await userDomain.loadCurrentUser();
    dispatch({ type: actionTypes.SUCCESS, data: data });
    dispatch({
      type: actionTypes.API_SUCCESS,
      data: { feedback: profileApiFeedbacks.EMAIL_VERIFICATION_SUCCESS },
    });
  } catch (error) {
    dispatch({
      type: actionTypes.API_ERROR,
      data: {
        error: error.errorCode,
        feedback: profileApiFeedbacks.EMAIL_VERIFICATION_FAIL,
      },
    });
  }
}

export {
  loadCurrentUser,
  logout,
  updateEmail,
  sendVerificationEmail,
  verifyEmail,
  sendChangePasswordLinkToUserEmail,
  changePassword,
};
