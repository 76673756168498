import React from "react";
import PropTypes from "prop-types";
import sportIconsMap from "maps/sporticons";
import { snakeCase } from "helpers/stringToSnakeCase";
import { StyledLogo, StyledLogoSmall, IconContainer } from "./style";

function CoachLogo({ isSelected, sport, small }) {
  const Icon = sportIconsMap[snakeCase(sport)] ?? sportIconsMap["default"];

  return (
    <>
      {small ? (
        <StyledLogoSmall $isSelected={isSelected}>
          <IconContainer>
            <Icon />
          </IconContainer>
        </StyledLogoSmall>
      ) : (
        <StyledLogo $isSelected={isSelected}>
          <IconContainer>
            <Icon />
          </IconContainer>
        </StyledLogo>
      )}
    </>
  );
}

CoachLogo.propTypes = {
  isSelected: PropTypes.bool,
  sport: PropTypes.string,
  small: PropTypes.bool,
};

export default CoachLogo;
