import React, { useState } from "react";
import omit from "lodash.omit";
import { Box } from "components/MaterialComponents";
import { Formik, Form } from "formik";
import * as yup from "yup";

import useSports from "components/useSports";
import Feedbacker from "components/Feedbacker";
import GeneralCard from "components/GeneralCard";
import { SuccessButton } from "components/Buttons";
import useRecruitingProfile, {
  updateRecruitingProfile,
  recruitingProfileFeedbacks,
} from "components/useRecruitingProfile";

import CustomTextField from "components/CustomTextField";
import DatePicker from "components/DatePicker";
import GraduationSelect from "../GraduationSelect";
import SportSelect from "../SportSelect";
import GenderSelect from "../GenderSelect";
import SocialMediaInput from "./SocialMediaInput";
import ConfirmSportUpdateModal from "./ConfirmSportUpdateModal";
import useClearFeedback from "customHooks/useClearFeedback";
import getSportListByGender from "helpers/getSportListByGender";
import * as validationHelper from "helpers/validationHelper";
import * as actionTypes from "components/useRecruitingProfile/actionTypes";

import { CardContainer } from "../style";
import { PersonalInfoFormContainer } from "./style";
import { ORDER_PRIMARY, ORDER_SECONDARY } from "../../../constants/sports";

const validationSchema = yup.object().shape({
  firstName: validationHelper.firstName,
  lastName: validationHelper.lastName,
  dateOfBirth: yup.date().nullable(),
  hsGradYear: yup.string().nullable(),
  heightInInches: validationHelper.heightInInches,
  weightInPounds: validationHelper.weightInPounds,
  instagramHandle: validationHelper.instagramHandle,
  facebookHandle: validationHelper.facebookHandle,
  twitterHandle: validationHelper.twitterHandle,
});

function PersonalInformation() {
  const [
    { profile, feedback, loading, error },
    dispatch,
  ] = useRecruitingProfile();
  const { data: sportList, loading: sportsLoading } = useSports();
  const [modalOpen, setModalOpen] = useState(false);
  useClearFeedback(dispatch);

  if (loading || sportsLoading) {
    return null;
  }

  const {
    userId,
    firstName,
    lastName,
    dateOfBirth,
    hsGradYear,
    heightInInches,
    weightInPounds,
    instagramHandle,
    facebookHandle,
    twitterHandle,
    sports,
    sportPositions,
    gender,
    ...restProfile
  } = profile;

  const {
    primary: primarySport,
    secondary: secondarySport,
    tertiary: tertiarySport,
  } = sports || {};

  const handleFormSubmit = async (values, { setSubmitting }) => {
    const apiCompatibleValues = getApiCompatibleValues(values);

    await updateRecruitingProfile(dispatch, userId, apiCompatibleValues);
    setSubmitting(false);
    setModalOpen(false);
  };

  const getApiCompatibleValues = (values) => {
    const sportPositions = Object.values(sports)
      .flatMap((sport) => sport.sportPositions)
      .filter(Boolean);

    const profileSports = {};

    values.primarySport && (profileSports.primary = values.primarySport);
    values.secondarySport && (profileSports.secondary = values.secondarySport);
    values.tertiarySport && (profileSports.tertiary = values.tertiarySport);

    const omittedValues = omit(values, [
      "primarySport",
      "secondarySport",
      "tertiarySport",
    ]);

    return {
      ...omittedValues,
      ...restProfile,
      sports: profileSports,
      sportPositions,
    };
  };

  const checkPrimarySportChanged = (formPrimarySport) => {
    return primarySport ? formPrimarySport?.id !== primarySport?.id : false;
  };

  const checkSecondarySportChanged = (formSecondarySport) => {
    return secondarySport
      ? formSecondarySport?.id !== secondarySport?.id
      : false;
  };

  const checkTertiarySportChanged = (formTertiarySport) => {
    return tertiarySport ? formTertiarySport?.id !== tertiarySport?.id : false;
  };

  const checkSportChanged = (formValues) => {
    const {
      primarySport: formPrimarySport,
      secondarySport: formSecondarySport,
      tertiarySport: formTertiarySport,
    } = formValues;
    const primaryChanged = checkPrimarySportChanged(formPrimarySport);
    const secondaryChanged = checkSecondarySportChanged(formSecondarySport);
    const tertiaryChanged = checkTertiarySportChanged(formTertiarySport);

    return primaryChanged || secondaryChanged || tertiaryChanged;
  };

  return (
    <>
      <CardContainer>
        <GeneralCard header="Personal Information">
          <Formik
            validateOnChange
            initialValues={{
              firstName,
              lastName,
              dateOfBirth: new Date(dateOfBirth) || null,
              hsGradYear,
              primarySport: primarySport || "",
              secondarySport: secondarySport || "",
              tertiarySport: tertiarySport || "",
              gender,
              heightInInches,
              weightInPounds,
              instagramHandle,
              facebookHandle,
              twitterHandle,
            }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ isSubmitting, values, setFieldValue, submitForm }) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  setModalOpen(checkSportChanged(values));
                }}
              >
                <PersonalInfoFormContainer>
                  <CustomTextField label="First Name" name="firstName" />
                  <CustomTextField label="Last Name" name="lastName" />
                  <Box mb="2.5em">
                    <DatePicker
                      label="Date of Birth"
                      date={values.dateOfBirth}
                      handleChange={(value) =>
                        setFieldValue("dateOfBirth", value)
                      }
                    />
                  </Box>
                  <Box marginBottom="2.5em">
                    <GraduationSelect
                      hsGradYear={values.hsGradYear}
                      handleChange={(e) =>
                        setFieldValue("hsGradYear", e.target.value)
                      }
                    />
                  </Box>
                  <Box marginBottom="2.5em">
                    <GenderSelect
                      gender={values.gender}
                      handleChange={(e) => {
                        setFieldValue("gender", e.target.value);
                        setFieldValue("primarySport", "");
                        setFieldValue("secondarySport", "");
                      }}
                    />
                  </Box>
                  <Box marginBottom="2.5em">
                    <SportSelect
                      label="Primary Sport"
                      order={ORDER_PRIMARY}
                      disabled={!values.gender}
                      sportList={getSportListByGender(values.gender, sportList)}
                      firstEntity={values.primarySport}
                      secondEntity={values.secondarySport}
                      showGender={false}
                      handleChange={(e) =>
                        setFieldValue("primarySport", e.target.value)
                      }
                    />
                  </Box>
                  <Box marginBottom="2.5em">
                    <SportSelect
                      label="Secondary Sport"
                      order={ORDER_SECONDARY}
                      disabled={!values.gender}
                      sportList={getSportListByGender(values.gender, sportList)}
                      firstEntity={values.secondarySport}
                      secondEntity={values.primarySport}
                      handleChange={(e) =>
                        setFieldValue("secondarySport", e.target.value)
                      }
                    />
                  </Box>
                  <Box marginBottom="2.5em">
                    <SportSelect
                      label="Tertiary Sport"
                      order={ORDER_SECONDARY}
                      disabled={!values.gender}
                      sportList={getSportListByGender(values.gender, sportList)}
                      firstEntity={values.tertiarySport}
                      secondEntity={values.primarySport}
                      handleChange={(e) =>
                        setFieldValue("tertiarySport", e.target.value)
                      }
                    />
                  </Box>
                  <CustomTextField
                    label="Height In Inches"
                    name="heightInInches"
                    type="number"
                  />
                  <CustomTextField
                    label="Weight In LBS"
                    name="weightInPounds"
                    type="number"
                  />
                  <SocialMediaInput label="Instagram" name="instagramHandle" />
                  <SocialMediaInput label="Facebook" name="facebookHandle" />
                  <SocialMediaInput label="Twitter" name="twitterHandle" />
                  {checkSportChanged(values) ? (
                    <SuccessButton type="submit">Save</SuccessButton>
                  ) : (
                    <SuccessButton onClick={submitForm} disabled={isSubmitting}>
                      Save
                    </SuccessButton>
                  )}
                  <ConfirmSportUpdateModal
                    open={modalOpen}
                    handleClose={() => setModalOpen(false)}
                    submitForm={submitForm}
                    isSubmitting={isSubmitting}
                  />
                </PersonalInfoFormContainer>
              </Form>
            )}
          </Formik>
        </GeneralCard>
      </CardContainer>
      <Feedbacker
        open={feedback === recruitingProfileFeedbacks.Updated}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage={feedback}
        clearFeedback={() => dispatch({ type: actionTypes.CLEAR_FEEDBACK })}
      />
      <Feedbacker
        open={Boolean(error)}
        autoHideDuration={3000}
        severity="error"
        feedbackMessage={feedback}
        clearFeedback={() => dispatch({ type: actionTypes.CLEAR_FEEDBACK })}
      />
    </>
  );
}

export default PersonalInformation;
