import React from "react";
import { Box } from "components/MaterialComponents";
import { CircularProgress } from "components/MaterialComponents";

function Spinner(props) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <CircularProgress {...props} />
    </Box>
  );
}

export default Spinner;
