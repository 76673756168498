import React, { useState, useCallback } from "react";
import EnrollmentFilter from "./EnrollmentFilter";
import AcceptanceRateFilter from "./AcceptanceRateFilter";
import GPAFilter from "./GPAFilter";
import SATFilter from "./SATFilter";
import ACTFilter from "./ACTFilter";
import FilterPopper from "components/FilterPopper";
import MajorsFilter from "./MajorsFilter";
import FilterPopperField from "./FilterPopperField";
import NameFilter from "./NameFilter";
import ShowNullValuesFilter from "./ShowNullValuesFilter";
import { Box } from "components/MaterialComponents";
import { StyledNullValueField } from "./style";

function AcademicsFilterPopper({ filter, onChange, majors }) {
  const [textQuery, setTextQuery] = useState("");

  const onQueryChange = ({ query }) => {
    setTextQuery(query);
  };

  const onResetFilter = useCallback(() => {
    onChange({
      ...filter,
      enrollmentMin: null,
      enrollmentMax: null,
      acceptanceRate: null,
      gpaMin: null,
      gpaMax: null,
      satMin: null,
      satMax: null,
      actMin: null,
      actMax: null,
      majors: null,
      nullGPA: null,
      nullSAT: null,
      nullACT: null,
    });
  }, [onChange, filter]);

  return (
    <FilterPopper
      anchor="Academics"
      filterTitle="Filter by Academics"
      resetFilter={onResetFilter}
      testId="academics-filter"
    >
      <EnrollmentFilter filter={filter} onChange={onChange} />
      <FilterPopperField label="Acceptance Rate:">
        <AcceptanceRateFilter filter={filter} onChange={onChange} />
      </FilterPopperField>
      <GPAFilter filter={filter} onChange={onChange} />
      <Box display="flex" mb="20px">
        <StyledNullValueField>
          Include colleges without data
        </StyledNullValueField>
        <ShowNullValuesFilter
          filter={filter}
          onChange={onChange}
          label="nullGPA"
        />
      </Box>
      <SATFilter filter={filter} onChange={onChange} />
      <Box display="flex" mb="20px">
        <StyledNullValueField>
          Include colleges without data
        </StyledNullValueField>
        <ShowNullValuesFilter
          filter={filter}
          onChange={onChange}
          label="nullSAT"
        />
      </Box>
      <ACTFilter filter={filter} onChange={onChange} />
      <Box display="flex" mb="20px">
        <StyledNullValueField>
          Include colleges without data
        </StyledNullValueField>
        <ShowNullValuesFilter
          filter={filter}
          onChange={onChange}
          label="nullACT"
        />
      </Box>
      <FilterPopperField label="Majors:">
        <NameFilter
          onChange={onQueryChange}
          placeholder="Search by major name"
        />
      </FilterPopperField>
      <MajorsFilter
        filter={filter}
        onChange={onChange}
        majors={majors}
        textQuery={textQuery}
      />
    </FilterPopper>
  );
}

export default React.memo(AcademicsFilterPopper);
