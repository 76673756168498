import * as actionTypes from "./actionTypes";

const initialState = {
  profile: null,
  loading: true,
  error: null,
  isLoggedIn: false,
  feedback: null,
};

function profileReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SUCCESS: {
      return {
        profile: action.data,
        loading: false,
        error: null,
        isLoggedIn: true,
      };
    }
    case actionTypes.EMPTY_SUCCESS: {
      return {
        ...state,
        error: null,
      };
    }
    case actionTypes.EMPTY_ERROR: {
      return {
        ...state,
        error: action.data.error,
      };
    }
    case actionTypes.ERROR: {
      return {
        profile: null,
        loading: false,
        error: action.error,
        isLoggedIn: false,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        profile: null,
        loading: false,
        error: null,
        isLoggedIn: false,
      };
    }

    case actionTypes.EMAIL_UPDATE: {
      return {
        ...state,
        profile: { ...state.profile, email: action.data.email },
        error: null,
        feedback: action.data.feedback,
      };
    }

    case actionTypes.API_ERROR: {
      return {
        ...state,
        error: action.data.error,
        feedback: action.data.feedback,
      };
    }

    case actionTypes.API_SUCCESS: {
      return {
        ...state,
        error: null,
        feedback: action.data.feedback,
      };
    }

    case actionTypes.SET_FEEDBACK: {
      return { ...state, feedback: action.data.feedback };
    }

    case actionTypes.CLEAR_FEEDBACK: {
      return { ...state, feedback: null };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default profileReducer;

export { initialState };
