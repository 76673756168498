import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Box } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import CollegeLogo from "components/CollegeLogo";

import { StyledTableRow, Name } from "./style";

function CustomTableRow({ row }) {
  const [isHover, setIsHover] = useState(false);
  const history = useHistory();
  return (
    <StyledTableRow
      onClick={() => history.push(`/contact-activity/${row.collegeId}`)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      $isHover={isHover}
    >
      <TableCell component="th" scope="row">
        <Box display="flex" alignItems="center">
          <Box marginRight="10px">
            <CollegeLogo
              name={row.schoolName}
              image={row.logoUrl}
              small={true}
            />
          </Box>
          <Name>{row.schoolName}</Name>
        </Box>
      </TableCell>
      <TableCell align="right">{row.sends}</TableCell>
      <TableCell align="right">{row.opens}</TableCell>
      <TableCell align="right">{row.visits}</TableCell>
      <TableCell align="right">{row.replies}</TableCell>
      <TableCell align="right">
        {Boolean(row.unread) ? (
          <Typography variant="h6">{row.unread}</Typography>
        ) : (
          row.unread
        )}
      </TableCell>
    </StyledTableRow>
  );
}

CustomTableRow.propTypes = {
  row: PropTypes.object,
};

export default CustomTableRow;
