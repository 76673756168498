import React from "react";
import { ItemSkeletonContainer } from "./style";
import { Skeleton } from "components/MaterialComponents";

function ItemSkeleton({ textWidth = 150, ...props }) {
  return (
    <ItemSkeletonContainer {...props}>
      <Skeleton
        variant="rect"
        width={20}
        height={20}
        style={{ marginRight: 10 }}
      />
      <Skeleton variant="text" width={textWidth} />
    </ItemSkeletonContainer>
  );
}

export default ItemSkeleton;
