import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import SportStatTextField from "./SportStatTextField";
import {
  transformToArray,
  transformToString,
} from "helpers/athletsFilterTransformers";
import { TIME_MIN_SEC_MS, TIME_SEC_MS } from "constants/sports";
import { SportStatField, NameField } from "./style";

function StatPositionText({ option, filter, onChange }) {
  const { sportStats = "" } = filter;

  const { inputType, prompt, inputUnits } = option;

  const statsArray = sportStats ? transformToArray(sportStats) : [];

  const checked = statsArray.find((el) => Number(el.id) === Number(option.id));

  const defaultMaxVal = checked?.maxText ? checked.maxText : "";
  const defaultMinVal = checked?.minText ? checked.minText : "";

  const timeInput = inputType === TIME_MIN_SEC_MS || inputType === TIME_SEC_MS;

  const [minText, setMin] = useState(defaultMinVal);
  const [maxText, setMax] = useState(defaultMaxVal);
  const [minInt, setMinInt] = useState("");
  const [maxInt, setMaxInt] = useState("");

  const onSubmit = useCallback(
    (e) => {
      if (!e.target.checked) {
        onChange({
          ...filter,
          sportStats: transformToString(
            statsArray.filter((el) => Number(el.id) !== Number(option.id)),
          ),
        });
        return;
      }

      if (!minInt && !maxInt) {
        return;
      }

      const position = {
        id: option.id,
        min: minInt ? minInt : "",
        max: maxInt ? maxInt : "",
        minText: minText ? minText : "",
        maxText: maxText ? maxText : "",
      };

      onChange({
        ...filter,
        sportStats: transformToString([...statsArray, position]),
      });
    },
    [filter, maxInt, minInt, maxText, minText, onChange, option, statsArray],
  );

  const setMinValues = useCallback((valInt, value) => {
    setMin(value);
    setMinInt(valInt);
  }, []);

  const setMaxValues = useCallback((valInt, value) => {
    setMax(value);
    setMaxInt(valInt);
  }, []);

  if (inputType === "RECORD") {
    return null;
  }

  const inputPoints = option.inputUnits ? option.inputUnits : "";

  const showPrompt =
    prompt && Number(prompt.toString().charAt(0)) ? prompt : "";

  return (
    <SportStatField>
      <NameField>
        <Typography variant="h5">{option.name}</Typography>
        <Typography variant="subtitle2">{inputPoints}</Typography>
      </NameField>
      <>
        {timeInput ? (
          <>
            <Box width="100px">
              <Typography variant="subtitle2">faster then</Typography>
            </Box>
            <SportStatTextField
              value={maxText}
              inputType={inputType}
              prompt={showPrompt}
              setFieldValue={setMaxValues}
              inputUnits={inputUnits}
              isDisabled={Boolean(checked)}
            />
          </>
        ) : (
          <>
            <Box width="100px">
              <Typography variant="subtitle2">greater then</Typography>
            </Box>
            <SportStatTextField
              value={minText}
              inputType={inputType}
              prompt={showPrompt}
              setFieldValue={setMinValues}
              inputUnits={inputUnits}
              isDisabled={Boolean(checked)}
            />
          </>
        )}
      </>
      <Box width="40px" marginLeft="30px">
        <Checkbox checked={Boolean(checked)} onChange={onSubmit} />
      </Box>
    </SportStatField>
  );
}

StatPositionText.propTypes = {
  option: PropTypes.object,
  filter: PropTypes.object,
  onChange: PropTypes.func,
};

export default StatPositionText;
