import { usePost } from "components/useFetch";

function useSearchResult(
  {
    page = 1,
    pageSize = 30,
    query,
    enrollmentMin,
    enrollmentMax,
    acceptanceRate,
    campusSettings,
    division,
    mensSports,
    womensSports,
    useInStateCost,
    costMin,
    costMax,
    states,
    majors,
    gpaMin,
    gpaMax,
    actMin,
    actMax,
    satMin,
    satMax,
    nullGPA,
    nullSAT,
    nullACT,
  },
  params,
  { removed, modified } = {},
) {
  const queryStringDictionary = { pageSize, page };
  const queryString = new URLSearchParams(queryStringDictionary).toString();
  let body = {};

  if (query) {
    body["collegeName"] = query;
  }

  if (params.favorite) {
    body["favorite"] = params.favorite;
  }

  if (removed) {
    body["removed"] = removed;
  }

  if (modified) {
    body["modified"] = modified;
  }

  if (majors) {
    body["majors"] = majors.split(",");
  }

  if (states) {
    body["states"] = states.split(",");
  }

  if (typeof useInStateCost !== "undefined") {
    body["costInState"] = useInStateCost;
  }

  if (satMax && nullSAT) {
    body["nullSAT"] = nullSAT;
  }

  if (gpaMax && nullGPA) {
    body["nullGPA"] = nullGPA;
  }

  if (actMax && nullACT) {
    body["nullACT"] = nullACT;
  }

  if (satMin && satMax) {
    body["satMin"] = parseInt(satMin);
    body["satMax"] = parseInt(satMax);
  }

  if (gpaMin || gpaMax) {
    body["gpaMin"] = parseFloat(gpaMin);
    body["gpaMax"] = parseFloat(gpaMax);
  }

  if (actMin || actMax) {
    body["actMin"] = parseInt(actMin);
    body["actMax"] = parseInt(actMax);
  }

  if (acceptanceRate) {
    body["academicSelectiveness"] = acceptanceRate
      .split(",")
      .map((v) => v.replace("_", " "));
  }

  if (campusSettings) {
    body["campusSettings"] = campusSettings
      .split(",")
      .map((v) => v.replace("_", " "));
  }

  if (division) {
    body["athleticDivision"] = division
      .split(",")
      .map((v) => v.replace("_", " "));
  }

  if (mensSports || womensSports) {
    const mensSportsArray = mensSports ? mensSports.split(",") : [];
    const womensSportsArray = womensSports ? womensSports.split(",") : [];

    body["sportIds"] = [...mensSportsArray, ...womensSportsArray];
  }

  if (enrollmentMin || enrollmentMax) {
    let enrollmentMaxParsed = parseInt(enrollmentMax);
    if (enrollmentMaxParsed === 35000)
      enrollmentMaxParsed = Number.MAX_SAFE_INTEGER;
    body["enrollmentRange"] = [
      parseInt(enrollmentMin) || 0,
      enrollmentMaxParsed,
    ];
  }

  if (costMin || costMax) {
    let costMaxParsed = parseInt(costMax);
    if (costMaxParsed === 40000) costMaxParsed = Number.MAX_SAFE_INTEGER;
    body["costRange"] = [parseInt(costMin) || 0, costMaxParsed];
  }

  return usePost(`/colleges/search?${queryString}`, body);
}

export default useSearchResult;
