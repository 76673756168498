import React, { useReducer } from "react";

import { HistoryDispatchContext, HistoryStateContext } from "./Context";
import historyReducer, { initialState } from "./reducer";

function AthleticHistoryProvider({ children, testState }) {
  const [state, dispatch] = useReducer(historyReducer, initialState);

  return (
    <HistoryStateContext.Provider value={testState ? testState : state}>
      <HistoryDispatchContext.Provider value={dispatch}>
        {children}
      </HistoryDispatchContext.Provider>
    </HistoryStateContext.Provider>
  );
}

export default AthleticHistoryProvider;
