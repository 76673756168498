import React from "react";
import styled from "styled-components";
import { Button } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { Close as CloseIcon } from "components/MaterialIcons";
import { Drawer } from "components/MaterialComponents";
import * as colors from "theme/colors";

export const StyledDrawer = styled(Drawer)`
  ${({ theme }) => `
    & .MuiPaper-root {
    width: 31.5em;
    padding: 30px;
    ${theme.breakpoints.down("xs")} {
      width: unset;
    }
  }
`}
`;

export const DrawerHeader = styled(Box)`
  ${({ theme }) => `
    margin: 20px 0;
    font-weight: 400;
    font-size: 30px;
    ${theme.breakpoints.down("xs")} {
      font-size: 26px;
    }
`}
`;

export const StyledCloseIcon = styled(CloseIcon)`
  ${({ theme }) => `
    opacity: 0.1;
    font-size: 5em;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
    ${theme.breakpoints.down("xs")} {
      font-size: 4em;
    }
`}
`;

export const GoogleButton = styled((props) => (
  <Button variant="contained" {...props} />
))`
  ${({ theme }) => `
  padding: 20px 12px;
  font-size: 19px;
  margin-bottom: 15px;
  background-color: ${theme.palette.common.blumine};
  color: #fff;
  &:hover {
    background-color: ${theme.palette.common.blumine};
    color: #000;
}
`}
`;

export const OutlookButton = styled((props) => (
  <Button variant="contained" {...props} />
))`
  ${({ theme }) => `
  padding: 20px 12px;
  font-size: 19px;
  margin-bottom: 15px;
  background-color: ${theme.palette.common.pictionBlue};
  color: #fff;
  &:hover {
    background-color: ${theme.palette.common.pictionBlue};
    color: #000;
  }
`}
`;

export const EmailButton = styled((props) => (
  <Button variant="contained" {...props} />
))`
  ${({ theme }) => `
  padding: 20px 12px;
  font-size: 19px;
  margin-bottom: 30px;
  background-color: ${theme.palette.common.allports};
  color: #fff;
  &:hover {
    background-color: #165283;
  }
`}
`;

export const NavigationButton = styled((props) => (
  <Button variant="contained" {...props} />
))`
  padding: 20px 12px;
  font-size: 19px;
  background-color: #f7941d;
  color: #fff;
  margin-top: 10px;
  &:hover {
    background-color: #de851a;
  }
`;

export const HelperText = styled.p`
  line-height: 1.43;
  margin-top: 0;
`;

export const LoginContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 2em;
`;

export const ForgotPasswordLink = styled.div`
  cursor: pointer;
  color: ${colors.Allports};
`;

export const StyledUnorderedList = styled.ul`
  margin: 0;
`;

export const StyledListItem = styled.li``;
