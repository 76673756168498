import styled from "styled-components";
import { Box } from "components/MaterialComponents";

export const ProgressText = styled(Box)`
  ${({ theme }) => `
  font-size: 8px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${theme.palette.common.catskillWhite};
  `}
`;
