import { useContext } from "react";

import AthleticHistoryProvider from "./AthletichistoryProvider";
import { HistoryStateContext, HistoryDispatchContext } from "./Context";
import { athleticHistoryFeedbacks } from "./AthleticHistoryFeedbacks";
import {
  getUserHistory,
  createHistory,
  deleteHistory,
  updateHistory,
} from "./actions";

function useHistoryState() {
  const context = useContext(HistoryStateContext);
  if (context === undefined) {
    throw new Error(
      "useHistoryState must be used within a AthleticHistoryProvider",
    );
  }
  return context;
}

function useHistoryDispatch() {
  const context = useContext(HistoryDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useHistoryDispatch must be used within a AthleticHistoryProvider",
    );
  }
  return context;
}

function useAthleticHistory() {
  return [useHistoryState(), useHistoryDispatch()];
}

export default useAthleticHistory;

export {
  AthleticHistoryProvider,
  useHistoryState,
  useHistoryDispatch,
  getUserHistory,
  createHistory,
  deleteHistory,
  updateHistory,
  athleticHistoryFeedbacks,
};
