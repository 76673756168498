import React, { useCallback } from "react";
import CheckboxOptionsList from "components/CheckboxOptionsList";

const OPTIONS = {
  MOST_SELECTIVE: "Most selective",
  SELECTIVE: "Selective",
  LESS_SELECTIVE: "Less selective",
  OTHER: "Other",
};

function AcceptanceRateFilter({ filter, onChange }) {
  const { acceptanceRate = "" } = filter;
  const acceptanceRateArray = acceptanceRate ? acceptanceRate.split(",") : [];
  const handleOnChange = useCallback(
    (newAcceptanceRateArray) => {
      onChange({ ...filter, acceptanceRate: newAcceptanceRateArray.join(",") });
    },
    [onChange, filter],
  );
  return (
    <CheckboxOptionsList
      value={acceptanceRateArray}
      onChange={handleOnChange}
      options={OPTIONS}
    />
  );
}

export default AcceptanceRateFilter;
