import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import GeneralCard from "components/GeneralCard";
import { capitalizeEachWord } from "helpers/capitalizeStrings";

import { StudentStatName, AcademicInfoStat, StyledSchoolIcon } from "./style";

function Academics({ data, checkIfValue }) {
  return (
    <GeneralCard header="Academic Information">
      <Box display="flex" flexDirection="column" mt={2}>
        {data.school && (
          <Box display="flex" mb="1.5em" alignItems="baseline">
            <StudentStatName>
              <StyledSchoolIcon />
              High School:
            </StudentStatName>
            <Typography variant="h5">
              {capitalizeEachWord(data.school.name)}
            </Typography>
          </Box>
        )}
        <Box width="70%" display="flex" justifyContent="space-between">
          <Box mr="1em">
            <AcademicInfoStat>
              <Typography variant="body2">GPA:</Typography>
              <Typography variant="h5">{checkIfValue(data.gpa)}</Typography>
            </AcademicInfoStat>
            <AcademicInfoStat>
              <Typography variant="body2">SAT Math:</Typography>
              <Typography variant="h5">{checkIfValue(data.satMath)}</Typography>
            </AcademicInfoStat>
            <AcademicInfoStat>
              <Typography variant="body2">SAT Reading/Writing:</Typography>
              <Typography variant="h5">
                {checkIfValue(data.satVerbal)}
              </Typography>
            </AcademicInfoStat>
          </Box>
          <Box>
            <AcademicInfoStat>
              <Typography variant="body2">PSAT Math:</Typography>
              <Typography variant="h5">
                {checkIfValue(data.psatMath)}
              </Typography>
            </AcademicInfoStat>
            <AcademicInfoStat>
              <Typography variant="body2">PSAT Reading/Writing:</Typography>
              <Typography variant="h5">
                {checkIfValue(data.psatVerbal)}
              </Typography>
            </AcademicInfoStat>
            <AcademicInfoStat>
              <Typography variant="body2">Class Rank:</Typography>
              <Typography variant="h5">
                {checkIfValue(data.classRank)}
              </Typography>
            </AcademicInfoStat>
          </Box>
        </Box>
      </Box>
    </GeneralCard>
  );
}

Academics.propTypes = {
  checkIfValue: PropTypes.func,
  data: PropTypes.array,
};

export default Academics;
