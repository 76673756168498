import React, { useCallback } from "react";
import CheckboxOptionsList from "components/CheckboxOptionsList";

const OPTIONS = {
  NCAA_I: "NCAA I",
  NCAA_II: "NCAA II",
  NCAA_III: "NCAA III",
  NAIA: "NAIA",
  JC: "JC",
};

function DivisionFilter({ filter, onChange }) {
  const { division = "" } = filter;
  const divisionArray = division ? division.split(",") : [];
  const handleOnChange = useCallback(
    (newDivisionArray) => {
      onChange({
        ...filter,
        division: newDivisionArray.join(","),
      });
    },
    [onChange, filter],
  );

  return (
    <CheckboxOptionsList
      value={divisionArray}
      onChange={handleOnChange}
      options={OPTIONS}
    />
  );
}

export default DivisionFilter;
