import client from "../adapter";

function createParentProfile(body) {
  return client(`/parent/profile`, { body });
}

function loadParentProfile() {
  return client(`/parent/profile`);
}

function updateParentProfile(profileId, body) {
  return client(`/parent/profile/${profileId}`, { body, method: "PATCH" });
}

function uploadParentProfilePhoto(image) {
  return client(`/parent/profile/photo`, {
    body: { image },
  });
}

function deleteParentProfilePhoto() {
  return client(`/parent/profile/photo`, {
    method: "DELETE",
  });
}

function addChildrenToParentProfile(email) {
  return client(`/parent/profile/children`, { body: { email } });
}

function removeChildrenFromParentProfile(childId) {
  return client(`/parent/profile/children/${childId}`, { method: "DELETE" });
}

function loadFavorites() {
  return client(`/parent/profile/favorites`);
}

function saveFavorites(groupName, collegeId) {
  return client(`/parent/profile/favorites`, {
    body: { groupName, collegeId },
    method: "POST",
  });
}

function updateFavorites(groupName, favoriteId) {
  return client(`/parent/profile/favorites/${favoriteId}`, {
    body: { groupName },
    method: "PATCH",
  });
}

function removeFavorites(favoriteId) {
  return client(`/parent/profile/favorites/${favoriteId}`, {
    method: "DELETE",
  });
}

export {
  createParentProfile,
  loadParentProfile,
  updateParentProfile,
  uploadParentProfilePhoto,
  deleteParentProfilePhoto,
  addChildrenToParentProfile,
  removeChildrenFromParentProfile,
  loadFavorites,
  saveFavorites,
  updateFavorites,
  removeFavorites,
};
