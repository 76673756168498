import React, { useState, useCallback } from "react";
import { useHistory, useParams } from "react-router";
import * as coachDomain from "api/domains/coach";
import useRecruitingProfile, {
  refreshRecruitingProfile,
} from "components/useRecruitingProfile";
import { Add as AddIcon } from "components/MaterialIcons";
import CreateCategoryField from "components/CoachFavoritePopover/CreateCategoryField";
import CoachCategoryField from "./CoachCategoryField";
import { Box } from "components/MaterialComponents";
import coachFavoriteGroupsColorsMap from "maps/coachFavoriteGroupsColors";
import Tooltip from "components/Tooltip";

import {
  CoachGroupItem,
  CoachGroupTitle,
  CoachFavoritesMainContainer,
  StyledAddbutton,
  StyledButton,
  StyledEditeIcon,
  StyledDeleteIcon,
  CreateGroupContainer,
  CoachGroupsContainer,
} from "./style";

function CoachCategories({ favoriteGroups }) {
  const params = useParams();
  const history = useHistory();
  const [selectedGroupId, setSelectedGroupId] = useState(params.groupId);
  const [editedGroupId, setEditedGroupId] = useState(null);
  const [isCreateMode, setIsCreateMode] = useState();
  const [{ profile: recruitingProfile }, dispatch] = useRecruitingProfile();

  const handleRemoveGroup = useCallback(
    async (groupId) => {
      try {
        const res = await coachDomain.deleteGroup(groupId);

        await refreshRecruitingProfile(dispatch, {
          ...recruitingProfile,
          athlete_favorites: res.favorites,
          favorite_groups: res.groups,
        });
        history.push(`/athletes/favorite`);
        setSelectedGroupId(null);
      } catch (error) {
        dispatch({ type: "update_error", error: error.errorCode });
      }
    },
    [dispatch, history, recruitingProfile],
  );

  const handleSelectGroup = useCallback(
    (id) => {
      history.push(`/athletes/favorite/${id}`);
      setSelectedGroupId(id);
    },
    [history],
  );

  const favoriteGroupsColorsNumber =
    Object.keys(coachFavoriteGroupsColorsMap).length - 1;

  const toggleCreateMode = useCallback(() => {
    setIsCreateMode(!isCreateMode);
  }, [isCreateMode]);

  const handleCreateGroup = useCallback(
    async (groupName) => {
      try {
        const body = {
          groupName,
          colorId: Math.floor(Math.random() * favoriteGroupsColorsNumber) + 1,
        };
        const newGroup = await coachDomain.createGroup(body);
        await refreshRecruitingProfile(dispatch, {
          ...recruitingProfile,
          favorite_groups: [...recruitingProfile.favorite_groups, newGroup],
        });
        toggleCreateMode();
      } catch (error) {
        dispatch({ type: "update_error", error: error.errorCode });
      }
    },
    [dispatch, recruitingProfile, favoriteGroupsColorsNumber, toggleCreateMode],
  );

  const handleUpdateGroup = useCallback(
    async (groupId, body) => {
      try {
        await coachDomain.updateGroup(groupId, body);
        const groups = await coachDomain.getAllgroups();
        await refreshRecruitingProfile(dispatch, {
          ...recruitingProfile,
          favorite_groups: groups,
        });
        setEditedGroupId(null);
      } catch (error) {
        dispatch({ type: "update_error", error: error.errorCode });
      }
    },
    [dispatch, recruitingProfile],
  );

  const handleSelectAll = useCallback(() => {
    history.push(`/athletes/favorite`);
    setSelectedGroupId(null);
  }, [history]);

  return (
    <CoachFavoritesMainContainer>
      <CoachGroupsContainer>
        <CoachGroupItem
          selected={!selectedGroupId}
          onClick={handleSelectAll}
          bgColor={coachFavoriteGroupsColorsMap.default}
        >
          <CoachGroupTitle>All Athletes</CoachGroupTitle>
        </CoachGroupItem>
        {favoriteGroups?.map((group) => (
          <CoachGroupItem
            key={group.id}
            onClick={() => handleSelectGroup(group.id)}
            selected={Number(selectedGroupId) === Number(group.id)}
            bgColor={
              coachFavoriteGroupsColorsMap[group.colorId] ||
              coachFavoriteGroupsColorsMap.default
            }
          >
            {Number(editedGroupId) === Number(group.id) ? (
              <CoachCategoryField
                group={group}
                updateGroup={handleUpdateGroup}
                setEditedGroupId={setEditedGroupId}
              />
            ) : (
              <Box display="flex" justifyContent="space-between" width="100%">
                <CoachGroupTitle>{group.groupName}</CoachGroupTitle>
                {Number(selectedGroupId) === Number(group.id) && (
                  <Box display="flex">
                    <Tooltip
                      title={"Edite group name"}
                      arrow={true}
                      placement="top"
                    >
                      <StyledButton onClick={() => setEditedGroupId(group.id)}>
                        <StyledEditeIcon />
                      </StyledButton>
                    </Tooltip>
                    <Tooltip
                      title={
                        "Remove group. By removing group, you will remove all favorites inside!"
                      }
                      arrow={true}
                      placement="top"
                    >
                      <StyledButton onClick={() => handleRemoveGroup(group.id)}>
                        <StyledDeleteIcon />
                      </StyledButton>
                    </Tooltip>
                  </Box>
                )}
              </Box>
            )}
          </CoachGroupItem>
        ))}
      </CoachGroupsContainer>
      <CreateGroupContainer>
        {isCreateMode ? (
          <Box marginLeft="10px">
            <CreateCategoryField
              onGroupCreate={handleCreateGroup}
              closeForm={toggleCreateMode}
            />
          </Box>
        ) : (
          <StyledAddbutton onClick={toggleCreateMode}>
            <AddIcon />
          </StyledAddbutton>
        )}
      </CreateGroupContainer>
    </CoachFavoritesMainContainer>
  );
}

export default React.memo(CoachCategories);
