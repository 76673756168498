import React, { useCallback, useRef, useState } from "react";
import { Box } from "components/MaterialComponents";
import { Card } from "components/MaterialComponents";
import { ClickAwayListener } from "components/MaterialComponents";
import { useMediaQuery } from "components/MaterialComponents";
import Modal from "components/Modal";
import FilterMobileFooter from "./FilterMobileFooter";

import { ReactComponent as ExpandIcon } from "assets/icons/expand.svg";

import {
  FilterPopperContent,
  StyledPopper,
  FilterButton,
  FilterHeader,
  FilterTitle,
  FilterCloseButton,
  MobileContainer,
  MobileContentContainer,
  MobileHeaderContainer,
} from "./style";

function FilterPopper({
  children,
  anchor,
  filterTitle,
  resetFilter,
  isData = true,
  testId,
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef();
  const onAnchorClick = useCallback(() => setOpen(true), [setOpen]);
  const onClickAway = useCallback(() => setOpen(false), [setOpen]);
  const matches = useMediaQuery("(max-width:900px)");

  return (
    <Box pr={1}>
      <FilterButton
        variant="text"
        ref={anchorRef}
        onClick={onAnchorClick}
        endIcon={<ExpandIcon />}
        disableTouchRipple
        disabled={!isData}
        data-testid={testId}
      >
        {anchor}
      </FilterButton>
      {matches ? (
        <Modal open={open} onClose={onClickAway} fluid={true}>
          <MobileContainer>
            <MobileHeaderContainer>
              <FilterTitle>{filterTitle}</FilterTitle>
            </MobileHeaderContainer>
            <MobileContentContainer>{children}</MobileContentContainer>
            <FilterMobileFooter
              resetFilter={resetFilter}
              onDone={onClickAway}
            />
          </MobileContainer>
        </Modal>
      ) : (
        <StyledPopper
          anchorEl={anchorRef.current}
          open={open}
          data-testid="filter-popper"
        >
          <Card elevation={3}>
            <FilterPopperContent>
              <ClickAwayListener onClickAway={onClickAway}>
                <Box p={2}>
                  <FilterHeader>
                    <FilterTitle>{filterTitle}</FilterTitle>
                    <FilterCloseButton onClick={onClickAway} />
                  </FilterHeader>
                  {children}
                </Box>
              </ClickAwayListener>
            </FilterPopperContent>
          </Card>
        </StyledPopper>
      )}
    </Box>
  );
}

export default FilterPopper;
