import React, { useCallback, useState, useEffect } from "react";
import FilterPopperField from "./FilterPopperField";
import {
  StyledSlider,
  StyledFilterValuesBlue,
  StyledFilterValuesGrey,
} from "./style";

const min = 0;
const max = 36;
/*const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 16,
    label: "16",
  },
  {
    value: 24,
    label: "24",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 36,
    label: "36",
  },
];*/
function ACTFilter({ filter, onChange }) {
  const { actMax } = filter;
  const [value, setValue] = useState(parseInt(actMax) || min);

  useEffect(() => {
    setValue(parseInt(filter.actMax) || min);
  }, [filter]);

  const onValueChange = useCallback(
    (e, newValue) => {
      setValue(newValue);
    },
    [setValue],
  );
  const onChangeCommitted = useCallback(
    (e, actMax) => {
      if (actMax === min) {
        onChange({
          ...filter,
          actMin: null,
          actMax: null,
        });
        return;
      }
      onChange({
        ...filter,
        actMin: min,
        actMax,
      });
    },
    [onChange, filter],
  );
  return (
    <FilterPopperField label="ACT:">
      {value === min ? (
        <StyledFilterValuesGrey variant="h5">
          Select the value
        </StyledFilterValuesGrey>
      ) : (
        <StyledFilterValuesBlue variant="h5">{value}</StyledFilterValuesBlue>
      )}
      <StyledSlider
        value={value}
        min={min}
        max={max}
        step={1}
        onChange={onValueChange}
        onChangeCommitted={onChangeCommitted}
        valueLabelDisplay="auto"
        aria-labelledby="continuous-slider"
        //marks={marks}
      />
    </FilterPopperField>
  );
}

export default ACTFilter;
