import styled from "styled-components";
import { KeyboardDatePicker } from "components/MaterialComponents";
import * as colors from "theme/colors";

export const StyledDatePicker = styled(KeyboardDatePicker)`
  & .MuiButtonBase-root {
    color: ${colors.Allports};
  }

  & .MuiInputBase-root {
    padding-right: 0px;
  }
`;
