import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

function useFilter() {
  const filterFromSearchString = useCallback((search) => {
    if (!search) return {};
    const items = new URLSearchParams(search);
    const result = {};
    for (const item of items) {
      const [key, value] = item;
      result[key] = value;
    }
    return result;
  }, []);

  const history = useHistory();
  const { search } = useLocation();

  const [filter, setFilter] = useState(filterFromSearchString(search));
  const [query, setQuery] = useState(new URLSearchParams(search).toString());

  useEffect(() => {
    const strippedFilter = Object.fromEntries(
      Object.entries(filter).filter(
        ([, value]) => Boolean(value) || value === 0,
      ),
    );
    setQuery(new URLSearchParams(strippedFilter).toString());
  }, [filter]);

  useEffect(() => {
    if (query === new URLSearchParams(history.location.search).toString())
      return;
    history.push({ pathname: history.location.pathname, search: query });
  }, [query, history]);

  useEffect(() => {
    setFilter(filterFromSearchString(search));
  }, [search, filterFromSearchString]);

  return [filter, setFilter];
}

export default useFilter;
