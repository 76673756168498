import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";

import { NavigationButton } from "./style";

const routes = ["admissions", "sports", "cost", "majors"];

function Navigation({ refProps }) {
  const onLinkPress = (name) => {
    const ref = refProps[name];
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Box mt="64px">
      {routes.map((route) => (
        <NavigationButton
          type="text"
          disableRipple
          onClick={() => onLinkPress(route)}
          key={route}
        >
          {route}
        </NavigationButton>
      ))}
    </Box>
  );
}

Navigation.propTypes = {
  refProps: PropTypes.object,
};

export default Navigation;
