import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal";
import { Box } from "components/MaterialComponents";
import MessagesList from "./MessagesList";
import { AddButton } from "components/Buttons";
import { Add as AddIcon } from "components/MaterialIcons";
import ReplyEmailPopUp from "./ReplyEmailPopUp";

import {
  BackButton,
  StyledTitle,
  MobileModalHeader,
  MobileModalFooter,
  AddButtonContainer,
} from "./style";

function MobileMessagesPopUp({
  open,
  onClose,
  currentCoach,
  messages,
  sendEmail,
}) {
  const [isSendWindowOpen, setSendWindowOpen] = useState(false);
  const [modalRef, setModalRef] = useState();

  const toggleSendWindow = useCallback(() => {
    setSendWindowOpen(!isSendWindowOpen);
  }, [isSendWindowOpen]);

  const handleGoBack = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal open={open} fluid={true} showCloseIcon={false} setRef={setModalRef}>
      <MobileModalHeader>
        <Box display="flex" alignItems="baseline">
          <BackButton onClick={handleGoBack}>&#8592; Back</BackButton>
          <StyledTitle>{currentCoach?.name}</StyledTitle>
        </Box>
      </MobileModalHeader>
      <MessagesList
        messages={messages}
        sendEmail={sendEmail}
        containerRef={modalRef}
      />
      <MobileModalFooter>
        <AddButtonContainer>
          <AddButton onClick={toggleSendWindow}>
            <AddIcon fontSize="large" />
          </AddButton>
        </AddButtonContainer>
      </MobileModalFooter>
      <ReplyEmailPopUp
        open={isSendWindowOpen}
        onClose={toggleSendWindow}
        onSendEmail={sendEmail}
        containerRef={modalRef}
      />
    </Modal>
  );
}

MobileMessagesPopUp.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  currentCoach: PropTypes.object,
  messages: PropTypes.array,
  sendEmail: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
};

export default MobileMessagesPopUp;
