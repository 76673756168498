import React, { useEffect, useState } from "react";
import { Grid } from "components/MaterialComponents";
import { Skeleton } from "components/MaterialComponents";
import { KeyboardArrowUp as KeyboardArrowUpIcon } from "components/MaterialIcons";
import { useMediaQuery } from "components/MaterialComponents";
import useOnScreen from "customHooks/useOnScreen";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import AppBar from "components/AppBar";
import Sidebar from "components/Sidebar";
import Spinner from "components/Spinner";
import FindColleges from "containers/FindColleges";
import CollegeDetails from "containers/CollegeDetails";
import PersonalInformation from "containers/Profile/PersonalInformation";
import AthleticPerformance from "containers/Profile/AthleticPerformance";
import AcademicInformation from "containers/Profile/AcademicInformation";
import ContactInformation from "containers/Profile/ContactInformation";
import HighlightReel from "containers/Profile/HighlightReel";
import ContactCoaches from "containers/ContactCoaches";
import ContactActivity from "containers/ContactActivity";
import ContactDetails from "containers/ContactActivity/ContactDetails";
import AthleticHistory from "containers/AthleticHistory";
import ProfileCard from "containers/Profile/ProfileCard";
import CoachProfileCard from "containers/Profile/CoachProfileCard";
import ParentProfileCard from "containers/Profile/ParentProfileCard";
import FindAthletes from "containers/FindAthletes";
import ReadOnlyProfileView from "containers/Profile/ReadOnlyProfileView";
import CoachPersonalInformation from "containers/Profile/CoachPersonalInformation";
import ParentPersonalInformation from "containers/Profile/ParentProfile/PersonalInformation";
import ParentChildrenInformation from "containers/Profile/ParentProfile/ChildrenInformation";
import UnsortedContactDetails from "containers/ContactActivity/UnsortedContactDetails";
import Notes from "containers/Notes";
import Admin from "containers/Admin";
import ProfileButton from "containers/Profile/ProfileButton";
import useProfile, { loadCurrentUser, logout } from "components/useProfile";
import { useNotesDispatch, getUserNotes } from "components/useNotes";
import {
  useMessagesDispatch,
  useMessagesState,
  getContactsSummary,
  setCurrentThreadForCoach,
} from "components/useMessages";
import { useRecruitingProfileState } from "components/useRecruitingProfile";
import ScrollToTop from "components/ScrollToTop";
import SessionTimeout from "components/SessionTimeOut";
import { GoTopButton } from "components/Buttons";
import { userTypesEnum } from "enums/userTypesEnum";
import ContactCoachActivity from "containers/CoachContactActivity";
import {
  MainGridContainer,
  SidebarGridItem,
  ContentGridItem,
  MainContainer,
} from "./style";

function Routing() {
  const matches = useMediaQuery("(max-width:1575px)");
  const [{ profile, isLoggedIn, loading }, dispatch] = useProfile();
  const isAdmin = profile?.isAdmin;
  const isAthlete = profile?.type === userTypesEnum.ATHLETE;
  const isCoach = profile?.type === userTypesEnum.COACH;
  const isParent = profile?.type === userTypesEnum.PARENT;
  const {
    profile: recruitingProfile,
    loading: recruitingProfileLoading,
  } = useRecruitingProfileState();

  const notesDispatch = useNotesDispatch();
  const messagesDispatch = useMessagesDispatch();

  const { collegesSummary, coachSummary } = useMessagesState();
  const messagesSummary = isCoach ? coachSummary : collegesSummary;

  const [appBarRef, setAppBarRef] = useState(null);
  const isAppBarVisible = useOnScreen(appBarRef, {}, true);

  useEffect(() => {
    loadCurrentUser(dispatch);
  }, [dispatch]);

  useEffect(() => {
    isAthlete && getUserNotes(notesDispatch);
    isParent && getUserNotes(notesDispatch);
  }, [notesDispatch, isAthlete, isParent]);

  useEffect(() => {
    isAthlete && getContactsSummary(messagesDispatch);
    isParent && getContactsSummary(messagesDispatch);
    isCoach && setCurrentThreadForCoach(messagesDispatch);
  }, [messagesDispatch, profile]);

  const sidebarNavigationVisible = isLoggedIn && recruitingProfile;
  const sidebarLg = matches ? 3 : 2;
  const contentLg = matches ? 9 : 10;

  if (loading) {
    return <Spinner size={60} />;
  }

  const { displayName } = profile || {};

  const profileCard = recruitingProfileLoading ? (
    <Skeleton variant="text" width="100%" style={{ marginTop: "2em" }} />
  ) : (
    <ProfileCard name={displayName} />
  );

  const coachProfileCard = recruitingProfileLoading ? (
    <Skeleton variant="text" width="100%" style={{ marginTop: "2em" }} />
  ) : (
    <CoachProfileCard name={displayName} />
  );

  const parentProfileCard = recruitingProfileLoading ? (
    <Skeleton variant="text" width="100%" style={{ marginTop: "2em" }} />
  ) : (
    <ParentProfileCard name={displayName} />
  );

  const AuthenticatedAthleteSwitch = (
    <Switch>
      <Route path="/contact-coaches/:id">
        <ContactCoaches />
      </Route>
      <Route path="/contact-activity" exact>
        <ContactActivity />
      </Route>
      <Route path="/contact/usorted">
        <UnsortedContactDetails />
      </Route>
      <Route path="/contact-activity/:collegeId">
        <ContactDetails />
      </Route>
      <Route path="/profile/personal-information">
        {profileCard}
        <PersonalInformation />
      </Route>
      <Route path="/profile/athletic-performance">
        {profileCard}
        <AthleticPerformance />
      </Route>
      <Route path="/profile/highlight-reel">
        {profileCard}
        <HighlightReel />
      </Route>
      <Route path="/profile/contact-information">
        {profileCard}
        <ContactInformation />
      </Route>
      <Route path="/profile/academic-information">
        {profileCard}
        <AcademicInformation />
      </Route>
      <Route path="/profile/athletic-history">
        {profileCard}
        <AthleticHistory />
      </Route>
      <Route path="/public-profile-view/:id">
        <ReadOnlyProfileView />
      </Route>
      <Route path="/coaches-profile-view/:id">
        <ReadOnlyProfileView forCoaches={true} />
      </Route>
      <Route path="/college/:id">
        <CollegeDetails />
      </Route>
      <Route path="/colleges/:favorite">
        <FindColleges />
      </Route>
      <Route path="/user/email/verify/:token">
        <FindColleges />
      </Route>
      <Route path="/signup/email/verify/:token">
        <FindColleges />
      </Route>
      <Route path="/colleges">
        <FindColleges />
      </Route>
      <Route path="/notes">
        <Notes />
      </Route>
      <Route path="/admin">{isAdmin ? <Admin /> : <Redirect to="/" />}</Route>
      <Route path="/">
        <Redirect to="/colleges" />
      </Route>
    </Switch>
  );

  const AuthenticatedCoachSwitch = (
    <Switch>
      <Route path="/coaches-profile-view/:id">
        <ReadOnlyProfileView forCoaches={true} />
      </Route>
      <Route path="/profile/personal-information">
        {coachProfileCard}
        <CoachPersonalInformation />
      </Route>
      <Route path="/user/email/verify/:token">
        <FindAthletes />
      </Route>
      <Route path="/athletes/favorite/:groupId">
        <FindAthletes />
      </Route>
      <Route path="/athletes">
        <FindAthletes />
      </Route>
      <Route path="/signup/email/verify/:token">
        <FindAthletes />
      </Route>
      <Route path="/contact-activity" exact>
        <ContactCoachActivity />
      </Route>
      <Route path="/colleges">
        <Redirect to="/athletes" />
      </Route>
      <Route path="/">
        <Redirect to="/colleges" />
      </Route>
    </Switch>
  );

  const AuthenticatedParentSwitch = (
    <Switch>
      <Route path="/profile/personal-information">
        {parentProfileCard}
        <ParentPersonalInformation />
      </Route>
      <Route path="/profile/children-information">
        {parentProfileCard}
        <ParentChildrenInformation />
      </Route>
      <Route path="/contact-activity" exact>
        <ContactActivity />
      </Route>
      <Route path="/contact-activity/:collegeId">
        <ContactDetails />
      </Route>
      <Route path="/contact-coaches/:id">
        <ContactCoaches />
      </Route>
      <Route path="/notes">
        <Notes />
      </Route>
      <Route path="/colleges/:favorite">
        <FindColleges />
      </Route>
      <Route path="/colleges">
        <FindColleges />
      </Route>
      <Route path="/">
        <Redirect to="/colleges" />
      </Route>
    </Switch>
  );

  const renderRoutes = () => {
    switch (profile.type) {
      case userTypesEnum.COACH:
        return AuthenticatedCoachSwitch;
      case userTypesEnum.PARENT:
        return AuthenticatedParentSwitch;
      default:
        return AuthenticatedAthleteSwitch;
    }
  };

  const NotAuthenticatedAppSwitch = (
    <Switch>
      <Route path="/public-profile-view/:id">
        <ReadOnlyProfileView />
      </Route>
      <Route path="/coaches-profile-view/:id">
        <ReadOnlyProfileView forCoaches={true} />
      </Route>
      <Route path="/college/:id">
        <CollegeDetails />
      </Route>
      <Route path="/user/email/verify/:token">
        <FindColleges />
      </Route>
      <Route path="/signup/email/verify/:token">
        <FindColleges />
      </Route>
      <Route path="/colleges">
        <FindColleges />
      </Route>
      <Route path="/athletes">
        <Redirect to="/colleges" />
      </Route>
      <Route path="/reset-password">
        <FindColleges />
      </Route>
      <Route path="/">
        <Redirect to="/colleges" />
      </Route>
    </Switch>
  );

  return (
    <Router>
      <ScrollToTop />
      <AppBar setRef={setAppBarRef}>
        <ProfileButton />
      </AppBar>
      <MainContainer maxWidth="xl">
        <MainGridContainer container>
          {sidebarNavigationVisible && (
            <SidebarGridItem item lg={sidebarLg} sm={4} md={3}>
              <Sidebar messagesSummary={messagesSummary} />
            </SidebarGridItem>
          )}
          {isLoggedIn ? (
            <ContentGridItem item lg={contentLg} sm={8} md={9} xs={12}>
              {renderRoutes()}
              <SessionTimeout logOut={() => logout(dispatch)} />
            </ContentGridItem>
          ) : (
            <Grid item sm={12}>
              {NotAuthenticatedAppSwitch}
            </Grid>
          )}
        </MainGridContainer>
      </MainContainer>
      {!isAppBarVisible && (
        <GoTopButton
          onClick={() => appBarRef.scrollIntoView({ behavior: "smooth" })}
        >
          <KeyboardArrowUpIcon fontSize="large" />
        </GoTopButton>
      )}
    </Router>
  );
}

export default Routing;
