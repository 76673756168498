import React from "react";
import styled from "styled-components";
import { Avatar } from "components/MaterialComponents";
import * as colors from "theme/colors";

export const StyledAvatar = styled(({ withBorder, ...rest }) => (
  <Avatar {...rest} />
))`
  ${({ $withBorder }) => `
    width: 200px;
    height: 200px;
    border-radius: 50%;
    font-size: 2rem;
    background-color: ${colors.Whisper};
    color: ${colors.Allports};
    border: ${$withBorder ? `1px solid ${colors.CatskillWhite}` : "none"};
    @media (max-width: 1250px) {
      width: 160px;
      height: 160px;
    }
  `}
`;
