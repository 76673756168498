export const campusSizeOptions = [
  { value: "Midsize", label: "Midsize" },
  { value: "Distant", label: "Distant" },
  { value: "Large", label: "Large" },
  { value: "Remote", label: "Remote" },
  { value: "Small", label: "Small" },
  { value: "Fringe", label: "Fringe" },
];

export const campusTypeOptions = [
  { value: "City", label: "City" },
  { value: "Town", label: "Town" },
  { value: "Suburb", label: "Suburb" },
  { value: "Rural", label: "Rural" },
];

export const sanctionOptions = [
  { value: 1, label: "NCAA" },
  { value: 2, label: "NAIA" },
  { value: 3, label: "NJCAA" },
  { value: 4, label: "NCCAA" },
  { value: 9, label: "other" },
];
