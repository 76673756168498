import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Table } from "components/MaterialComponents";
import { TableBody } from "components/MaterialComponents";
import { TableContainer } from "components/MaterialComponents";
import { TableRow } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { makeStyles } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import SelectOptionsTableRow from "./SelectOptionsTableRow";
import SelectOptionsCreateEditePopUp from "./SelectOptionsCreateEditePopUp";
import last from "lodash.last";
import * as sportsDomain from "api/domains/sports";
import { StyledTableHead, SportTableHeadContainer } from "../../style";

const useStyles = makeStyles({
  table: {
    width: 1100,
  },
});

function SelectOptionsList({ options, sportDefId, refetchSportsData }) {
  const [isOptionsModalOpen, setOptionsModalOpen] = useState(false);
  const classes = useStyles();

  const optionModalOpen = useCallback(() => {
    setOptionsModalOpen(true);
  }, []);

  const optionModalClose = useCallback(() => {
    setOptionsModalOpen(false);
  }, []);

  const handleCreateOption = useCallback(
    async (values) => {
      const lastOption = last(options);
      const ordinal = lastOption ? lastOption.ordinal + 1 : 1;
      const body = {
        label: values.label,
        value: values.value,
        ordinal,
        sportDefId,
      };
      await sportsDomain.createSportStatSelectOption(body);
      await refetchSportsData();
    },
    [options, refetchSportsData, sportDefId],
  );

  const handleEditeOption = useCallback(
    async (values, id) => {
      const body = {
        label: values.label,
        value: values.value,
      };
      await sportsDomain.updateSportStatSelectOption(body, id);
      await refetchSportsData();
    },
    [refetchSportsData],
  );

  const handleDeleteOption = useCallback(
    async (id) => {
      await sportsDomain.deleteSportStatSelectOption(id);
      await refetchSportsData();
    },
    [refetchSportsData],
  );

  return (
    <>
      <SportTableHeadContainer>
        <Box marginRight="10px" marginLeft="10px">
          <Typography variant="h5">Select Options</Typography>
        </Box>
        <Button color="primary" onClick={optionModalOpen}>
          Add new
        </Button>
      </SportTableHeadContainer>
      <TableContainer className={classes.table}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell>ORDINAL</TableCell>
              <TableCell>LABEL</TableCell>
              <TableCell>VALUE</TableCell>
              <TableCell>EDIT</TableCell>
              <TableCell>DELETE</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {options.map((option) => (
              <SelectOptionsTableRow
                key={option.id}
                option={option}
                handleEditeOption={handleEditeOption}
                handleDeleteOption={handleDeleteOption}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SelectOptionsCreateEditePopUp
        open={isOptionsModalOpen}
        onClose={optionModalClose}
        onCreateOption={handleCreateOption}
      />
    </>
  );
}

SelectOptionsList.propTypes = {
  options: PropTypes.array,
  sportDefId: PropTypes.number,
  refetchSportsData: PropTypes.func,
};

export default SelectOptionsList;
