import React from "react";
import PropTypes from "prop-types";

import getShortName from "helpers/getShortName";
import { StyledAvatar, StyledAvatarSmall } from "./style";

function CollegeLogo({ name, image, small, inverted = false }) {
  return (
    <>
      {small ? (
        <StyledAvatarSmall
          src={image}
          alt="college logo"
          variant="circle"
          color="primary"
        >
          {getShortName(name)}
        </StyledAvatarSmall>
      ) : (
        <StyledAvatar
          src={image}
          alt="college logo"
          variant="circle"
          color="primary"
          $inverted={inverted}
        >
          {getShortName(name)}
        </StyledAvatar>
      )}
    </>
  );
}

CollegeLogo.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  small: PropTypes.bool,
};

export default CollegeLogo;
