const transformRoutes = (routes) => {
  const reducedRoutes = routes.reduce((acc, route) => {
    acc[route.parentId] = acc[route.parentId] || [];
    acc[route.parentId].push(route);
    return acc;
  }, {});

  Object.keys(reducedRoutes).forEach((key) => {
    if (key === "undefined") {
      delete reducedRoutes[key];
    }
  });
  return reducedRoutes;
};

export default transformRoutes;
