import React from "react";
import { Skeleton } from "components/MaterialComponents";

import { CollegeDetailedCardSkeleton } from "containers/CollegeDetails/CollegeDetailedCard";

function CollegeDetailsSkeleton() {
  const style = { margin: "0 auto", marginBottom: "10px", marginTop: "25px" };

  return (
    <>
      <CollegeDetailedCardSkeleton />
      <Skeleton style={style} variant="rect" width="70%" height="20em" />
      <Skeleton style={style} variant="rect" width="70%" height="20em" />
      <Skeleton style={style} variant="rect" width="70%" height="20em" />
      <Skeleton style={style} variant="rect" width="70%" height="20em" />
    </>
  );
}

export default CollegeDetailsSkeleton;
