import React from "react";
import PropTypes from "prop-types";

import SportStatSelect from "./SportStatSelect";
import SportStatTextField from "./SportStatTextField";
import SportStatVerify from "./SportStatVerify";
import { SportStatWithAnswerContainer } from "./style";

function SportStatWithAnswer({ item, index, setFieldValue }) {
  const {
    inputType,
    name,
    answer,
    sportStatsSelectOptions,
    prompt,
    min,
    max,
    qualifierDescription,
    inputUnits,
  } = item;

  const labelId = `sportStatSelect-${index}`;

  return (
    <SportStatWithAnswerContainer>
      {inputType === "SELECT" ? (
        <SportStatSelect
          labelId={labelId}
          label={name}
          index={index}
          value={answer.valueText || ""}
          selectOptions={sportStatsSelectOptions}
          setFieldValue={setFieldValue}
        />
      ) : (
        <SportStatTextField
          label={name}
          inputType={inputType}
          min={min}
          max={max}
          value={answer.valueText || ""}
          index={index}
          prompt={prompt}
          setFieldValue={setFieldValue}
          inputUnits={inputUnits}
        />
      )}
      {qualifierDescription && (
        <SportStatVerify
          label={qualifierDescription}
          index={index}
          verifiedBy={answer.verifiedBy}
          verifiedDate={answer.verifiedDate}
          setFieldValue={setFieldValue}
        />
      )}
    </SportStatWithAnswerContainer>
  );
}

SportStatWithAnswer.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  setFieldValue: PropTypes.func,
};

export default SportStatWithAnswer;
