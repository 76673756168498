import styled from "styled-components";
import { TextField } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Edit as EditIcon } from "components/MaterialIcons";
import { Delete as DeleteIcon } from "components/MaterialIcons";
import * as colors from "theme/colors";

export const FormContainer = styled.div`
  display: flex;
`;

export const CreateFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledButton = styled.div`
  margin-right: 10px;
  font-size: 12px;
  cursor: pointer;
`;

export const StyledEditeIcon = styled(EditIcon)`
  :hover {
    color: ${colors.Allports};
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  :hover {
    color: ${colors.Red};
  }
`;

export const SaveButton = styled(Button)`
  font-size: 12px;
  width: 40px;
  height: 30px;
`;

export const CancelButton = styled.div`
  padding: 10px;
  font-size: 12px;
  cursor: pointer;
  color: ${colors.BrightGray};
`;

export const StyledInput = styled(TextField)`
  width: 100%;

  .MuiInputBase-input {
    color: black;
  }

  .MuiInput-underline::before {
    border-color: ${(props) => props.disabled && "transparent"};
  }
`;

export const InputContainer = styled.div`
  margin-right: 20px;
  width: 80%;
`;

export const ListContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;

    ${theme.breakpoints.down("sm")} {
      padding: 40px 0;
    }
  `}
`;

export const NotesListContaner = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;
  padding-right: 30px;
  padding-left: 30px;
  background-color: white;
`;

export const NotesModalContainer = styled.div`
  ${({ theme }) => `
    margin-top: 40px;
    min-width: 450px;
    min-height: 450px;

    ${theme.breakpoints.down("sm")} {
      min-width: 90%;
      min-height: 100%;
    }
  `}
`;

export const ListBullet = styled.div`
  margin-right: 10px;
  padding-top: 5px;
  font-weight: 700;
`;

export const NotesContainer = styled.div`
  display: flex;
`;

export const CollegesContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 30%;
    border-right: 1px solid ${colors.AthensGrayDark};
    min-height: 900px;

    ${theme.breakpoints.down("sm")} {
      width: 100%;
      min-height: 0;
    }
  `}
`;

export const Name = styled.div`
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  margin-left: 10px;
  color: ${colors.Allports};
`;

export const CollegeItemContainer = styled.div`
  display: flex;
  padding: 15px;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? colors.AthensGray : "white"};
  transition: all 0.3s;
  border-top: 1px solid ${colors.AthensGray};

  :last-child {
    border-bottom: 1px solid ${colors.AthensGray};
  }

  :hover {
    background-color: ${colors.AthensGray};
  }
`;

export const CollegeModalHeader = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: 0;
    width: 100%;
    background-color: ${colors.AthensGray};

    ${theme.breakpoints.down("sm")} {
      position: fixed;
      z-index: 5;
    }
  `}
`;

export const HeaderData = styled.div`
  ${({ theme }) => `
    padding: 10px;
    display: flex;
    align-items: center;

    ${theme.breakpoints.down("sm")} {
      padding: 20px 10px;
    }
  `}
`;

export const CreateFormContainer = styled.div`
  margin-top: 20px;
  padding-left: 15px;
`;

export const NoteFieldContainer = styled.div`
  margin-top: 10px;
`;

export const CreateButtonGroup = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const HeadersContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const SearchContainer = styled.div`
  padding: 10px 20px;
`;
