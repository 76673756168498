import React from "react";
import ParentTreeItem from "./ParentTreeItem";
import ChildrenTreeItem from "./ChildrenTreeItem";
import {
  StyledProfileIcon,
  StyledNavLink,
  StyledCollegeIcon,
  StyledContactIcon,
  StyledNoteIcon,
} from "./style";

function ParentsTree({ selected, unredMessagesCount }) {
  return (
    <>
      <ParentTreeItem nodeId="1" name="Profile" icon={<StyledProfileIcon />}>
        <ChildrenTreeItem
          to="/profile/personal-information"
          nodeId="2"
          label="Personal Information"
        />

        <ChildrenTreeItem
          to="/profile/children-information"
          nodeId="3"
          label="Children Information"
        />
      </ParentTreeItem>
      <ParentTreeItem
        nodeId="4"
        name="Find Colleges"
        icon={<StyledCollegeIcon />}
      >
        <ChildrenTreeItem to="/" nodeId="5" label="All Schools" />
        <ChildrenTreeItem
          to="/colleges/reach"
          nodeId="6"
          label="Reach Schools"
        />
        <ChildrenTreeItem
          to="/colleges/target"
          nodeId="7"
          label="Target Schools"
        />
        <ChildrenTreeItem
          to="/colleges/safety"
          nodeId="8"
          label="Safety Schools"
        />
      </ParentTreeItem>
      <StyledNavLink to="/contact-activity">
        <ParentTreeItem
          selected={"9" === selected}
          nodeId="9"
          name="Contact Activity"
          icon={<StyledContactIcon />}
          unredMessagesCount={unredMessagesCount}
        />
      </StyledNavLink>

      <StyledNavLink to="/notes">
        <ParentTreeItem
          selected={"10" === selected}
          nodeId="10"
          name="Notes"
          icon={<StyledNoteIcon />}
        />
      </StyledNavLink>
    </>
  );
}

export default ParentsTree;
