import React from "react";
import PropTypes from "prop-types";
import CoachCategories from "./CoachCategories";
import Modal from "components/Modal";
import { Typography } from "components/MaterialComponents";
import { MobileButton } from "components/Buttons";
import {
  FavoritesGroupsModalBody,
  MobileHeaderContainer,
  MobileFooterContainer,
} from "./style";

function CoachCategoriesPopUp({ favoriteGroups, filter, open, onClose }) {
  return (
    <Modal open={open} onClose={onClose} fluid={true}>
      <MobileHeaderContainer>
        <Typography variant="h4">Favorite Groups</Typography>
      </MobileHeaderContainer>
      <FavoritesGroupsModalBody>
        <CoachCategories filter={filter} favoriteGroups={favoriteGroups} />
      </FavoritesGroupsModalBody>
      <MobileFooterContainer>
        <MobileButton variant="contained" color="primary" onClick={onClose}>
          Select
        </MobileButton>
      </MobileFooterContainer>
    </Modal>
  );
}

CoachCategoriesPopUp.propTypes = {
  favoriteGroups: PropTypes.array,
  filter: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CoachCategoriesPopUp;
