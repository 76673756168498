import React from "react";
import AcademicsFilterPopper from "./AcademicsFilterPopper";
import LocationFilterPopper from "./LocationFilterPopper";
import AthleticsFilterPopper from "./AthleticsFilterPopper";
import CostFilterPopper from "./CostFilterPopper";

function FilterMenu({ filter, onChange, majors }) {
  return (
    <>
      <AcademicsFilterPopper
        filter={filter}
        majors={majors}
        onChange={onChange}
      />
      <LocationFilterPopper filter={filter} onChange={onChange} />
      <AthleticsFilterPopper filter={filter} onChange={onChange} />
      <CostFilterPopper filter={filter} onChange={onChange} />
    </>
  );
}

export default React.memo(FilterMenu);
