import React from "react";
import { Box } from "components/MaterialComponents";
import PropTypes from "prop-types";
import getShortName from "helpers/getShortName";
import { Typography } from "components/MaterialComponents";

import {
  StyledUserItem,
  StyledTitle,
  StyledAvatar,
  CoachNewMessagesLabel,
} from "containers/ContactActivity/style";

function AthleteItem({ athlete, onSelect, selected, messages }) {
  const name = `${athlete.firstName} ${athlete.lastName}`;
  const unread = messages.reduce((sum, message) => {
    const condition =
      !message.messageRead && !message.emailFromConverted.includes("coaches");
    return condition ? sum + 1 : sum;
  }, 0);

  return (
    <StyledUserItem
      onClick={() => onSelect(athlete.athleteId)}
      selected={selected}
    >
      <StyledAvatar src={athlete.profilePhotoMediaItem}>
        {getShortName(name)}
      </StyledAvatar>
      <Box display="flex" flexDirection="column" marginLeft="10px">
        <StyledTitle>{name}</StyledTitle>
      </Box>
      {Boolean(unread) && (
        <CoachNewMessagesLabel>
          <Typography variant="caption">{`${unread} new`}</Typography>
        </CoachNewMessagesLabel>
      )}
    </StyledUserItem>
  );
}

AthleteItem.propTypes = {
  athlete: PropTypes.object,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

export default AthleteItem;
