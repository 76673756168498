import React from "react";
import styled from "styled-components";
import { Link } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import { TreeItem } from "components/MaterialComponents";
import { Link as ReactLink } from "react-router-dom";

export const CardDataContainer = styled(Box)`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
`}
`;

export const StyledLink = styled(Link)`
  text-transform: capitalize;
  cursor: pointer;
`;

export const NavigationButton = styled(Button)`
  ${({ theme }) => `
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding: 12px 32px;
  color: ${theme.palette.common.white};
  text-transform: uppercase;
  border-radius: 0;
  width: 150px;
  &:hover {
    background: ${theme.palette.common.fern};
  }
  ${theme.breakpoints.down("md")} {
      width: unset;
  }
    ${theme.breakpoints.down("sm")} {
      padding: 6px 16px;
  }
`}
`;

export const CardRegularText = styled((props) => (
  <Typography variant="body2" {...props} />
))`
  :not(:last-child) {
    margin-bottom: 6px;
  }
`;

export const CardSelectedSport = styled(ReactLink)`
  ${({ theme }) => `
    cursor: pointer;
    font-size: 14px;
    font-family: 'Open Sans',Arial,sans-serif;
    text-decoration: none;
    color: ${theme.palette.common.allports};

    :hover{
      text-decoration: none;
    }
  `}
`;

export const LinkContainer = styled.div`
  margin-bottom: 6px;
`;

export const CardBoldText = styled.span`
  margin: 0;
  display: inline;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
`;

export const CostTextContainer = styled(Box)`
  :not(:last-child) {
    margin-bottom: 19px;
  }
`;

export const CostValuesContainer = styled(Box)`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  ${theme.breakpoints.down("sm")} {
    align-items: flex-start;
  }
`}
`;

export const SportHeaderContainer = styled(Box)`
  margin-bottom: 0.6rem;
`;

export const StyledTreeItem = styled(TreeItem)`
  ${({ theme }) => `
    & .MuiTreeItem-iconContainer {
    color: ${theme.palette.common.allports};
  }
`}
`;
