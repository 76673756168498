import React, { useCallback } from "react";
import CampusSettingsFilter from "./CampusSettingsFilter";
import StatesFilter from "components/StatesFilter";
import FilterPopper from "components/FilterPopper";
import FilterPopperField from "./FilterPopperField";

function LocationFilterPopper({ filter, onChange }) {
  const onResetFilter = useCallback(() => {
    onChange({
      ...filter,
      campusSettings: null,
      states: null,
    });
  }, [onChange, filter]);

  return (
    <FilterPopper
      anchor="Location"
      filterTitle="Filter by Location"
      resetFilter={onResetFilter}
      testId="location-filter"
    >
      <FilterPopperField label="College Settings:">
        <CampusSettingsFilter filter={filter} onChange={onChange} />
      </FilterPopperField>
      <FilterPopperField label="States:">
        <StatesFilter filter={filter} onChange={onChange} />
      </FilterPopperField>
    </FilterPopper>
  );
}

export default LocationFilterPopper;
