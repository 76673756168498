import * as actionTypes from "./actionTypes";

const initialState = {
  history: [],
  loading: true,
  error: null,
  feedback: undefined,
};

function notesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SUCCESS: {
      return {
        ...state,
        history: action.data,
        loading: false,
        error: null,
      };
    }
    case actionTypes.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case actionTypes.SET_FEEDBACK: {
      return { ...state, feedback: action.data };
    }
    case actionTypes.CLEAR_FEEDBACK: {
      return { ...state, feedback: undefined, error: undefined };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default notesReducer;

export { initialState };
