import React from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal";
import NotesList from "./NotesList";
import CollegeLogo from "components/CollegeLogo";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import {
  NotesModalContainer,
  Name,
  CollegeModalHeader,
  HeaderData,
} from "./style";

function NotesPopUp({ open, onClose, collegeId, collegeNotes, name, logo }) {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));

  const isModalFluid = matchesXs ? true : false;

  return (
    <Modal open={open} onClose={onClose} fluid={isModalFluid}>
      <CollegeModalHeader>
        <HeaderData>
          <CollegeLogo name={name} image={logo} small={true} />
          <Name>{name}</Name>
        </HeaderData>
      </CollegeModalHeader>
      <NotesModalContainer data-testid="notes-modal-body">
        <NotesList collegeNotes={collegeNotes} collegeId={collegeId} />
      </NotesModalContainer>
    </Modal>
  );
}

NotesPopUp.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  collegeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  collegeNotes: PropTypes.array,
  name: PropTypes.string,
  logo: PropTypes.string,
};

export default NotesPopUp;
