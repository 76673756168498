import React from "react";
import HorizontalLine from "components/HorizontalLine";
import GeneralCard from "components/GeneralCard";
import { Headline } from "components/Typography";
import { makeStyles } from "components/MaterialComponents";
import { Table } from "components/MaterialComponents";
import { TableBody } from "components/MaterialComponents";
import { TableContainer } from "components/MaterialComponents";
import { TableHead } from "components/MaterialComponents";
import { Paper } from "components/MaterialComponents";
import isempty from "lodash.isempty";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import useMessages from "components/useMessages";
import CustomTableRow from "./CustomTableRow";
import NoMessages from "./NoMessages";
import Feedbacker from "components/Feedbacker";
import { CLEAR_FEEDBACK } from "components/useMessages/actionTypes";
import { messagesFeedbacks } from "components/useMessages/messagesFeedbacks";
import UnsortedEmailsRow from "./UnsortedEmailsRow";

import { CardContainer, StyledHeadRow, StyledHeadCell } from "./style";

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
});

function ContactActivity() {
  const [
    { collegesSummary, unsortedEmails, feedback },
    dispatch,
  ] = useMessages();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("xs"));

  const classes = useStyles();

  const cellPadding = matchesSm ? "checkbox" : "default";
  const cardheader = matchesSm ? "" : "Coaches Activity - All";

  return (
    <>
      <Headline>Contact Activity</Headline>
      <HorizontalLine />
      <CardContainer>
        {!isempty(collegesSummary) ? (
          <GeneralCard header={cardheader} width="80%">
            <TableContainer component={Paper}>
              <Table
                className={!matchesSm && classes.table}
                aria-label="Contact Activity"
                padding={cellPadding}
              >
                <TableHead>
                  <StyledHeadRow>
                    <StyledHeadCell>School</StyledHeadCell>
                    <StyledHeadCell align="right">Sends</StyledHeadCell>
                    <StyledHeadCell align="right">Opens</StyledHeadCell>
                    <StyledHeadCell align="right">Visits</StyledHeadCell>
                    <StyledHeadCell align="right">Replies</StyledHeadCell>
                    <StyledHeadCell align="right">New</StyledHeadCell>
                  </StyledHeadRow>
                </TableHead>
                <TableBody>
                  {collegesSummary.map((row) => (
                    <CustomTableRow key={row.schoolName} row={row} />
                  ))}
                  {!isempty(unsortedEmails) && (
                    <UnsortedEmailsRow emailsCount={unsortedEmails.length} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </GeneralCard>
        ) : (
          <NoMessages />
        )}
      </CardContainer>
      <Feedbacker
        open={feedback === messagesFeedbacks.Success}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage="Your message was successfully sent!"
        clearFeedback={() => dispatch({ type: CLEAR_FEEDBACK })}
      />
      <Feedbacker
        open={feedback === messagesFeedbacks.Error}
        autoHideDuration={3000}
        severity="error"
        feedbackMessage="Something went wrong!"
        clearFeedback={() => dispatch({ type: CLEAR_FEEDBACK })}
      />
    </>
  );
}

export default ContactActivity;
