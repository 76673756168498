import React, { useReducer } from "react";

import {
  RecruitingProfileStateContext,
  RecruitingProfileDispatchContext,
} from "./Context";
import recruitingProfileReducer, { initialState } from "./reducer";

function RecruitingProfileProvider({ children, testState }) {
  const [state, dispatch] = useReducer(recruitingProfileReducer, initialState);

  return (
    <RecruitingProfileStateContext.Provider
      value={testState ? testState : state}
    >
      <RecruitingProfileDispatchContext.Provider value={dispatch}>
        {children}
      </RecruitingProfileDispatchContext.Provider>
    </RecruitingProfileStateContext.Provider>
  );
}

export default RecruitingProfileProvider;
