import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal";
import { Box } from "components/MaterialComponents";
import MessageItem from "./MessageItem";
import { AddButton } from "components/Buttons";
import { Add as AddIcon } from "components/MaterialIcons";
import ReplyEmailPopUp from "containers/ContactActivity/ReplyEmailPopUp";

import {
  BackButton,
  StyledTitle,
  MobileModalHeader,
  MobileModalFooter,
  AddButtonContainer,
  MessagesListContainerCoach,
} from "containers/ContactActivity/style";

function MobileMessagesPopUp({
  open,
  onClose,
  currentAthlete,
  messages,
  sendEmail,
  setListref,
}) {
  const [isSendWindowOpen, setSendWindowOpen] = useState(false);
  const [modalRef, setModalRef] = useState();

  const toggleSendWindow = useCallback(() => {
    setSendWindowOpen(!isSendWindowOpen);
  }, [isSendWindowOpen]);

  const handleGoBack = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal open={open} fluid={true} showCloseIcon={false} setRef={setModalRef}>
      <MobileModalHeader>
        <Box display="flex" alignItems="baseline">
          <BackButton onClick={handleGoBack}>&#8592; Back</BackButton>
          <StyledTitle>{`${currentAthlete?.firstName} ${currentAthlete?.lastName}`}</StyledTitle>
        </Box>
      </MobileModalHeader>
      <MessagesListContainerCoach ref={setListref}>
        {messages?.map((message) => (
          <MessageItem
            key={message.id}
            message={message}
            containerRef={modalRef}
            sendEmail={sendEmail}
          />
        ))}
      </MessagesListContainerCoach>
      <MobileModalFooter>
        <AddButtonContainer>
          <AddButton onClick={toggleSendWindow}>
            <AddIcon fontSize="large" />
          </AddButton>
        </AddButtonContainer>
      </MobileModalFooter>
      <ReplyEmailPopUp
        open={isSendWindowOpen}
        onClose={toggleSendWindow}
        containerRef={modalRef}
        onSendEmail={sendEmail}
      />
    </Modal>
  );
}

MobileMessagesPopUp.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  currentAthlete: PropTypes.object,
  messages: PropTypes.array,
  sendEmail: PropTypes.func,
};

export default MobileMessagesPopUp;
