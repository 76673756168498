import React, { useState, useMemo, useCallback } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { useField } from "formik";
import * as colors from "theme/colors";
import { ErrorMessage } from "./style";

const MobileEditor = ({ name, setFieldValue, placeholder }) => {
  const [editorState, updateEditorState] = useState(EditorState.createEmpty());

  const [isFocused, setFocus] = useState(false);

  const [field, meta] = useField(name);
  const error = meta.touched && meta.error;

  const getBoxShadow = useCallback(() => {
    if (error && isFocused) {
      return `0 0 0 1px red`;
    }
    if (isFocused) {
      return `0 0 0 2px ${colors.Allports}`;
    }

    return "none";
  }, [error, isFocused]);

  const styles = useMemo(
    () => ({
      editor: {
        border: error ? "1px solid red" : "1px solid #F1F1F1",
        padding: "5px",
        marginBottom: "10px",
        height: "220px",
        overflow: "auto",
        background: "white",
        boxShadow: getBoxShadow(),
      },
    }),
    [error, getBoxShadow],
  );

  const onEditorStateChange = (editorStateToSet) => {
    updateEditorState(editorStateToSet);
    const rawContent = editorStateToSet.getCurrentContent().hasText()
      ? draftToHtml(convertToRaw(editorStateToSet.getCurrentContent()))
      : "";

    setFieldValue(name, rawContent);
  };

  return (
    <>
      <Editor
        name={field.name}
        placeholder={placeholder}
        editorState={editorState}
        editorStyle={styles.editor}
        onEditorStateChange={onEditorStateChange}
        handlePastedText={() => false}
        toolbar={{ options: ["inline"] }}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

export default MobileEditor;
