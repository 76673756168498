import * as coachDomain from "api/domains/coach";
import * as actionTypes from "./actionTypes";
import { recruitingProfileFeedbacks } from "./recruitingProfileFeedbacks";

async function createCoachProfile(dispatch, data) {
  try {
    dispatch({ type: actionTypes.FETCHING });
    const res = await coachDomain.createCoachProfile(data);
    dispatch({ type: actionTypes.SUCCESS, data: res });
    const feedback = res.verified
      ? recruitingProfileFeedbacks.ActivationEmailSent
      : recruitingProfileFeedbacks.ActivationEmailNotSent;

    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: feedback,
    });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.errorCode });
  }
}

async function loadCoachProfile(dispatch) {
  try {
    const res = await coachDomain.loadCoachProfile();
    dispatch({ type: actionTypes.SUCCESS, data: res });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.errorCode });
  }
}

async function updateCoachProfile(dispatch, profileId, data) {
  try {
    dispatch({ type: actionTypes.UPDATING });
    const res = await coachDomain.updateCoachProfile(profileId, data);
    dispatch({ type: actionTypes.SUCCESS, data: res });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: recruitingProfileFeedbacks.Updated,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_ERROR,
      error: error.errorCode,
      message: error.message,
    });
  }
}

async function uploadCoachProfilePhoto(dispatch, profileData, image) {
  try {
    dispatch({ type: actionTypes.UPDATING });
    const url = await coachDomain.uploadCoachProfilePhoto(image);
    dispatch({
      type: actionTypes.SUCCESS,
      data: { ...profileData, profilePhotoMediaItem: url },
    });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: recruitingProfileFeedbacks.Updated,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_ERROR,
      error: error.errorCode,
      message: error.message,
    });
  }
}

async function deleteCoachProfilePhoto(dispatch, profileData) {
  try {
    dispatch({ type: actionTypes.UPDATING });
    await coachDomain.deleteCoachProfilePhoto();
    dispatch({
      type: actionTypes.SUCCESS,
      data: { ...profileData, profilePhotoMediaItem: null },
    });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: recruitingProfileFeedbacks.Updated,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_ERROR,
      error: error.errorCode,
      message: error.message,
    });
  }
}

export {
  createCoachProfile,
  loadCoachProfile,
  updateCoachProfile,
  uploadCoachProfilePhoto,
  deleteCoachProfilePhoto,
};
