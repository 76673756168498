export function transformToString(array = []) {
  return array
    .map(
      (item) =>
        `${item.id},${item.min},${item.max},${item.minText},${item.maxText}`,
    )
    .join("/");
}

export function transformToArray(string = "") {
  if (!string) {
    return [];
  }

  return string.split("/").map((item) => {
    const itemArr = item.split(",");
    return {
      id: itemArr[0],
      min: itemArr[1],
      max: itemArr[2],
      minText: itemArr[3],
      maxText: itemArr[4],
    };
  });
}

export function transformToStringSelect(array = []) {
  return array.map((item) => `${item.id},${item.select}`).join("/");
}

export function transformToArraySelect(string = "") {
  if (!string) {
    return [];
  }

  return string.split("/").map((item) => {
    const itemArr = item.split(",");
    return {
      id: itemArr[0],
      select: itemArr[1],
    };
  });
}
