import * as colors from "theme/colors";

const coachFavoriteGroupsColorsMap = {
  1: colors.Tizzy,
  2: colors.LightYellow,
  3: colors.PattensBlue,
  4: colors.Orange,
  5: colors.Pink,
  default: colors.PattensBlue,
};

export default coachFavoriteGroupsColorsMap;
