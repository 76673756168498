import client from "../adapter";

function getCollegeCoaches(page, pageSize, errorFilter, name) {
  return client(
    `/colleges/college-coaches?page=${page}&pageSize=${pageSize}&errorFilter=${errorFilter}&name=${name}`,
  );
}

function getCollegeCoachesErrorsSummary(page, pageSize) {
  return client(`/colleges/college-coaches/errors-summary`);
}

function createCollegeCoach(body) {
  return client(`/colleges/college-coaches`, { body, method: "POST" });
}

function collegeCoachBulkCreation(body) {
  return client(`/colleges/college-coaches/bulk-creation`, {
    body,
    method: "POST",
  });
}

function deleteCollegeCoach(id) {
  return client(`/colleges/college-coaches/${id}`, { method: "DELETE" });
}

function editCollegeCoach(id, body) {
  return client(`/colleges/college-coaches/${id}`, { body, method: "PATCH" });
}

function startScript() {
  window.fetch(`${process.env.REACT_APP_SCRAPING_SERVER_API}/start`);
}

function stopScript() {
  window.fetch(`${process.env.REACT_APP_SCRAPING_SERVER_API}/stop`);
}

export {
  getCollegeCoaches,
  createCollegeCoach,
  deleteCollegeCoach,
  editCollegeCoach,
  collegeCoachBulkCreation,
  getCollegeCoachesErrorsSummary,
  startScript,
  stopScript,
};
