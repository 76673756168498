import styled from "styled-components";
import { Popover } from "components/MaterialComponents";
import * as colors from "theme/colors";

export const StyledPopover = styled(Popover)`
  & .MuiPopover-paper {
    padding: 15px 30px 19px 33px;
    border-radius: 4px;
    background: ${colors.CatskillWhite};
    overflow: visible;
    &::before {
      content: " ";
      position: absolute;
      border-top: 11px solid ${colors.CatskillWhite};
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      left: 44.8%;
      bottom: -8.2%;
      transform: rotate(0deg);
    }
  }
  & .MuiBox-root {
    &:first-child {
      margin-bottom: 4px;
    }
  }
  transform: translate(0, -10px);
`;
