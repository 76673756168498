import React from "react";
import { Box } from "components/MaterialComponents";
import PropTypes from "prop-types";

import { ContactAthletesPopover as Popover, SportItem } from "./style";

function ContactAthletesPopover({
  id,
  open,
  anchorEl,
  sports = [],
  handleClose,
  onSportSelect,
}) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      {sports.map((sport) => (
        <SportItem
          key={sport.id}
          onClick={() => onSportSelect(sport)}
          data-testid={sport.name}
        >
          <Box>Regarding {sport.name}</Box>
        </SportItem>
      ))}
    </Popover>
  );
}

ContactAthletesPopover.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  sports: PropTypes.array,
  handleClose: PropTypes.func,
  onSportSelect: PropTypes.func,
};

export default ContactAthletesPopover;
