import React from "react";
import { Box } from "components/MaterialComponents";
import { Headline } from "components/Typography";
import HorizontalLine from "components/HorizontalLine";
import CoachesList from "./CoachesList";
import AdminTabs from "./AdminTabs";
import SportsList from "./Sports";
import CollegesList from "./Colleges";
import CollegeCoachesList from "./CollegeCoaches";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import { HeadersContainer, MainContainer } from "./style";

function Admin() {
  const { path, url, isExact } = useRouteMatch();

  if (isExact) {
    return <Redirect to={`${url}/coaches-verification`} />;
  }

  const adminSwitch = (
    <Switch>
      <Route path={`${path}/coaches-verification`}>
        <CoachesList />
      </Route>
      <Route path={`${path}/sports`}>
        <SportsList />
      </Route>
      <Route path={`${path}/colleges`}>
        <CollegesList />
      </Route>
      <Route path={`${path}/college-coaches`}>
        <CollegeCoachesList />
      </Route>
    </Switch>
  );

  return (
    <>
      <HeadersContainer>
        <Box marginRight="15px">
          <Headline>Admin</Headline>
        </Box>
      </HeadersContainer>
      <HorizontalLine />
      <MainContainer>
        <AdminTabs path={path} url={url} />
        {adminSwitch}
      </MainContainer>
    </>
  );
}

export default Admin;
