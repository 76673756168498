import styled from "styled-components";
import { Box } from "components/MaterialComponents";
import { Grid } from "components/MaterialComponents";
import { Pagination } from "components/MaterialComponents";
import { TextField } from "components/MaterialComponents";
import { Close as CloseIcon } from "components/MaterialIcons";
import { Typography } from "components/MaterialComponents";
import { Slider } from "components/MaterialComponents";
import { TreeItem } from "components/MaterialComponents";
import * as colors from "theme/colors";

export const Container = styled.div``;

export const MobileContainer = styled.div`
  width: 90%;
`;

export const MainFiltersContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
    ${theme.breakpoints.down("sm")} {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 10px;
    }
  `}
`;

export const FilterBarContainer = styled(Box)`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    ${theme.breakpoints.down("sm")} {
      width: 100%;
      flex-direction: column;
      margin-bottom: 10px;
    }
  `}
`;

export const FilterSubTitle = styled.div`
  font-weight: bold;
  margin: 0 0 8px -8px;
`;

export const FilterCloseButton = styled(CloseIcon)`
  cursor: pointer;
`;

export const FilterHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

export const FilterTitle = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Open Sans", Arial, sans-serif;
`;

export const StyledPagination = styled(Pagination)`
  ${({ theme }) => `
    & .MuiPagination-ul {
      ${theme.breakpoints.down("sm")} {
        justify-content: center;
      }
    }
  `}
`;

export const StyledTextField = styled(TextField)`
  ${({ theme }) => `{
    width: 100%;
    ${theme.breakpoints.down("sm")} {
      width: 80%;
    }
  `}
`;

export const CollegesGridContainer = styled(Grid)`
  ${({ theme }) => `
  width: 100%;
  ${theme.breakpoints.down("sm")} {
      margin: 0;
    }
`}
`;

export const CollegesContainer = styled(Box)`
  ${({ theme }) => `{
    margin: 28px 0 0 40.5px;
    ${theme.breakpoints.down("sm")} {
      margin: 1em 0;
    }
  `}
`;

export const SearchContainer = styled.div`
  ${({ theme }) => `
  width: 31.5%;
  ${theme.breakpoints.down("sm")} {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
    }
  `}
`;

export const MenuContainer = styled.div`
  ${({ theme }) => `
  display: flex;
  ${theme.breakpoints.down("sm")} {
    flex-direction: column;
    width: 100%;
    margin-left: 10px;
    }
  `}
`;

export const StyledItem = styled.div`
  ${({ theme }) => `{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${theme.palette.common.catskillWhite};
    padding: 7px 10px;
    border-radius: 5px;
    width: fit-content;
    margin: 2px;
    font-size: 14px;
    color: ${theme.palette.common.athensGrayDark};
  `}
`;

export const FilterItemCloseButton = styled(CloseIcon)`
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
`;

export const StyledItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;

export const MobileBanner = styled(Typography)`
  ${({ theme }) => `{
    margin-left: 22px;
    margin-bottom: 10px;
    -line-height: 20px;
    font-weight: 600;
    font-size: 16px;
    color: ${theme.palette.common.brightGrey};
    background: ${theme.palette.common.geyser};
    border-radius: 5px;
    padding: 0 10px;
    width: fit-content;
  `}
`;

export const FavouriteHeding = styled.span`
  ${({ theme }) => `{
    padding: 5px 10px;
    color: ${theme.palette.common.brightGrey};
    margin-right: 10px;
    background: ${theme.palette.common.geyser};
    border-radius: 5px;
  `}
`;

export const MajorItemContainer = styled.div``;

export const StyledSlider = styled(Slider)`
  ${({ theme }) => `{
    .MuiSlider-root {
      height: 12px;
    }

    .MuiSlider-thumb {
      height: 25px;
      width: 25px;
      margin-top: -6px;
      margin-left: -12px;
      background: white;
      border: 2px solid ${theme.palette.common.allports};

      &:hover, &:active, &:focus{
        box-shadow: none;
      }
    }

    .MuiSlider-track {
      height: 12px;
      border-radius: 5px;
      background: ${theme.palette.common.allports};
    }

    .MuiSlider-rail {
      height: 12px;
      border-radius: 5px;
      background: ${theme.palette.common.allports};
    }

    .MuiSlider-valueLabel {
      display: none;
    }
  `}
`;

export const StyledFilterValuesBlue = styled(Typography)`
  ${({ theme }) => `{
    color: ${theme.palette.common.allports};
  `}
`;

export const StyledFilterValuesGrey = styled(Typography)`
  ${({ theme }) => `{
    color: ${theme.palette.common.brightGrey};
    opacity: 0.7;
  `}
`;

export const StyledNullValueField = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
`;

export const StyledTreeItem = styled(TreeItem)`
  color: ${({ $isPresentInSearch }) =>
    $isPresentInSearch === false && colors.Manatee};

  .MuiTreeItem-content {
    cursor: ${({ $isPresentInSearch }) =>
      $isPresentInSearch === false && "default"};
  }
`;
