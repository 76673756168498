import client from "../adapter";

function createCoachProfile(body) {
  return client(`/coach/profile`, { body });
}

function loadCoachProfile() {
  return client(`/coach/profile`);
}

function updateCoachProfile(profileId, body) {
  return client(`/coach/profile/${profileId}`, { body, method: "PATCH" });
}

function uploadCoachProfilePhoto(image) {
  return client(`/coach/profile/photo`, {
    body: { image },
  });
}

function deleteCoachProfilePhoto() {
  return client(`/coach/profile/photo`, {
    method: "DELETE",
  });
}

function loadFavorites() {
  return client(`/coach/profile/favorites`);
}

function updateFavorites(body, favoriteId) {
  return client(`/coach/profile/favorites/${favoriteId}`, {
    body,
    method: "PATCH",
  });
}

function removeFavorites(favoriteId) {
  return client(`/coach/profile/favorites/${favoriteId}`, {
    method: "DELETE",
  });
}

function saveFavorites(data) {
  return client(`/coach/profile/favorites`, {
    body: data,
    method: "POST",
  });
}

function deleteGroup(groupId) {
  return client(`/coach/profile/favorites/group/${groupId}`, {
    method: "DELETE",
  });
}

function createGroup(body) {
  return client(`/coach/profile/favorites/group`, {
    body,
    method: "POST",
  });
}

function updateGroup(groupId, body) {
  return client(`/coach/profile/favorites/group/${groupId}`, {
    body,
    method: "PATCH",
  });
}

function getAllgroups() {
  return client(`/coach/profile/favorites/group`);
}

export {
  createCoachProfile,
  loadCoachProfile,
  updateCoachProfile,
  uploadCoachProfilePhoto,
  deleteCoachProfilePhoto,
  loadFavorites,
  updateFavorites,
  removeFavorites,
  saveFavorites,
  deleteGroup,
  createGroup,
  updateGroup,
  getAllgroups,
};
