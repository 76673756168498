import React from "react";
import { Box, Card } from "components/MaterialComponents";
import { Details, CurrencyContainer, InfoBlock, NameSkeleton } from "./style";
import { Skeleton } from "components/MaterialComponents";

function AthletesCardSkeleton() {
  return (
    <Card>
      <Box p={5}>
        <NameSkeleton>
          <Skeleton variant="text" width={250} />
        </NameSkeleton>
        <InfoBlock>
          <Skeleton
            variant="text"
            width={50}
            style={{ display: "inline-block" }}
          />
          &nbsp;
          <Skeleton
            variant="text"
            width={30}
            style={{ display: "inline-block" }}
          />
        </InfoBlock>
        <Details>
          <Skeleton variant="text" width={150} />
        </Details>
        <CurrencyContainer>
          <Skeleton variant="text" width={70} />
        </CurrencyContainer>
      </Box>
    </Card>
  );
}

export default AthletesCardSkeleton;
