import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import InputComponent from "components/InputComponent";
import {
  CreateFieldsContainer,
  SaveButton,
  CancelButton,
  CreateFormContainer,
  CreateButtonGroup,
} from "./style";

const validationSchema = yup.object().shape({
  text: yup.string().required(),
});

function CreateNoteField({ collegeId, createNote, closeForm }) {
  const handleFormSubmit = useCallback(
    (values) => {
      const body = {
        collegeId,
        text: values.text,
      };

      createNote(body);
    },
    [collegeId, createNote],
  );

  return (
    <CreateFormContainer>
      <Formik
        validateOnChange
        initialValues={{
          text: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {() => (
          <Form>
            <CreateFieldsContainer>
              <Field name="text" charLimit={250} component={InputComponent} />
              <CreateButtonGroup>
                <SaveButton variant="outlined" color="primary" type="submit">
                  Save
                </SaveButton>
                <CancelButton type="button" onClick={closeForm}>
                  Cancel
                </CancelButton>
              </CreateButtonGroup>
            </CreateFieldsContainer>
          </Form>
        )}
      </Formik>
    </CreateFormContainer>
  );
}

CreateNoteField.propTypes = {
  collegeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  createNote: PropTypes.func,
  closeForm: PropTypes.func,
};

export default CreateNoteField;
