import styled from "styled-components";
import { Popover } from "components/MaterialComponents";

export const RespondToEmailPopover = styled(Popover)`
  ${({ theme }) => `
    & .MuiPopover-paper {
      padding: 10px;
      width: 55%;
      background-color: ${theme.palette.common.whiteSmoke};
      ${theme.breakpoints.down("sm")} {
        width: 85%;
      }
    }
  `}
`;
