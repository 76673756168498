import React from "react";
import PropTypes from "prop-types";
import CustomTextField from "components/CustomTextField";
import { Box } from "components/MaterialComponents";
import { Formik, Form } from "formik";
import {
  sendChangePasswordLinkToUserEmail,
  useProfileDispatch,
} from "components/useProfile";

import {
  DrawerHeader,
  StyledCloseIcon,
  EmailButton,
  LoginContainer,
} from "./style";

function SendResetPasswordLinkBody({ onDrawerClose }) {
  const profileDispatch = useProfileDispatch();

  const handleFormSubmit = async (data, { setFieldError }) => {
    await sendChangePasswordLinkToUserEmail(profileDispatch, data);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        data-testid="send-link-form"
      >
        <DrawerHeader>Recover password</DrawerHeader>
        <StyledCloseIcon onClick={onDrawerClose} />
      </Box>
      <Formik
        validateOnChange
        initialValues={{
          email: "",
        }}
        onSubmit={handleFormSubmit}
      >
        {({ values, setFieldValue, handleChange, isSubmitting }) => (
          <Form>
            <LoginContainer>
              <CustomTextField
                label="Your account email"
                name="email"
                type="email"
              />
              <EmailButton disabled={isSubmitting} type="submit">
                email me a recovery link
              </EmailButton>
            </LoginContainer>
          </Form>
        )}
      </Formik>
    </>
  );
}

SendResetPasswordLinkBody.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
};

export default SendResetPasswordLinkBody;
