import styled from "styled-components";
import { Box } from "components/MaterialComponents";

export const PersonalInfoFormContainer = styled(Box)`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
  margin-top: 3em;
  width: 60%;
  ${theme.breakpoints.down("sm")} {
    width: inherit;
  }
  `}
`;
