import React from "react";
import { Box } from "components/MaterialComponents";
import PropTypes from "prop-types";

import { ProfilePicturePopover as Popover } from "./style";

function ProfilePicturePopover({
  id,
  open,
  anchorEl,
  handleClose,
  onUploadClick,
  onDeleteClick,
}) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
    >
      <Box onClick={onUploadClick}>Upload Photo</Box>
      <Box onClick={onDeleteClick}>Delete Photo</Box>
    </Popover>
  );
}

ProfilePicturePopover.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  onUploadClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default ProfilePicturePopover;
