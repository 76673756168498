import React, { useCallback } from "react";
import { Box } from "components/MaterialComponents";
import { Formik, Form } from "formik";
import * as yup from "yup";
import CustomTextField from "components/CustomTextField";
import { CommonEditor, MobileEditor } from "components/TextEditor";
import { InputLabel } from "components/Typography";
import { SendButton } from "components/Buttons";
import * as validationHelper from "helpers/validationHelper";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import {
  useMessagesDispatch,
  sendEmailToAthlete,
} from "components/useMessages";
import Modal from "components/Modal";
import {
  SaveBtnContainer,
  InputContainer,
  MainContainer,
  SubjectContainer,
} from "./style";

const validationSchema = yup.object().shape({
  subject: validationHelper.subject,
  body: validationHelper.body,
});

function ContactAthletePopUp({ open, onClose, athleteId, athleteName, sport }) {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("xs"));

  const dispatch = useMessagesDispatch();

  const handleFormSubmit = useCallback(
    async (data, { setSubmitting }) => {
      const { subject, body } = data;

      const recipients = [
        {
          id: athleteId,
          name: athleteName,
        },
      ];
      const message = {
        body,
        subject,
        isCoach: true,
        sportId: sport.id,
      };

      await sendEmailToAthlete(dispatch, { recipients, message });
      setSubmitting(false);

      onClose();
    },
    [athleteId, athleteName, dispatch, onClose, sport],
  );

  return (
    <Modal open={open} onClose={onClose} fluid={matchesSm}>
      <MainContainer>
        <Formik
          validateOnChange
          initialValues={{
            subject: "",
            body: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <Box marginTop="2em">
                <InputContainer>
                  <InputLabel mb="0.5em">Subject</InputLabel>
                  <SubjectContainer>
                    <CustomTextField
                      name="subject"
                      type="text"
                      placeholder="Subject"
                    />
                  </SubjectContainer>
                </InputContainer>
                <InputContainer>
                  <InputLabel mb="0.5em">Body</InputLabel>
                  {matchesSm ? (
                    <MobileEditor
                      name="body"
                      setFieldValue={setFieldValue}
                      placeholder="Your Message"
                    />
                  ) : (
                    <CommonEditor
                      name="body"
                      setFieldValue={setFieldValue}
                      placeholder="Your Message"
                    />
                  )}
                </InputContainer>
                <SaveBtnContainer>
                  <SendButton type="submit" disabled={isSubmitting}>
                    Send
                  </SendButton>
                </SaveBtnContainer>
              </Box>
            </Form>
          )}
        </Formik>
      </MainContainer>
    </Modal>
  );
}

export default ContactAthletePopUp;
