import React from "react";
import PropTypes from "prop-types";
import { Table } from "components/MaterialComponents";
import { TableBody } from "components/MaterialComponents";
import { TableContainer } from "components/MaterialComponents";
import { TableRow } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import Modal from "components/Modal";
import SportsTableRow from "./SportsTableRow";
import AddSportForm from "./AddSportForm";
import { StyledTableHead } from "../../style";

function SportsListPopup({
  sports,
  open,
  onClose,
  onAddSports,
  college,
  onDeleteSport,
}) {
  const heading = `${college.schoolName} sports`;
  return (
    <Modal open={open} onClose={onClose}>
      <Box paddingTop="10px" paddingBottom="10px" width="700px">
        <Typography variant="h4">{heading}</Typography>
      </Box>
      <AddSportForm
        onAddSports={onAddSports}
        collegeId={college.unitId}
        collegeSports={sports}
      />
      <TableContainer>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {sports?.map((sport) => (
              <SportsTableRow
                key={sport.id}
                sport={sport}
                onDeleteSport={onDeleteSport}
                unitSport={`${college.unitId}-${sport.id}`}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Modal>
  );
}

SportsListPopup.propTypes = {
  sports: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAddSports: PropTypes.func,
  college: PropTypes.object,
  onDeleteSport: PropTypes.func,
};

export default SportsListPopup;
