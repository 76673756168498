import React, { useState, useCallback } from "react";
import { EditOutlined as EditOutlinedIcon } from "components/MaterialIcons";
import PropTypes from "prop-types";

import UploadPictureModal from "./UploadPictureModal";
import ProfilePicturePopover from "./ProfilePicturePopover";
import Feedbacker from "components/Feedbacker";
import ImageCircle from "components/ImageCircle";
import useClearFeedback from "customHooks/useClearFeedback";
import ConfirmationModal from "components/ConfirmationModal";
import useRecruitingProfile, {
  recruitingProfileFeedbacks,
  uploadParentProfilePhoto,
  deleteParentProfilePhoto,
} from "components/useRecruitingProfile";
import { Headline } from "components/Typography";
import {
  ProfilePictureEditIconContainer,
  ProfilePictureEditContainer,
  ProfileCardContainer,
  StyledProfileCard,
  ProfilePictureText,
  ProfileNavigationContainer,
  ProfileNavigationLink,
} from "./style";

function ParentProfileCard({ name }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [uploadModal, setUploadModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [
    { profile: recruitingProfile, feedback, updating, error },
    dispatch,
  ] = useRecruitingProfile();

  useClearFeedback(dispatch);

  const { profilePhotoMediaItem: profileImg } = recruitingProfile || {};

  const handlePopoverOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpenUploadModal = useCallback(() => {
    setUploadModal(true);
  }, []);

  const handleDeleteProfilePhoto = useCallback(async () => {
    await deleteParentProfilePhoto(dispatch, recruitingProfile);
    setDeleteModal(false);
  }, [dispatch, recruitingProfile]);

  const handleUploadProfilePhoto = useCallback(
    async (image) => {
      await uploadParentProfilePhoto(dispatch, recruitingProfile, image);
      handlePopoverClose();
    },
    [dispatch, recruitingProfile, handlePopoverClose],
  );

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? "profile-image-popover" : undefined;
  const isProfileImgSet = Boolean(profileImg);

  const routes = [
    {
      name: "personal information",
      link: "/profile/personal-information",
    },
    {
      name: "children information",
      link: "/profile/children-information",
    },
  ];

  return (
    <>
      <Headline>Profile</Headline>
      <StyledProfileCard>
        <ProfileCardContainer>
          <ImageCircle withBorder image={profileImg} name={name} />
          <ProfilePictureEditContainer
            onClick={
              isProfileImgSet ? handlePopoverOpen : handleOpenUploadModal
            }
          >
            <ProfilePictureEditIconContainer>
              <EditOutlinedIcon />
            </ProfilePictureEditIconContainer>
            <ProfilePictureText>Edit</ProfilePictureText>
          </ProfilePictureEditContainer>
        </ProfileCardContainer>
        <ProfileNavigationContainer>
          {routes.map(({ name, link }, index) => (
            <ProfileNavigationLink key={index} to={link}>
              {name}
            </ProfileNavigationLink>
          ))}
        </ProfileNavigationContainer>
      </StyledProfileCard>
      <ProfilePicturePopover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onUploadClick={handleOpenUploadModal}
        onDeleteClick={() => setDeleteModal(true)}
        handleClose={handlePopoverClose}
      />
      <UploadPictureModal
        open={uploadModal}
        handleClose={() => setUploadModal(false)}
        handleUpload={handleUploadProfilePhoto}
        updating={updating}
      />
      <ConfirmationModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        disabled={updating}
        handleConfirm={() => {
          handlePopoverClose();
          handleDeleteProfilePhoto();
        }}
      />
      <Feedbacker
        open={feedback === recruitingProfileFeedbacks.Updated}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage={feedback}
        clearFeedback={() => dispatch({ type: "clear_feedback" })}
      />
      <Feedbacker
        open={Boolean(error)}
        autoHideDuration={3000}
        severity="error"
        feedbackMessage={feedback}
        clearFeedback={() => dispatch({ type: "clear_feedback" })}
      />
    </>
  );
}

ParentProfileCard.propTypes = {
  name: PropTypes.string,
};

export default ParentProfileCard;
