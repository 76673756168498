import React from "react";
import { Box } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { ErrorValueContainer } from "./style";

function CoachErrorFilterButton({ id, label, value, onSelect, currentId }) {
  return (
    <>
      {Number(value) > 0 && (
        <Box marginRight="10px">
          <Button
            variant={id === currentId ? "contained" : "outlined"}
            onClick={() => onSelect(id)}
          >
            {label}
            <ErrorValueContainer>{value}</ErrorValueContainer>
          </Button>
        </Box>
      )}
    </>
  );
}

export default CoachErrorFilterButton;
