import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";

import Modal from "components/Modal";
import { ModalHeader, ModalButton } from "../style";

function ConfirmSportUpdateModal({
  open,
  handleClose,
  submitForm,
  isSubmitting,
}) {
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader>
        We noticed you've changed Primary and/or Secondary, Tertiary Sport. This
        action will remove all <b>Athletic Performance</b> data related to
        changed sport
      </ModalHeader>
      <Box mt="2em" display="flex" justifyContent="space-around">
        <ModalButton
          variant="contained"
          color="primary"
          onClick={submitForm}
          disabled={isSubmitting}
        >
          Confirm
        </ModalButton>
        <ModalButton variant="outlined" color="secondary" onClick={handleClose}>
          Cancel
        </ModalButton>
      </Box>
    </Modal>
  );
}

ConfirmSportUpdateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default ConfirmSportUpdateModal;
