import React from "react";
import PropTypes from "prop-types";
import { InputLabel } from "components/MaterialComponents";
import { Select } from "components/MaterialComponents";
import { MenuItem } from "components/MaterialComponents";
import { useField } from "formik";

import { StyledFormControl, ErrorMessage } from "./style";

function SportSelect({
  order,
  label,
  sportList = [],
  firstEntity,
  secondEntity,
  handleChange,
  showGender,
  disabled,
  showEmptyField = true,
  ...rest
}) {
  const [field, meta] = useField(rest);
  const { value, ...restField } = field;
  const errorText = meta.error && meta.touched ? meta.error : "";

  const error = typeof errorText === "string" ? errorText : null;

  return (
    <StyledFormControl>
      <InputLabel id={order}>{label}</InputLabel>
      <Select
        labelId={order}
        value={firstEntity}
        renderValue={(item) =>
          showGender ? item.name + " - " + item.gender : item.name
        }
        onChange={handleChange}
        label={label}
        error={Boolean(error)}
        disabled={disabled}
        {...rest}
      >
        {showEmptyField && <MenuItem value={null}>&nbsp;</MenuItem>}
        {sportList
          .filter((sport) =>
            secondEntity ? secondEntity.id !== sport.id : true,
          )
          .map((sport) => (
            <MenuItem key={sport.id} value={sport}>
              {showGender ? sport.name + " - " + sport.gender : sport.name}
            </MenuItem>
          ))}
      </Select>
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
    </StyledFormControl>
  );
}

SportSelect.propTypes = {
  order: PropTypes.string,
  label: PropTypes.string.isRequired,
  sportList: PropTypes.array,
  firstEntity: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondEntity: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleChange: PropTypes.func,
};

export default SportSelect;
