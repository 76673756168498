export const PictionBlue = "#40C4E8"; // tmp

// Brand
export const Allports = "#006AA6";
export const Blumine = "#1E4F7D";
export const Trout = "#4E4E5A";
export const Jumbo = "#727279";
export const YellowOrange = "#FF9F41";
export const Fern = "#5CB85C";

// Typography
export const White = "#fff";
export const BrightGray = "#3C4257";
export const TroutLight = "#505565";
export const Manatee = "#8B90A0";

// Surface / Background
export const Whitesmoke = "#F6F6F6";
export const Black = "#000";
export const Whisper = "#FAFAFC";
export const AthensGray = "#F0F1F3";
export const CatskillWhite = "#DEE6EF";
export const Geyser = "#CFD7DF";
export const EbonyClay = "#232735";

// Hover state
export const OxfordBlue = "#3E465F";
export const AthensGrayDark = "#EEEEF1";
export const MandysPink = "#FFB499";

// Coach favorite groups background

export const Tizzy = "#F9F3DF";
export const LightYellow = "#FDFCE5";
export const PattensBlue = "#D7E9F7";
export const Orange = "#F4D19B";
export const Pink = "#FFE3E3";

// Error
export const Red = "#FF0000";
