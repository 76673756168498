import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { TableRow } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Collapse } from "components/MaterialComponents";
import { IconButton } from "components/MaterialComponents";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "components/MaterialIcons";
import { KeyboardArrowUp as KeyboardArrowUpIcon } from "components/MaterialIcons";
import SportsCreateEditePopUp from "./SportsCreateEditePopUp";
import SportPositionsList from "./Positions/SportPositionsList";
import SportStatsList from "./Stats/SportStatsList";
import ConfirmDeleteSportModal from "./ConfirmDeleteSportModal";
import { StyledTableRowPositions, StyledTableRowStats } from "../style";

function SportsTableRow({
  sport,
  handleCreateSport,
  handleEditeSport,
  handleDeleteSport,
  refetchSportsData,
}) {
  const [isEditeModalOpen, setIsEditeModalOpen] = useState(false);
  const [isPositionsCollapseOpen, setPositionsCollapseOpen] = useState(false);
  const [isStatsCollapseOpen, setStatsCollapseOpen] = useState(false);
  const [isDeleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);

  const toggleEditeModal = useCallback(() => {
    setIsEditeModalOpen(!isEditeModalOpen);
  }, [isEditeModalOpen]);

  const togglePositionsCollapse = useCallback(() => {
    setPositionsCollapseOpen(!isPositionsCollapseOpen);
  }, [isPositionsCollapseOpen]);

  const toggleStatsCollapse = useCallback(() => {
    setStatsCollapseOpen(!isStatsCollapseOpen);
  }, [isStatsCollapseOpen]);

  const toggleDeleteConfirmModal = useCallback(() => {
    setDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);
  }, [isDeleteConfirmModalOpen]);

  const onSportDelete = useCallback(() => {
    handleDeleteSport(sport.id);
  }, [handleDeleteSport, sport]);

  return (
    <>
      <TableRow key={sport.id}>
        <TableCell>{sport.id}</TableCell>
        <TableCell>{sport.name}</TableCell>
        <TableCell>{sport.gender}</TableCell>
        <TableCell>
          <Button color="primary" onClick={toggleEditeModal}>
            Edit
          </Button>
        </TableCell>
        <TableCell>
          <Button color="secondary" onClick={toggleDeleteConfirmModal}>
            Delete
          </Button>
        </TableCell>
        <TableCell>
          Positions
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={togglePositionsCollapse}
          >
            {isPositionsCollapseOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          Stats
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={toggleStatsCollapse}
          >
            {isStatsCollapseOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <StyledTableRowPositions>
        <TableCell colSpan={7} style={{ padding: 0 }}>
          <Collapse in={isPositionsCollapseOpen} timeout="auto" unmountOnExit>
            <SportPositionsList
              positions={sport?.sportPositions}
              sportId={sport.id}
              refetchSportsData={refetchSportsData}
            />
          </Collapse>
        </TableCell>
      </StyledTableRowPositions>
      <StyledTableRowStats>
        <TableCell colSpan={7} style={{ padding: 0 }}>
          <Collapse in={isStatsCollapseOpen} timeout="auto" unmountOnExit>
            <SportStatsList
              stats={sport?.sportStatsDefinitions}
              sportId={sport.id}
              refetchSportsData={refetchSportsData}
              positions={sport?.sportPositions}
            />
          </Collapse>
        </TableCell>
      </StyledTableRowStats>
      <ConfirmDeleteSportModal
        open={isDeleteConfirmModalOpen}
        handleClose={toggleDeleteConfirmModal}
        handleDelete={onSportDelete}
      />
      <SportsCreateEditePopUp
        open={isEditeModalOpen}
        onClose={toggleEditeModal}
        item={sport}
        onCreateSport={handleCreateSport}
        onEditeSport={handleEditeSport}
      />
    </>
  );
}

SportsTableRow.propTypes = {
  sport: PropTypes.object,
  handleCreateSport: PropTypes.func,
  handleEditeSport: PropTypes.func,
  handleDeleteSport: PropTypes.func,
  refetchSportsData: PropTypes.func,
};

export default SportsTableRow;
