import React from "react";
import PropTypes from "prop-types";
import { StyledInput, InputContainer } from "./style";

function InputComponent({ field, form, disabled, charLimit }) {
  return (
    <InputContainer>
      <StyledInput
        type="text"
        {...field}
        multiline
        disabled={disabled}
        autoFocus={true}
        inputProps={{
          maxLength: charLimit,
        }}
        helperText={disabled ? null : `${field.value.length}/${charLimit}`}
      />
    </InputContainer>
  );
}

InputComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  disabled: PropTypes.bool,
  charLimit: PropTypes.number,
};

export default InputComponent;
