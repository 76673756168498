import {
  DECIMAL_1_REGEX,
  DISTANCE_REGEX,
  INTEGER_REGEX,
  TIME_MIN_SEC_MS_REGEX,
  TIME_SEC_MS_REGEX,
  DECIMAL_2_REGEX,
  DECIMAL_3_REGEX,
  DECIMAL_4_REGEX,
} from "constants/regExps";

import {
  DECIMAL_1,
  DISTANCE,
  INT,
  TIME_MIN_SEC_MS,
  TIME_SEC_MS,
  RECORD,
  DECIMAL_2,
  DECIMAL_3,
  DECIMAL_4,
} from "constants/sports";

export const getSSfromTime = (timeString) => {
  if (timeString === "") {
    return "";
  }
  let min = 0;
  let secSS = 0;

  const minArray = timeString.split(":");

  //if only Min are entered
  if (minArray.length === 1) {
    if (timeString.includes(".")) {
      //e.g. 78.77 -- assume 78sec 77ss
      secSS = Number(timeString) * 100;
    } else {
      //e.g. 5 -- assume 5 mins
      min = Number(timeString);
    }
  } else {
    min = Number(minArray[0]);
    secSS = Number(minArray[1]) * 100;
  }
  return min * 60 * 100 + secSS;
};

export const getValInt = (value, inputType) => {
  switch (inputType) {
    case DISTANCE:
      const { groups: { feet, inches } = {} } =
        value.match(DISTANCE_REGEX) ?? {};
      return feet || inches
        ? 12 * (parseInt(feet) || 0) + (parseInt(inches) || 0)
        : null;

    case TIME_SEC_MS:
      return TIME_SEC_MS_REGEX.test(value) ? Number(value) * 100 : null;

    case TIME_MIN_SEC_MS:
      return TIME_MIN_SEC_MS_REGEX.test(value) ? getSSfromTime(value) : null;

    case DECIMAL_1:
      return DECIMAL_1_REGEX.test(value) ? Number(value) * 10 : null;

    case DECIMAL_2:
      return DECIMAL_2_REGEX.test(value) ? Number(value) * 100 : null;

    case DECIMAL_3:
      return DECIMAL_3_REGEX.test(value) ? Number(value) * 1000 : null;

    case DECIMAL_4:
      return DECIMAL_4_REGEX.test(value) ? Number(value) * 10000 : null;

    case INT:
      return INTEGER_REGEX.test(value) ? parseInt(value) : null;

    case RECORD:
      return null;

    default:
      return null;
  }
};
