import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { TextField } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";

import DatePicker from "components/DatePicker";

function SportStatVerify({
  label,
  index,
  verifiedBy,
  verifiedDate,
  setFieldValue,
}) {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleTextFieldChange = useCallback(
    ({ target: { value } }) => {
      setFieldValue(`sportStatsWithAnswers.${index}.answer.verifiedBy`, value);
    },
    [index, setFieldValue],
  );

  const handleDateChange = useCallback(
    (value) => {
      setFieldValue(
        `sportStatsWithAnswers.${index}.answer.verifiedDate`,
        value,
      );
    },
    [index, setFieldValue],
  );

  const textFieldStyle = {
    marginBottom: matchesSm ? "2em" : 0,
  };

  const datePickerContainerStyle = {
    marginBottom: matchesSm ? "2em" : 0,
  };

  return (
    <>
      <TextField
        style={textFieldStyle}
        variant="outlined"
        type="text"
        size="small"
        label={label}
        value={verifiedBy || ""}
        onChange={handleTextFieldChange}
      />
      <Box style={datePickerContainerStyle}>
        <DatePicker
          index={index}
          label="Date"
          date={verifiedDate}
          handleChange={handleDateChange}
        />
      </Box>
    </>
  );
}

SportStatVerify.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  index: PropTypes.number,
  setFieldValue: PropTypes.func,
  verifiedBy: PropTypes.string,
  verifiedDate: PropTypes.any,
};

export default SportStatVerify;
