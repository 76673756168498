import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Table } from "components/MaterialComponents";
import { TableBody } from "components/MaterialComponents";
import { TableContainer } from "components/MaterialComponents";
import { TableRow } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { makeStyles } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import StatCreateEditePopUp from "./StatCreateEditePopUp";
import StatEditGroupPopUp from "./StatEditGroupPopUp";
import StatsTableRow from "./StatsTableRow";
import * as sportsDomain from "api/domains/sports";
import { getValInt } from "helpers/getSportStatIntValue";
import { StyledTableHead, SportTableHeadContainer } from "../../style";

const useStyles = makeStyles({
  table: {
    width: 1100,
  },
});

function SportStatsList({ stats, positions, sportId, refetchSportsData }) {
  const [isStatsModalOpen, setStatsModalOpen] = useState(false);
  const [isGroupEditModalOpen, setGroupEditModalOpen] = useState(false);
  const classes = useStyles();

  const statsModalOpen = useCallback(() => {
    setStatsModalOpen(true);
  }, []);

  const statsModalClose = useCallback(() => {
    setStatsModalOpen(false);
  }, []);

  const toggleGroupEditModal = useCallback(() => {
    setGroupEditModalOpen(!isGroupEditModalOpen);
  }, [isGroupEditModalOpen]);

  const handleCreateStat = useCallback(
    async (values) => {
      const lastStat = stats[stats.length - 1];
      const ordinal = lastStat ? lastStat.ordinal + 1 : 1;
      const body = {
        ordinal,
        sportId,
        ...values,
      };
      await sportsDomain.createSportStat(body);
      await refetchSportsData();
    },
    [refetchSportsData, sportId, stats],
  );

  const handleEditeStat = useCallback(
    async (values, id) => {
      const body = {
        sportId,
        ...values,
      };
      await sportsDomain.updateSportStat(body, id);
      await refetchSportsData();
    },
    [refetchSportsData, sportId],
  );

  const handleEditStatGroup = useCallback(
    async (values) => {
      const { inputType, stats, ...rest } = values;
      const body = {
        inputType: inputType ? inputType.value : null,
        stats: stats.map((stat) => stat.value),
        sportId,
        maxInt: getValInt(values.max, inputType?.value),
        minInt: getValInt(values.min, inputType?.value),
        ...rest,
      };
      await sportsDomain.updateSportStatGroup(body);
      await refetchSportsData();
    },
    [refetchSportsData, sportId],
  );

  const handleDeleteStat = useCallback(
    async (id) => {
      await sportsDomain.deleteSportStat(id);
      await refetchSportsData();
    },
    [refetchSportsData],
  );

  return (
    <>
      <SportTableHeadContainer>
        <Box marginRight="10px" marginLeft="10px">
          <Typography variant="h5">Sport Stats</Typography>
        </Box>
        <Button color="primary" onClick={statsModalOpen}>
          Add new
        </Button>
        <Button color="primary" onClick={toggleGroupEditModal}>
          Group Edit
        </Button>
      </SportTableHeadContainer>
      <TableContainer className={classes.table}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell>ORDINAL</TableCell>
              <TableCell>NAME</TableCell>
              <TableCell>PROMPT</TableCell>
              <TableCell>INPUT TYPE</TableCell>
              <TableCell>INPUT UNITS</TableCell>
              <TableCell>QUALIFIER DESC</TableCell>
              <TableCell>STORAGE UNITS</TableCell>
              <TableCell>MIN</TableCell>
              <TableCell>MAX</TableCell>
              <TableCell>EDIT</TableCell>
              <TableCell>DELETE</TableCell>
              <TableCell />
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {stats.map((stat) => (
              <StatsTableRow
                key={stat.id}
                stat={stat}
                positions={positions}
                handleEditeStat={handleEditeStat}
                handleDeleteStat={handleDeleteStat}
                refetchSportsData={refetchSportsData}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <StatCreateEditePopUp
        open={isStatsModalOpen}
        onClose={statsModalClose}
        onCreateStat={handleCreateStat}
        positions={positions}
      />
      <StatEditGroupPopUp
        open={isGroupEditModalOpen}
        onClose={toggleGroupEditModal}
        stats={stats}
        onEditStatsGroup={handleEditStatGroup}
      />
    </>
  );
}

SportStatsList.propTypes = {
  stats: PropTypes.array,
  positions: PropTypes.array,
  sportId: PropTypes.number,
  refetchSportsData: PropTypes.func,
};

export default SportStatsList;
