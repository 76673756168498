import React from "react";
import PropTypes from "prop-types";
import { useRouteMatch } from "react-router-dom";

import { SportTabLink, StyledTabButton } from "./style";

function SportTabs({ url, path, primary, secondary, tertiary }) {
  const primaryMatch = useRouteMatch(`${path}/primary-sport/:id`);
  const secondaryMatch = useRouteMatch(`${path}/secondary-sport/:id`);
  const tertiaryMatch = useRouteMatch(`${path}/tertiary-sport/:id`);

  return (
    <>
      {primary ? (
        <SportTabLink to={`${url}/primary-sport/${primary.id}`}>
          <StyledTabButton active={primaryMatch}>
            {primary.name}
          </StyledTabButton>
        </SportTabLink>
      ) : (
        <StyledTabButton variant="contained" color="primary" disabled={true}>
          Primary Sport
        </StyledTabButton>
      )}
      {secondary ? (
        <SportTabLink to={`${url}/secondary-sport/${secondary.id}`}>
          <StyledTabButton active={secondaryMatch}>
            {secondary.name}
          </StyledTabButton>
        </SportTabLink>
      ) : (
        <StyledTabButton variant="contained" color="primary" disabled={true}>
          Secondary Sport
        </StyledTabButton>
      )}
      {tertiary ? (
        <SportTabLink to={`${url}/tertiary-sport/${tertiary.id}`}>
          <StyledTabButton active={tertiaryMatch}>
            {tertiary.name}
          </StyledTabButton>
        </SportTabLink>
      ) : (
        <StyledTabButton variant="contained" color="primary" disabled={true}>
          Tertiary Sport
        </StyledTabButton>
      )}
    </>
  );
}

SportTabs.props = {
  url: PropTypes.string,
  path: PropTypes.string,
  primary: PropTypes.object,
  secondary: PropTypes.object,
  tertiary: PropTypes.object,
};

export default SportTabs;
