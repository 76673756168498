import React from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal";
import { Typography } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";

const SessionTimeoutDialog = ({ open, onLogout }) => {
  return (
    <Modal open={open} onClose={onLogout}>
      <Box marginBottom="10px">
        <Typography variant="h5">
          Your session has expired. Please relogin
        </Typography>
      </Box>
      <Button onClick={onLogout} color="primary">
        Close
      </Button>
    </Modal>
  );
};

SessionTimeoutDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default SessionTimeoutDialog;
