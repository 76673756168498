import React from "react";
import styled from "styled-components";
import { Box } from "components/MaterialComponents";
import { Grid } from "components/MaterialComponents";
import { Pagination } from "components/MaterialComponents";
import { Popper } from "components/MaterialComponents";
import { TextField } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Close as CloseIcon } from "components/MaterialIcons";
import { Typography } from "components/MaterialComponents";
import { Select } from "components/MaterialComponents";
import { FormControl } from "components/MaterialComponents";
import * as colors from "theme/colors";
import { IconButton } from "components/MaterialComponents";
import { Edit as EditIcon } from "components/MaterialIcons";
import { Delete as DeleteIcon } from "components/MaterialIcons";

export const Container = styled.div``;

export const MobileContainer = styled.div`
  width: 100%;
`;

export const MainFiltersContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
    ${theme.breakpoints?.down("sm")} {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 10px;
    }
  `}
`;

export const FilterBarContainer = styled(Box)`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    ${theme.breakpoints?.down("sm")} {
      width:100%;
      flex-direction: column;
      padding-bottom: 10px;
    }
  `}
`;

export const FilterPopperContent = styled(Box)`
  ${({ theme }) => `
    max-height: 600px;
    overflow: auto;
    width: 600px;
    ${theme.breakpoints?.down("xs")} {
      width: 90vw;
    }

    & .MuiCheckbox-root {
      padding: 2px 5px 2px 0;
    }
  `}
`;

export const FilterSubTitle = styled.div`
  font-weight: bold;
  margin: 0 0 8px -8px;
`;

export const FilterCloseButton = styled(CloseIcon)`
  cursor: pointer;
`;

export const FilterHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

export const FilterTitle = styled.div`
  text-transform: uppercase;
  font-weight: bold;
`;

export const StyledPagination = styled(Pagination)`
  ${({ theme }) => `
    & .MuiPagination-ul {
      ${theme.breakpoints?.down("sm")} {
        justify-content: center;
      }
    }
  `}
`;

export const StyledPopper = styled(Popper)`
  z-index: 10;
`;

export const StyledTextField = styled(TextField)`
  ${({ theme }) => `{
    width: 100%;
    ${theme.breakpoints?.down("sm")} {
      width: 80%;
    }
  `}
`;

export const AthletesGridContainer = styled(Grid)`
  ${({ theme }) => `
  width: 100%;
  ${theme.breakpoints?.down("sm")} {
      margin: 0;
    }
`}
`;

export const AthletesContainer = styled(Box)`
  ${({ theme }) => `{
    margin: 28px 0 0 40.5px;
    ${theme.breakpoints?.down("sm")} {
      margin: 1em 0;
    }
  `}
`;

export const FilterButton = styled(Button)`
  ${({ theme }) => `{
    margin-left: 30px;
    padding: 11px 11px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;

    ${theme.breakpoints.down("sm")} {
      margin-left: 0;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      border: 1px solid ${theme.palette?.common.catskillWhite};
      background: ${theme.palette?.common.whisper};

    &:hover {
      background: ${theme.palette?.common.catskillWhite};
      color: ${theme.palette?.common.allports};
    }
  `}
`;

export const SearchContainer = styled.div`
  ${({ theme }) => `
  width: 31.5%;
  ${theme.breakpoints?.down("sm")} {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
    }
  `}
`;

export const MenuContainer = styled.div`
  ${({ theme }) => `
  display: flex;
  ${theme.breakpoints?.down("sm")} {
    flex-direction: column;
    width: 100%;
    margin-left: 10px;
    }
  `}
`;

export const MobileHeaderContainer = styled.div`
  ${({ theme }) => `{
    position: fixed;
    top: 9px;
    left: 0;
    background: ${theme.palette?.common.whisper};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9;
    padding: 20px 10px;
  `}
`;

export const MobileContentContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
`;

export const MobileFooterContainer = styled.div`
  ${({ theme }) => `{
    position: fixed;
    bottom: 0;
    left: 0;
    background: ${theme.palette?.common.whisper};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    padding: 15px;
  `}
`;

export const StyledItem = styled.div`
  ${({ theme }) => `{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${theme.palette?.common.catskillWhite};
    padding: 7px 10px;
    border-radius: 5px;
    width: fit-content;
    margin: 2px;
    font-size: 14px;
    color: ${theme.palette?.common.athensGrayDark};
  `}
`;

export const FilterItemCloseButton = styled(CloseIcon)`
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
`;

export const StyledItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;

export const MobileBanner = styled(Typography)`
  ${({ theme }) => `{
    margin-left: 22px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
    color: ${theme.palette?.common.brightGrey};
    background: ${theme.palette.common.geyser};
    border-radius: 5px;
    padding: 0 10px;
    width: fit-content;
  `}
`;

export const FavouriteHeding = styled.span`
  ${({ theme }) => `{
    padding: 5px 10px;
    color: ${theme.palette?.common.brightGrey};
    margin-right: 10px;
    background: ${theme.palette?.common.geyser};
    border-radius: 5px;
  `}
`;

export const MajorItemContainer = styled.div``;

export const SportStatWithAnswerContainer = styled(Box)`
  width: 20%;
  margin-right: 20px;
`;

export const StyledSelect = styled(Select)`
  ${({ theme }) => `
  min-width: 50%;
  ${theme.breakpoints?.down("sm")} {
    width: 100%;
  }
  `}
`;

export const SportStatFormControl = styled((props) => (
  <FormControl variant="outlined" {...props} />
))`
  min-width: 50%;
`;

export const StyledSportStatTextField = styled(TextField)`
  z-index: 0;
  width: 150px;
  .MuiOutlinedInput-root {
    height: 40px;
    margin-right: 20px;
  }
  .MuiInputBase-root {
    font-weight: bolder;
  }
`;

export const NameField = styled.div`
  width: 30%;
  margin-right: 10px;
`;
export const SportStatField = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
  margin-bottom: 25px;
`;

export const SportFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SportStatsContainer = styled(Box)`
  ${({ theme }) => `
    padding: 0 30px;
  ${theme.breakpoints?.down("sm")} {
    padding: 0;
  }
  `}
`;

export const SportFilterContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    margin-left: 20px;
    width: 300px;
  ${theme.breakpoints?.down("sm")} {
    margin-left: 0;
    width: 98%;
  }
  `}
`;

export const CoachFavoritesMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  margin-bottom: 10px;
`;

export const CoachGroupsContainer = styled.div`
  ${({ theme }) => `
    width: 100%;
    height: 60vh;
    overflow-y: auto;
    direction: rtl;
  ${theme.breakpoints?.down("sm")} {
    height: 100%;
    direction: ltr;
    overflow-y: unset;
  }
  `}
`;

export const CoachGroupItem = styled(Box)`
  display: flex;
  align-items: center;
  width: 80%;
  height: 80px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: ${(props) =>
    props.selected ? colors.AthensGrayDark : props.bgColor};
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  direction: ltr;

  &:hover {
    background-color: ${colors.AthensGrayDark};
  }
`;

export const CoachGroupTitle = styled(Typography)`
  text-transform: capitalize;
`;

export const StyledAddbutton = styled(IconButton)`
  background-color: ${colors.CatskillWhite};
  width: 50px;
  height: 50px;

  &:hover {
    background-color: ${colors.AthensGray};
  }
`;

export const FormContainer = styled.div`
  display: flex;
`;

export const StyledButton = styled.div`
  margin-right: 10px;
  font-size: 12px;
  cursor: pointer;
`;

export const SaveButton = styled(Button)`
  font-size: 12px;
  width: 40px;
  height: 30px;
`;

export const StyledEditeIcon = styled(EditIcon)`
  :hover {
    color: ${colors.Allports};
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  :hover {
    color: ${colors.Red};
  }
`;

export const CategoryFieldContainer = styled.div``;

export const CreateGroupContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
`;

export const FavoritesGroupsModalBody = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  width: 90%;
`;

export const SelectedGroupNameContainer = styled.div`
  padding: 20px;
  background-color: ${colors.CatskillWhite};
  margin-right: 5px;
`;
