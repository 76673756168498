export function getNamesFromOptions(values, options, filterField) {
  if (!values || !options) {
    return;
  }
  const valuesToArray = values.split(",");
  return valuesToArray.map((val) => ({
    name: options[val],
    value: val,
    filterField: filterField,
  }));
}

export function getNamesFromOptionsArray(values, options, filterField) {
  if (!values || !options) {
    return;
  }
  const valuesToArray = values.split(",");
  return valuesToArray.map((val) => ({
    name: options.find((option) => Number(option.id) === Number(val))?.name,
    value: val,
    filterField: filterField,
  }));
}

export function removeItemFromField(field, item) {
  return field
    .split(",")
    .filter((el) => el !== item)
    .join(",");
}

export function getFieldsToRemove(filterFields) {
  const fieldsToRemove = filterFields.map((field) => [[field], null]);
  return Object.fromEntries(fieldsToRemove);
}
