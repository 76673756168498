import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import useSports from "components/useSports";
import Feedbacker from "components/Feedbacker";
import GeneralCard from "components/GeneralCard";
import { SuccessButton } from "components/Buttons";
import useRecruitingProfile, {
  recruitingProfileFeedbacks,
  updateCoachProfile,
} from "components/useRecruitingProfile";
import CustomTextField from "components/CustomTextField";
import useClearFeedback from "customHooks/useClearFeedback";
import * as validationHelper from "helpers/validationHelper";
import SelectInput from "components/Select";
import { Search } from "components/MaterialIcons";
import { Box } from "components/MaterialComponents";
import { CardContainer } from "../style";
import {
  getOptionsFromSports,
  loadOptionsCollege,
} from "helpers/coachProfileSelectHelpers";
import * as actionTypes from "components/useRecruitingProfile/actionTypes";
import { PersonalInfoFormContainer } from "./style";

const validationSchema = yup.object().shape({
  firstName: validationHelper.firstName,
  lastName: validationHelper.lastName,
  college: validationHelper.college,
  sports: validationHelper.sports,
  collegeEmail: validationHelper.checkUpdateCoachEmail,
  phoneNumber: yup.string().nullable(),
  contactEmail: yup.string().nullable(),
  coachTitle: yup.string().nullable(),
});

function CoachPersonalInformation() {
  const [
    { profile, feedback, loading, error },
    dispatch,
  ] = useRecruitingProfile();

  const { data: sportList, loading: sportsLoading } = useSports();

  useClearFeedback(dispatch);

  if (loading || sportsLoading) {
    return null;
  }

  const {
    userId,
    firstName,
    lastName,
    college,
    sports,
    phoneNumber,
    email,
    contactEmail,
    coachTitle,
  } = profile;

  const handleFormSubmit = async (values, { setSubmitting }) => {
    const body = {
      firstName: values.firstName,
      lastName: values.lastName,
      collegeId: values.college.value,
      sportIds: values.sports.map((sport) => sport.value),
      phoneNumber: values.phoneNumber,
      email: values.collegeEmail,
      contactEmail: values.contactEmail,
      coachTitle: values.coachTitle,
    };
    await updateCoachProfile(dispatch, userId, body);
    setSubmitting(false);
  };

  return (
    <>
      <CardContainer>
        <GeneralCard header="Personal Information">
          <Formik
            validateOnChange
            initialValues={{
              firstName,
              lastName,
              college: college
                ? { value: college.unitId, label: college.schoolName }
                : null,
              sports: sports.map((sport) => ({
                value: sport.id,
                label: `${sport.name} (${sport.gender})`,
              })),
              phoneNumber,
              collegeEmail: email,
              contactEmail,
              coachTitle,
            }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ isSubmitting, values, setFieldValue, submitForm, errors }) => (
              <Form>
                <PersonalInfoFormContainer>
                  <CustomTextField label="First Name" name="firstName" />
                  <CustomTextField label="Last Name" name="lastName" />
                  <Box marginBottom="30px">
                    <SelectInput
                      placeholder="Type your college name..."
                      name="college"
                      loadOptions={loadOptionsCollege}
                      async={true}
                      setFieldValue={setFieldValue}
                      values={values}
                      errors={errors}
                    >
                      <Search />
                    </SelectInput>
                  </Box>
                  <CustomTextField label="Coach Title" name="coachTitle" />
                  <Box marginBottom="30px">
                    <SelectInput
                      placeholder="Sports..."
                      name="sports"
                      options={getOptionsFromSports(sportList)}
                      setFieldValue={setFieldValue}
                      values={values}
                      isMulti={true}
                      errors={errors}
                    />
                  </Box>
                  <CustomTextField label="College Email" name="collegeEmail" />
                  <CustomTextField label="Contact Email" name="contactEmail" />
                  <CustomTextField label="Phone Number" name="phoneNumber" />
                  <Box marginTop="30px">
                    <SuccessButton type="submit" disabled={isSubmitting}>
                      Save
                    </SuccessButton>
                  </Box>
                </PersonalInfoFormContainer>
              </Form>
            )}
          </Formik>
        </GeneralCard>
      </CardContainer>
      <Feedbacker
        open={feedback === recruitingProfileFeedbacks.Updated}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage={feedback}
        clearFeedback={() => dispatch({ type: actionTypes.CLEAR_FEEDBACK })}
      />
      <Feedbacker
        open={Boolean(error)}
        autoHideDuration={3000}
        severity="error"
        feedbackMessage={feedback}
        clearFeedback={() => dispatch({ type: actionTypes.CLEAR_FEEDBACK })}
      />
    </>
  );
}

export default CoachPersonalInformation;
