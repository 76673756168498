import React, { useCallback } from "react";
import { Checkbox } from "components/MaterialComponents";

function ShowNullValuesFilter({ filter, onChange, label }) {
  const { [label]: filterField } = filter;
  const handleOnChange = useCallback(
    () => onChange({ ...filter, [label]: !filterField }),
    [onChange, filter, label, filterField],
  );
  return <Checkbox checked={Boolean(filterField)} onChange={handleOnChange} />;
}

export default ShowNullValuesFilter;
