import React from "react";
import PropTypes from "prop-types";
import { Button } from "components/MaterialComponents";
import Modal from "components/Modal";
import { Subtitle } from "components/Typography";
import { ButtonsBlock } from "../style";

function ConfirmUploadBigFile({ open, onClose, onSendData, loadingState }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Subtitle>
        For files larger than 100 lines data will be loaded without preview
      </Subtitle>
      <ButtonsBlock>
        <Button
          onClick={onSendData}
          variant="contained"
          color="primary"
          size="large"
          disabled={loadingState}
        >
          Confirm
        </Button>
        <Button
          onClick={onClose}
          variant="outlined"
          color="secondary"
          size="large"
        >
          Cancel
        </Button>
      </ButtonsBlock>
    </Modal>
  );
}

ConfirmUploadBigFile.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSendData: PropTypes.func.isRequired,
  loadingState: PropTypes.bool,
};

export default ConfirmUploadBigFile;
