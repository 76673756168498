import * as emailDomain from "api/domains/email";
import {
  SET_CURRENT_THREAD,
  SET_SUMMARY,
  SET_FEEDBACK,
  LOADING,
  ERROR,
  SET_CURRENT_THREAD_FOR_COACH,
  SET_COACH_SUMMARY,
} from "./actionTypes";
import { messagesFeedbacks } from "./messagesFeedbacks";

async function getContactsSummary(dispatch) {
  try {
    const data = await emailDomain.getContactsSummary();
    dispatch({ type: SET_SUMMARY, data });
  } catch (error) {
    dispatch({ type: ERROR, error: error.message });
  }
}

async function setCurrentThread(dispatch, collegeId) {
  try {
    const data = await emailDomain.loadMessagesByCollegeId(collegeId);
    dispatch({ type: SET_CURRENT_THREAD, data });
  } catch (error) {
    dispatch({ type: ERROR, error: error.message });
  }
}

async function setCurrentThreadForCoach(dispatch) {
  try {
    dispatch({ type: LOADING });
    const data = await emailDomain.loadMessagesGroupedByAthleteId();
    dispatch({ type: SET_CURRENT_THREAD_FOR_COACH, data: data.messages });
    dispatch({ type: SET_COACH_SUMMARY, data: data.summaryData[0] });
  } catch (error) {
    dispatch({ type: ERROR, error: error.message });
  }
}

function setLoading(dispatch) {
  dispatch({ type: LOADING });
}

async function sendEmail(dispatch, body, collegeId) {
  try {
    await emailDomain.sendEmail(body);
    if (collegeId) {
      const emails = await emailDomain.loadMessagesByCollegeId(collegeId);
      dispatch({ type: SET_CURRENT_THREAD, data: emails });
    }
    const summary = await emailDomain.getContactsSummary();
    dispatch({ type: SET_SUMMARY, data: summary });

    dispatch({ type: SET_FEEDBACK, data: messagesFeedbacks.Success });
  } catch (error) {
    dispatch({ type: ERROR, error: error.message });
    dispatch({ type: SET_FEEDBACK, data: messagesFeedbacks.Error });
  }
}

async function sendEmailToCoachNotInDB(dispatch, body, collegeId) {
  try {
    await emailDomain.sendEmailToCoachNotInDB(body);
    if (collegeId) {
      const emails = await emailDomain.loadMessagesByCollegeId(collegeId);
      dispatch({ type: SET_CURRENT_THREAD, data: emails });
    }
    const summary = await emailDomain.getContactsSummary();
    dispatch({ type: SET_SUMMARY, data: summary });

    dispatch({ type: SET_FEEDBACK, data: messagesFeedbacks.Success });
  } catch (error) {
    dispatch({ type: ERROR, error: error.message });
    dispatch({ type: SET_FEEDBACK, data: messagesFeedbacks.Error });
  }
}

async function sendEmailToAthlete(dispatch, body) {
  try {
    await emailDomain.sendEmail(body);
    const data = await emailDomain.loadMessagesGroupedByAthleteId();
    dispatch({ type: SET_CURRENT_THREAD_FOR_COACH, data: data.messages });
    dispatch({ type: SET_FEEDBACK, data: messagesFeedbacks.Success });
  } catch (error) {
    dispatch({ type: ERROR, error: error.message });
    dispatch({ type: SET_FEEDBACK, data: messagesFeedbacks.Error });
  }
}

async function updateUnsortedEmails(dispatch, emailId, body) {
  try {
    await emailDomain.updateEmail(emailId, body);
    const data = await emailDomain.getContactsSummary();
    dispatch({ type: SET_SUMMARY, data });
    dispatch({ type: SET_FEEDBACK, data: messagesFeedbacks.MessageUpdated });
  } catch (error) {
    dispatch({ type: ERROR, error: error.message });
    dispatch({
      type: SET_FEEDBACK,
      data: messagesFeedbacks.MessagesUpdateFailure,
    });
  }
}

async function updateUnreadEmailsForAthlete(dispatch, body) {
  try {
    const messages = await emailDomain.updateUnreadEmails(body);
    dispatch({ type: SET_CURRENT_THREAD, data: messages });
    const summary = await emailDomain.getContactsSummary();
    dispatch({ type: SET_SUMMARY, data: summary });
  } catch (error) {
    console.log(error);
  }
}

async function updateUnreadEmailsForCoach(dispatch, body) {
  try {
    const data = await emailDomain.updateUnreadEmails(body);
    dispatch({ type: SET_CURRENT_THREAD_FOR_COACH, data: data.messages });
    dispatch({ type: SET_COACH_SUMMARY, data: data.summaryData[0] });
  } catch (error) {
    console.log(error);
  }
}

export {
  setCurrentThread,
  sendEmail,
  getContactsSummary,
  setLoading,
  setCurrentThreadForCoach,
  sendEmailToAthlete,
  sendEmailToCoachNotInDB,
  updateUnsortedEmails,
  updateUnreadEmailsForAthlete,
  updateUnreadEmailsForCoach,
};
