export function getUserSportsInCollegeList(userSports = {}, collegeSportIds) {
  const {
    primary: primarySport,
    secondary: secondarySport,
    tertiary: tertiarySport,
  } = userSports;

  const primaryInTheList =
    primarySport && collegeSportIds.find((el) => el.id === primarySport.id);
  const secondaryInTheList =
    secondarySport && collegeSportIds.find((el) => el.id === secondarySport.id);
  const tertiaryInTheList =
    tertiarySport && collegeSportIds.find((el) => el.id === tertiarySport.id);

  const userSportsIncollegelist = {};
  if (primaryInTheList) {
    userSportsIncollegelist.primarySport = primaryInTheList;
  }
  if (secondaryInTheList) {
    userSportsIncollegelist.secondarySport = secondaryInTheList;
  }
  if (tertiaryInTheList) {
    userSportsIncollegelist.tertiarySport = tertiaryInTheList;
  }

  return userSportsIncollegelist;
}
