import React, { useRef } from "react";
import { useParams } from "react-router-dom";

import CollegeDetailsSkeleton from "./skeleton";
import CollegeDetailedCard from "containers/CollegeDetails/CollegeDetailedCard";
import AdmissionCard from "./AdmissionCard";
import SportsCard from "./SportsCard";
import CostCard from "./CostCard";
import MajorsCard from "./MajorsCard";
import Navigation from "./Navigation";
import { useGet } from "components/useFetch";
import Feedbacker from "components/Feedbacker";

import { CardDataContainer } from "./style";
import useRecruitingProfile, {
  recruitingProfileFeedbacks,
} from "components/useRecruitingProfile";
import useClearFeedback from "customHooks/useClearFeedback";
import * as actionTypes from "components/useRecruitingProfile/actionTypes";

function CollegeDetails() {
  const { id } = useParams();
  const [data, loading] = useGet(`/colleges/${id}`);
  const admissions = useRef(null);
  const cost = useRef(null);
  const sports = useRef(null);
  const majors = useRef(null);
  const { sportids, cipcodes } = data || {};
  const [{ feedback }, dispatch] = useRecruitingProfile();

  useClearFeedback(dispatch);

  return (
    <>
      {loading ? (
        <CollegeDetailsSkeleton />
      ) : (
        <>
          <CollegeDetailedCard {...data} collegeId={id} sportids={sportids}>
            <Navigation refProps={{ admissions, cost, sports, majors }} />
          </CollegeDetailedCard>
          <CardDataContainer>
            <AdmissionCard refProp={admissions} {...data} />
            <SportsCard refProp={sports} sportids={sportids} collegeId={id} />
            <CostCard refProp={cost} {...data} />
            <MajorsCard refProp={majors} cipcodes={cipcodes} />
          </CardDataContainer>
        </>
      )}
      <Feedbacker
        open={feedback === recruitingProfileFeedbacks.FavoritesUpdated}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage={feedback}
        clearFeedback={() => dispatch({ type: actionTypes.CLEAR_FEEDBACK })}
      />
    </>
  );
}

export default CollegeDetails;
