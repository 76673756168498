import React from "react";
import { Box } from "components/MaterialComponents";
import PropTypes from "prop-types";

import GeneralCard from "components/GeneralCard";
import { CardRegularText, CardBoldText } from "./style";

function AdmissionCard({
  totalEnrollment,
  acceptanceRate,
  hsGPA,
  satRange,
  actRange,
  refProp,
}) {
  return (
    <GeneralCard header="Admissions" refProp={refProp}>
      <Box display="flex" mt={2}>
        <Box mr={20}>
          <CardRegularText>
            Enrollment: <CardBoldText>{totalEnrollment}</CardBoldText>
          </CardRegularText>
          <CardRegularText>
            Acceptance Rate: <CardBoldText>{acceptanceRate}%</CardBoldText>
          </CardRegularText>
          <CardRegularText>
            Avg GPA: <CardBoldText>{hsGPA}</CardBoldText>
          </CardRegularText>
        </Box>
        <Box>
          <CardRegularText>Test Scores: ---</CardRegularText>
          <CardRegularText>
            SAT Range*: <CardBoldText>{satRange}</CardBoldText>
          </CardRegularText>
          <CardRegularText>
            ACT Range*: <CardBoldText>{actRange}</CardBoldText>
          </CardRegularText>
        </Box>
      </Box>
    </GeneralCard>
  );
}

AdmissionCard.propTypes = {
  totalEnrollment: PropTypes.number,
  acceptanceRate: PropTypes.number,
  hsGPA: PropTypes.number,
  satRange: PropTypes.string,
  actRange: PropTypes.string,
  refProp: PropTypes.object,
};

export default AdmissionCard;
