import React, { useState } from "react";
import { FilterBarContainer, SearchContainer, MenuContainer } from "./style";
import NameFilter from "components/NameFilter";
import HumburgerButton from "components/HumburgerButton";
import { useMediaQuery } from "components/MaterialComponents";
import { Collapse } from "components/MaterialComponents";
import FilterMenu from "./FilterMenu";

function FilterBar({ filter, onChange, majors }) {
  const [isNavMenuOpen, setNavMenuOpen] = useState(false);
  const matches = useMediaQuery("(max-width:900px)");

  return (
    <FilterBarContainer>
      <SearchContainer>
        {matches && (
          <HumburgerButton
            isOpen={isNavMenuOpen}
            toggleMenu={() => setNavMenuOpen(!isNavMenuOpen)}
          />
        )}
        <NameFilter
          filter={filter}
          onChange={onChange}
          placeholder="Search by name"
        />
      </SearchContainer>
      {matches ? (
        <MenuContainer>
          <Collapse in={isNavMenuOpen}>
            <FilterMenu filter={filter} majors={majors} onChange={onChange} />
          </Collapse>
        </MenuContainer>
      ) : (
        <FilterMenu filter={filter} majors={majors} onChange={onChange} />
      )}
    </FilterBarContainer>
  );
}

export default React.memo(FilterBar);
