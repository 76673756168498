import React, { useCallback } from "react";
import { Box } from "components/MaterialComponents";
import { Formik, Form } from "formik";
import * as yup from "yup";
import CustomTextField from "components/CustomTextField";
import { CommonEditor, MobileEditor } from "components/TextEditor";
import { InputLabel } from "components/Typography";
import { SendButton } from "components/Buttons";
import * as validationHelper from "helpers/validationHelper";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import SelectInput from "components/Select";
import {
  useMessagesDispatch,
  sendEmailToAthlete,
} from "components/useMessages";
import Modal from "components/Modal";
import {
  SaveBtnContainer,
  InputContainer,
  MainContainer,
  SubjectContainer,
  SelectContainer,
} from "./style";

const validationSchema = yup.object().shape({
  parent: yup.mixed().required("Please select parent"),
  subject: validationHelper.subject,
  body: validationHelper.body,
});

function getOptionsForParents(parents) {
  return parents.map((parent) => ({
    value: parent.userId,
    label: `${parent.firstName} ${parent.lastName}`,
  }));
}

function ContactParentsPopUp({ open, onClose, parents, sport }) {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("xs"));

  const dispatch = useMessagesDispatch();

  const handleFormSubmit = useCallback(
    async (data, { setSubmitting }) => {
      const { subject, body, parent } = data;

      const recipients = [
        {
          id: parent.value,
          name: parent.label,
        },
      ];
      const message = {
        body,
        subject,
        sportId: sport.id,
      };

      await sendEmailToAthlete(dispatch, { recipients, message });
      setSubmitting(false);

      onClose();
    },
    [dispatch, sport, onClose],
  );

  return (
    <Modal open={open} onClose={onClose} fluid={matchesSm}>
      <MainContainer>
        <Formik
          validateOnChange
          initialValues={{
            parent: "",
            subject: "",
            body: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <Box marginTop="2em">
                <InputContainer>
                  <InputLabel mb="0.5em">Parent</InputLabel>
                  <SelectContainer>
                    <SelectInput
                      name="parent"
                      options={getOptionsForParents(parents)}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  </SelectContainer>
                </InputContainer>
                <InputContainer>
                  <InputLabel mb="0.5em">Subject</InputLabel>
                  <SubjectContainer>
                    <CustomTextField
                      name="subject"
                      type="text"
                      placeholder="Subject"
                    />
                  </SubjectContainer>
                </InputContainer>
                <InputContainer>
                  <InputLabel mb="0.5em">Body</InputLabel>
                  {matchesSm ? (
                    <MobileEditor
                      name="body"
                      setFieldValue={setFieldValue}
                      placeholder="Your Message"
                    />
                  ) : (
                    <CommonEditor
                      name="body"
                      setFieldValue={setFieldValue}
                      placeholder="Your Message"
                    />
                  )}
                </InputContainer>
                <SaveBtnContainer>
                  <SendButton type="submit" disabled={isSubmitting}>
                    Send
                  </SendButton>
                </SaveBtnContainer>
              </Box>
            </Form>
          )}
        </Formik>
      </MainContainer>
    </Modal>
  );
}

export default ContactParentsPopUp;
