import * as notesDomain from "api/domains/notes";
import * as actionTypes from "./actionTypes";

async function getUserNotes(dispatch) {
  try {
    const data = await notesDomain.getUserNotes();
    dispatch({ type: actionTypes.SUCCESS, data });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.message });
  }
}

async function createNote(dispatch, body) {
  try {
    await notesDomain.createNote(body);
    const data = await notesDomain.getUserNotes();
    dispatch({ type: actionTypes.SUCCESS, data });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.message });
  }
}

async function updateNote(dispatch, id, body) {
  try {
    await notesDomain.updateNote(id, body);
    const data = await notesDomain.getUserNotes();
    dispatch({ type: actionTypes.SUCCESS, data });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.message });
  }
}

async function deleteNote(dispatch, id) {
  try {
    await notesDomain.deleteNote(id);
    const data = await notesDomain.getUserNotes();
    dispatch({ type: actionTypes.SUCCESS, data });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.message });
  }
}

export { getUserNotes, createNote, updateNote, deleteNote };
