import React from "react";
import styled from "styled-components";
import { FormControl } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";

export const SaveBtnContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const InputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const CardContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 2em;
`;

export const StyledFormControl = styled((props) => (
  <FormControl variant="outlined" {...props} />
))`
  min-width: 60%;
  margin-bottom: 2em;
`;
