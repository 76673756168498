import * as historyDomain from "api/domains/athleticHistory";
import * as actionTypes from "./actionTypes";
import { athleticHistoryFeedbacks } from "./AthleticHistoryFeedbacks";

async function getUserHistory(dispatch) {
  try {
    const data = await historyDomain.getUserHistory();
    dispatch({ type: actionTypes.SUCCESS, data });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.message });
  }
}

async function createHistory(dispatch, body) {
  try {
    await historyDomain.createHistory(body);
    const data = await historyDomain.getUserHistory();
    dispatch({ type: actionTypes.SUCCESS, data });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: athleticHistoryFeedbacks.Created,
    });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.message });
    dispatch({ type: actionTypes.SET_FEEDBACK, data: "error" });
  }
}

async function updateHistory(dispatch, id, body) {
  try {
    await historyDomain.updateHistory(id, body);
    const data = await historyDomain.getUserHistory();
    dispatch({ type: actionTypes.SUCCESS, data });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: athleticHistoryFeedbacks.Updated,
    });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.message });
    dispatch({ type: actionTypes.SET_FEEDBACK, data: "error" });
  }
}

async function deleteHistory(dispatch, id) {
  try {
    await historyDomain.deleteHistory(id);
    const data = await historyDomain.getUserHistory();
    dispatch({ type: actionTypes.SUCCESS, data });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: athleticHistoryFeedbacks.Deleted,
    });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.message });
    dispatch({ type: actionTypes.SET_FEEDBACK, data: "error" });
  }
}

export { getUserHistory, createHistory, updateHistory, deleteHistory };
