import React from "react";
import styled from "styled-components";
import { Box } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Select } from "components/MaterialComponents";
import { TextField } from "components/MaterialComponents";
import { DeleteOutline as DeleteOutlineIcon } from "components/MaterialIcons";
import { AddOutlined as AddOutlinedIcon } from "components/MaterialIcons";
import { NavLink } from "react-router-dom";

export const Title = styled(Box)`
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
`;

export const StyledDeleteIcon = styled(DeleteOutlineIcon)`
  ${({ theme }) => `
  color: ${theme.palette.common.geyser};
  `}
`;

export const SportTabLink = styled((props) => (
  <NavLink style={{ textDecoration: "none" }} {...props} />
))`
  text-decoration: none;
`;

export const StyledTabButton = styled(({ active, ...rest }) => (
  <Button disableRipple {...rest} />
))`
  ${({ active, theme }) => `
  padding: 12px 32px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${theme.palette.common.allports};
  border-radius: 0;
  border-bottom: ${
    active ? `3px solid ${theme.palette.common.allports}` : "unset"
  };
  &:disabled, &:hover {
    background-color: inherit;
  }
  `}
`;

export const AthleticPerformanceFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AddPositionButton = styled(Button)`
  ${({ theme }) => `
  color: ${theme.palette.common.allports};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 2em;
  &:hover {
    background-color: unset;
  }
  `}
`;

export const StyledAddIcon = styled(AddOutlinedIcon)`
  ${({ theme }) => `
  color: ${theme.palette.common.allports};
  font-size: 16px !important;
  `}
`;

export const StyledSelect = styled(Select)`
  ${({ theme }) => `
  min-width: 50%;
  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
  `}
`;

export const StyledSportStatTextField = styled(TextField)`
  ${({ theme }) => `
  ${theme.breakpoints.down("sm")} {
    width: 100%;
    margin-bottom: 2em;
  }
  `}
`;

export const SportStatWithAnswerContainer = styled(Box)`
  ${({ theme }) => `
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 5fr 4fr 160px;
  margin-bottom: 2em;
  ${theme.breakpoints.down("sm")} {
    display: flex;
    flex-direction: column;
  }
  `}
`;
