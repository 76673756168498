import React, { useCallback, useEffect, useState } from "react";
import { Grid, Box } from "components/MaterialComponents";
import { useParams, useHistory } from "react-router-dom";
import FilterBar from "./FilterBar";
import useSearchResult from "./useSearchResult";
import useFilter from "customHooks/useFilter";
import ItemsResults from "./ItemsResults";
import HorizontalLine from "components/HorizontalLine";
import Feedbacker from "components/Feedbacker";
import { Headline } from "components/Typography";
import { CollegeCardSkeleton } from "containers/FindColleges/CollegeCard";
import useRecruitingProfile, {
  recruitingProfileFeedbacks,
} from "components/useRecruitingProfile";
import useProfile, { verifyEmail } from "components/useProfile";
import useClearFeedback from "customHooks/useClearFeedback";
import FiltersPreview from "./FiltersPreview";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import * as recruitingProfileActionTypes from "components/useRecruitingProfile/actionTypes";
import * as profileActionTypes from "components/useProfile/actionTypes";
import SavedFilters from "containers/SavedFilters";
import {
  CollegesContainer,
  CollegesGridContainer,
  StyledPagination,
  MobileBanner,
  FavouriteHeding,
  MainFiltersContainer,
} from "./style";

const loadingItems = Array(21).fill(true);

const mapFavouritesToBanners = {
  reach:
    "Schools that may be an ultimate goal and need either Academic or Athletic Improvements",
  target:
    "Schools you would like to go to but may need a little improvement in your GPA, Test Scores or Athletic Performance",
  safety: "Schools you can easily go to academically and athletically",
};

const mapFavouritesToBannersHeadings = {
  reach: "Reach Schools",
  target: "Target Schools",
  safety: "Safety Schools",
};

function FindColleges() {
  const params = useParams();
  const verifyEmailToken = params.token;
  const history = useHistory();
  const [filter, setFilter] = useFilter();
  const parsedPage = parseInt(filter.page) || 1;
  const [lastRemoved, setLastRemoved] = useState(null);
  const [lastModified, setLastModified] = useState(null);
  const [{ last_page, data: items, majors } = {}, loading] = useSearchResult(
    filter,
    params,
    {
      removed: lastRemoved,
      modified: lastModified,
    },
  );

  const [{ feedback, profile }, dispatch] = useRecruitingProfile();
  const [{ feedback: profileFeedback, error }, profileDispatch] = useProfile();

  const onPageChange = (_, page) => setFilter({ ...filter, page });
  const onFilterChange = (filter) => setFilter({ ...filter, page: 1 });
  const onItemRemoved = (favoriteId) => setLastRemoved(favoriteId);
  const onItemModified = (favoriteId) => setLastModified(favoriteId);

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const attemptEmailVerification = useCallback(async () => {
    if (!verifyEmailToken) {
      return;
    }
    await verifyEmail(profileDispatch, verifyEmailToken);
    history.push("/colleges");
  }, [history, profileDispatch, verifyEmailToken]);

  useEffect(() => {
    attemptEmailVerification();
  }, [attemptEmailVerification]);

  useEffect(() => {
    if (!params.favorite) {
      return;
    }
    setFilter((filter) => ({ ...filter, page: 1 }));
  }, [params.favorite, setFilter]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [items]);

  useClearFeedback(dispatch);

  return (
    <>
      <Headline>Find Colleges</Headline>
      {matchesSm && !params.favorite && (
        <MobileBanner>All Schools</MobileBanner>
      )}
      {params.favorite && (
        <>
          {matchesSm ? (
            <MobileBanner>
              {mapFavouritesToBannersHeadings[params.favorite]}
            </MobileBanner>
          ) : (
            <Headline variant="h4">
              <FavouriteHeding>
                {mapFavouritesToBannersHeadings[params.favorite]}
              </FavouriteHeding>
              {mapFavouritesToBanners[params.favorite]}
            </Headline>
          )}
        </>
      )}
      <HorizontalLine />
      <CollegesContainer>
        <MainFiltersContainer>
          <FilterBar
            filter={filter}
            majors={majors}
            onChange={onFilterChange}
          />
          {profile && <SavedFilters />}
        </MainFiltersContainer>
        <FiltersPreview filter={filter} onChange={onFilterChange} />
        <CollegesGridContainer container spacing={2}>
          {loading ? (
            loadingItems.map((_, i) => (
              <Grid item md={4} sm={6} xs={12} key={i}>
                <CollegeCardSkeleton />
              </Grid>
            ))
          ) : (
            <ItemsResults
              items={items}
              onItemRemoved={onItemRemoved}
              onItemModified={onItemModified}
            />
          )}
        </CollegesGridContainer>
        {last_page && (
          <Box py={2}>
            <StyledPagination
              variant="outlined"
              shape="rounded"
              count={last_page}
              page={parsedPage}
              onChange={onPageChange}
            />
          </Box>
        )}
      </CollegesContainer>
      <Feedbacker
        open={feedback === recruitingProfileFeedbacks.Updated}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage={feedback}
        clearFeedback={() =>
          dispatch({ type: recruitingProfileActionTypes.CLEAR_FEEDBACK })
        }
      />
      <Feedbacker
        open={!!profileFeedback}
        autoHideDuration={3000}
        severity={error ? "error" : "success"}
        feedbackMessage={profileFeedback}
        clearFeedback={() =>
          profileDispatch({ type: profileActionTypes.CLEAR_FEEDBACK })
        }
      />
    </>
  );
}

export default FindColleges;
