import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { TreeView } from "components/MaterialComponents";
import { TreeItem } from "components/MaterialComponents";

import { KeyboardArrowDown as KeyboardArrowDownIcon } from "components/MaterialIcons";
import { SupervisorAccount as SupervisorAccountIcon } from "components/MaterialIcons";
import { SportsHandball as SportsHandballIcon } from "components/MaterialIcons";
import { Stars as StarsIcon } from "components/MaterialIcons";
import { Create as CreateIcon } from "components/MaterialIcons";

import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";
import { ReactComponent as ContactIcon } from "assets/icons/contact.svg";
import { ReactComponent as CollegeIcon } from "assets/icons/college.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail-white.svg";

export const StyledTreeView = styled(TreeView)`
  ${({ theme }) => `
  ${theme.breakpoints.down("xs")} {
    display: flex;
    justify-content: space-around;
  }
  `}
`;

export const StyledNavLink = styled((props) => (
  <NavLink
    {...props}
    exact
    activeClassName="selected"
    activeStyle={{
      textDecoration: "none",
    }}
  />
))`
  text-decoration: none;
`;

export const StyledParentTreeItem = styled(TreeItem)`
  ${({ theme }) => `
    & .Mui-selected {
      background: ${theme.palette.common.fern};
    }

    & .MuiTreeItem-content {
      flex-direction: row-reverse;
      position: relative;
    }

    & .MuiTreeItem-label {
      padding: 8px 10px 8px 10%;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.palette.common.white};

      ${theme.breakpoints.down("xs")} {
        padding: 8px 0px 8px 0px;
      }
    }

    & .MuiTreeItem-group {
      margin-left: 0;
    }

    & .MuiTreeItem-iconContainer {
      position: absolute;
      right: 8%;
      color: ${theme.palette.common.white};
    }
  }
  `}
`;

export const StyledChildrenTreeItem = styled(TreeItem)`
  ${({ theme }) => `
    & .MuiTreeItem-label {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      padding: 8px 10px 8px 15%;
      color: ${theme.palette.common.athensGray};
    }
  `}
`;

export const StyledProfileIcon = styled(ProfileIcon)`
  align-self: flex-end;
  margin-right: 8.37px;
`;

export const StyledContactIcon = styled(ContactIcon)`
  align-self: flex-end;
  margin-right: 7.75px;
`;

export const StyledCollegeIcon = styled(CollegeIcon)`
  align-self: flex-end;
  margin-right: 6.69px;
`;

export const StyledMailIcon = styled(MailIcon)`
  align-self: flex-end;
  margin-right: 7.75px;
`;

export const StyledNoteIcon = styled(CreateIcon)`
  align-self: flex-end;
  margin-right: 7.75px;
  font-size: 14px;
`;

export const StyledAdminIcon = styled(SupervisorAccountIcon)`
  align-self: flex-end;
  margin-right: 7.75px;
  font-size: 14px;
`;

export const StyledAthletesIcon = styled(SportsHandballIcon)`
  align-self: flex-end;
  margin-right: 7.75px;
  font-size: 16px;
`;

export const StyledFavoriteIcon = styled(StarsIcon)`
  align-self: flex-end;
  margin-right: 7.75px;
  font-size: 16px;
`;

export const StyledTabsContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 15px;
    font-family: 'Open Sans',Arial,sans-serif;
    font-weight: normal;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  `}
`;

export const StyledTabsHeader = styled((props) => <div {...props} />)`
  ${({ theme, $selected, $isnavlink }) => `
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-bottom: ${
      $selected && !$isnavlink
        ? `2px solid ${theme.palette.common.fern}`
        : `2px solid transparent`
    };
    height: 48px;
    color: ${
      $selected ? theme.palette.common.fern : theme.palette.common.athensGray
    };
  `}
`;

export const StyledArrowDownIcon = styled(KeyboardArrowDownIcon)`
  transition: all 0.3s;
  transform: ${(props) =>
    props.$selected ? `rotate(-180deg)` : `rotate(0deg)`};
`;

export const StyledItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 10px;
`;

export const MobileLink = styled.div`
  ${({ theme }) => `
    cursor: pointer;
    color: ${theme.palette.common.athensGray};
    font-family: 'Open Sans',Arial,sans-serif;
    font-size: 15px;
    padding: 8px 8px;
    border-radius: 5px;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  `}
`;

export const StyledUnreadMessages = styled.div`
  position: absolute;
  bottom: -6px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: black;
  background-color: white;
`;
