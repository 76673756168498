import React, { useState, useCallback } from "react";
import { TableRow } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import PropTypes from "prop-types";
import { IconButton } from "components/MaterialComponents";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "components/MaterialIcons";
import { KeyboardArrowUp as KeyboardArrowUpIcon } from "components/MaterialIcons";
import StatCreateEditePopUp from "./StatCreateEditePopUp";
import { Collapse } from "components/MaterialComponents";
import SelectOptionsList from "../SelectOptions/SelectOptionsList";

import { StyledTableRowOptions } from "../../style";

function StatsTableRow({
  stat,
  positions,
  handleEditeStat,
  handleDeleteStat,
  refetchSportsData,
}) {
  const [isEditeModalOpen, setIsEditeModalOpen] = useState(false);
  const [isOptionsCollapseOpen, setOptionsCollapseOpen] = useState(false);

  const isSelectInput = stat.inputType === "SELECT";

  const toggleEditeModal = useCallback(() => {
    setIsEditeModalOpen(!isEditeModalOpen);
  }, [isEditeModalOpen]);

  const toggleOptionsCollapse = useCallback(() => {
    setOptionsCollapseOpen(!isOptionsCollapseOpen);
  }, [isOptionsCollapseOpen]);

  return (
    <>
      <TableRow key={stat.id}>
        <TableCell>{stat.ordinal}</TableCell>
        <TableCell>{stat.name}</TableCell>
        <TableCell>{stat.prompt}</TableCell>
        <TableCell>{stat.inputType}</TableCell>
        <TableCell>{stat.inputUnits}</TableCell>
        <TableCell>{stat.qualifierDescription}</TableCell>
        <TableCell>{stat.storageUnits}</TableCell>
        <TableCell>{stat.min}</TableCell>
        <TableCell>{stat.max}</TableCell>
        <TableCell>
          <Button color="primary" onClick={toggleEditeModal}>
            Edit
          </Button>
        </TableCell>
        <TableCell>
          <Button color="secondary" onClick={() => handleDeleteStat(stat.id)}>
            Delete
          </Button>
        </TableCell>
        {isSelectInput && (
          <TableCell>
            Options
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={toggleOptionsCollapse}
            >
              {isOptionsCollapseOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <StyledTableRowOptions>
        <TableCell colSpan={12} style={{ padding: 0 }}>
          <Collapse in={isOptionsCollapseOpen} timeout="auto" unmountOnExit>
            <SelectOptionsList
              options={stat.sportStatsSelectOptions}
              sportDefId={stat.id}
              refetchSportsData={refetchSportsData}
            />
          </Collapse>
        </TableCell>
      </StyledTableRowOptions>
      <StatCreateEditePopUp
        open={isEditeModalOpen}
        onClose={toggleEditeModal}
        item={stat}
        onEditeStat={handleEditeStat}
        positions={positions}
      />
    </>
  );
}

StatsTableRow.propTypes = {
  stat: PropTypes.object,
  positions: PropTypes.array,
  handleEditeStat: PropTypes.func,
  handleDeleteStat: PropTypes.func,
  refetchSportsData: PropTypes.func,
};

export default StatsTableRow;
