import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Box } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import { pluralizeWord } from "helpers/pluralize";

import { StyledTableRow, Name } from "./style";

function UnsortedEmailsRow({ emailsCount }) {
  const [isHover, setIsHover] = useState(false);
  const history = useHistory();
  return (
    <StyledTableRow
      onClick={() => history.push(`contact/usorted`)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      $isHover={isHover}
    >
      <TableCell colSpan={5}>
        <Box display="flex" alignItems="center" width="100%">
          <Name>
            {`You have ${emailsCount} unassigned ${pluralizeWord(
              "email",
              emailsCount,
            )} from ${pluralizeWord("coach", emailsCount)}`}
          </Name>
        </Box>
      </TableCell>
    </StyledTableRow>
  );
}

UnsortedEmailsRow.propTypes = {
  emailsCount: PropTypes.number,
};

export default UnsortedEmailsRow;
