import React, { useState, useEffect, useCallback } from "react";
import { Skeleton } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { Link, useRouteMatch } from "react-router-dom";

import useProfile, {
  logout,
  updateEmail,
  sendVerificationEmail,
} from "components/useProfile";
import useRecruitingProfile, {
  resetRecruitingProfile,
  loadRecruitingProfile,
  createRecruitingProfile,
  recruitingProfileFeedbacks,
  createCoachProfile,
  loadCoachProfile,
  loadParentProfile,
  createParentProfile,
} from "components/useRecruitingProfile";
import Feedbacker from "components/Feedbacker";
import NoRecruitingProfileModal from "./NoRecruitingProfileModal";
import NoCoachProfileModal from "./NoCoachProfileModal";
import NoParentProfileModal from "./NoParentProfileModal";
import EmailNotActivatedModal from "./EmailNotActivatedModal";
import CoachNotVerifiedModal from "./CoachNotVerifiedModal";
import ProfilePopover from "./ProfilePopover";
import AuthenticationDrawer from "../AuthenticationDrawer";
import getShortName from "helpers/getShortName";
import * as actionTypes from "components/useRecruitingProfile/actionTypes";
import {
  Container,
  StyledAvatar,
  LoginButton,
  StyledLink,
  Name,
} from "./style";
import { profileApiFeedbacks } from "components/useProfile/profileApiFeedbacks";
import { userTypesEnum } from "enums/userTypesEnum";

function ProfileButton() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [coachModalOpen, setCoachModalOpen] = useState(false);
  const [
    { profile, loading, error, feedback: profileFeedback },
    dispatch,
  ] = useProfile();
  const [anchorEl, setAnchorEl] = useState(null);
  const [
    {
      profile: recruitingProfile,
      error: recruitingError,
      loading: recruitingLoading,
      feedback,
    },
    recruitingDispatch,
  ] = useRecruitingProfile();

  const isCoach = profile?.type === userTypesEnum.COACH;
  const isAthlete = profile?.type === userTypesEnum.ATHLETE;
  const isParent = profile?.type === userTypesEnum.PARENT;

  const { params: { token } = {} } =
    useRouteMatch("/user/email/verify/:token") ?? {};

  useEffect(() => {
    if (!error && profile) {
      isCoach && loadCoachProfile(recruitingDispatch);
      isAthlete && loadRecruitingProfile(recruitingDispatch);
      isParent && loadParentProfile(recruitingDispatch);
    }
  }, [profile, error, recruitingDispatch]);

  const open = Boolean(!profile?.isNew && !token);
  const activated = Boolean(profile?.verified);
  const verified = isCoach ? Boolean(recruitingProfile?.verified) : true;
  const openCoachModal = !verified && open && isCoach;
  const openEmailModal = !activated && verified && open;

  useEffect(() => {
    if (recruitingProfile) {
      setCoachModalOpen(openCoachModal);
    }
  }, [openCoachModal, recruitingProfile]);

  useEffect(() => {
    setEmailModalOpen(openEmailModal);
  }, [openEmailModal]);

  useEffect(() => {
    setProfileModalOpen(!!recruitingError);
  }, [recruitingError]);

  const handleCreateRecruitingProfile = useCallback(
    async (dataToSend) => {
      await createRecruitingProfile(recruitingDispatch, { ...dataToSend });
    },
    [recruitingDispatch],
  );

  const handleCreateCoachProfile = useCallback(
    async (dataToSend) => {
      await createCoachProfile(recruitingDispatch, { ...dataToSend });
    },
    [recruitingDispatch],
  );

  const handleCreateParentProfile = useCallback(
    async (dataToSend) => {
      await createParentProfile(recruitingDispatch, { ...dataToSend });
    },
    [recruitingDispatch],
  );

  const handleEmailUpdate = useCallback(
    async (data) => {
      await updateEmail(dispatch, data);
    },
    [dispatch],
  );

  const handleUpdateError = useCallback(
    (error) => {
      dispatch({
        type: "api_error",
        data: {
          error: error.errorCode,
          feedback: profileApiFeedbacks.UPDATE_ERROR,
        },
      });
    },
    [dispatch],
  );

  const handleSendEmail = useCallback(async () => {
    await sendVerificationEmail(dispatch);
    setEmailModalOpen(false);
  }, [dispatch]);

  const onLogout = useCallback(() => {
    logout(dispatch).then(() => resetRecruitingProfile(recruitingDispatch));
  }, [dispatch, recruitingDispatch]);

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? "simple-popover" : undefined;

  const feedbackMessage = (
    <Box display="flex">
      <Box mr="5px">{feedback}</Box>
      <StyledLink component={Link} to="/profile/personal-information">
        To Profile
      </StyledLink>
    </Box>
  );

  if (loading)
    return (
      <Container>
        <Skeleton variant="circle" width={45} height={45} />
      </Container>
    );
  if (!profile)
    return (
      <>
        <LoginButton variant="outlined" onClick={() => setDrawerOpen(true)}>
          Login / Sign Up
        </LoginButton>
        <AuthenticationDrawer open={drawerOpen} setOpen={setDrawerOpen} />
      </>
    );

  const { email, displayName } = profile;
  const { profilePhotoMediaItem, firstName } = recruitingProfile || {};

  return (
    <>
      <Container>
        <Name>{firstName || displayName}</Name>
        <StyledAvatar
          aria-describedby={id}
          src={profilePhotoMediaItem}
          onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
          data-testid="open-profile-popup-btn"
        >
          {getShortName(displayName)}
        </StyledAvatar>
      </Container>

      <NoRecruitingProfileModal
        open={
          !emailModalOpen && !coachModalOpen && profileModalOpen && isAthlete
        }
        handleClose={() => setProfileModalOpen(false)}
        handleCreateRecruitingProfile={handleCreateRecruitingProfile}
        loading={recruitingLoading}
        displayName={profile.displayName}
        dob={profile.dob}
      />
      <NoCoachProfileModal
        open={!emailModalOpen && !coachModalOpen && profileModalOpen && isCoach}
        handleClose={() => setProfileModalOpen(false)}
        handleCreateCoachProfile={handleCreateCoachProfile}
        loading={recruitingLoading}
        displayName={profile.displayName}
        type={profile.type}
      />

      <NoParentProfileModal
        open={
          !emailModalOpen && !coachModalOpen && profileModalOpen && isParent
        }
        handleClose={() => setProfileModalOpen(false)}
        handleCreateParentProfile={handleCreateParentProfile}
        loading={recruitingLoading}
        displayName={profile.displayName}
        type={profile.type}
      />

      <CoachNotVerifiedModal
        open={coachModalOpen}
        handleClose={() => setCoachModalOpen(false)}
      />

      <EmailNotActivatedModal
        open={emailModalOpen}
        email={profile.email}
        handleClose={() => setEmailModalOpen(false)}
        handleEmailUpdate={handleEmailUpdate}
        handleSendEmail={handleSendEmail}
        handleError={handleUpdateError}
      />

      <ProfilePopover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        email={email}
        recruitingProfileNotCreated={!recruitingProfile}
        openModal={() => setProfileModalOpen(true)}
        handleLogout={onLogout}
      />

      <Feedbacker
        open={
          feedback === recruitingProfileFeedbacks.Created ||
          feedback === recruitingProfileFeedbacks.ActivationEmailSent ||
          feedback === recruitingProfileFeedbacks.ActivationEmailNotSent
        }
        autoHideDuration={5000}
        severity="success"
        feedbackMessage={feedbackMessage}
        clearFeedback={() =>
          recruitingDispatch({ type: actionTypes.CLEAR_FEEDBACK })
        }
      />

      <Feedbacker
        open={!!profileFeedback}
        autoHideDuration={5000}
        severity={error ? "error" : "success"}
        feedbackMessage={profileFeedback}
        clearFeedback={() => dispatch({ type: actionTypes.CLEAR_FEEDBACK })}
      />
    </>
  );
}

export default ProfileButton;
