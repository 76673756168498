import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import InputComponent from "components/InputComponent";
import { Add as AddIcon } from "components/MaterialIcons";
import { Delete as DeleteIcon } from "components/MaterialIcons";

import {
  CreateFieldsContainer,
  CreateFormContainer,
  CreateButtonGroup,
  StyledIconButton,
} from "./style";

const validationSchema = yup.object().shape({
  text: yup.string().required(),
});

function CreateCategoryField({ onGroupCreate, closeForm }) {
  const handleFormSubmit = (values) => {
    onGroupCreate(values.text);
  };

  return (
    <CreateFormContainer data-testid="form-create-group">
      <Formik
        validateOnChange
        initialValues={{
          text: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {() => (
          <Form>
            <CreateFieldsContainer>
              <Field name="text" charLimit={20} component={InputComponent} />
              <CreateButtonGroup>
                <StyledIconButton color="primary" type="submit">
                  <AddIcon />
                </StyledIconButton>
                <StyledIconButton
                  color="secondary"
                  onClick={closeForm}
                  data-testid="close-form"
                >
                  <DeleteIcon />
                </StyledIconButton>
              </CreateButtonGroup>
            </CreateFieldsContainer>
          </Form>
        )}
      </Formik>
    </CreateFormContainer>
  );
}

CreateCategoryField.propTypes = {
  createCategory: PropTypes.func,
  closeForm: PropTypes.func,
};

export default CreateCategoryField;
