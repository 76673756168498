import React from "react";
import { Container } from "components/MaterialComponents";
import { Link } from "react-router-dom";

import Logo from "components/Logo";
import HallowSrc from "assets/hallow.png";
import { Bar, LogoRegion, HallowImage, Content, ProfileRegion } from "./style";

function AppBar({ setRef, children }) {
  return (
    <Bar ref={setRef}>
      <Container maxWidth="xl">
        <Content>
          <Link to="/">
            <LogoRegion>
              <HallowImage src={HallowSrc} />
              <Logo height={42} />
            </LogoRegion>
          </Link>
          <ProfileRegion>{children}</ProfileRegion>
        </Content>
      </Container>
    </Bar>
  );
}

export default AppBar;
