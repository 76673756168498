import React from "react";
import { MobileButton } from "components/Buttons";
import { MobileFooterContainer } from "./style";

function FilterMobileFooter({ resetFilter, onDone }) {
  return (
    <MobileFooterContainer>
      <MobileButton
        variant="contained"
        color="primary"
        onClick={() => onDone()}
      >
        Done
      </MobileButton>
      <MobileButton
        variant="outlined"
        color="secondary"
        onClick={() => resetFilter()}
      >
        Reset
      </MobileButton>
    </MobileFooterContainer>
  );
}

export default FilterMobileFooter;
