import React, { useCallback } from "react";
import { Box } from "components/MaterialComponents";
import { Skeleton } from "components/MaterialComponents";
import { Formik, Form } from "formik";
import { useParams, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";

import CustomTextField from "components/CustomTextField";
import HorizontalLine from "components/HorizontalLine";
import GeneralCard from "components/GeneralCard";
import { CommonEditor, MobileEditor } from "components/TextEditor";
import CoachesSelect from "./CoachesSelect";
import { Headline, InputLabel } from "components/Typography";
import { SendButton } from "components/Buttons";
import { useGet } from "components/useFetch";
import { CardContainer, SaveBtnContainer, InputContainer } from "./style";
import * as validationHelper from "helpers/validationHelper";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import { useMessagesDispatch, sendEmail } from "components/useMessages";

const validationSchema = yup.object().shape({
  to: validationHelper.to,
  subject: validationHelper.subject,
  body: validationHelper.body,
});

function ContactCoaches() {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [data, loading] = useGet(`/colleges/coaches/${id}`);

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("xs"));

  const dispatch = useMessagesDispatch();

  const childData = location.state?.childData;

  const handleFormSubmit = useCallback(
    async (data, { setSubmitting }) => {
      const {
        to: { name, id },
        subject,
        body,
      } = data;

      const recipients = [
        {
          id,
          name,
        },
      ];
      const message = {
        body,
        subject,
        messageRegarding: childData ? childData.value : null,
      };

      await sendEmail(dispatch, { recipients, message });
      setSubmitting(false);

      history.push(`/contact-activity`);
    },
    [childData, dispatch, history],
  );

  return (
    <>
      <Headline>Contact Coaches</Headline>
      <HorizontalLine />
      {loading ? (
        <Box display="flex" justifyContent="center" mt="4em">
          <Skeleton variant="rect" width="55%" height="20em" />
        </Box>
      ) : (
        <CardContainer>
          <GeneralCard header="New Email">
            <Formik
              validateOnChange
              initialValues={{
                to: data ? data[data.length - 1] : "",
                subject: childData ? `Regarding ${childData.label}` : "",
                body: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                  <Box my="2em">
                    <InputContainer>
                      <InputLabel mb="0.5em">To</InputLabel>
                      <Box>
                        <CoachesSelect
                          value={values.to}
                          selectOptions={data || []}
                          setFieldValue={setFieldValue}
                        />
                      </Box>
                    </InputContainer>
                    <InputContainer>
                      <InputLabel mb="0.5em">Subject</InputLabel>
                      <Box maxWidth="60%">
                        <CustomTextField
                          name="subject"
                          type="text"
                          placeholder="Subject"
                        />
                      </Box>
                    </InputContainer>
                    <InputContainer>
                      <InputLabel mb="0.5em">Body</InputLabel>
                      {matchesSm ? (
                        <MobileEditor
                          name="body"
                          setFieldValue={setFieldValue}
                          placeholder="Your Message"
                        />
                      ) : (
                        <CommonEditor
                          name="body"
                          setFieldValue={setFieldValue}
                          placeholder="Your Message"
                        />
                      )}
                    </InputContainer>
                    <SaveBtnContainer>
                      <SendButton type="submit" disabled={isSubmitting}>
                        Send
                      </SendButton>
                    </SaveBtnContainer>
                  </Box>
                </Form>
              )}
            </Formik>
          </GeneralCard>
        </CardContainer>
      )}
    </>
  );
}

export default ContactCoaches;
