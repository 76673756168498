import React, { useCallback } from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { Formik } from "formik";
import SelectInput from "components/Select";

function getOptionsFromFilters(filters) {
  return filters.map((el) => ({ label: el.name, value: el.filter, id: el.id }));
}

function FilterSelect({ filters, filterValue, setFiltervalue }) {
  const history = useHistory();

  const handleFilterChange = useCallback(
    (option) => {
      setFiltervalue(option);
      history.push({
        search: option ? option.value : null,
      });
    },
    [history, setFiltervalue],
  );

  return (
    <Formik>
      <SelectInput
        placeholder="Select saved filter"
        name="filter"
        options={getOptionsFromFilters(filters)}
        onChange={handleFilterChange}
        value={filterValue}
      />
    </Formik>
  );
}

FilterSelect.propTypes = {
  filters: PropTypes.array,
  filterValue: PropTypes.object,
  setFiltervalue: PropTypes.func,
};

export default FilterSelect;
