import { useLocation, useRouteMatch } from "react-router-dom";
import { useEffect } from "react";

const pathExeptions = [
  "/profile/athletic-performance/tertiary-sport/:id",
  "/profile/athletic-performance/secondary-sport/:id",
  "/profile/athletic-performance/primary-sport/:id",
];

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const match = useRouteMatch(pathExeptions);

  useEffect(() => {
    if (!match) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}
