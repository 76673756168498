import React from "react";
import PropTypes from "prop-types";
import { TreeView } from "components/MaterialComponents";
import { ExpandMore as ExpandMoreIcon } from "components/MaterialIcons";
import { ChevronRight as ChevronRightIcon } from "components/MaterialIcons";
import { Box } from "components/MaterialComponents";

import GeneralCard from "components/GeneralCard";
import useMajors from "components/useMajors";
import { StyledTreeItem } from "./style";

const majorsMapper = (cipCodes, options) => {
  if (!options) return [];

  const isCipCodeExist = (cipCode) => cipCodes.includes(cipCode);
  const isMajorsMatchAnyCipCode = (majors) =>
    majors.some(({ cip_code }) => isCipCodeExist(cip_code));

  const filteredOptions = options.filter(({ children }) =>
    children.some((child) => isMajorsMatchAnyCipCode(child.majors)),
  );

  const filteredOptionsChildren = filteredOptions.map(
    ({ children, ...rest }) => ({
      children: children.filter(({ majors }) =>
        isMajorsMatchAnyCipCode(majors),
      ),
      ...rest,
    }),
  );

  const finalOptions = filteredOptionsChildren.map(({ children, ...rest }) => ({
    children: children.map(({ majors, ...rest }) => ({
      majors: majors.filter(({ cip_code }) => isCipCodeExist(cip_code)),
      ...rest,
    })),
    ...rest,
  }));

  return finalOptions;
};

function MajorsCard({ refProp, cipcodes }) {
  const { majors: majorsOptions } = useMajors();

  const cipCodes = cipcodes.map((code) => code.cipCode);

  const currentMajorOptions = majorsMapper(cipCodes, majorsOptions);

  const renderTree = ({
    id,
    cip_code,
    group_name,
    major,
    children,
    majors,
  }) => {
    const key = id || cip_code;
    const isGroup = !cip_code;
    const calculatedId = isGroup ? `_${id}` : `${cip_code}`;
    const calculatedName = group_name || major;
    const calculatedChildren =
      Array.isArray(children) && children.length > 0 ? children : majors;

    return (
      <StyledTreeItem nodeId={calculatedId} label={calculatedName} key={key}>
        {calculatedChildren &&
          calculatedChildren.map((item) => renderTree(item))}
      </StyledTreeItem>
    );
  };

  return (
    <GeneralCard header="Majors" refProp={refProp}>
      <Box mt={2}>
        <TreeView
          defaultExpandIcon={<ChevronRightIcon />}
          defaultCollapseIcon={<ExpandMoreIcon />}
          multiSelect
        >
          {currentMajorOptions.map((item) => renderTree(item))}
        </TreeView>
      </Box>
    </GeneralCard>
  );
}

MajorsCard.propTypes = {
  cipcodes: PropTypes.arrayOf(
    PropTypes.shape({
      unitId: PropTypes.number,
      cipCode: PropTypes.number,
    }),
  ),
  refProp: PropTypes.object,
};

export default MajorsCard;
