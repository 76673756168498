function getListOfMajors(majorsOptions = []) {
  return majorsOptions.reduce((result, option) => {
    const hasChildren = option.children.length;
    const majors = hasChildren
      ? getListOfMajors(option.children)
      : getOptionMajors(option);
    return { ...result, ...majors };
  }, {});
}
const getOptionMajors = (option) => {
  return (option.majors || []).reduce(
    (majors, { cip_code, major }) => ({
      ...majors,
      [cip_code]: major,
    }),
    {},
  );
};

export default getListOfMajors;
