import React, { useCallback } from "react";
import useStates from "components/useStates";
import CheckboxOptionsList from "components/CheckboxOptionsList";

function StatesFilter({ filter, onChange }) {
  const { states: stateOptions } = useStates();
  const { states = "" } = filter;
  const statesArray = states ? states.split(",") : [];
  const handleOnChange = useCallback(
    (newStatesArray) => {
      onChange({ ...filter, states: newStatesArray.join(",") });
    },
    [onChange, filter],
  );
  return (
    <CheckboxOptionsList
      value={statesArray}
      onChange={handleOnChange}
      options={stateOptions}
    />
  );
}

export default React.memo(StatesFilter);
