import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Feedbacker from "components/Feedbacker";
import GeneralCard from "components/GeneralCard";
import { SuccessButton } from "components/Buttons";
import useRecruitingProfile, {
  recruitingProfileFeedbacks,
  updateParentProfile,
} from "components/useRecruitingProfile";
import CustomTextField from "components/CustomTextField";
import useClearFeedback from "customHooks/useClearFeedback";
import * as validationHelper from "helpers/validationHelper";
import { Box } from "components/MaterialComponents";
import { CardContainer } from "../style";
import * as actionTypes from "components/useRecruitingProfile/actionTypes";
import { PersonalInfoFormContainer } from "./style";

const validationSchema = yup.object().shape({
  firstName: validationHelper.firstName,
  lastName: validationHelper.lastName,
  phoneNumber: yup.string().nullable(),
});

function ParentPersonalInformation() {
  const [
    { profile, feedback, loading, error },
    dispatch,
  ] = useRecruitingProfile();

  useClearFeedback(dispatch);

  if (loading) {
    return null;
  }

  const { userId, firstName, lastName, phoneNumber } = profile;

  const handleFormSubmit = async (values, { setSubmitting }) => {
    const body = {
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
    };
    await updateParentProfile(dispatch, userId, body);
    setSubmitting(false);
  };

  return (
    <>
      <CardContainer>
        <GeneralCard header="Personal Information">
          <Formik
            validateOnChange
            initialValues={{
              firstName,
              lastName,
              phoneNumber,
            }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ isSubmitting, values, setFieldValue, submitForm, errors }) => (
              <Form>
                <PersonalInfoFormContainer>
                  <CustomTextField label="First Name" name="firstName" />
                  <CustomTextField label="Last Name" name="lastName" />
                  <CustomTextField label="Phone Number" name="phoneNumber" />
                  <Box marginTop="30px">
                    <SuccessButton type="submit" disabled={isSubmitting}>
                      Save
                    </SuccessButton>
                  </Box>
                </PersonalInfoFormContainer>
              </Form>
            )}
          </Formik>
        </GeneralCard>
      </CardContainer>
      <Feedbacker
        open={feedback === recruitingProfileFeedbacks.Updated}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage={feedback}
        clearFeedback={() => dispatch({ type: actionTypes.CLEAR_FEEDBACK })}
      />
      <Feedbacker
        open={Boolean(error)}
        autoHideDuration={3000}
        severity="error"
        feedbackMessage={feedback}
        clearFeedback={() => dispatch({ type: actionTypes.CLEAR_FEEDBACK })}
      />
    </>
  );
}

export default ParentPersonalInformation;
