import React, { useState } from "react";
import { Box } from "components/MaterialComponents";
import PropTypes from "prop-types";
import Modal from "components/Modal";
import CreateCategoryField from "./CreateCategoryField";
import coachFavoriteGroupsColorsMap from "maps/coachFavoriteGroupsColors";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import SelectForm from "./SelectForm";

import {
  CategoriesContainer,
  MainContainer,
  SportButtonRemove,
  SportButtonNew,
  ButtonContainer,
} from "./style";

function CoachFavoritePopover({
  open,
  handleClose,
  handleLike,
  handleUnlike,
  favorite,
  favoriteGroups = [],
}) {
  const [isCreateMode, setCreateMode] = useState(false);

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));

  const toggleCreateMode = () => {
    setCreateMode(!isCreateMode);
  };

  const favoriteGroupsColorsNumber =
    Object.keys(coachFavoriteGroupsColorsMap).length - 1;

  const handleGroupCreate = (groupName) => {
    const body = {
      groupName,
      colorId: Math.floor(Math.random() * favoriteGroupsColorsNumber) + 1,
    };
    handleLike(body);
    toggleCreateMode();
  };

  const handleSelectGroup = (groupName) => {
    const body = {
      groupName,
    };
    handleLike(body);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      withScroll={false}
      fluid={matchesXs}
    >
      <MainContainer>
        <CategoriesContainer>
          <SelectForm
            favoriteGroups={favoriteGroups}
            selectGroup={handleSelectGroup}
            favorite={favorite}
          />
        </CategoriesContainer>
        <ButtonContainer>
          {!isCreateMode ? (
            <SportButtonNew onClick={toggleCreateMode} data-testid="open-form">
              <Box>+ Add to new group</Box>
            </SportButtonNew>
          ) : (
            <CreateCategoryField
              closeForm={toggleCreateMode}
              onGroupCreate={handleGroupCreate}
            />
          )}
        </ButtonContainer>
        {favorite && (
          <ButtonContainer>
            <SportButtonRemove
              onClick={handleUnlike}
              data-testid="unlike-coach-favorite"
            >
              <Box>Remove from likes</Box>
            </SportButtonRemove>
          </ButtonContainer>
        )}
      </MainContainer>
    </Modal>
  );
}

CoachFavoritePopover.propTypes = {
  id: PropTypes.string,
  collegeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  handleLike: PropTypes.func,
  handleUnlike: PropTypes.func,
  favorite: PropTypes.object,
};

export default CoachFavoritePopover;
