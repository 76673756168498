import React, { useCallback } from "react";
import CheckboxOptionsList, {
  ItemSkeleton,
} from "components/CheckboxOptionsList";
import useSports from "components/useSports";

function MensSportsFilter({ filter, onChange }) {
  const { mensSports: mensSportsOptions, loading } = useSports();
  const { mensSports = "" } = filter;
  const mensSportsArray = mensSports ? mensSports.split(",") : [];
  const handleOnChange = useCallback(
    (newMensSportsArray) => {
      onChange({
        ...filter,
        mensSports: newMensSportsArray.join(","),
      });
    },
    [onChange, filter],
  );

  if (loading)
    return (
      <>
        <ItemSkeleton />
        <ItemSkeleton textWidth={90} />
      </>
    );

  return (
    <CheckboxOptionsList
      value={mensSportsArray}
      onChange={handleOnChange}
      options={mensSportsOptions}
    />
  );
}

export default MensSportsFilter;
