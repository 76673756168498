import React, { useCallback, useState, useEffect } from "react";
import FilterPopperField from "./FilterPopperField";
import {
  StyledSlider,
  StyledFilterValuesBlue,
  StyledFilterValuesGrey,
} from "./style";

const min = 400;
const max = 1600;
/*const marks = [
  {
    value: 400,
    label: "400",
  },
  {
    value: 800,
    label: "800",
  },
  {
    value: 1200,
    label: "1200",
  },
  {
    value: 1600,
    label: "1600",
  },
];*/
function SATFilter({ filter, onChange }) {
  const { satMax } = filter;
  const [value, setValue] = useState(parseInt(satMax) || min);

  useEffect(() => {
    setValue(parseInt(satMax) || min);
  }, [satMax]);

  const onValueChange = useCallback(
    (e, newValue) => {
      setValue(newValue);
    },
    [setValue],
  );
  const onChangeCommitted = useCallback(
    (e, satMax) => {
      if (satMax === min) {
        onChange({
          ...filter,
          satMin: null,
          satMax: null,
        });
        return;
      }
      onChange({
        ...filter,
        satMin: min,
        satMax,
      });
    },
    [onChange, filter],
  );
  return (
    <FilterPopperField label="SAT:">
      {value === min ? (
        <StyledFilterValuesGrey variant="h5">
          Select the value
        </StyledFilterValuesGrey>
      ) : (
        <StyledFilterValuesBlue variant="h5">{value}</StyledFilterValuesBlue>
      )}
      <StyledSlider
        value={value}
        min={min}
        max={max}
        step={10}
        onChange={onValueChange}
        onChangeCommitted={onChangeCommitted}
        valueLabelDisplay="auto"
        aria-labelledby="continuous-slider"
        //marks={marks}
      />
    </FilterPopperField>
  );
}

export default SATFilter;
