export const tabHeadersAthlete = [
  {
    id: 1,
    name: "Profile",
    link: null,
  },
  {
    id: 8,
    name: "Colleges",
    link: null,
  },
  {
    id: 13,
    name: "Contacts",
    link: "/contact-activity",
  },
  {
    id: 14,
    name: "Notes",
    link: "/notes",
  },
];
