import React from "react";
import { Card } from "components/MaterialComponents";
import { Grid } from "components/MaterialComponents";
import { Skeleton } from "components/MaterialComponents";

import { CollegeName, CollegeInfo } from "./style";

function CollegeDetailedCardSkeleton() {
  const TextSkeleton = (props) => (
    <Skeleton variant="text" width={250} {...props} />
  );

  const SmallIconSkeleton = (props) => (
    <Skeleton variant="rect" width="1.5em" height="1.5em" {...props} />
  );

  const mainSection = (
    <>
      <CollegeName>
        <TextSkeleton />
      </CollegeName>
      <CollegeInfo>
        <TextSkeleton />
      </CollegeInfo>
      <CollegeInfo>
        <TextSkeleton />
      </CollegeInfo>
      <CollegeInfo>
        <TextSkeleton />
      </CollegeInfo>
      <Grid style={{ marginTop: "1em", marginBottom: "2em" }} container>
        <Grid item style={{ marginRight: "1em" }}>
          <SmallIconSkeleton
            style={{ display: "inline-block", marginRight: "0.3em" }}
          />
          <TextSkeleton style={{ display: "inline-block" }} width={100} />
        </Grid>
        <Grid item>
          <SmallIconSkeleton
            style={{ display: "inline-block", marginRight: "0.3em" }}
          />
          <TextSkeleton style={{ display: "inline-block" }} width={100} />
        </Grid>
      </Grid>
    </>
  );

  return (
    <Card>
      <Grid
        style={{ padding: "4em 0.5em 4em 3em" }}
        justify="space-between"
        container
      >
        <Grid item>
          <Skeleton variant="circle" width="12em" height="12em" />
        </Grid>
        <Grid item>{mainSection}</Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            justify="flex-end"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Skeleton
                variant="text"
                width="5em"
                style={{ display: "inline-block", marginRight: "1em" }}
              />
              <SmallIconSkeleton
                style={{ display: "inline-block", marginRight: "0.5em" }}
              />
              <SmallIconSkeleton
                style={{ display: "inline-block", marginRight: "0.5em" }}
              />
              <SmallIconSkeleton style={{ display: "inline-block" }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default CollegeDetailedCardSkeleton;
