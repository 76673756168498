import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal";
import { Formik, Form } from "formik";
import CustomTextField from "components/CustomTextField";
import { Typography } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import SelectInput from "components/Select";
import { INPUT_TYPES } from "constants/sports";
import { getValInt } from "helpers/getSportStatIntValue";
import * as yup from "yup";

import { FormContainer, FormModalHeader, ButtonsBlock } from "../../style";

function getInputTypesSelectOptions() {
  return INPUT_TYPES.map((type) => ({ label: type, value: type }));
}

function getStatsSelectOptions(stats) {
  return stats.map((stat) => ({ label: stat.name, value: stat.id }));
}

const validationSchema = yup.object().shape({
  stats: yup.mixed().required("Field is required"),
});

function StatEditGroupPopUp({ open, onClose, stats, onEditStatsGroup }) {
  const [inputType, setInputType] = useState();

  const handleCloseModal = useCallback(() => {
    onClose();
    setInputType(null);
  }, [onClose]);

  const handleFormSubmit = useCallback(
    async (values) => {
      await onEditStatsGroup(values);
      handleCloseModal();
    },
    [onEditStatsGroup, handleCloseModal],
  );

  const handleSetFieldValue = useCallback(
    (name, value, setFieldValue) => {
      const valueInt = getValInt(value, inputType);
      if (value && valueInt === null) {
        return;
      }
      setFieldValue(name, value);
    },
    [inputType],
  );

  return (
    <>
      <Modal open={open} onClose={handleCloseModal}>
        <FormModalHeader>
          <Typography variant="h4">Group Edit</Typography>
        </FormModalHeader>
        <Formik
          validateOnChange
          initialValues={{
            stats: null,
            prompt: "",
            inputType: null,
            inputUnits: "",
            qualifierDescription: "",
            storageUnits: "",
            min: "",
            max: "",
          }}
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, submitForm, values }) => (
            <Form>
              <FormContainer>
                <Box marginBottom="30px">
                  <SelectInput
                    placeholder="Stats"
                    name="stats"
                    options={getStatsSelectOptions(stats)}
                    setFieldValue={setFieldValue}
                    values={values}
                    isMulti={true}
                  />
                </Box>
                <CustomTextField
                  label="Prompt"
                  name="prompt"
                  type="text"
                  disabled={!inputType}
                  setFieldValue={(name, value) => {
                    handleSetFieldValue(name, value, setFieldValue);
                  }}
                />
                <Box marginBottom="30px">
                  <SelectInput
                    placeholder="Input Type"
                    name="inputType"
                    options={getInputTypesSelectOptions()}
                    setFieldValue={(name, option) => {
                      setFieldValue(name, option);
                      setInputType(option?.value);
                    }}
                    values={values}
                  />
                </Box>
                <CustomTextField
                  label="Input Units"
                  name="inputUnits"
                  type="text"
                />
                <CustomTextField
                  label="Qualifier Description"
                  name="qualifierDescription"
                  type="text"
                />
                <CustomTextField
                  label="Storage Units"
                  name="storageUnits"
                  type="text"
                />
                <CustomTextField
                  label="Min"
                  name="min"
                  type="text"
                  disabled={!inputType}
                  setFieldValue={(name, value) => {
                    handleSetFieldValue(name, value, setFieldValue);
                  }}
                />
                <CustomTextField
                  label="Max"
                  name="max"
                  type="text"
                  disabled={!inputType}
                  setFieldValue={(name, value) => {
                    handleSetFieldValue(name, value, setFieldValue);
                  }}
                />
                <ButtonsBlock>
                  <Button
                    onClick={submitForm}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={onClose}
                    variant="outlined"
                    color="secondary"
                    size="large"
                  >
                    Cancel
                  </Button>
                </ButtonsBlock>
              </FormContainer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

StatEditGroupPopUp.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  stats: PropTypes.arrayOf(PropTypes.object),
  onEditStatsGroup: PropTypes.func,
};

export default StatEditGroupPopUp;
