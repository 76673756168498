import React, { useEffect, useState, useCallback } from "react";
import { Box } from "components/MaterialComponents";
import { MailOutline as MailOutlineIcon } from "components/MaterialIcons";
import { ThumbUpAltOutlined as ThumbUpAltOutlinedIcon } from "components/MaterialIcons";
import { NoteOutlined as NoteOutlinedIcon } from "components/MaterialIcons";
import { useParams } from "react-router-dom";

import CollegeLogo from "components/CollegeLogo";
import CollegeCardSkeleton from "./CollegeCardSkeleton";
import useRecruitingProfile, {
  refreshRecruitingProfile,
} from "components/useRecruitingProfile";
import { useProfileState } from "components/useProfile";
import { useNotesState } from "components/useNotes";
import { NOT_FOUND } from "constants/backendErrors";
import ContactCoachesPopover from "components/ContactCoachesPopover";
import ParentContactCoachesPopover from "components/ParentContactCoachesPopover";
import BookmarkCollegePopover from "components/BookmarkCollegePopover";
import Tooltip from "components/Tooltip";
import NotesPopUp from "containers/Notes/NotesPopUp";
import { useMessagesState } from "components/useMessages";
import { userTypesEnum } from "enums";
import {
  CollegeLogoContainer,
  ActionsIconContainer,
  StyledLocationIcon,
  StyledIconButton,
  StyledInfoIcon,
  CardContainer,
  StyledCard,
  CardInfo,
  Location,
  Details,
  Name,
} from "./style";
import * as userDomain from "api/domains/user";
import * as parentDomain from "api/domains/parent";
import isempty from "lodash.isempty";
import { getUserSportsInCollegeList } from "helpers/getUserSportsInCollegeList";

function CollegeCard({
  unitId,
  schoolName,
  city,
  state,
  divisionAbbr,
  sanctionName,
  totalEnrollment,
  acceptanceRate,
  logoUrl,
  onItemRemoved,
  onItemModified,
  sports: sportIds,
}) {
  const [anchorElMail, setanchorElMail] = useState(null);
  const [isParentMailModalOpen, setParentMailModalOpen] = useState(false);
  const [anchorElBookmark, setanchorElBookmark] = useState(null);
  const [favorite, setFavorite] = useState(null);
  const [isOpenNoteModal, setIsOpenNoteModal] = useState(false);

  const routeParams = useParams();
  const { isLoggedIn, profile } = useProfileState();
  const [
    { error: recruitingError, profile: recruitingProfile },
    dispatch,
  ] = useRecruitingProfile();

  const { notes } = useNotesState();
  const collegeNotes = notes[unitId];

  const { collegesSummary } = useMessagesState();
  const hasActiveMessaging = collegesSummary.find(
    (el) => Number(el.collegeId) === Number(unitId),
  );

  const isParent = profile?.type === userTypesEnum.PARENT;
  const isAthlete = profile?.type === userTypesEnum.ATHLETE;

  const getCurrentDomain = useCallback(() => {
    switch (profile.type) {
      case userTypesEnum.PARENT:
        return parentDomain;
      default:
        return userDomain;
    }
  }, [profile]);

  useEffect(
    function () {
      setFavorite(
        recruitingProfile?.collegeFavorites?.find(
          (favorite) => favorite.collegeId === unitId,
        ),
      );
    },
    [recruitingProfile, unitId],
  );

  const toggleParentMailModal = useCallback(() => {
    setParentMailModalOpen(!isParentMailModalOpen);
  }, [isParentMailModalOpen]);

  const handleMailPopoverOpen = useCallback((event) => {
    setanchorElMail(event.currentTarget);
  }, []);

  const handleNoteModalClose = useCallback(() => {
    setIsOpenNoteModal(false);
  }, []);

  const handleMailPopoverClose = useCallback(() => {
    setanchorElMail(null);
  }, []);

  const handleBookmarkPopoverClose = useCallback(() => {
    setanchorElBookmark(null);
  }, []);

  const handleBookmarkPopoverOpen = useCallback(async (event) => {
    setanchorElBookmark(event.currentTarget);
  }, []);

  const handleLike = useCallback(
    async (bookmark, collegeId) => {
      try {
        const res = await getCurrentDomain().saveFavorites(
          bookmark.groupName,
          collegeId,
        );

        const { collegeFavorites, ...rest } = recruitingProfile;

        await refreshRecruitingProfile(dispatch, {
          ...rest,
          collegeFavorites: [...collegeFavorites, res],
        });

        handleBookmarkPopoverClose();
      } catch (error) {
        dispatch({ type: "update_error", error: error.errorCode });
      }
    },
    [dispatch, getCurrentDomain, handleBookmarkPopoverClose, recruitingProfile],
  );

  const handleModifyLike = useCallback(
    async (bookmark) => {
      try {
        const res = await getCurrentDomain().updateFavorites(
          bookmark.groupName,
          favorite.id,
        );

        const { collegeFavorites, ...rest } = recruitingProfile;

        await refreshRecruitingProfile(dispatch, {
          ...rest,
          collegeFavorites: res,
        });

        if (Object.keys(routeParams).includes("favorite")) {
          onItemModified(favorite.id);
        }

        handleBookmarkPopoverClose();
      } catch (error) {
        dispatch({ type: "update_error", error: error.errorCode });
      }
    },
    [
      dispatch,
      favorite,
      getCurrentDomain,
      handleBookmarkPopoverClose,
      onItemModified,
      recruitingProfile,
      routeParams,
    ],
  );

  const unlikeCollege = useCallback(async () => {
    try {
      const { collegeFavorites, ...rest } = recruitingProfile;

      collegeFavorites.splice(collegeFavorites.indexOf(favorite), 1);

      await getCurrentDomain().removeFavorites(favorite.id);

      Object.keys(routeParams).includes("favorite") &&
        onItemRemoved(favorite.id);

      await refreshRecruitingProfile(dispatch, { ...rest, collegeFavorites });
      handleBookmarkPopoverClose();
    } catch (error) {
      dispatch({ type: "update_error", error: error.errorCode });
    }
  }, [
    dispatch,
    favorite,
    getCurrentDomain,
    handleBookmarkPopoverClose,
    recruitingProfile,
    routeParams,
    onItemRemoved,
  ]);

  const id = !!anchorElMail ? "college-card-popover" : undefined;

  const { sports } = recruitingProfile || {};

  const isActionsVisible = isLoggedIn && recruitingError !== NOT_FOUND;

  const athletesSportsInCollegeList = getUserSportsInCollegeList(
    sports,
    sportIds,
  );

  const isAthleteMailIconVisible =
    isAthlete && !isempty(athletesSportsInCollegeList);

  const isParentMailIconVisible = isParent && recruitingProfile?.children;

  return (
    <StyledCard>
      <CardContainer>
        <CardInfo>
          <CollegeLogoContainer>
            <CollegeLogo name={schoolName} image={logoUrl} />
          </CollegeLogoContainer>
          <Box>
            <Name to={`/college/${unitId}`}>{schoolName}</Name>
            <Location>
              <StyledLocationIcon />
              {city}, {state}
            </Location>
            <Details>
              <StyledInfoIcon />
              {sanctionName} {sanctionName === "NCAA" ? divisionAbbr : ""}{" "}
              {totalEnrollment && (
                <span>
                  - Enrollment <b>{totalEnrollment}</b>{" "}
                </span>
              )}
              {acceptanceRate && (
                <span>
                  - Acceptance <b>{acceptanceRate}%</b>
                </span>
              )}
            </Details>
          </Box>
        </CardInfo>
        {isActionsVisible && (
          <ActionsIconContainer data-testid="action-buttons">
            {isAthleteMailIconVisible && (
              <Tooltip title="Message Coaches" arrow={true} placement="top">
                <StyledIconButton
                  onClick={handleMailPopoverOpen}
                  $selected={hasActiveMessaging}
                  data-testid="contact-coach-btn"
                >
                  <MailOutlineIcon color="primary" />
                </StyledIconButton>
              </Tooltip>
            )}
            {isParentMailIconVisible && (
              <Tooltip title="Message Coaches" arrow={true} placement="top">
                <StyledIconButton
                  onClick={toggleParentMailModal}
                  $selected={hasActiveMessaging}
                >
                  <MailOutlineIcon color="primary" />
                </StyledIconButton>
              </Tooltip>
            )}
            <Tooltip
              title={favorite ? "Modify likes" : "Add to likes"}
              arrow={true}
              placement="top"
            >
              <StyledIconButton
                onClick={handleBookmarkPopoverOpen}
                $selected={favorite}
                data-testid="favorites-btn"
              >
                <ThumbUpAltOutlinedIcon color="primary" />
              </StyledIconButton>
            </Tooltip>
            <Tooltip title="Notes about college" arrow={true} placement="top">
              <StyledIconButton
                onClick={() => setIsOpenNoteModal(true)}
                $selected={collegeNotes}
                data-testid="notes-btn"
              >
                <NoteOutlinedIcon color="primary" />
              </StyledIconButton>
            </Tooltip>
          </ActionsIconContainer>
        )}
      </CardContainer>
      <ContactCoachesPopover
        primarySport={athletesSportsInCollegeList.primarySport}
        secondarySport={athletesSportsInCollegeList.secondarySport}
        tertiarySport={athletesSportsInCollegeList.tertiarySport}
        id={id}
        collegeId={unitId}
        open={!!anchorElMail}
        anchorEl={anchorElMail}
        handleClose={handleMailPopoverClose}
      />
      <BookmarkCollegePopover
        id={id}
        collegeId={unitId}
        open={!!anchorElBookmark}
        anchorEl={anchorElBookmark}
        handleClose={handleBookmarkPopoverClose}
        handleLike={favorite ? handleModifyLike : handleLike}
        handleUnlike={unlikeCollege}
        favorite={favorite}
      />
      <NotesPopUp
        open={isOpenNoteModal}
        onClose={handleNoteModalClose}
        collegeId={unitId}
        collegeNotes={collegeNotes}
        name={schoolName}
        logo={logoUrl}
      />
      <ParentContactCoachesPopover
        open={isParentMailModalOpen}
        handleClose={toggleParentMailModal}
        athletes={recruitingProfile?.children}
        collegeId={unitId}
        sportIds={sportIds}
      />
    </StyledCard>
  );
}

export default React.memo(CollegeCard);

export { CollegeCardSkeleton };
