import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { StyledTabsHeader, StyledArrowDownIcon } from "./style";

function TabHeader({ header, onSelect, selected }) {
  const history = useHistory();
  const isnavlink = Boolean(header.link);

  const handleSelect = () => {
    if (selected) {
      onSelect(null);
      return;
    }

    if (header.link) {
      history.push(header.link);
    }

    onSelect(header.id);
  };

  return (
    <StyledTabsHeader
      onClick={handleSelect}
      $selected={selected}
      $isnavlink={isnavlink}
    >
      {header.name}
      {!header.link && <StyledArrowDownIcon $selected={selected} />}
    </StyledTabsHeader>
  );
}

TabHeader.propTypes = {
  header: PropTypes.object,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

export default TabHeader;
