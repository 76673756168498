import React, { useEffect, useState, useCallback } from "react";
import { Table } from "components/MaterialComponents";
import { TableBody } from "components/MaterialComponents";
import { TableContainer } from "components/MaterialComponents";
import { TableRow } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { makeStyles } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { TablePagination } from "components/MaterialComponents";
import SportsCreateEditePopUp from "./SportsCreateEditePopUp";
import SportsTableRow from "./SportsTableRow";
import * as sportsDomain from "api/domains/sports";

import { StyledTableHead } from "../style";

const useStyles = makeStyles({
  table: {
    width: 1100,
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "white",
  },
});

function SportsList() {
  const [sportsData, setSportsData] = useState([]);
  const [isSportModalOpen, setSportModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const classes = useStyles();

  useEffect(() => {
    getAllsports();
  }, []);

  const sportModalOpen = useCallback(() => {
    setSportModalOpen(true);
  }, []);

  const sportModalClose = useCallback(() => {
    setSportModalOpen(false);
  }, []);

  const getAllsports = useCallback(async () => {
    const data = await sportsDomain.getAllsports();
    setSportsData(data);
  }, []);

  const handleCreateSport = useCallback(
    async (values) => {
      const body = {
        name: values.name,
        gender: values.gender.value,
      };
      await sportsDomain.createSport(body);
      await getAllsports();
    },
    [getAllsports],
  );

  const handleEditeSport = useCallback(
    async (values, id) => {
      const body = {
        name: values.name,
        gender: values.gender.value,
      };
      await sportsDomain.updateSport(body, id);
      await getAllsports();
    },
    [getAllsports],
  );

  const handleDeleteSport = useCallback(
    async (id) => {
      await sportsDomain.deleteSport(id);
      const filteredSports = sportsData.filter((sport) => sport.id !== id);
      setSportsData(filteredSports);
    },
    [sportsData],
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const begin = page * rowsPerPage;
  const end = page * rowsPerPage + rowsPerPage;

  const calculatedSportDats = sportsData.slice(begin, end);

  return (
    <>
      <Box width="1100px">
        <Button variant="outlined" color="primary" onClick={sportModalOpen}>
          New Sport
        </Button>
      </Box>
      <TableContainer className={classes.table}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>NAME</TableCell>
              <TableCell>GENDER</TableCell>
              <TableCell>EDIT</TableCell>
              <TableCell>DELETE</TableCell>
              <TableCell colSpan={2} />
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {calculatedSportDats.map((sport) => (
              <SportsTableRow
                key={sport.id}
                sport={sport}
                handleCreateSport={handleCreateSport}
                handleEditeSport={handleEditeSport}
                handleDeleteSport={handleDeleteSport}
                refetchSportsData={getAllsports}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sportsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <SportsCreateEditePopUp
        open={isSportModalOpen}
        onClose={sportModalClose}
        onCreateSport={handleCreateSport}
        onEditeSport={handleEditeSport}
      />
    </>
  );
}

export default SportsList;
