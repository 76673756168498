import React, { useState } from "react";
import PropTypes from "prop-types";
import NameFilter from "components/NameFilter";
import { useMediaQuery } from "components/MaterialComponents";
import HumburgerButton from "components/HumburgerButton";
import FilterMenu from "./FilterMenu";
import { Collapse } from "components/MaterialComponents";

import { FilterBarContainer, SearchContainer, MenuContainer } from "./style";

function FilterBar({
  filter,
  onChange,
  positions,
  stats,
  onGenderChange,
  onSportChange,
  sportId,
}) {
  const [isNavMenuOpen, setNavMenuOpen] = useState(false);
  const matches = useMediaQuery("(max-width:900px)");

  return (
    <FilterBarContainer>
      <SearchContainer>
        {matches && (
          <HumburgerButton
            isOpen={isNavMenuOpen}
            toggleMenu={() => setNavMenuOpen(!isNavMenuOpen)}
          />
        )}
        <NameFilter
          filter={filter}
          onChange={onChange}
          placeholder="Search by name"
        />
      </SearchContainer>
      {matches ? (
        <MenuContainer>
          <Collapse in={isNavMenuOpen}>
            <FilterMenu
              filter={filter}
              onChange={onChange}
              positions={positions}
              stats={stats}
              onGenderChange={onGenderChange}
              onSportChange={onSportChange}
              sportId={sportId}
            />
          </Collapse>
        </MenuContainer>
      ) : (
        <FilterMenu
          filter={filter}
          onChange={onChange}
          positions={positions}
          stats={stats}
          onGenderChange={onGenderChange}
          onSportChange={onSportChange}
          sportId={sportId}
        />
      )}
    </FilterBarContainer>
  );
}

FilterBar.propTypes = {
  filter: PropTypes.object,
  onChange: PropTypes.func,
  positions: PropTypes.array,
  stats: PropTypes.array,
  sportId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onGenderChange: PropTypes.func,
  onSportChange: PropTypes.func,
};

export default React.memo(FilterBar);
