import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { TableRow } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import SelectOptionsCreateEditePopUp from "./SelectOptionsCreateEditePopUp";

function SelectOptionsTableRow({
  option,
  handleEditeOption,
  handleDeleteOption,
}) {
  const [isEditeModalOpen, setIsEditeModalOpen] = useState(false);

  const toggleEditeModal = useCallback(() => {
    setIsEditeModalOpen(!isEditeModalOpen);
  }, [isEditeModalOpen]);

  return (
    <>
      <TableRow key={option.id}>
        <TableCell>{option.ordinal}</TableCell>
        <TableCell>{option.optionLabel}</TableCell>
        <TableCell>{option.optionValue}</TableCell>
        <TableCell>
          <Button color="primary" onClick={toggleEditeModal}>
            Edit
          </Button>
        </TableCell>
        <TableCell>
          <Button
            color="secondary"
            onClick={() => handleDeleteOption(option.id)}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <SelectOptionsCreateEditePopUp
        open={isEditeModalOpen}
        onClose={toggleEditeModal}
        item={option}
        onEditeOption={handleEditeOption}
      />
    </>
  );
}

SelectOptionsTableRow.propTypes = {
  option: PropTypes.object,
  handleEditeOption: PropTypes.func,
  handleDeleteOption: PropTypes.func,
};

export default SelectOptionsTableRow;
