export function capitalizeFirst(string) {
  const lower = string.toLowerCase();
  return lower.charAt(0).toUpperCase() + lower.slice(1);
}

export function capitalizeEachWord(string) {
  return string
    .split(" ")
    .map((word) => capitalizeFirst(word))
    .join(" ");
}
