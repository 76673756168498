import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";

import { StyledCard, CardHeader, CardHorizontalLine } from "./style";

function GeneralCard({ header, children, refProp = null }) {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <StyledCard ref={refProp}>
      <CardHeader>{header}</CardHeader>
      {!matchesSm && <CardHorizontalLine />}
      {children}
    </StyledCard>
  );
}

GeneralCard.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  refProp: PropTypes.object,
};

export default GeneralCard;
