import React from "react";
import CoachLogo from "./CoachLogo";
import { Box } from "components/MaterialComponents";
import PropTypes from "prop-types";
import { Typography } from "components/MaterialComponents";

import {
  StyledUserItem,
  StyledTitle,
  StyledSubtitle1,
  StyledCaption,
  CoachNewMessagesLabel,
} from "./style";

function CoachItem({ coach, onSelect, selected, coachSummary }) {
  return (
    <StyledUserItem onClick={() => onSelect(coach.coachId)} selected={selected}>
      <CoachLogo sport={coach.sport} isSelected={selected} />
      <Box display="flex" flexDirection="column" marginLeft="10px">
        <StyledTitle>{coach.name}</StyledTitle>
        <StyledSubtitle1>{coach.title}</StyledSubtitle1>
        <StyledCaption>{coach.sport}</StyledCaption>
      </Box>
      {Boolean(coachSummary?.unread) && (
        <CoachNewMessagesLabel>
          <Typography variant="caption">
            {`${coachSummary.unread} new`}
          </Typography>
        </CoachNewMessagesLabel>
      )}
    </StyledUserItem>
  );
}

CoachItem.propTypes = {
  coach: PropTypes.object,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

export default CoachItem;
