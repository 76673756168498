import React from "react";
import { TextField } from "components/MaterialComponents";
import { useField } from "formik";

function CustomTextField({
  label,
  type,
  disabled = false,
  multiline = false,
  autoFocus = false,
  placeholder,
  rows = 1,
  rowsMax = 1,
  inputProps,
  variant = "outlined",
  setFieldValue = null,
  ...props
}) {
  const [field, meta] = useField(props);
  const { value, onChange, name, ...restField } = field;
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <TextField
      fullWidth
      style={{ marginBottom: "2em", borderRadius: "5px" }}
      variant={variant}
      size="small"
      label={label}
      type={type}
      helperText={errorText}
      error={!!errorText}
      value={value || ""}
      placeholder={placeholder}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      rowsMax={rowsMax}
      autoFocus={autoFocus}
      inputProps={inputProps}
      name={name}
      onChange={(event) => {
        setFieldValue
          ? setFieldValue(name, event.target.value)
          : onChange(event);
      }}
      {...restField}
    />
  );
}

export default CustomTextField;
