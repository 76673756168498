import styled from "styled-components";
import { Box } from "components/MaterialComponents";
import { IconButton } from "components/MaterialComponents";
import { Delete as DeleteIcon } from "components/MaterialIcons";
import * as colors from "theme/colors";

export const PersonalInfoFormContainer = styled(Box)`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
  margin-top: 3em;
  width: 60%;
  ${theme.breakpoints.down("sm")} {
    width: inherit;
  }
  `}
`;

export const Title = styled(Box)`
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
`;

export const CreateFormContainer = styled.div``;

export const CreateFieldsContainer = styled.div`
  display: flex;
`;

export const CreateButtonGroup = styled.div`
  display: flex;
  margin-bottom: 2em;
  margin-left: 10px;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 10px;

  &:hover {
    background-color: transparent;
  }
`;

export const ChildrenListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const ChildrenItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 10px 0;
`;

export const StyledButton = styled.div`
  cursor: pointer;
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  font-size: 20px;
  margin-right: 20px;
  :hover {
    color: ${colors.Red};
  }
`;
