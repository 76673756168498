import React, { useState, useCallback } from "react";
import { useLocation, useHistory } from "react-router";
import SaveFilterPopUp from "./SaveFilterPopUp";
import { Save as SaveIcon } from "components/MaterialIcons";
import Tooltip from "components/Tooltip";
import * as filterDomain from "api/domains/filter";
import useRecruitingProfile, {
  refreshRecruitingProfile,
} from "components/useRecruitingProfile";
import FilterSelect from "./FiltersSelect";
import { Box } from "components/MaterialComponents";
import isempty from "lodash.isempty";
import {
  SaveIconButton,
  SaveFilterContainer,
  StyledButton,
  StyledDeleteIcon,
} from "./style";

function SavedFilters() {
  const location = useLocation();
  const history = useHistory();
  const [anchorElSave, setanchorElSave] = useState(null);
  const [filterValue, setFiltervalue] = useState(null);

  const [{ profile: recruitingProfile }, dispatch] = useRecruitingProfile();

  const { filters, ...rest } = recruitingProfile;

  const id = !!anchorElSave ? "save-filter-button" : undefined;

  const handleSavePopoverOpen = useCallback((event) => {
    setanchorElSave(event.currentTarget);
  }, []);

  const handleSavePopoverClose = useCallback(() => {
    setanchorElSave(null);
  }, []);

  const handleSaveFilter = useCallback(
    async (name) => {
      if (!location.search) {
        return;
      }

      const body = {
        name,
        filter: location.search,
      };

      try {
        const filter = await filterDomain.saveFilter(body);
        await refreshRecruitingProfile(dispatch, {
          ...rest,
          filters: [...filters, filter],
        });
      } catch (error) {
        dispatch({ type: "update_error", error: error.errorCode });
      }
      handleSavePopoverClose();
    },
    [dispatch, filters, handleSavePopoverClose, location.search, rest],
  );

  const handleRemoveFilter = useCallback(async () => {
    try {
      await filterDomain.deleteFilter(filterValue.id);
      await refreshRecruitingProfile(dispatch, {
        ...rest,
        filters: filters.filter(
          (el) => Number(el.id) !== Number(filterValue.id),
        ),
      });
    } catch (error) {
      dispatch({ type: "update_error", error: error.errorCode });
    }
    setFiltervalue(null);
    history.push({ search: null });
  }, [dispatch, filterValue, filters, history, rest]);

  return (
    <>
      <SaveFilterContainer>
        <Tooltip title="Save current filter" arrow={true} placement="top">
          <SaveIconButton onClick={handleSavePopoverOpen}>
            <SaveIcon />
          </SaveIconButton>
        </Tooltip>
        {!isempty(filters) && (
          <Box width="200px" marginLeft="10px" marginRight="10px">
            <FilterSelect
              filters={filters}
              filterValue={filterValue}
              setFiltervalue={setFiltervalue}
            />
          </Box>
        )}
        {filterValue && (
          <Tooltip title="Remove filter" arrow={true} placement="top">
            <StyledButton onClick={handleRemoveFilter}>
              <StyledDeleteIcon />
            </StyledButton>
          </Tooltip>
        )}
      </SaveFilterContainer>
      <SaveFilterPopUp
        id={id}
        open={!!anchorElSave}
        anchorEl={anchorElSave}
        handleClose={handleSavePopoverClose}
        onSave={handleSaveFilter}
      />
    </>
  );
}

export default SavedFilters;
