import React, { useCallback } from "react";
import CheckboxOptionsList from "components/CheckboxOptionsList";

const OPTIONS = {
  Large_City: "Large City",
  Midsize_City: "Midsize City",
  Small_City: "Small City",
  Suburban: "Suburban",
  Town: "Town",
  Rural: "Rural",
};

function CampusSettingsFilter({ filter, onChange }) {
  const { campusSettings = "" } = filter;
  const campusSettingsArray = campusSettings ? campusSettings.split(",") : [];
  const handleOnChange = useCallback(
    (NewCampusSettingsArray) => {
      onChange({
        ...filter,
        campusSettings: NewCampusSettingsArray.join(","),
      });
    },
    [onChange, filter],
  );

  return (
    <CheckboxOptionsList
      value={campusSettingsArray}
      onChange={handleOnChange}
      options={OPTIONS}
    />
  );
}

export default CampusSettingsFilter;
