import React from "react";
import { Box } from "components/MaterialComponents";

import Currency from "components/Currency";
import GeneralCard from "components/GeneralCard";

import {
  CardRegularText,
  CardBoldText,
  CostTextContainer,
  CostValuesContainer,
} from "./style";

function CostCard({
  netPrice,
  tuition,
  fee,
  onCampusRoom,
  undergraduatesAid,
  refProp,
}) {
  return (
    <GeneralCard refProp={refProp} header={"Cost"}>
      <Box display="flex" mt={2}>
        <Box mr="7em">
          <CostTextContainer>
            <CardRegularText>Total Cost:</CardRegularText>
          </CostTextContainer>
          <CostTextContainer>
            <CardRegularText>Tuition:</CardRegularText>
          </CostTextContainer>
          <CostTextContainer>
            <CardRegularText>Fee:</CardRegularText>
          </CostTextContainer>
          <CostTextContainer>
            <CardRegularText>On Campus Room & Board:</CardRegularText>
          </CostTextContainer>
          <CostTextContainer>
            <CardRegularText>% Undergraduates Receiving Aid:</CardRegularText>
          </CostTextContainer>
        </Box>
        <CostValuesContainer>
          <CardBoldText>
            <Currency>{netPrice}</Currency>
          </CardBoldText>
          <CardBoldText>{tuition}</CardBoldText>
          <CardBoldText>{fee}</CardBoldText>
          <CardBoldText>{onCampusRoom}</CardBoldText>
          <CardBoldText>{undergraduatesAid}</CardBoldText>
        </CostValuesContainer>
      </Box>
    </GeneralCard>
  );
}

CostCard.defaultProps = {
  netPrice: "Not Reported",
  tuition: "Not Reported",
  fee: "Not Reported",
  onCampusRoom: "Not Reported",
  undergraduatesAid: "Not Reported",
};

export default CostCard;
