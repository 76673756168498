import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as yup from "yup";
import useSports from "components/useSports";
import { SuccessButton } from "components/Buttons";
import * as validationHelper from "helpers/validationHelper";
import SelectInput from "components/Select";
import { Search } from "components/MaterialIcons";
import { Box } from "components/MaterialComponents";
import Modal from "components/Modal";
import {
  getOptionsFromSports,
  loadOptionsCollege,
} from "helpers/coachProfileSelectHelpers";
import { Typography } from "components/MaterialComponents";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import { FormContainer } from "./style";

const validationSchema = yup.object().shape({
  college: validationHelper.college,
  sport: validationHelper.sport,
});

function UnsortedEmailsForm({ updateEmail, emailId, open, onClose }) {
  const { data: sportList, loading: sportsLoading } = useSports();
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));

  const handleFormSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const body = {
        collegeId: values.college.value,
        sportId: values.sport.value,
      };

      updateEmail(emailId, body);

      setSubmitting(false);
    },
    [emailId, updateEmail],
  );

  return (
    <Modal open={open} onClose={onClose} fluid={matchesXs} withScroll={false}>
      <FormContainer>
        <Box marginBottom="20px">
          <Typography variant="h4">Please select college and sport:</Typography>
        </Box>
        <Formik
          validateOnChange
          initialValues={{
            college: null,
            sport: null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting, values, setFieldValue, submitForm, errors }) => (
            <Form>
              <Box marginBottom="30px">
                <SelectInput
                  placeholder="Type your college name..."
                  name="college"
                  loadOptions={loadOptionsCollege}
                  async={true}
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                >
                  <Search />
                </SelectInput>
              </Box>
              <Box marginBottom="30px">
                <SelectInput
                  placeholder="Sport..."
                  name="sport"
                  options={getOptionsFromSports(sportList)}
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                  disabled={sportsLoading}
                />
              </Box>
              <Box>
                <SuccessButton type="submit" disabled={isSubmitting}>
                  Save
                </SuccessButton>
              </Box>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </Modal>
  );
}

UnsortedEmailsForm.propTypes = {
  updateEmail: PropTypes.func,
  emailId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default UnsortedEmailsForm;
