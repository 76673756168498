import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import isempty from "lodash.isempty";
import {
  SportName,
  SportNamesContainer,
  SportsContainer,
  PositionsContainer,
  Position,
  StyledStarsIcon,
} from "./style";

function AthleteSports({ sports = {} }) {
  return (
    <>
      {!isempty(sports) && (
        <SportsContainer>
          <StyledStarsIcon />
          <SportNamesContainer>
            {Object.values(sports).map((sport) => (
              <Box
                key={sport.id}
                display="flex"
                flexDirection="column"
                width="100%"
                marginBottom="5px"
              >
                <Box display="flex" alignItems="center">
                  <SportName>
                    {sport.name}
                    {sport.sportPositions && ":"}
                    {sport.sportPositions && (
                      <PositionsContainer>
                        {sport.sportPositions.map((position) => (
                          <Position key={position.id}>{position.name}</Position>
                        ))}
                      </PositionsContainer>
                    )}
                  </SportName>
                </Box>
              </Box>
            ))}
          </SportNamesContainer>
        </SportsContainer>
      )}
    </>
  );
}

AthleteSports.propTypes = {
  sports: PropTypes.object,
};

export default AthleteSports;
