import React from "react";
import { Typography } from "components/MaterialComponents";

import { NoMessagesContainer } from "./style";

function NoMessages() {
  return (
    <NoMessagesContainer>
      <Typography variant="h2">No Contacts Made at this Time</Typography>
      <Typography variant="h5">
        Please notify the help desk if your have reached out to coaches and
        contacts are not showing up
      </Typography>
    </NoMessagesContainer>
  );
}

export default NoMessages;
