import React from "react";
import styled from "styled-components";
import { Avatar } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { Popover } from "components/MaterialComponents";
import { Link } from "components/MaterialComponents";
import { Card } from "components/MaterialComponents";
import { FormControl } from "components/MaterialComponents";
import { Slider } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import { NavLink } from "react-router-dom";

import background from "assets/background.svg";
import * as colors from "theme/colors";

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-right: 29px;
`;

export const CardContainer = styled(Box)`
  display: flex;
  justify-content: center;
`;

export const StyledAvatar = styled(Avatar)`
  ${({ theme }) => `
  cursor: pointer;
  width: 45px;
  height: 45px;
  background-color: ${theme.palette.common.whisper};
  color: ${theme.palette.common.allports};
  `}
`;

export const Name = styled.div`
  padding: 0 8px;
  color: #fff;
  font-size: 13px;
  font-weight: 300;
`;

export const LoginButton = styled(Button)`
  border: solid 1px #fff;
  color: #fff;
  font-weight: 300;
`;

export const ModalHeader = styled(Box)`
  ${({ theme }) => `
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.6;
    text-align: center;
    width: 23em;
    ${theme.breakpoints.down("xs")} {
      width: 9em;
      font-size: 1rem;
    }
    ${theme.breakpoints.down("sm")} {
      font-size: 1rem;
    }
  `}
`;

export const ModalButton = styled(Button)`
  font-size: 1rem;
  &:first-child {
    margin-right: 1em;
  }
`;

export const ProfilePopover = styled(Popover)`
  & .MuiPopover-paper {
    color: #1a202c;
    font-weight: 500;
    font-size: 1rem;
  }
`;

export const EmailText = styled(Box)`
  margin-top: 5px;
  color: #a0aec0;
`;

export const StyledLink = styled(Link)`
  color: #e6e6e6;
  text-decoration: underline;
`;

export const ProfileCardContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-bottom: 85px;
  @media (max-width: 1250px) {
    margin-bottom: 20px;
  }
`;

export const StyledProfileCard = styled(Card)`
  ${({ theme }) => `
  padding-top: 61px;
  background-image: url(${background});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0;
  color: ${theme.palette.common.white};
  @media (max-width: 1250px) {
      padding-top: 30px;
    }
`}
`;

export const ModalFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1em;
`;

export const StyledFormControl = styled((props) => (
  <FormControl variant="outlined" {...props} />
))`
  width: 100%;
`;

export const SportStatFormControl = styled((props) => (
  <FormControl variant="outlined" {...props} />
))`
  min-width: 50%;
`;

export const ProfilePictureEditIconContainer = styled(Box)`
  ${({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: ${theme.palette.common.blumine};

  & .MuiSvgIcon-root {
    font-size: 1.2rem;
  }
  `}
`;

export const ProfilePictureEditContainer = styled(Box)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  transform: translate(-20px, 0);
`;

export const ProfilePictureText = styled(Box)`
  font-size: 15px;
  line-height: 32px;
  margin-left: 8px;
  text-decoration-line: underline;
`;

export const ProfilePicturePopover = styled(Popover)`
  ${({ theme }) => `
  & .MuiPopover-paper {
    padding: 15px 30px 19px 33px;
    border-radius: 4px;
    background: ${theme.palette.common.catskillWhite};
    overflow: visible;
  &::before {
    content: " ";
    position: absolute;
    border-top: 11px solid ${theme.palette.common.catskillWhite};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: 44%;
    left: -8.2%;
    transform: rotate(90deg);
  }
  }
  & .MuiBox-root {
    cursor: pointer;
    font-size: 14px;
    line-height: 23px;
    &:hover {
      color: ${theme.palette.common.allports};
    }
    &:first-child {
      margin-bottom: 4px;
    }
  }
  transform: translate(22px, 0);
  `}
`;

export const ProfileNavigationContainer = styled(Box)`
  ${({ theme }) => `
  display: flex;
  justify-content: center;
  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`}
`;

export const ProfileNavigationLink = styled((props) => (
  <NavLink
    activeStyle={{ textDecoration: "none", background: colors.Fern }}
    {...props}
  />
))`
  ${({ theme }) => `
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding: 12px 32px;
  color: ${theme.palette.common.white};
  text-transform: uppercase;
  border-radius: 0;
  &:hover {
  background: ${theme.palette.common.blumine};
  }
  @media (max-width: 1412px) {
    font-size: 11px;
    padding: 6px 16px;
  }
`}
`;

export const UploadPictureModalContainer = styled(Box)`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
`;

export const CropperContainer = styled(Box)`
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 30%;
`;

export const UploadPictureButtonsContainer = styled(Box)`
  ${({ theme }) => `
  display: flex;
  margin-top: 3em;
  ${theme.breakpoints.down("sm")} {
    margin-top: 1em;
  }
  `}
`;

export const UploadPictureSlider = styled(Slider)`
  width: 80%;
`;

export const ErrorMessage = styled(Typography)`
  color: red;
  font-size: 12px;
  margin: 0 14px;
`;
