import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { Box } from "components/MaterialComponents";
import { CircularProgress } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import PropTypes from "prop-types";
import { getOrientation } from "get-orientation/browser";

import Modal from "components/Modal";
import readFile from "helpers/readFile";
import { getCroppedImg, getRotatedImage } from "helpers/canvas";
import {
  UploadPictureModalContainer,
  CropperContainer,
  UploadPictureButtonsContainer,
  UploadPictureSlider,
} from "./style";

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

function UploadPictureModal({ open, updating, handleClose, handleUpload }) {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const file = e.target.files[0];
        let imageDataUrl = await readFile(file);

        // apply rotation if needed
        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation];
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }
        resetCropperState();
        setImageSrc(imageDataUrl);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const saveProfileImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
      );
      await handleUpload(croppedImage);
      handleCloseUploadModal();
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseUploadModal = () => {
    handleClose();
    setImageSrc(null);
    resetCropperState();
  };

  const resetCropperState = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);
  };

  return (
    <Modal open={open} onClose={handleCloseUploadModal}>
      <UploadPictureModalContainer height={imageSrc ? "40em" : "100%"}>
        {imageSrc && (
          <CropperContainer>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              cropShape="round"
              showGrid={false}
              aspect={1}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </CropperContainer>
        )}
        <UploadPictureButtonsContainer
          justifyContent={imageSrc ? "space-between" : "center"}
        >
          <Button variant="outlined" color="primary" component="label">
            Upload Photo
            <input
              type="file"
              onChange={onFileChange}
              hidden
              accept="image/*"
            />
          </Button>
          {imageSrc && (
            <Button
              variant="contained"
              color="primary"
              onClick={saveProfileImage}
              startIcon={updating && <CircularProgress size={20} />}
              disabled={updating}
            >
              Save
            </Button>
          )}
        </UploadPictureButtonsContainer>
        {imageSrc && (
          <>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="overline">Zoom</Typography>
              <UploadPictureSlider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="overline">Rotation</Typography>
              <UploadPictureSlider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </Box>
          </>
        )}
      </UploadPictureModalContainer>
    </Modal>
  );
}

UploadPictureModal.propTypes = {
  open: PropTypes.bool,
  updating: PropTypes.bool,
  handleClose: PropTypes.func,
  handleUpload: PropTypes.func,
};

export default UploadPictureModal;
