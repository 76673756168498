import React from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal";
import { ModalHeader } from "./style";

function CoachNotVerifieddModal({ open, handleClose }) {
  return (
    <Modal onClose={handleClose} open={open}>
      <ModalHeader data-testid="coach-not-verified-modal-header">
        Your data is going through verification process, as soon as it will be
        finished you will receive an activation email for your account.
      </ModalHeader>
    </Modal>
  );
}

CoachNotVerifieddModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CoachNotVerifieddModal;
