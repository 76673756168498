import React from "react";
import ParentTreeItem from "./ParentTreeItem";
import {
  StyledProfileIcon,
  StyledNavLink,
  StyledAthletesIcon,
  StyledFavoriteIcon,
  StyledContactIcon,
} from "./style";

function CoachesTree({ selected, unredMessagesCount }) {
  return (
    <>
      <StyledNavLink to="/profile/personal-information">
        <ParentTreeItem
          selected={"2" === selected}
          nodeId="2"
          name="Profile"
          icon={<StyledProfileIcon />}
        />
      </StyledNavLink>
      <StyledNavLink to="/athletes">
        <ParentTreeItem
          selected={"3" === selected}
          nodeId="3"
          name="Find Athletes"
          icon={<StyledAthletesIcon />}
        />
      </StyledNavLink>
      <StyledNavLink to="/athletes/favorite">
        <ParentTreeItem
          selected={"4" === selected}
          nodeId="4"
          name="Favorites"
          icon={<StyledFavoriteIcon />}
        />
      </StyledNavLink>
      <StyledNavLink to="/contact-activity">
        <ParentTreeItem
          selected={"5" === selected}
          nodeId="5"
          name="Contact Activity"
          icon={<StyledContactIcon />}
          unredMessagesCount={unredMessagesCount}
        />
      </StyledNavLink>
    </>
  );
}

export default CoachesTree;
