import styled from "styled-components";
import React from "react";
import { Avatar } from "components/MaterialComponents";

export const StyledAvatar = styled(({ inverted, ...rest }) => (
  <Avatar {...rest} />
))`
  ${({ theme, $inverted }) => `
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid;
  border-color: ${$inverted && theme.palette.common.allports};
  color: ${$inverted && theme.palette.common.allports};
  background-color: ${$inverted && "white"};
  font-size: 1rem;
  ${theme.breakpoints?.down("md")} {
      width: 40px;
      height: 40px;
    }
  `}
`;

export const StyledAvatarSmall = styled(Avatar)`
  ${({ theme }) => `
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid ${theme.palette.common.catskillWhite};
    font-size: 1rem;
    background-color: white;
    color: ${theme.palette.common.allports}
`}
`;
