import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { SuccessButton } from "components/Buttons";
import useProfile from "components/useProfile";
import AuthenticationDrawer from "containers/AuthenticationDrawer";
import ContactAthletePopUp from "containers/ContactAthlete";
import ContactAthletesPopover from "components/ContactAthletesPopover";
import { useProfileState } from "components/useProfile";
import { userTypesEnum } from "enums";
import { useLocation, useParams } from "react-router-dom";
import { useGet } from "components/useFetch";
import { Skeleton } from "components/MaterialComponents";
import {
  getPlayableString,
  getVideoPreviewImg,
  getVideoType,
} from "helpers/videoUrlHelper";
import defaultHudlLogo from "../../../assets/default-hudl-bg.jpg";
import useRecruitingProfile from "components/useRecruitingProfile";
import { messagesFeedbacks } from "components/useMessages/messagesFeedbacks";
import useMessages from "components/useMessages";
import { Typography } from "components/MaterialComponents";
import { CLEAR_FEEDBACK } from "components/useMessages/actionTypes";
import Feedbacker from "components/Feedbacker";
import Athletics from "./Athletics";
import Videos from "./Videos";
import Stats from "./Stats";
import Academics from "./Academics";
import Contacts from "./Contacts";
import ProfileCard from "./ProfileCard";
import {
  CardsContainer,
  CallToAction,
  ActivateMessageContainer,
} from "./style";

function inchesToFtInches(inches) {
  var feet = Math.floor(inches / 12);
  inches %= 12;
  return feet + "-" + inches;
}

function calcAgeFromDOB(dob) {
  const dateOfBirth = Date.parse(dob);
  var ageDifMs = Date.now() - dateOfBirth;
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

function checkIfValue(value) {
  return Boolean(value) === false ? "-" : value;
}

function ReadOnlyProfileView({ forCoaches = false }) {
  const { id } = useParams();
  const search = useLocation().search;
  const coachToken = new URLSearchParams(search).get("coach_token");
  const url = coachToken
    ? `/profile/${id}?coach_token=${coachToken}`
    : `/profile/${id}`;
  const [data = {}, loading] = useGet(url);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [{ isLoggedIn }] = useProfile();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { profile } = useProfileState();
  const isCoach = profile?.type === userTypesEnum.COACH;
  const isProfileVerified = Boolean(profile?.verified);

  const [selectedSport, setSelectedSport] = useState(null);
  const [anchorElMail, setanchorElMail] = useState(null);
  const [
    { feedback: messagesFeedback, coachCurrentThread },
    messagesDispatch,
  ] = useMessages();
  const [isContactModalOpen, setContactModalOpen] = useState(false);
  const [{ profile: recruitingProfile }] = useRecruitingProfile();
  const coachSports = recruitingProfile?.sports || [];
  const hasMoreThanOneSport = coachSports.length > 1;
  const anchor = !!anchorElMail ? "athlete-card-popover" : undefined;

  const toggleContactModal = useCallback(() => {
    setContactModalOpen(!isContactModalOpen);
  }, [isContactModalOpen]);

  const handleMailPopoverOpen = useCallback((event) => {
    setanchorElMail(event.currentTarget);
  }, []);

  const hasActiveMessages = Object.keys(coachCurrentThread).includes(
    String(id),
  );

  const handleMailPopoverClose = useCallback(() => {
    setanchorElMail(null);
  }, []);

  const handleSelectSport = useCallback(
    (sport) => {
      setSelectedSport(sport);
      handleMailPopoverClose();
      toggleContactModal();
    },
    [handleMailPopoverClose, toggleContactModal],
  );

  const [athleticHistory] = useGet(`/profile/athletic-history/${id}`);

  const getImg = (url) => getVideoPreviewImg(url) || defaultHudlLogo;

  const handleVideoPreviewClick = useCallback((url) => {
    const type = getVideoType(url);
    const playableString = getPlayableString(url, type);

    setCurrentVideo({ playableString, type });
    setVideoModalOpen(true);
  }, []);

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" mt="4em">
          <Skeleton variant="rect" width="55%" height="20em" />
        </Box>
      ) : (
        <ProfileCard
          data={data}
          inchesToFtInches={inchesToFtInches}
          calcAgeFromDOB={calcAgeFromDOB}
          isCoach={isCoach}
          isProfileVerified={isProfileVerified}
          hasMoreThanOneSport={hasMoreThanOneSport}
          handleMailPopoverOpen={handleMailPopoverOpen}
          toggleContactModal={toggleContactModal}
          hasActiveMessages={hasActiveMessages}
        />
      )}

      {loading ? (
        <Box display="flex" justifyContent="center" mt="4em">
          <Skeleton variant="rect" width="55%" height="20em" />
        </Box>
      ) : (
        <CardsContainer>
          <Athletics athleticHistory={athleticHistory} />
          <Videos
            data={data}
            handleVideoPreviewClick={handleVideoPreviewClick}
            videoModalOpen={videoModalOpen}
            setVideoModalOpen={setVideoModalOpen}
            currentVideo={currentVideo}
            getImg={getImg}
          />
          <Stats data={data} inchesToFtInches={inchesToFtInches} />
          {isProfileVerified && (
            <>
              <Academics data={data} checkIfValue={checkIfValue} />
              <Contacts data={data} />
            </>
          )}

          {!isProfileVerified && isLoggedIn && (
            <ActivateMessageContainer>
              <Typography>Activate account to see full profile.</Typography>
            </ActivateMessageContainer>
          )}

          {!isLoggedIn && (
            <Box mt="2em" mb="7em">
              <CallToAction>
                College Coach? Log in to see {data.firstName}'s full profile!
              </CallToAction>
              <Box textAlign="center" mt="1.3em">
                <SuccessButton onClick={() => setDrawerOpen(true)}>
                  Log in
                </SuccessButton>
                <AuthenticationDrawer
                  open={drawerOpen}
                  setOpen={setDrawerOpen}
                />
              </Box>
            </Box>
          )}
        </CardsContainer>
      )}
      {isCoach && (
        <>
          <ContactAthletesPopover
            id={anchor}
            sports={coachSports}
            open={!!anchorElMail}
            anchorEl={anchorElMail}
            handleClose={handleMailPopoverClose}
            onSportSelect={handleSelectSport}
          />
          <ContactAthletePopUp
            open={isContactModalOpen}
            onClose={toggleContactModal}
            athleteId={data?.userId}
            athleteName={`${data?.firstName} ${data?.lastName}`}
            sport={selectedSport || coachSports[0]}
          />
          <Feedbacker
            open={messagesFeedback === messagesFeedbacks.Success}
            autoHideDuration={3000}
            severity="success"
            feedbackMessage="Your message was successfully sent!"
            clearFeedback={() => messagesDispatch({ type: CLEAR_FEEDBACK })}
          />
          <Feedbacker
            open={messagesFeedback === messagesFeedbacks.Error}
            autoHideDuration={3000}
            severity="error"
            feedbackMessage="Something went wrong!"
            clearFeedback={() => messagesDispatch({ type: CLEAR_FEEDBACK })}
          />
        </>
      )}
    </>
  );
}

ReadOnlyProfileView.propTypes = {
  forCoaches: PropTypes.bool,
};

export default ReadOnlyProfileView;
