import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import RespondEmailPopover from "components/RespondEmailPopover";
import CustomTextField from "components/CustomTextField";
import { Typography } from "components/MaterialComponents";
import { SendButton, CancelButton } from "components/Buttons";
import { Box } from "components/MaterialComponents";
import * as yup from "yup";
import * as validationHelper from "helpers/validationHelper";
import { CommonEditor, MobileEditor } from "components/TextEditor";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import { useRecruitingProfileState } from "components/useRecruitingProfile";
import Select from "components/Select";
import { ReplyFormContainer, InputLabel } from "./style";

function createValidationSchema(isParentProfile) {
  return yup.object().shape({
    body: validationHelper.body,
    subject: validationHelper.subject,
    athlete: isParentProfile
      ? yup.mixed().required("Field is required")
      : yup.mixed().nullable(),
  });
}

function getAthletesOptions(athletes = []) {
  return athletes.map((athlete) => ({
    value: athlete.userId,
    label: `${athlete.firstName} ${athlete.lastName}`,
  }));
}

function ReplyEmailPopUp({
  open,
  onClose,
  subject,
  containerRef,
  onSendEmail,
}) {
  const { profile: recruitingProfile } = useRecruitingProfileState();
  const athletes = recruitingProfile?.children;
  const isParentProfile = Boolean(athletes);

  const initialValues = {
    body: "",
    subject: subject ? subject : "",
    athlete: "",
  };

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleFormSubmit = useCallback(
    async (data, { setSubmitting }) => {
      await onSendEmail(data);
      setSubmitting(false);
      onClose();
    },
    [onSendEmail, onClose],
  );

  return (
    <RespondEmailPopover open={open} onClose={onClose} anchorEl={containerRef}>
      {!subject && <Typography>Create new message</Typography>}
      <ReplyFormContainer>
        {subject && <InputLabel>{subject}</InputLabel>}
        <Formik
          validateOnChange
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={createValidationSchema(isParentProfile)}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              {isParentProfile && (
                <Box width="70%" marginBottom="10px">
                  <Select
                    name="athlete"
                    placeholder="Message regarding"
                    options={getAthletesOptions(athletes)}
                    setFieldValue={setFieldValue}
                    value={values.athlete}
                  />
                </Box>
              )}
              {!subject && (
                <Box width="70%" marginTop="20px">
                  <CustomTextField
                    placeholder="Subject"
                    name="subject"
                    type="text"
                  />
                </Box>
              )}
              {matchesSm ? (
                <MobileEditor
                  name="body"
                  setFieldValue={setFieldValue}
                  placeholder="Your Message"
                />
              ) : (
                <CommonEditor
                  name="body"
                  setFieldValue={setFieldValue}
                  placeholder="Your Message"
                />
              )}
              <Box display="flex" justifyContent="space-between">
                <SendButton type="submit" disabled={isSubmitting}>
                  Send
                </SendButton>
                <CancelButton onClick={onClose}>Cancel</CancelButton>
              </Box>
            </Form>
          )}
        </Formik>
      </ReplyFormContainer>
    </RespondEmailPopover>
  );
}

ReplyEmailPopUp.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  containerRef: PropTypes.object,
  subject: PropTypes.string,
  onSendEmail: PropTypes.func,
};

export default ReplyEmailPopUp;
