import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import GeneralCard from "components/GeneralCard";
import HistoryListItem from "containers/AthleticHistory/HistoryListitem";

function Athletics({ athleticHistory }) {
  return (
    <GeneralCard header="Athletic">
      <Box display="flex" flexDirection="column" mt={2}>
        {athleticHistory ? (
          <>
            {athleticHistory.map((history) => (
              <HistoryListItem
                key={history.id}
                item={history}
                allowedToChange={false}
              />
            ))}
          </>
        ) : null}
      </Box>
    </GeneralCard>
  );
}

Athletics.propTypes = {
  athleticHistory: PropTypes.array,
};

export default Athletics;
