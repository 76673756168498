import { useState, useEffect } from "react";

const useFetch = (url, { method = "GET", body } = {}) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    let isMounted = true;
    setError(null);
    const abortController = new AbortController();

    (async () => {
      setLoading(true);
      const response = await fetch("/api/v2x" + url, {
        method,
        body,
        headers: { "Content-Type": "application/json" },
        signal: abortController.signal,
      }).catch((err) => {
        err.code !== 20 && setError(err);
      });

      if (response && isMounted) {
        if (response.status >= 400 && response.status < 600) {
          setError(response);
        } else {
          setData(await response.json());
        }
      }
      setLoading(false);
    })();

    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [url, method, body]);

  return [data, loading, error];
};

function useGet(url, request) {
  return useFetch(url, { method: "GET", ...request });
}

function usePost(url, body = {}, request) {
  return useFetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    ...request,
  });
}

export default useFetch;

export { useGet, usePost };
