import React, { useCallback } from "react";
import PropTypes from "prop-types";
import FilterPopper from "components/FilterPopper";
import StatPositionText from "./StatPositionText";
import StatPositionSelect from "./StatPositionSelect";
import { SportStatsContainer } from "./style";

function PositionsFilterPopper({ filter, onChange, stats, sportId }) {
  const onResetFilter = useCallback(() => {
    onChange({
      ...filter,
      sportStats: null,
      sportStatsSelect: null,
    });
  }, [onChange, filter]);

  return (
    <FilterPopper
      anchor="Sport Stats"
      filterTitle="Filter by Sport Stats"
      resetFilter={onResetFilter}
      isData={Boolean(stats && sportId)}
      testId="stats-filter-coach"
    >
      <SportStatsContainer>
        {stats?.map((stat) => {
          if (stat.inputType === "RECORD") {
            return null;
          }
          if (stat.inputType === "SELECT") {
            return (
              <StatPositionSelect
                key={stat.id}
                option={stat}
                filter={filter}
                onChange={onChange}
              />
            );
          }

          return (
            <StatPositionText
              key={stat.id}
              option={stat}
              filter={filter}
              onChange={onChange}
            />
          );
        })}
      </SportStatsContainer>
    </FilterPopper>
  );
}

PositionsFilterPopper.propTypes = {
  stats: PropTypes.array,
  filter: PropTypes.object,
  onChange: PropTypes.func,
  sportId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PositionsFilterPopper;
