import React, { useReducer } from "react";

import { ProfileStateContext, ProfileDispatchContext } from "./Context";
import profileReducer, { initialState } from "./reducer";

function ProfileProvider({ children, testState }) {
  const [state, dispatch] = useReducer(profileReducer, initialState);

  return (
    <ProfileStateContext.Provider value={testState ? testState : state}>
      <ProfileDispatchContext.Provider value={dispatch}>
        {children}
      </ProfileDispatchContext.Provider>
    </ProfileStateContext.Provider>
  );
}

export default ProfileProvider;
