import React from "react";
import PropTypes from "prop-types";
import { Button } from "components/MaterialComponents";
import Modal from "components/Modal";
import { Subtitle } from "components/Typography";
import { ButtonsBlock } from "./style";

function ConfirmDeleteModal({ open, onClose, onDelete, modalText }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Subtitle>{modalText}</Subtitle>
      <ButtonsBlock>
        <Button
          onClick={onDelete}
          variant="contained"
          color="primary"
          size="large"
        >
          Confirm
        </Button>
        <Button
          onClick={onClose}
          variant="outlined"
          color="secondary"
          size="large"
        >
          Cancel
        </Button>
      </ButtonsBlock>
    </Modal>
  );
}

ConfirmDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ConfirmDeleteModal;
