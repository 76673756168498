import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { TableRow } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import SportsListPopup from "./sports/SportsListPopup";
import CreateEditCollegePopup from "./CreateEditCollegePopup";
import ConfirmDeleteModal from "../ConfirmDeleteModal";

function CollegeTableRow({
  college,
  onDelete,
  onEdit,
  onAddSports,
  onDeleteSport,
}) {
  const [isSportModalOpen, setSportModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const toggleSportsCollapse = useCallback(() => {
    setSportModalOpen(!isSportModalOpen);
  }, [isSportModalOpen]);

  const toggleEditModal = useCallback(() => {
    setEditModalOpen(!isEditModalOpen);
  }, [isEditModalOpen]);

  const toggleDeleteModal = useCallback(() => {
    setDeleteModalOpen(!isDeleteModalOpen);
  }, [isDeleteModalOpen]);

  return (
    <>
      <TableRow key={college.unitId}>
        <TableCell>{college.schoolName}</TableCell>
        <TableCell>{college.city}</TableCell>
        <TableCell>{college.acceptanceRate}</TableCell>
        <TableCell>{college.act}</TableCell>
        <TableCell>{college.actRange}</TableCell>
        <TableCell>{college.campusSettingSize}</TableCell>
        <TableCell>{college.campusSettingType}</TableCell>
        <TableCell>{college.division}</TableCell>
        <TableCell>{college.divisionAbbr}</TableCell>
        <TableCell>{college.graduationRate}</TableCell>
        <TableCell>{college.hsGPA}</TableCell>
        <TableCell>{college.logoUrl}</TableCell>
        <TableCell>{college.netPrice}</TableCell>
        <TableCell>{college.publicOrPrivate}</TableCell>
        <TableCell>{college.sanctionName}</TableCell>
        <TableCell>{college.sat}</TableCell>
        <TableCell>{college.satRange}</TableCell>
        <TableCell>{college.schoolUrl}</TableCell>
        <TableCell>{college.sportsUrl}</TableCell>
        <TableCell>{college.state}</TableCell>
        <TableCell>{college.totalEnrollment}</TableCell>
        <TableCell>{college.undergradEnrollment}</TableCell>
        <TableCell>{college.usnewsUrl}</TableCell>
        <TableCell>{college.search}</TableCell>
        <TableCell>
          <Button color="primary" onClick={toggleSportsCollapse}>
            Sports
          </Button>
        </TableCell>
        <TableCell>
          <Button color="primary" onClick={toggleEditModal}>
            Edit
          </Button>
        </TableCell>
        <TableCell>
          <Button color="secondary" onClick={toggleDeleteModal}>
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <SportsListPopup
        open={isSportModalOpen}
        onClose={toggleSportsCollapse}
        sports={college.sports}
        onAddSports={onAddSports}
        onDeleteSport={onDeleteSport}
        college={college}
      />
      <CreateEditCollegePopup
        open={isEditModalOpen}
        onClose={toggleEditModal}
        onEditCollege={onEdit}
        item={college}
      />
      <ConfirmDeleteModal
        open={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        onDelete={() => onDelete(college.unitId)}
        modalText="Are you sure you want to delete all college data?"
      />
    </>
  );
}

CollegeTableRow.propTypes = {
  college: PropTypes.object,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onAddSports: PropTypes.func,
  onDeleteSport: PropTypes.func,
};

export default CollegeTableRow;
