import React from "react";
import PropTypes from "prop-types";
import { Grid } from "components/MaterialComponents";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";

import AthletesCard from "containers/FindAthletes/AthletesCard";

function ItemsResults({ items, onItemRemoved, onItemModified }) {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matches1150 = useMediaQuery("(max-width:1150px)");

  const itemMd = matches1150 ? 6 : 4;
  const style = { width: matchesSm ? "100%" : "inherit" };
  return (
    items &&
    items.map((item) => (
      <Grid item lg={4} md={itemMd} sm={12} key={item.userId} style={style}>
        <AthletesCard
          {...item}
          onItemRemoved={onItemRemoved}
          onItemModified={onItemModified}
        />
      </Grid>
    ))
  );
}

ItemsResults.propTypes = {
  items: PropTypes.array,
  onItemRemoved: PropTypes.func,
};

export default React.memo(ItemsResults);
