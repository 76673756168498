import React from "react";
import styled from "styled-components";
import { Typography } from "components/MaterialComponents";
import { NavLink } from "react-router-dom";
import { Button } from "components/MaterialComponents";
import { TableRow } from "components/MaterialComponents";
import { TableHead } from "components/MaterialComponents";
import * as colors from "theme/colors";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TabsContainer = styled.div`
  display: flex;
`;

export const HeadersContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const CoachesContainer = styled.div`
  width: 90%;
  background-color: white;
  margin-top: 20px;
  padding: 20px;
`;

export const CoacheItemContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding: 20px 10px;
  justify-content: space-between;

  :not(:last-child) {
    border-bottom: 1px solid black;
  }
`;

export const SportItem = styled(Typography)`
  margin-right: 10px;
`;

export const StyledTabLink = styled((props) => (
  <NavLink style={{ textDecoration: "none" }} {...props} />
))`
  text-decoration: none;
`;

export const StyledTabButton = styled(({ active, ...rest }) => (
  <Button disableRipple {...rest} />
))`
  ${({ $active, theme }) => `
  padding: 12px 32px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${theme.palette.common.allports};
  border-radius: 0;
  border-bottom: ${
    $active
      ? `3px solid ${theme.palette.common.allports}`
      : `3px solid transparent`
  };
  &:disabled, &:hover {
    background-color: inherit;
  }
  `}
`;

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-top: 60px;
`;

export const FormModalHeader = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  padding: 15px 40px;
  box-sizing: border-box;
  border-bottom: 2px solid ${colors.AthensGray};
`;

export const StyledTableRowPositions = styled(TableRow)`
  background-color: ${colors.LightYellow};
`;

export const StyledTableRowStats = styled(TableRow)`
  background-color: ${colors.Tizzy};
`;

export const StyledTableHead = styled(TableHead)`
  background-color: ${colors.AthensGrayDark};
`;

export const StyledTableRowOptions = styled(TableRow)`
  background-color: ${colors.PattensBlue};
`;

export const CoachesFilterContainer = styled.div`
  display: flex;
  width: 50%;
  height: 50px;
  padding: 50px;
  padding-top: 20px;
  padding-bottom: 0;
`;

export const SportTableHeadContainer = styled.div`
  width: 700px;
  display: flex;
  align-items: center;
  padding: 10px;
`;
