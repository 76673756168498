import styled from "styled-components";
import { TextField } from "components/MaterialComponents";

export const StyledInput = styled(TextField)`
  width: 100%;
  background-color: transparent;

  .MuiInputBase-input {
    color: black;
  }

  .MuiInput-underline::before {
    border-color: ${(props) => props.disabled && "transparent"};
  }
`;

export const InputContainer = styled.div`
  margin-right: 20px;
  width: 80%;
`;
