export const tabHeadersCoach = [
  {
    id: 2,
    link: "/profile/personal-information",
    name: "Profile",
  },
  {
    id: 3,
    link: "/athletes",
    name: "Athletes",
  },
  {
    id: 4,
    link: "/athletes/favorite",
    name: "Favorites",
  },
  {
    id: 5,
    link: "/contact-activity",
    name: "Contacts",
  },
];
