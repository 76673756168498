import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { InputLabel } from "components/MaterialComponents";
import { MenuItem } from "components/MaterialComponents";
import { IconButton } from "components/MaterialComponents";

import getSportStatsWithAnswers from "./getSportStatsWithAnswers";
import {
  getSportPrimaryInputLabel,
  getSportSecondaryInputLabel,
  getSportOtherInputLabel,
} from "helpers/getSportSpecificFormText";
import { StyledFormControl } from "../style";
import { StyledDeleteIcon, StyledSelect } from "./style";

function PositionSelect({
  position,
  index,
  errors,
  currentTabSportPositions,
  sportPositions,
  sportStatsWithAnswers,
  sportStatsDefinitions,
  currentSportName,
  setValues,
}) {
  const getInputLabel = (index) => {
    switch (index) {
      case 0:
        return getSportPrimaryInputLabel(currentSportName);
      case 1:
        return getSportSecondaryInputLabel(currentSportName);
      case 2:
        return getSportOtherInputLabel(currentSportName);
      default:
        break;
    }
  };

  const updateSportStatsAnswers = useCallback(
    (newSportPositions) => {
      const sportStatsAnswers = sportStatsWithAnswers.map(
        ({ answer }) => answer,
      );
      const newSportStatsAnswers = getSportStatsWithAnswers(
        newSportPositions,
        sportStatsAnswers,
        sportStatsDefinitions,
      );

      return newSportStatsAnswers;
    },
    [sportStatsDefinitions, sportStatsWithAnswers],
  );

  const setNewValues = useCallback(
    (newSportPositions) => {
      const newSportStatsAnswers = updateSportStatsAnswers(newSportPositions);
      setValues({
        sportPositions: newSportPositions,
        sportStatsWithAnswers: newSportStatsAnswers,
      });
    },
    [setValues, updateSportStatsAnswers],
  );

  const handleChange = useCallback(
    ({ target: { value } }, index) => {
      let newSportPositions = [...sportPositions];
      newSportPositions[index] = value;
      setNewValues(newSportPositions);
    },
    [setNewValues, sportPositions],
  );

  const handleRemove = useCallback(
    (index) => {
      const newSportPositions = sportPositions.filter((_, i) => i !== index);
      setNewValues(newSportPositions);
    },
    [setNewValues, sportPositions],
  );

  const labelId = `position-${index}`;
  const label = getInputLabel(index);
  const error = errors && errors[index];

  return (
    <StyledFormControl error={!!error}>
      <Box display="flex">
        <InputLabel id={labelId}>{label}</InputLabel>
        <StyledSelect
          labelId={labelId}
          label={label}
          value={position || ""}
          renderValue={(item) => item.name}
          onChange={(e) => handleChange(e, index)}
        >
          {currentTabSportPositions.map((position) => (
            <MenuItem key={position.id} value={position}>
              {position.name}
            </MenuItem>
          ))}
        </StyledSelect>
        <Box width="50px" marginLeft="10px">
          <IconButton onClick={() => handleRemove(index)}>
            <StyledDeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </StyledFormControl>
  );
}

PositionSelect.propTypes = {
  position: PropTypes.object,
  index: PropTypes.number,
  errors: PropTypes.array,
  currentSportPositons: PropTypes.array,
  sportPositions: PropTypes.array,
  sportStatsWithAnswers: PropTypes.array,
  sportStatsDefinitions: PropTypes.array,
  setValues: PropTypes.func,
  currentSportName: PropTypes.string,
};

export default PositionSelect;
