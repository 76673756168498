import styled from "styled-components";
import { Box } from "components/MaterialComponents";
import { Avatar } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import { TableRow } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { IconButton } from "components/MaterialComponents";
import * as colors from "theme/colors";

export const MainMessagesContainer = styled.div``;

export const CardContainer = styled(Box)`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    margin-top: 2em;

    ${theme.breakpoints.down("sm")} {
      margin: 0;
    }
  `}
`;

export const Name = styled(Typography)`
  display: block;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  color: ${colors.Allports};
`;

export const NoMessagesContainer = styled(Box)`
  color: ${colors.BrightGray};
`;

export const ThreadContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const SelectSection = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 82vh;
    overflow-y: auto;
    border-right: 1px solid ${colors.AthensGrayDark};

    ${theme.breakpoints.down("sm")} {
      width: 100%;
    }
  `}
`;

export const StyledUserItem = styled.div`
  position: relative;
  display: flex;
  padding: 15px;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? colors.AthensGray : "white"};
  transition: all 0.3s;
  border-top: 1px solid ${colors.AthensGray};

  :last-child {
    border-bottom: 1px solid ${colors.AthensGray};
  }

  :hover {
    background-color: ${colors.AthensGray};
  }
`;

export const StyledMessageCard = styled.div`
  ${({ theme }) => `
    margin-left: 10px;
    margin-right: 10px;
    width: 90%;

    ${theme.breakpoints.down("sm")} {
      width: 80%;
    }
  `}
`;

export const MessageSubtitles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 20px;
  padding-bottom: 5px;
  width: 100%;
`;

export const StyledMessageBody = styled.div`
  padding: 15px;
  border-radius: 5px;
  background-color: white;
  font-weight: ${(props) => (props.unread ? "bold" : "normal")};
`;

export const MessagesSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 75%;
  background-color: white;
`;

export const MessagesListContainer = styled.div`
  ${({ theme, $withSummary }) => `
    display: flex;
    flex-direction: column;
    height:${$withSummary ? "60vh" : "67vh"};
    overflow-y: auto;
    padding: 20px;
    margin: 20px;
    color: ${colors.BrightGray};
    border: 1px solid ${colors.AthensGrayDark};
    background-color: ${colors.AthensGray};

    ${theme.breakpoints.down("sm")} {
      padding: 5px;
      margin: 0;
      width: 95%;
      height: 100%;
      padding-bottom: 75px;
    }
  `}
`;

export const MessagesListContainerCoach = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    height:68vh;
    overflow-y: auto;
    padding: 20px;
    margin: 20px;
    color: ${colors.BrightGray};
    border: 1px solid ${colors.AthensGrayDark};
    background-color: ${colors.AthensGray};

    ${theme.breakpoints.down("sm")} {
      padding: 5px;
      margin: 0;
      width: 95%;
      height: 100%;
      padding-bottom: 75px;
    }
  `}
`;

export const CoachSummaryContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.AthensGrayDark};
`;

export const CoachSummaryItem = styled.div`
  display: flex;
  width: 25%;
  padding: 20px;
  text-transform: uppercase;
  color: ${colors.Allports};
  font-size: 16px;
  border-right: 1px solid ${colors.AthensGrayDark};
`;

export const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.$isSelected ? colors.Fern : colors.Allports};
  transition: all 0.1s;
`;

export const StyledLogoSmall = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.$isSelected ? colors.Fern : colors.Allports};
  transition: all 0.3s;
`;

export const IconContainer = styled.div`
  width: 80%;
  height: 80%;
`;

export const MessageContainer = styled.div`
  ${({ theme, $sent }) => `
    display: flex;
    align-items: flex-end;
    width: 70%;
    margin-top: 25px;
    align-self: ${$sent ? "flex-end" : "flex-start"};

    :first-child {
      margin-top: 10px;
    }

    ${theme.breakpoints.down("sm")} {
      width: 100%;
    }
  `}
`;

export const StyledAvatar = styled(Avatar)`
  width: 45px;
  height: 45px;
  color: ${colors.Whisper};
  background-color: ${colors.Allports};
`;

export const StyledTitle = styled(Typography)`
  font-size: 16px;
  text-transform: uppercase;
  color: ${colors.Allports};
`;

export const StyledSubtitle1 = styled(Typography)`
  line-height: 16px;
  color: ${colors.BrightGray};
`;

export const StyledCaption = styled(Typography)`
  color: ${colors.BrightGray};
  font-size: 14px;
`;

export const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  background-color: ${(props) =>
    props.$isHover ? colors.AthensGray : "white"};
  height: 65px;
`;

export const StyledHeadRow = styled(TableRow)`
  background-color: ${colors.AthensGray};
`;

export const StyledHeadCell = styled(TableCell)`
  font-size: 14px;
  text-transform: uppercase;
  color: ${colors.Allports};
`;

export const BackButton = styled(Button)`
  font-size: 16px;
  margin-right: 10px;
`;

export const StyledHeadline = styled(Typography)`
  font-size: 24px;
`;

export const ActionsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const AddButtonContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    padding: 0 20px;
    margin-bottom: 15px;

    ${theme.breakpoints.down("sm")} {
      margin-bottom: 0;
    }
  `}
`;

export const ReplyFormContainer = styled.div``;

export const InputLabel = styled.div`
  ${({ theme }) => `
    margin-top: 10px;
    margin-bottom: 10px;

    ${theme.breakpoints.down("sm")} {
      font-size: 14px;
    }
  `}
`;

export const RespondButtonContainer = styled.div`
  ${({ theme }) => `
    margin-left: 5%;
    margin-top: 10px;

    ${theme.breakpoints.down("sm")} {
      margin-left: 15%;
    }
  `}
`;

export const MobileModalHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
  z-index: 10;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const MobileModalFooter = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  width: 100%;
  z-index: 10;
  height: 70px;
`;

export const UnsortedEmailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-top: 20px;
`;

export const UnsortedEmailItem = styled.div`
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.CatskillWhite};
`;

export const UnsortedEmaiHeaderContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    ${theme.breakpoints.down("sm")} {
      flex-direction: column;
    }
  `}
`;

export const UnsortedEmaiBodyContainer = styled.div`
  padding: 20px;
  background-color: white;
  border-radius: 10px;
`;

export const FormContainer = styled.div`
  ${({ theme }) => `
    width: 100%;

    ${theme.breakpoints.down("sm")} {
      padding-top: 15px;
      width: 90%;
    }
  `}
`;

export const ModalBody = styled.div`
  width: 600px;
  background-color: white;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchMenuContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 250px;
`;

export const GoBottomButtonContainer = styled.div`
  position: absolute;
  bottom: 120px;
  right: 40px;
`;

export const StyledIconButton = styled(IconButton)`
  background-color: ${colors.Allports};
  color: ${colors.AthensGray};

  :hover {
    background-color: ${colors.Allports};
    color: ${colors.AthensGray};
  }
`;

export const SearchButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 40px;
`;

export const CoachNewMessagesLabel = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  background-color: ${colors.Allports};
  color: ${colors.AthensGray};
  padding: 5px;
  border-radius: 5px;
`;
