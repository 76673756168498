import React, { useState, useEffect, useCallback } from "react";
import HorizontalLine from "components/HorizontalLine";
import CreateEditePopUp from "./CreateEditePopUp";
import useAthleticHistory from "components/useAthleticHistory";
import { getUserHistory } from "components/useAthleticHistory";
import HistoryListItem from "./HistoryListitem";
import Feedbacker from "components/Feedbacker";
import * as actionTypes from "components/useRecruitingProfile/actionTypes";
import {
  ListContainer,
  GeneralCard,
  CardHeader,
  AddButton,
  StyledHeader,
} from "./style";

function AthleticHistory() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [{ history, loading, feedback }, dispatch] = useAthleticHistory();

  useEffect(() => {
    getUserHistory(dispatch);
  }, [dispatch]);

  const toggleModalOpen = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  return (
    <>
      <StyledHeader>Athletic history</StyledHeader>
      <HorizontalLine />
      <GeneralCard>
        <CardHeader>
          <AddButton onClick={toggleModalOpen} data-testid="add-button">
            + Add experience
          </AddButton>
        </CardHeader>
        <HorizontalLine />
        {!loading && (
          <ListContainer>
            {history.map((item) => (
              <HistoryListItem key={item.id} item={item} />
            ))}
          </ListContainer>
        )}
      </GeneralCard>
      <CreateEditePopUp open={isModalOpen} onClose={toggleModalOpen} />

      <Feedbacker
        open={Boolean(feedback)}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage={feedback}
        clearFeedback={() => dispatch({ type: actionTypes.CLEAR_FEEDBACK })}
      />
    </>
  );
}

export default AthleticHistory;
