import * as actionTypes from "./actionTypes";

const initialState = {
  notes: {},
  loading: true,
  error: null,
};

function notesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SUCCESS: {
      return {
        ...state,
        notes: action.data,
        loading: false,
        error: null,
      };
    }
    case actionTypes.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default notesReducer;

export { initialState };
