import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import Modal from "components/Modal";
import CustomTextField from "components/CustomTextField";
import * as validationHelper from "helpers/validationHelper";
import SelectInput from "components/Select";
import { Search } from "components/MaterialIcons";
import { Typography } from "components/MaterialComponents";
import {
  getOptionsFromSports,
  loadOptionsCollege,
} from "helpers/coachProfileSelectHelpers";
import useSports from "components/useSports";

import { FormContainer, FormModalHeader, ButtonsBlock } from "../style";

const validationSchema = yup.object().shape({
  firstName: validationHelper.firstName.required("First Name is required"),
  lastName: validationHelper.lastName.required("Last Name is required"),
  college: validationHelper.college,
  phoneNumber: yup.string().nullable(),
  contactEmail: yup.string().nullable(),
  coachTitle: yup.string().nullable(),
});

function CreateEditCoachPopup({
  open,
  onClose,
  onCreateCoach,
  onEditCoach,
  item,
}) {
  const { data: sportList, loading: sportsLoading } = useSports();

  const handleFormSubmit = useCallback(
    async (values) => {
      const name = `${values.firstName} ${values.lastName}`;
      const collegeId = values.college.value;

      if (item) {
        const body = {
          name: name,
          unitSport: `${collegeId}-${values.sports.value}`,
          phoneNumber: values.phoneNumber,
          email: values.contactEmail,
          title: values.coachTitle,
          sportId: values.sports.value,
          pageUrl: values.pageUrl,
          selector: values.selector,
          resourceLoaded: values.resourceLoaded,
          selectorValid: values.selectorValid,
          emailValid: values.emailValid,
          coachNameOnPage: values.coachNameOnPage,
          emailOnPage: values.emailOnPage,
        };
        await onEditCoach(item.id, body);
        onClose();
        return;
      }

      const selectedSportIds = values.sports.map((sport) => sport.value);
      const unitSports = selectedSportIds.map(
        (sportId) => `${collegeId}-${sportId}`,
      );

      const body = {
        name: name,
        unitSports: unitSports,
        phoneNumber: values.phoneNumber,
        email: values.contactEmail,
        title: values.coachTitle,
        pageUrl: values.pageUrl,
        selector: values.selector,
      };
      await onCreateCoach(body);
      onClose();
    },
    [item, onClose, onCreateCoach, onEditCoach],
  );

  const header = item ? "EDIT COACH INFO" : "ADD NEW COLLEGE COACH";

  return (
    <Modal open={open} onClose={onClose}>
      <FormModalHeader>
        <Typography variant="h4">{header}</Typography>
      </FormModalHeader>
      <Formik
        validateOnChange
        initialValues={{
          firstName: item ? item.name.split(" ")[0] : "",
          lastName: item ? item.name.split(" ")[1] : "",
          college: item
            ? {
                value: item.college_sport.college.unitId,
                label: item.college_sport.college.schoolName,
              }
            : null,
          sports: item
            ? {
                value: item.college_sport.sport.id,
                label: item.college_sport.sport.name,
              }
            : [],
          contactEmail: item ? item.email : "",
          phoneNumber: item ? item.phone : "",
          coachTitle: item ? item.title : "",
          pageUrl: item ? item.pageUrl : "",
          selector: item ? item.selector : "",
          resourceLoaded: item ? Boolean(item.resourceLoaded) : null,
          selectorValid: item ? Boolean(item.selectorValid) : null,
          emailValid: item ? Boolean(item.emailValid) : null,
          coachNameOnPage: item ? Boolean(item.coachNameOnPage) : null,
          emailOnPage: item ? Boolean(item.emailOnPage) : null,
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <FormContainer>
              <CustomTextField label="First Name" name="firstName" />
              <CustomTextField label="Last Name" name="lastName" />
              <Box marginBottom="30px">
                <SelectInput
                  placeholder="Type your college name..."
                  name="college"
                  loadOptions={loadOptionsCollege}
                  async={true}
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                >
                  <Search />
                </SelectInput>
              </Box>
              <Box marginBottom="30px">
                <SelectInput
                  placeholder="Sports..."
                  name="sports"
                  options={getOptionsFromSports(sportList)}
                  setFieldValue={setFieldValue}
                  values={values}
                  isMulti={item ? false : true}
                  errors={errors}
                  isDisabled={sportsLoading}
                />
              </Box>
              <CustomTextField label="Coach Title" name="coachTitle" />
              <CustomTextField label="Contact Email" name="contactEmail" />
              <CustomTextField label="Phone Number" name="phoneNumber" />
              <CustomTextField label="Page Url" name="pageUrl" />
              <CustomTextField label="Selector" name="selector" />
              {item && item.resourceLoaded !== null && (
                <Box width="50%">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography>Resource loaded:</Typography>
                    <Field type="checkbox" name="resourceLoaded" />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography>Selector valid:</Typography>
                    <Field type="checkbox" name="selectorValid" />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography>Selector content equals email:</Typography>
                    <Field type="checkbox" name="emailValid" />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography>Coach name on page:</Typography>
                    <Field type="checkbox" name="coachNameOnPage" />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography>Email on page:</Typography>
                    <Field type="checkbox" name="emailOnPage" />
                  </Box>
                </Box>
              )}
              <ButtonsBlock>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Save
                </Button>
                <Button
                  onClick={onClose}
                  variant="outlined"
                  color="secondary"
                  size="large"
                >
                  Cancel
                </Button>
              </ButtonsBlock>
            </FormContainer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

CreateEditCoachPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreateCoach: PropTypes.func,
  onEditCoach: PropTypes.func,
};

export default CreateEditCoachPopup;
