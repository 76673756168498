import React from "react";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import GeneralCard from "components/GeneralCard";
import { useRecruitingProfileState } from "components/useRecruitingProfile";
import DynamicForm from "./DynamicForm";
import SportTabs from "./SportTabs";
import { Headline } from "components/Typography";
import { CardContainer } from "../style";

function AthleticPerformance() {
  const { path, url, isExact } = useRouteMatch();
  const { profile, loading } = useRecruitingProfileState();

  if (loading) return null;

  const { sports } = profile;

  if (!Object.keys(sports).length) {
    return (
      <>
        <Headline>Not Selected</Headline>
        <Box ml="3em">
          <Typography variant="h5">
            Please choose Primary Sport and/or Secondary Sport in{" "}
            <b>Personal Information</b> of your profile.
          </Typography>
        </Box>
      </>
    );
  }

  const { primary, secondary, tertiary } = sports;

  if (isExact) {
    const primarySport = primary ? "primary" : null;
    const secondarySport = secondary ? "secondary" : null;
    const tertiarySport = tertiary ? "tertiary" : null;
    const activeSport = primarySport || secondarySport || tertiarySport;
    return (
      <Redirect
        to={`${url}/${activeSport}-sport/${sports[activeSport]["id"]}`}
      />
    );
  }

  const athleticPerformanceSwitch = (
    <Switch>
      {primary && (
        <Route path={`${path}/primary-sport/:id`}>
          <DynamicForm />
        </Route>
      )}
      {secondary && (
        <Route path={`${path}/secondary-sport/:id`}>
          <DynamicForm />
        </Route>
      )}
      {tertiary && (
        <Route path={`${path}/tertiary-sport/:id`}>
          <DynamicForm />
        </Route>
      )}
    </Switch>
  );

  return (
    <CardContainer>
      <GeneralCard header="Athletic Performance">
        <Box mt="3em">
          <SportTabs
            url={url}
            path={path}
            primary={primary}
            secondary={secondary}
            tertiary={tertiary}
          />
          {athleticPerformanceSwitch}
        </Box>
      </GeneralCard>
    </CardContainer>
  );
}

export default AthleticPerformance;
