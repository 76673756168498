import React, { useState, useCallback } from "react";
import Feedbacker from "components/Feedbacker";
import GeneralCard from "components/GeneralCard";
import useRecruitingProfile, {
  recruitingProfileFeedbacks,
  addChildrenToParentProfile,
  removeChildrenFromParentProfile,
} from "components/useRecruitingProfile";
import useClearFeedback from "customHooks/useClearFeedback";
import { CardContainer } from "../style";
import * as actionTypes from "components/useRecruitingProfile/actionTypes";
import AddChildrenForm from "./AddChildrenForm";
import { Button } from "components/MaterialComponents";
import { Add as AddIcon } from "components/MaterialIcons";
import ChildrenList from "./ChildrenList";
import { Box } from "components/MaterialComponents";
import { Title } from "./style";

function ParentChildrenInformation() {
  const [
    { feedback, loading, updateError, profile },
    dispatch,
  ] = useRecruitingProfile();

  const [addChildrenMode, setAddChldrenMode] = useState(false);

  const toggleAddChildrenMode = useCallback(() => {
    setAddChldrenMode(!addChildrenMode);
  }, [addChildrenMode]);

  const handleAddChildren = useCallback(
    async (email) => {
      await addChildrenToParentProfile(dispatch, email);
      toggleAddChildrenMode();
    },
    [dispatch, toggleAddChildrenMode],
  );

  const handleRemoveChildren = useCallback(
    (childId) => {
      removeChildrenFromParentProfile(dispatch, childId);
    },
    [dispatch],
  );

  useClearFeedback(dispatch);

  if (loading) {
    return null;
  }

  return (
    <>
      <CardContainer>
        <GeneralCard header="Children Information">
          <Title mb="0.5em" mt="1em">
            Children have profiles?
          </Title>
          <ChildrenList
            athletes={profile.children}
            removeChildren={handleRemoveChildren}
          />
          <Box height="50px">
            {addChildrenMode ? (
              <AddChildrenForm
                closeForm={toggleAddChildrenMode}
                addChildren={handleAddChildren}
              />
            ) : (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={toggleAddChildrenMode}
              >
                Add here
              </Button>
            )}
          </Box>
        </GeneralCard>
      </CardContainer>
      <Feedbacker
        open={feedback === recruitingProfileFeedbacks.Updated}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage={feedback}
        clearFeedback={() => dispatch({ type: actionTypes.CLEAR_FEEDBACK })}
      />
      <Feedbacker
        open={Boolean(updateError)}
        autoHideDuration={3000}
        severity="error"
        feedbackMessage={feedback}
        clearFeedback={() => dispatch({ type: actionTypes.CLEAR_FEEDBACK })}
      />
    </>
  );
}

export default ParentChildrenInformation;
