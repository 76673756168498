import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Grid } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";

import { MailOutline as MailOutlineIcon } from "components/MaterialIcons";
import { ThumbUpAltOutlined as ThumbUpAltOutlinedIcon } from "components/MaterialIcons";
import { NoteOutlined as NoteOutlinedIcon } from "components/MaterialIcons";

import useStates from "components/useStates";
import Currency from "components/Currency";
import LargeCollegeLogo from "components/LargeCollegeLogo";
import NewTabLink from "components/NewTabLink";
import CollegeDetailedCardSkeleton from "./skeleton";
import {
  refreshRecruitingProfile,
  useRecruitingProfileDispatch,
  useRecruitingProfileState,
} from "components/useRecruitingProfile";
import { useProfileState } from "components/useProfile";
import ContactCoachesPopover from "components/ContactCoachesPopover";
import BookmarkCollegePopover from "components/BookmarkCollegePopover";
import Tooltip from "components/Tooltip";
import NotesPopUp from "containers/Notes/NotesPopUp";
import transformLink from "helpers/linksTransformHelper";
import { useNotesState } from "components/useNotes";
import { useMessagesState } from "components/useMessages";

import * as userDomain from "api/domains/user";

import { NOT_FOUND } from "constants/backendErrors";
import {
  CollegeLinkGridItem,
  StyledLocationIcon,
  StyledBankCardIcon,
  StyledInfoIcon,
  StyledCollegeIcon,
  StyledSportIcon,
  CardGridContainer,
  NewsProfileContainer,
  IconsGridItem,
  LogoGridItem,
  MainSectionGridItem,
  LinksGridContainer,
  CollegeName,
  CollegeLocation,
  CollegeMoney,
  CollegeInfo,
  StyledCard,
  StyledIconButton,
  NavigationGridItem,
} from "./style";

function CollegeDetailedCard({
  collegeId,
  city,
  state,
  sanctionName,
  divisionAbbr,
  totalEnrollment,
  acceptanceRate,
  schoolUrl,
  sportsUrl,
  usnewsUrl,
  netPrice,
  schoolName,
  logoUrl,
  children,
  sportids,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElBookmark, setAnchorElBookmark] = useState(null);
  const { states } = useStates();
  const [favorite, setFavorite] = useState(null);
  const routeParams = useParams();
  const { isLoggedIn } = useProfileState();
  const {
    error: recruitingError,
    profile: recruitingProfile,
  } = useRecruitingProfileState();
  const dispatch = useRecruitingProfileDispatch();

  const [isOpenNoteModal, setIsOpenNoteModal] = useState(false);

  const { notes } = useNotesState();
  const collegeNotes = notes[collegeId];

  const { collegesSummary } = useMessagesState();
  const hasActiveMessaging = collegesSummary.find(
    (el) => Number(el.collegeId) === Number(collegeId),
  );

  useEffect(
    function () {
      setFavorite(
        recruitingProfile?.collegeFavorites.find(
          (favorite) => favorite.collegeId === parseInt(collegeId),
        ),
      );
    },
    [recruitingProfile, collegeId],
  );

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const unlikeCollege = async () => {
    const { collegeFavorites, ...rest } = recruitingProfile;

    collegeFavorites.splice(collegeFavorites.indexOf(favorite), 1);

    await userDomain
      .removeFavorites(favorite.id)
      .then(() => Object.keys(routeParams).includes("favorite"));

    await refreshRecruitingProfile(dispatch, { ...rest, collegeFavorites });
    handleBookmarkPopoverClose();
  };

  const handleBookmarkPopoverOpen = async (event) => {
    setAnchorElBookmark(event.currentTarget);
  };

  const handleBookmarkPopoverClose = () => {
    setAnchorElBookmark(null);
  };

  const handleNoteModalClose = () => {
    setIsOpenNoteModal(false);
  };

  const handleLike = async (bookmark, collegeId) => {
    try {
      const res = await userDomain.saveFavorites(bookmark.groupName, collegeId);
      const { collegeFavorites, ...rest } = recruitingProfile;
      await refreshRecruitingProfile(dispatch, {
        ...rest,
        collegeFavorites: [...collegeFavorites, res],
      });
      handleBookmarkPopoverClose();
    } catch (error) {
      dispatch({ type: "error", error: error.errorCode });
    }
  };

  const handleModifyLike = async (bookmark) => {
    try {
      const res = await userDomain.updateFavorites(
        bookmark.groupName,
        favorite.id,
      );
      const { collegeFavorites, ...rest } = recruitingProfile;
      await refreshRecruitingProfile(dispatch, {
        ...rest,
        collegeFavorites: res,
      });
      handleBookmarkPopoverClose();
    } catch (error) {
      dispatch({ type: "error", error: error.errorCode });
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "college-detailed-card-popover" : undefined;

  const { sports = {} } = recruitingProfile || {};
  const {
    primary: primarySport,
    secondary: secondarySport,
    tertiary: tertiarySport,
  } = sports || {};
  const isActionsVisible = isLoggedIn && recruitingError !== NOT_FOUND;

  const primaryInTheList = Boolean(
    primarySport && sportids.find((el) => el.sportId === primarySport.id),
  );
  const secondaryInTheList = Boolean(
    secondarySport && sportids.find((el) => el.sportId === secondarySport.id),
  );
  const tertiaryInTheList = Boolean(
    tertiarySport && sportids.find((el) => el.sportId === tertiarySport.id),
  );

  const isMailIconVisible =
    Number(primaryInTheList) +
      Number(secondaryInTheList) +
      Number(tertiaryInTheList) >
    0;

  const mainSection = (
    <>
      <CollegeName>{schoolName}</CollegeName>
      <CollegeLocation>
        <StyledLocationIcon />
        {city}, {states[state]}
      </CollegeLocation>
      <CollegeInfo>
        <StyledInfoIcon />
        {sanctionName} {divisionAbbr} - Enrollment {totalEnrollment} -
        Acceptance {acceptanceRate}%
      </CollegeInfo>
      <CollegeMoney>
        <StyledBankCardIcon />
        <Currency>{netPrice}</Currency> in state,{" "}
        <Currency>{netPrice}</Currency> out-of-state
      </CollegeMoney>
      <LinksGridContainer container>
        <CollegeLinkGridItem item>
          <StyledCollegeIcon />
          <NewTabLink
            underline="always"
            color="inherit"
            name={schoolUrl}
            href={transformLink(schoolUrl)}
          />
        </CollegeLinkGridItem>
        <Grid item>
          <StyledSportIcon />
          <NewTabLink
            underline="always"
            color="inherit"
            name={sportsUrl}
            href={transformLink(sportsUrl)}
          />
        </Grid>
      </LinksGridContainer>
    </>
  );

  return (
    <StyledCard>
      <CardGridContainer container>
        <LogoGridItem item>
          <LargeCollegeLogo name={schoolName} image={logoUrl} />
        </LogoGridItem>
        <MainSectionGridItem item>{mainSection}</MainSectionGridItem>
        <IconsGridItem item>
          <NewsProfileContainer>
            <NewTabLink
              name="US News Profile"
              href={usnewsUrl}
              underline="always"
              color="inherit"
            />
          </NewsProfileContainer>
          {isActionsVisible && (
            <Box data-testid="action-buttons">
              {isMailIconVisible && (
                <Tooltip title={"Message Coaches"} arrow={true} placement="top">
                  <StyledIconButton
                    onClick={handlePopoverOpen}
                    $selected={hasActiveMessaging}
                    data-testid="contact-coach-btn"
                  >
                    <MailOutlineIcon color="primary" />
                  </StyledIconButton>
                </Tooltip>
              )}

              <Tooltip
                title={favorite ? "Modify likes" : "Add to likes"}
                arrow={true}
                placement="top"
              >
                <StyledIconButton
                  onClick={handleBookmarkPopoverOpen}
                  $selected={favorite}
                  data-testid="favorites-btn"
                >
                  <ThumbUpAltOutlinedIcon color="primary" />
                </StyledIconButton>
              </Tooltip>

              <Tooltip title="Notes about college" arrow={true} placement="top">
                <StyledIconButton
                  onClick={() => setIsOpenNoteModal(true)}
                  $selected={collegeNotes}
                  data-testid="notes-btn"
                >
                  <NoteOutlinedIcon color="primary" />
                </StyledIconButton>
              </Tooltip>
            </Box>
          )}
        </IconsGridItem>
        <NavigationGridItem item md={8} sm={12}>
          {children}
        </NavigationGridItem>
      </CardGridContainer>
      <ContactCoachesPopover
        primarySport={primaryInTheList ? primarySport : null}
        secondarySport={secondaryInTheList ? secondarySport : null}
        tertiarySport={tertiaryInTheList ? tertiarySport : null}
        id={id}
        collegeId={collegeId}
        open={open}
        anchorEl={anchorEl}
        handleClose={handlePopoverClose}
      />
      <BookmarkCollegePopover
        id={id}
        collegeId={collegeId}
        open={!!anchorElBookmark}
        anchorEl={anchorElBookmark}
        handleClose={handleBookmarkPopoverClose}
        handleLike={favorite ? handleModifyLike : handleLike}
        handleUnlike={unlikeCollege}
        favorite={favorite}
      />
      <NotesPopUp
        open={isOpenNoteModal}
        onClose={handleNoteModalClose}
        collegeId={collegeId}
        collegeNotes={collegeNotes}
        name={schoolName}
        logo={logoUrl}
      />
    </StyledCard>
  );
}

NewTabLink.propTypes = {
  city: PropTypes.string,
  state: PropTypes.string,
  sanctionName: PropTypes.string,
  divisionAbbr: PropTypes.string,
  totalEnrollment: PropTypes.number,
  acceptanceRate: PropTypes.number,
  schoolUrl: PropTypes.string,
  sportsUrl: PropTypes.string,
  usnewsUrl: PropTypes.string,
  netPrice: PropTypes.number,
  schoolName: PropTypes.string,
  collegeId: PropTypes.string,
  children: PropTypes.any,
};

export default React.memo(CollegeDetailedCard);

export { CollegeDetailedCardSkeleton };
