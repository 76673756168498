import React from "react";
import PropTypes from "prop-types";
import omit from "lodash.omit";
import { Box } from "components/MaterialComponents";
import { Skeleton } from "components/MaterialComponents";
import { CircularProgress } from "components/MaterialComponents";
import { Formik, Form } from "formik";
import * as yup from "yup";

import Modal from "components/Modal";
import useSports from "components/useSports";
import DatePicker from "components/DatePicker";
import CustomTextField from "components/CustomTextField";
import getSportListByGender from "helpers/getSportListByGender";
import GraduationSelect from "./GraduationSelect";
import SportSelect from "./SportSelect";
import getPastDateFromCurrent from "helpers/getPastDateFromCurrent";
import GenderSelect from "./GenderSelect";
import * as validationHelper from "helpers/validationHelper";

import { ModalHeader, ModalButton, ModalFormContainer } from "./style";

const validationSchema = yup.object().shape({
  firstName: validationHelper.firstName.required("First Name is required"),
  lastName: validationHelper.lastName.required("Last Name is required"),
  dateOfBirth: yup.date().nullable(),
  hsGradYear: yup.string().nullable(),
  gender: yup.string().nullable(),
});

function NoRecruitingProfileModal({
  open,
  handleClose,
  handleCreateRecruitingProfile,
  displayName,
  dob,
  loading,
}) {
  const { data: sportList, loading: sportsLoading } = useSports();

  if (sportsLoading) {
    return (
      <Skeleton variant="text" width="100%" style={{ marginTop: "2em" }} />
    );
  }

  const displayNameArray = displayName.split(" ");
  const firstName = displayNameArray[0];
  const lastName = displayNameArray[1] || null;

  const pastDefaultDate = getPastDateFromCurrent(16);

  const handleFormSubmit = async (values) => {
    const apiСompatibleValues = getApiCompatibleValues(values);

    await handleCreateRecruitingProfile(apiСompatibleValues);
  };

  const getApiCompatibleValues = (values) => {
    const { primarySport } = values;

    let sports = [];
    if (primarySport) {
      sports[0] = primarySport;
    }

    const omittedValues = omit(values, ["primarySport"]);

    return { ...omittedValues, sports, sportPositions: [] };
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader data-testid="no-recruiting-profile-modal-header">
        We noticed you don't have a recruiting profile yet. Press Create to
        continue
      </ModalHeader>
      <Formik
        validateOnChange
        initialValues={{
          firstName,
          lastName,
          dateOfBirth: new Date(dob || pastDefaultDate),
          hsGradYear: "",
          primarySport: "",
          gender: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <ModalFormContainer>
              <CustomTextField label="First Name" name="firstName" />
              <CustomTextField label="Last Name" name="lastName" />
              <Box mb="2.5em">
                <DatePicker
                  label="Date of Birth"
                  date={values.dateOfBirth}
                  handleChange={(value) => setFieldValue("dateOfBirth", value)}
                />
              </Box>
              <Box marginBottom="2.5em">
                <GraduationSelect
                  hsGradYear={values.hsGradYear}
                  handleChange={(e) =>
                    setFieldValue("hsGradYear", e.target.value)
                  }
                />
              </Box>
              <Box marginBottom="2.5em">
                <GenderSelect
                  gender={values.gender}
                  handleChange={(e) => {
                    setFieldValue("gender", e.target.value);
                    setFieldValue("primarySport", "");
                  }}
                />
              </Box>
              <Box marginBottom="2.5em">
                <SportSelect
                  labelId="primary-sport-label"
                  label="Primary Sport"
                  sportList={getSportListByGender(values.gender, sportList)}
                  firstEntity={values.primarySport}
                  disabled={!values.gender}
                  showGender={false}
                  handleChange={(e) =>
                    setFieldValue("primarySport", e.target.value)
                  }
                />
              </Box>
              <Box display="flex" justifyContent="space-around">
                <ModalButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  startIcon={loading && <CircularProgress size={20} />}
                  disabled={loading}
                >
                  Create
                </ModalButton>
                <ModalButton
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                >
                  Cancel
                </ModalButton>
              </Box>
            </ModalFormContainer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

NoRecruitingProfileModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreateRecruitingProfile: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  displayName: PropTypes.string,
  dob: PropTypes.string,
};

export default NoRecruitingProfileModal;
