import React from "react";

import { useCSVReader } from "react-papaparse";

const styles = {
  csvReader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  },
  browseFile: {
    width: "50%",
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "1px solid #0f72ab",
    color: "#0f72ab",
    borderRadius: "5px",
  },
  acceptedFile: {
    border: "1px solid #ccc",
    height: 35,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: "80%",
    borderRadius: "5px",
  },
  remove: {
    padding: "0 20px",
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "1px solid red",
    color: "red",
    borderRadius: "5px",
  },
  progressBarBackgroundColor: {
    backgroundColor: "red",
  },
};

function CSVReader({ onDataReady }) {
  const { CSVReader } = useCSVReader();

  return (
    <CSVReader
      onUploadAccepted={(results) => {
        onDataReady(results.data);
      }}
      config={{
        header: true,
      }}
    >
      {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
        <>
          <div style={styles.csvReader}>
            <button type="button" {...getRootProps()} style={styles.browseFile}>
              Browse file
            </button>
            <div style={styles.acceptedFile}>
              {acceptedFile && acceptedFile.name}
            </div>
            <button {...getRemoveFileProps()} style={styles.remove}>
              Remove
            </button>
          </div>
          <ProgressBar style={styles.progressBarBackgroundColor} />
        </>
      )}
    </CSVReader>
  );
}

export default CSVReader;
