import React from "react";
import styled from "styled-components";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";

export const Headline = styled((props) => (
  <Typography variant="h2" {...props} />
))`
  ${({ theme }) => `
  margin: 22px 0 12px 38px;
  @media (max-width: 1250px) {
    font-size: 24px;
    margin: 7px 0 7px 22px;
  }
  ${theme.breakpoints?.down("sm")} {
    font-size: 26px;
  }
`}
`;

export const InputLabel = styled(Box)`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`;

export const Title = styled(Box)`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0075em;
`;

export const Subtitle = styled(Box)`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
`;

export const Subtitle1 = styled(Box)`
  font-size: 1.15rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
`;

export const RegularText = styled(Box)`
  ${({ theme }) => `
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    ${theme.breakpoints?.down("xs")} {
      text-align: center;
    }
`}
`;
