import React from "react";
import styled from "styled-components";
import { Card } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import * as colors from "theme/colors";

export const StyledCard = styled(Card)`
  ${({ theme }) => `
  background-color: ${colors.White};
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 21px 58px 40px 52px;
  width: 55%;
  margin-bottom: 12px;
  &:first-child {
    margin-top: 2em;
  }
  &:last-child {
    margin-bottom: 90px;
  }

  ${theme.breakpoints.down("md")} {
      width: 70%;
  }

  ${theme.breakpoints.down("sm")} {
    width: 100%;
    padding: 5px;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  `}
`;

export const CardHeader = styled((props) => (
  <Typography variant="h3" {...props} />
))`
  ${({ theme }) => `
    margin-bottom: 13px;
    color: ${colors.BrightGray};
    ${theme.breakpoints.down("sm")} {
      text-align: center;
  }
  `}
`;

export const CardHorizontalLine = styled.hr`
  margin: 0;
  border: 0.1px solid ${colors.CatskillWhite};
`;
