import React from "react";
import { Box } from "components/MaterialComponents";
import { CircularProgress } from "components/MaterialComponents";

import { ProgressText } from "./style";

function CircularProgressWithLabel(props) {
  const style = {
    width: "33px",
    height: "33px",
    transform: "rotate(90deg)",
  };

  return (
    <React.Fragment>
      {props.value ? (
        <Box position="relative" display="inline-flex">
          <CircularProgress
            style={style}
            variant="static"
            color="inherit"
            {...props}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ProgressText>{props.value}%</ProgressText>
          </Box>
        </Box>
      ) : null}
    </React.Fragment>
  );
}

export default CircularProgressWithLabel;
