export const tabHeadersParent = [
  {
    id: 1,
    name: "Profile",
    link: null,
  },
  {
    id: 4,
    name: "Colleges",
    link: null,
  },
  {
    id: 5,
    link: "/contact-activity",
    name: "Contacts",
  },
  {
    id: 6,
    name: "Notes",
    link: "/notes",
  },
];
