import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import AthleteItem from "./AthleteItem";
import MessageItem from "./MessageItem";
import useMessages, { sendEmailToAthlete } from "components/useMessages";
import isempty from "lodash.isempty";
import HorizontalLine from "components/HorizontalLine";
import { Headline } from "components/Typography";
import { Box } from "components/MaterialComponents";
import { Skeleton } from "components/MaterialComponents";
import Feedbacker from "components/Feedbacker";
import { CLEAR_FEEDBACK } from "components/useMessages/actionTypes";
import { Typography } from "components/MaterialComponents";
import { Add as AddIcon } from "components/MaterialIcons";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "components/MaterialIcons";
import { ArrowForwardIos as ArrowForwardIosIcon } from "components/MaterialIcons";
import { Search as SearchIcon } from "components/MaterialIcons";

import { AddButtonWithText } from "components/Buttons";
import ReplyEmailPopUp from "containers/ContactActivity/ReplyEmailPopUp";
import NameFilter from "containers/FindColleges/NameFilter";
import NoMessages from "containers/ContactActivity/NoMessages";
import { messagesFeedbacks } from "components/useMessages/messagesFeedbacks";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import MobileMessagesPopUp from "./MobileMessagesPopUp";
import useOnScreen from "customHooks/useOnScreen";
import Tooltip from "components/Tooltip";
import {
  updateUnreadEmailsForCoach,
  useMessagesDispatch,
} from "components/useMessages";
import MessagesSearch from "containers/ContactActivity/MessagesSearch";
import last from "lodash.last";
import {
  ThreadContainer,
  MainMessagesContainer,
  SelectSection,
  MessagesSection,
  MessagesListContainerCoach,
  AddButtonContainer,
  CardContainer,
  GoBottomButtonContainer,
  StyledIconButton,
  SearchButtonContainer,
} from "containers/ContactActivity/style";
import { SearchMenuContainer } from "./style";

const getAthletesList = (currentThread) => {
  return Object.keys(currentThread).map((key) => ({
    athleteId: key,
    firstName: currentThread[key][0]["firstName"],
    lastName: currentThread[key][0]["lastName"],
    profilePhotoMediaItem: currentThread[key][0]["profilePhotoMediaItem"],
  }));
};

function ContactCoachActivity() {
  const [
    { coachCurrentThread, loading, error, feedback },
    dispatch,
  ] = useMessages();
  const athletes = useMemo(() => getAthletesList(coachCurrentThread), [
    coachCurrentThread,
  ]);
  const defaultAthleteId = !isempty(athletes) ? athletes[0]["athleteId"] : null;
  const [currentAthleteId, setCurrentAthleteId] = useState(defaultAthleteId);
  const [isSendWindowOpen, setSendWindowOpen] = useState(false);
  const [athletesList, setAthletesList] = useState(athletes);
  const [isMobilePopUpOpen, setIsMobilePopUpOpen] = useState(false);

  const messages = coachCurrentThread[currentAthleteId];
  const messagesRefs = useRef([]);
  const [currentMessagesRefs, setCurrentMessagesRefs] = useState([]);
  const isLastMessageVisible = useOnScreen(last(currentMessagesRefs));

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [containerRef, setContainerRef] = useState(null);
  const [listRef, setListref] = useState();

  const messagesDispatch = useMessagesDispatch();

  const [isSearchMenuVisible, setSearchMenuVisible] = useState(false);

  useEffect(() => {
    const refs = messagesRefs.current.filter((el) => el !== null);
    setCurrentMessagesRefs(refs);

    const newMessagesIds = messages
      ?.filter((message) => !Boolean(message.messageRead))
      .map((message) => message.id);
    if (!isempty(newMessagesIds)) {
      updateUnreadEmailsForCoach(messagesDispatch, { ids: newMessagesIds });
    }
  }, [messages]);

  useEffect(() => {
    setCurrentAthleteId(defaultAthleteId);
  }, [defaultAthleteId]);

  const toggleMobilePopUp = useCallback(() => {
    setIsMobilePopUpOpen(!isMobilePopUpOpen);
  }, [isMobilePopUpOpen]);

  const handleSetCurrentAthlete = useCallback(
    (athleteId) => {
      setCurrentAthleteId(athleteId);

      if (matchesSm) {
        toggleMobilePopUp();
      }
    },
    [matchesSm, toggleMobilePopUp],
  );

  const toggleSearchMenu = useCallback(() => {
    setSearchMenuVisible(!isSearchMenuVisible);
  }, [isSearchMenuVisible]);

  const currentAthlete = athletes.find(
    (el) => Number(el.athleteId) === Number(currentAthleteId),
  );

  const currentSportId = messages && messages[0].sportId;

  const handleGoBottom = useCallback(() => {
    listRef.scrollTop = listRef.scrollHeight;
  }, [listRef]);

  const handleSendEmail = useCallback(
    async (data) => {
      const { body, subject } = data;
      const recipients = [
        {
          id: currentAthleteId,
          name: `${currentAthlete.athleteFirstName} ${currentAthlete.athleteLastName}`,
        },
      ];
      const message = {
        body,
        subject,
        isCoach: true,
        sportId: currentSportId,
      };
      await sendEmailToAthlete(dispatch, { recipients, message });
      handleGoBottom();
    },
    [
      currentAthlete,
      currentAthleteId,
      currentSportId,
      handleGoBottom,
      dispatch,
    ],
  );

  const toggleSendWindow = useCallback(() => {
    setSendWindowOpen(!isSendWindowOpen);
  }, [isSendWindowOpen]);

  const filterAthletes = useCallback(
    (search) => {
      const filteredList = athletes.filter(
        (el) =>
          el.firstName.toLowerCase().includes(search.query.toLowerCase()) ||
          el.lastName.toLowerCase().includes(search.query.toLowerCase()),
      );

      setAthletesList(filteredList);
    },
    [athletes],
  );

  useEffect(() => {
    setAthletesList(athletes);
  }, [athletes]);

  useEffect(() => {
    if (listRef) {
      listRef.scrollTop = listRef.scrollHeight;
    }
  }, [listRef, currentAthleteId]);

  const renderMessagesContent = () => {
    return (
      <>
        {isempty(coachCurrentThread) ? (
          <CardContainer>
            <NoMessages />
          </CardContainer>
        ) : (
          <ThreadContainer>
            <SelectSection>
              <Box margin="5px">
                <NameFilter
                  placeholder="Filter by name"
                  onChange={filterAthletes}
                />
              </Box>
              {athletesList.map((athlete) => (
                <AthleteItem
                  key={athlete.athleteId}
                  athlete={athlete}
                  onSelect={handleSetCurrentAthlete}
                  selected={
                    Number(athlete.athleteId) === Number(currentAthleteId)
                  }
                  messages={coachCurrentThread[athlete.athleteId]}
                />
              ))}
            </SelectSection>
            {!matchesSm && (
              <MessagesSection ref={setContainerRef}>
                <MessagesListContainerCoach ref={setListref}>
                  {!matchesSm && (
                    <>
                      {isSearchMenuVisible ? (
                        <>
                          <SearchMenuContainer>
                            <MessagesSearch
                              messagesRefs={currentMessagesRefs}
                            />
                          </SearchMenuContainer>
                          <SearchButtonContainer>
                            <Tooltip
                              title={"Close search"}
                              arrow={true}
                              placement="top"
                            >
                              <StyledIconButton onClick={toggleSearchMenu}>
                                <ArrowForwardIosIcon />
                              </StyledIconButton>
                            </Tooltip>
                          </SearchButtonContainer>
                        </>
                      ) : (
                        <SearchButtonContainer>
                          <Tooltip
                            title={"Search in messages"}
                            arrow={true}
                            placement="top"
                          >
                            <StyledIconButton onClick={toggleSearchMenu}>
                              <SearchIcon />
                            </StyledIconButton>
                          </Tooltip>
                        </SearchButtonContainer>
                      )}
                    </>
                  )}
                  {messages?.map((message, i) => (
                    <MessageItem
                      key={message.id}
                      message={message}
                      containerRef={containerRef}
                      sendEmail={handleSendEmail}
                      messageRef={(el) => {
                        messagesRefs.current[i] = el;
                      }}
                    />
                  ))}
                  {!isLastMessageVisible && (
                    <GoBottomButtonContainer>
                      <StyledIconButton onClick={handleGoBottom}>
                        <KeyboardArrowDownIcon />
                      </StyledIconButton>
                    </GoBottomButtonContainer>
                  )}
                </MessagesListContainerCoach>
                <AddButtonContainer>
                  <AddButtonWithText onClick={toggleSendWindow}>
                    <AddIcon fontSize="large" />
                    <Typography variant="h5">New message</Typography>
                  </AddButtonWithText>
                </AddButtonContainer>
              </MessagesSection>
            )}
            <MobileMessagesPopUp
              open={isMobilePopUpOpen}
              onClose={toggleMobilePopUp}
              currentAthlete={currentAthlete}
              messages={messages}
              sendEmail={handleSendEmail}
              setListref={setListref}
            />
            <ReplyEmailPopUp
              open={isSendWindowOpen}
              onClose={toggleSendWindow}
              containerRef={containerRef}
              onSendEmail={handleSendEmail}
            />
          </ThreadContainer>
        )}
      </>
    );
  };

  return (
    <MainMessagesContainer>
      <Headline>Contact Activity</Headline>
      <HorizontalLine />
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          marginTop="40px"
          width="100%"
        >
          <Skeleton variant="rect" width="80%" height="400px" />
        </Box>
      ) : (
        renderMessagesContent()
      )}
      <Feedbacker
        open={feedback === messagesFeedbacks.Success}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage="Your message was successfully sent!"
        clearFeedback={() => dispatch({ type: CLEAR_FEEDBACK })}
      />
      <Feedbacker
        open={error}
        autoHideDuration={3000}
        severity="error"
        feedbackMessage="Something went wrong!"
        clearFeedback={() => dispatch({ type: CLEAR_FEEDBACK })}
      />
    </MainMessagesContainer>
  );
}

export default ContactCoachActivity;
