import styled from "styled-components";
import { TableRow } from "components/MaterialComponents";
import { TableHead } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import * as colors from "theme/colors";

export const StyledTableRowSports = styled(TableRow)`
  background-color: ${colors.LightYellow};
`;

export const TableHeadContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
`;

export const StyledTableHead = styled(TableHead)``;

export const StyledHeaderTableCell = styled(TableCell)`
  background-color: ${colors.Allports};
  color: white;
`;
