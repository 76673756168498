import React, { useCallback } from "react";
import PropTypes from "prop-types";
import CustomPopover from "components/CustomPopover";
import { Formik, Form, Field } from "formik";
import InputComponent from "components/InputComponent";

import { FormContainer, SaveButton } from "./style";

function SaveFilterPopUp({ id, open, anchorEl, handleClose, onSave }) {
  const handleFormSubmit = useCallback(
    (values) => {
      onSave(values.name);
    },
    [onSave],
  );

  return (
    <CustomPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      handleClose={handleClose}
    >
      <Formik
        initialValues={{
          name: "",
        }}
        onSubmit={handleFormSubmit}
      >
        {() => (
          <Form>
            <FormContainer>
              <Field name="name" charLimit={20} component={InputComponent} />
              <SaveButton variant="outlined" color="primary" type="submit">
                Save
              </SaveButton>
            </FormContainer>
          </Form>
        )}
      </Formik>
    </CustomPopover>
  );
}

SaveFilterPopUp.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
};

export default SaveFilterPopUp;
