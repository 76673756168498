import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { TableRow } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import PositionCreateEditePopUp from "./PositionCreateEditePopUp";

function SportPositionsTableRow({
  position,
  handleEditePosition,
  handleDeletePosition,
}) {
  const [isEditeModalOpen, setIsEditeModalOpen] = useState(false);

  const toggleEditeModal = useCallback(() => {
    setIsEditeModalOpen(!isEditeModalOpen);
  }, [isEditeModalOpen]);

  return (
    <>
      <TableRow key={position.id}>
        <TableCell>{position.ordinal}</TableCell>
        <TableCell>{position.name}</TableCell>
        <TableCell>
          <Button color="primary" onClick={toggleEditeModal}>
            Edit
          </Button>
        </TableCell>
        <TableCell>
          <Button
            color="secondary"
            onClick={() => handleDeletePosition(position.id)}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <PositionCreateEditePopUp
        open={isEditeModalOpen}
        onClose={toggleEditeModal}
        item={position}
        onEditePosition={handleEditePosition}
      />
    </>
  );
}

SportPositionsTableRow.propTypes = {
  position: PropTypes.object,
  handleEditePosition: PropTypes.func,
  handleDeletePosition: PropTypes.func,
};

export default SportPositionsTableRow;
