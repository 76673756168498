import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import Modal from "components/Modal";

function ConfirmDeleteSportModal({ open, handleClose, handleDelete }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box marginTop="20px">
        By removing sport you will also remove connected sport positions and
        stats!
      </Box>
      <Box mt="2em" display="flex" justifyContent="space-around" width="100%">
        <Button variant="contained" color="primary" onClick={handleDelete}>
          Confirm
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}

ConfirmDeleteSportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default ConfirmDeleteSportModal;
