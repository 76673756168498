import React from "react";
import PropTypes from "prop-types";
import { TableRow } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";

function SportsTableRow({ sport, onDeleteSport, unitSport }) {
  return (
    <>
      <TableRow key={sport.id}>
        <TableCell>{sport.name}</TableCell>
        <TableCell>{sport.gender}</TableCell>
        <TableCell>
          <Button color="secondary" onClick={() => onDeleteSport(unitSport)}>
            Delete
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

SportsTableRow.propTypes = {
  sport: PropTypes.object,
  onDeleteSport: PropTypes.func,
  unitSport: PropTypes.string,
};

export default SportsTableRow;
