import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal";
import { Formik, Form } from "formik";
import * as yup from "yup";
import useRecruitingProfile from "components/useRecruitingProfile";
import useSports from "components/useSports";
import getSportListByGender from "helpers/getSportListByGender";
import SportSelect from "containers/Profile/SportSelect";
import SelectMultiple from "components/SelectMultiple";
import DatePicker from "components/DatePicker";
import { ORDER_PRIMARY } from "constants/sports";
import useGet from "components/useFetch";
import CustomTextField from "components/CustomTextField";
import AthleticTextArea from "./AthleticTextArea";
import { Typography } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import AthleticCheckBox from "./AthleticCheckBox";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import {
  useHistoryDispatch,
  createHistory,
  updateHistory,
} from "components/useAthleticHistory";
import * as validationHelper from "helpers/validationHelper";

import {
  FormContainer,
  FormModalHeader,
  FieldsBlock,
  DatesBlock,
  ButtonsBlock,
  StyledSubtitle2,
  FieldContainer,
} from "./style";

const validationSchema = yup.object().shape({
  sport: yup.object().required("Field is required"),
  sportPositions: yup.array(),
  program: yup.string().required("Field is required"),
  location: yup.string().required("Field is required"),
  current: yup.bool(),
  startDate: validationHelper.startDate,
  endDate: validationHelper.endDate,
  statLine: yup.string().nullable(),
  coach: yup.string().nullable(),
  coachContact: yup.string().nullable(),
  achivements: yup.string().nullable(),
  teamLink: yup.string().nullable(),
});

const transformListForMultSelect = (list = []) => {
  return list.map((item) => item.name);
};

const getIdListFromSelectList = (listSelect, listPositios) => {
  return listSelect.map(
    (item) => listPositios.find((position) => position.name === item).id,
  );
};

function CreateEditePopUp({ open, onClose, item }) {
  const [{ profile }] = useRecruitingProfile();
  const { data: sportList, loading: sportsLoading } = useSports();
  const defaultId = item ? item.sportId : null;
  const [sportId, setSportId] = useState(defaultId);
  const [sportData, loading] = useGet(`/colleges/sports/${sportId}`);

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));

  const historyDispatch = useHistoryDispatch();

  const sportPositionsList = sportData?.sportPositions;

  const getDefaultSportPosition = useCallback(() => {
    if (!item) {
      return [];
    }
    return item.sport_positions.map((position) => position.name);
  }, [item]);

  const handleFormSubmit = useCallback(
    (values) => {
      const body = {
        ...values,
        endDate: Boolean(values.current) ? null : values.endDate,
        sport: values.sport.id,
        sportPositions: getIdListFromSelectList(
          values.sportPositions,
          sportPositionsList,
        ),
      };

      onClose();

      if (item) {
        updateHistory(historyDispatch, item.id, body);
        return;
      }
      createHistory(historyDispatch, body);
    },
    [item, historyDispatch, onClose, sportPositionsList],
  );

  const headerText = item ? "EDIT ATHLETIC HISTORY" : "CREATE ATHLETIC HISTORY";

  return (
    <>
      <Modal open={open} onClose={onClose} fluid={matchesXs}>
        <FormModalHeader>
          <Typography variant="h4">{headerText}</Typography>
        </FormModalHeader>
        <Formik
          validateOnChange
          initialValues={{
            sport: item ? item.sport : "",
            sportPositions: getDefaultSportPosition(),
            program: item ? item.program : "",
            location: item ? item.location : "",
            startDate: item ? new Date(item.startDate) : null,
            endDate: item?.endDate ? new Date(item.endDate) : null,
            current: item ? Boolean(item.current) : false,
            statLine: item ? item.statLine : "",
            coach: item ? item.coach : "",
            coachContact: item ? item.coachContact : "",
            achivements: item ? item.achivements : "",
            teamLink: item?.team_link ? item?.team_link : "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ setFieldValue, submitForm, handleChange, values, errors }) => (
            <Form>
              <FormContainer data-testid="create-modal">
                <Box marginBottom="2.5em">
                  <SportSelect
                    label="Sport"
                    disabled={sportsLoading}
                    order={ORDER_PRIMARY}
                    sportList={getSportListByGender(profile?.gender, sportList)}
                    firstEntity={values.sport}
                    showGender={false}
                    name="sport"
                    showEmptyField={false}
                    handleChange={(e) => {
                      setFieldValue("sport", e.target.value);
                      setFieldValue("sportPositions", []);
                      setSportId(e.target.value ? e.target.value.id : null);
                    }}
                  />
                </Box>
                <SelectMultiple
                  label="Positions"
                  disabled={!values.sport || loading}
                  order={ORDER_PRIMARY}
                  itemsList={transformListForMultSelect(sportPositionsList)}
                  name="sportPositions"
                  handleChange={(e) => {
                    setFieldValue("sportPositions", e.target.value);
                  }}
                />
                <CustomTextField label="Program" name="program" type="text" />
                <CustomTextField label="Location" name="location" type="text" />
                <CustomTextField
                  label="Team web link"
                  name="teamLink"
                  type="text"
                />
                <AthleticCheckBox
                  value={values.current}
                  handleChange={handleChange}
                />
                <DatesBlock>
                  <Box marginTop="10px">
                    <DatePicker
                      label="Start Date"
                      date={values.startDate}
                      name="startDate"
                      handleChange={(value) =>
                        setFieldValue("startDate", value)
                      }
                    />
                  </Box>
                  {!values.current ? (
                    <Box marginTop="10px">
                      <DatePicker
                        label="End Date"
                        date={values.endDate}
                        name="endDate"
                        handleChange={(value) =>
                          setFieldValue("endDate", value)
                        }
                      />
                    </Box>
                  ) : (
                    <Box width="50%" marginTop="10px">
                      <StyledSubtitle2>*Present</StyledSubtitle2>
                    </Box>
                  )}
                </DatesBlock>
                <CustomTextField label="Statline" name="statLine" type="text" />
                <Box marginBottom="10px">
                  <StyledSubtitle2>Coach</StyledSubtitle2>
                </Box>
                <FieldsBlock>
                  <FieldContainer>
                    <CustomTextField label="Name" name="coach" type="text" />
                  </FieldContainer>
                  <FieldContainer>
                    <CustomTextField
                      label="Contact"
                      name="coachContact"
                      type="text"
                    />
                  </FieldContainer>
                </FieldsBlock>
                <AthleticTextArea
                  value={values.achivements}
                  handleChange={handleChange}
                />
                <ButtonsBlock>
                  <Button
                    onClick={submitForm}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={onClose}
                    variant="outlined"
                    color="secondary"
                    size="large"
                  >
                    Cancel
                  </Button>
                </ButtonsBlock>
              </FormContainer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

CreateEditePopUp.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CreateEditePopUp;
