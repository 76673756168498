function getArrayOfMajors(majorsOptions = []) {
  return majorsOptions.reduce((result, option) => {
    const hasChildren = option.children.length;
    const majors = hasChildren
      ? getArrayOfMajors(option.children)
      : option.majors;
    return [...result, ...majors];
  }, []);
}

export default getArrayOfMajors;
