import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import InputComponent from "components/InputComponent";
import {
  FormContainer,
  StyledButton,
  SaveButton,
  ListBullet,
  StyledEditeIcon,
  StyledDeleteIcon,
  NoteFieldContainer,
} from "./style";

const validationSchema = yup.object().shape({
  text: yup.string().required(),
});

function NoteField({ collegeId, updateNote, deleteNote, note }) {
  const [editeMode, setEditeMode] = useState(false);

  const handleFormSubmit = useCallback(
    (values) => {
      const body = {
        collegeId,
        text: values.text,
      };
      if (editeMode) {
        updateNote(note.id, body);
        setEditeMode(false);
      }
    },
    [collegeId, editeMode, note, updateNote],
  );

  const handleSetEditeMode = useCallback(() => {
    setEditeMode(true);
  }, []);

  const handleDeleteNote = useCallback(() => deleteNote(note.id), [
    deleteNote,
    note,
  ]);

  return (
    <NoteFieldContainer>
      <Formik
        validateOnChange
        initialValues={{
          text: note.text,
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {() => (
          <Form>
            <FormContainer>
              <ListBullet>-</ListBullet>
              <Field
                name="text"
                charLimit={250}
                component={InputComponent}
                disabled={!editeMode}
              />
              {editeMode ? (
                <SaveButton variant="outlined" color="primary" type="submit">
                  Save
                </SaveButton>
              ) : (
                <>
                  <StyledButton onClick={handleSetEditeMode}>
                    <StyledEditeIcon />
                  </StyledButton>
                  <StyledButton onClick={handleDeleteNote}>
                    <StyledDeleteIcon />
                  </StyledButton>
                </>
              )}
            </FormContainer>
          </Form>
        )}
      </Formik>
    </NoteFieldContainer>
  );
}

NoteField.propTypes = {
  collegeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateNote: PropTypes.func,
  deleteNote: PropTypes.func,
  note: PropTypes.object,
};

export default NoteField;
