import { ReactComponent as Basketball } from "assets/icons/sports/basketball.svg";
import { ReactComponent as Default } from "assets/icons/sports/default.svg";
import { ReactComponent as Soccer } from "assets/icons/sports/soccer.svg";
import { ReactComponent as Volleyball } from "assets/icons/sports/volleyball.svg";
import { ReactComponent as Tennis } from "assets/icons/sports/tennis.svg";
import { ReactComponent as Baseball } from "assets/icons/sports/baseball.svg";
import { ReactComponent as Football } from "assets/icons/sports/football.svg";
import { ReactComponent as Hockey } from "assets/icons/sports/hockey.svg";
import { ReactComponent as TableTennis } from "assets/icons/sports/table-tennis.svg";
import { ReactComponent as Golf } from "assets/icons/sports/golf.svg";
import { ReactComponent as Swimming } from "assets/icons/sports/swimming.svg";
import { ReactComponent as TrackAndField } from "assets/icons/sports/track-and-field.svg";
import { ReactComponent as WeightLifting } from "assets/icons/sports/weight-lifting.svg";
import { ReactComponent as Sailing } from "assets/icons/sports/sailing.svg";
import { ReactComponent as Skiing } from "assets/icons/sports/skiing.svg";
import { ReactComponent as Badminton } from "assets/icons/sports/badminton.svg";
import { ReactComponent as Target } from "assets/icons/sports/target.svg";

const sportIconsMap = {
  basketball: Basketball,
  soccer: Soccer,
  volleyball: Volleyball,
  beach_volleyball: Volleyball,
  tennis: Tennis,
  baseball: Baseball,
  football: Football,
  ice_hockey: Hockey,
  table_tennis: TableTennis,
  golf: Golf,
  swimming: Swimming,
  swimming_and_diving: Swimming,
  track_and_field_indoor: TrackAndField,
  track_and_field_outdoor: TrackAndField,
  weight_lifting: WeightLifting,
  sailing: Sailing,
  skiing: Skiing,
  badminton: Badminton,
  archery: Target,
  rifle: Target,
  squash: Tennis,
  default: Default,
};

export default sportIconsMap;
