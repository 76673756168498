import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import IdleTimer from "react-idle-timer";
import SessionTimeoutDialog from "./SessionTimeoutDialog";
import {
  resetRecruitingProfile,
  useRecruitingProfileDispatch,
} from "components/useRecruitingProfile";

const SessionTimeout = ({ logOut }) => {
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const idleTimer = useRef(null);

  const recruitingDispatch = useRecruitingProfileDispatch();

  const minutes = 30;

  const handleLogout = () => {
    setTimeoutModalOpen(false);
    logOut().then(() => resetRecruitingProfile(recruitingDispatch));
  };

  const onIdle = () => {
    setTimeoutModalOpen(true);
  };

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onIdle={onIdle}
        debounce={250}
        timeout={1000 * 60 * minutes}
      />
      <SessionTimeoutDialog
        onLogout={() => handleLogout()}
        open={timeoutModalOpen}
      />
    </>
  );
};

SessionTimeout.propTypes = {
  logOut: PropTypes.func.isRequired,
};

export default SessionTimeout;
