import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Grid, Box, Button, Typography } from "components/MaterialComponents";
import { Headline } from "components/Typography";
import useFilter from "customHooks/useFilter";
import useSearchResult from "./useSearchResult";
import FilterBar from "./FilterBar";
import ItemsResults from "./ItemsResults";
import { useGet } from "components/useFetch";
import HorizontalLine from "components/HorizontalLine";
import AthletesCardSkeleton from "./AthletesCard/AthletesCardSkeleton";
import { verifyEmail, useProfileDispatch } from "components/useProfile";
import useRecruitingProfile, {
  recruitingProfileFeedbacks,
} from "components/useRecruitingProfile";
import Feedbacker from "components/Feedbacker";
import useClearFeedback from "customHooks/useClearFeedback";
import * as recruitingProfileActionTypes from "components/useRecruitingProfile/actionTypes";
import { messagesFeedbacks } from "components/useMessages/messagesFeedbacks";
import useMessages from "components/useMessages";
import { CLEAR_FEEDBACK } from "components/useMessages/actionTypes";
import FiltersPreview from "./FiltersPreview";
import CoachCategories from "./CoachCategories";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import CoachCategoriesPopUp from "./CoachCategoriesPopUp";
import SavedFilters from "containers/SavedFilters";
import {
  AthletesContainer,
  AthletesGridContainer,
  StyledPagination,
  SelectedGroupNameContainer,
  MainFiltersContainer,
} from "./style";

const loadingItems = Array(21).fill(true);

function FindAthletes() {
  const params = useParams();
  const location = useLocation();
  const [filter, setFilter] = useFilter();
  const [lastRemoved, setLastRemoved] = useState(null);
  const [lastModified, setLastModified] = useState(null);
  const [isFavoriteModalOpen, setIsFavoriteModalOpen] = useState(false);
  const [{ items, lastPage } = {}, loading] = useSearchResult(filter, params, {
    removed: lastRemoved,
    modified: lastModified,
  });
  const parsedPage = parseInt(filter.page) || 1;
  const onFilterChange = (filter) => setFilter({ ...filter, page: 1 });
  const onPageChange = (_, page) => setFilter({ ...filter, page });
  const { sportId } = filter;
  const [sportData] = useGet(`/colleges/sports/${sportId}`);
  const history = useHistory();
  const profileDispatch = useProfileDispatch();

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [{ feedback, profile }, dispatch] = useRecruitingProfile();
  const { favorite_groups: favoriteGroups } = profile || {};
  const [{ feedback: messagesFeedback }, messagesDispatch] = useMessages();

  const onItemRemoved = (favoriteId) => setLastRemoved(favoriteId);
  const onItemModified = (favoriteId) => setLastModified(favoriteId);

  const isFavoritePage = location.pathname.includes("favorite");

  const verifyEmailToken = params.token;

  const mainContainerWidth = isFavoritePage && !matchesSm ? "82%" : "100%";

  const verify = async () => {
    if (!verifyEmailToken) {
      return;
    }
    await verifyEmail(profileDispatch, verifyEmailToken);
    history.push("/athletes");
  };

  const attemptEmailVerification = useCallback(verify, []);

  useEffect(() => {
    attemptEmailVerification();
  }, [attemptEmailVerification]);

  useEffect(() => {
    if (!params.favorite) {
      return;
    }
    setFilter((filter) => ({ ...filter, page: 1 }));
  }, [params.favorite, setFilter]);

  const handleSetGender = useCallback(
    (e) => {
      onFilterChange({
        ...filter,
        gender: e.target.value,
        sportId: null,
        sportPositions: null,
        sportStats: null,
        sportStatsSelect: null,
      });
    },
    [filter, onFilterChange],
  );

  const handleSetSport = useCallback(
    (option) => {
      const sportId = option ? option.value : null;
      onFilterChange({
        ...filter,
        sportId: sportId,
        sportPositions: null,
        sportStats: null,
        sportStatsSelect: null,
      });
    },
    [filter, onFilterChange],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [items]);

  const toggleFavoriteModal = useCallback(() => {
    setIsFavoriteModalOpen(!isFavoriteModalOpen);
  }, [isFavoriteModalOpen]);

  const selectedFavoriteGroup = useMemo(
    () =>
      params.groupId
        ? favoriteGroups?.find(
            (group) => Number(group.id) === Number(params.groupId),
          )
        : null,
    [params.groupId, favoriteGroups],
  );

  useClearFeedback(dispatch);

  return (
    <>
      <Headline>Find Athletes</Headline>
      <HorizontalLine />
      <AthletesContainer>
        <MainFiltersContainer>
          <FilterBar
            filter={filter}
            onChange={onFilterChange}
            positions={sportData?.sportPositions}
            stats={sportData?.sportStatsDefinitions}
            onGenderChange={handleSetGender}
            onSportChange={handleSetSport}
            sportId={sportId}
          />
          {profile && <SavedFilters />}
        </MainFiltersContainer>
        <FiltersPreview
          filter={filter}
          onChange={onFilterChange}
          sportData={sportData}
        />
        {isFavoritePage && matchesSm && (
          <Box display="flex" padding="10px">
            {selectedFavoriteGroup ? (
              <SelectedGroupNameContainer>
                <Typography variant="h5">
                  {selectedFavoriteGroup.groupName}
                </Typography>
              </SelectedGroupNameContainer>
            ) : (
              <SelectedGroupNameContainer>
                <Typography variant="h5">All Athletes</Typography>
              </SelectedGroupNameContainer>
            )}
            <Button
              onClick={toggleFavoriteModal}
              variant="outlined"
              color="primary"
            >
              Manage favorite groups
            </Button>
          </Box>
        )}
        <Box display="flex">
          {isFavoritePage && (
            <>
              {matchesSm ? (
                <CoachCategoriesPopUp
                  open={isFavoriteModalOpen}
                  onClose={toggleFavoriteModal}
                  fluid={true}
                  filter={filter}
                  favoriteGroups={favoriteGroups}
                />
              ) : (
                <Box width="18%">
                  <CoachCategories
                    filter={filter}
                    favoriteGroups={favoriteGroups}
                  />
                </Box>
              )}
            </>
          )}
          <Box width={mainContainerWidth}>
            <AthletesGridContainer container spacing={2}>
              {loading ? (
                loadingItems.map((_, i) => (
                  <Grid item md={4} sm={6} xs={12} key={i}>
                    <AthletesCardSkeleton />
                  </Grid>
                ))
              ) : (
                <ItemsResults
                  items={items}
                  onItemRemoved={onItemRemoved}
                  onItemModified={onItemModified}
                />
              )}
            </AthletesGridContainer>
            {lastPage && (
              <Box py={2}>
                <StyledPagination
                  variant="outlined"
                  shape="rounded"
                  count={lastPage}
                  page={parsedPage}
                  onChange={onPageChange}
                />
              </Box>
            )}
          </Box>
        </Box>
      </AthletesContainer>
      <Feedbacker
        open={feedback === recruitingProfileFeedbacks.FavoritesUpdated}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage={feedback}
        clearFeedback={() =>
          dispatch({ type: recruitingProfileActionTypes.CLEAR_FEEDBACK })
        }
      />
      <Feedbacker
        open={messagesFeedback === messagesFeedbacks.Success}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage="Your message was successfully sent!"
        clearFeedback={() => messagesDispatch({ type: CLEAR_FEEDBACK })}
      />
      <Feedbacker
        open={messagesFeedback === messagesFeedbacks.Error}
        autoHideDuration={3000}
        severity="error"
        feedbackMessage="Something went wrong!"
        clearFeedback={() => messagesDispatch({ type: CLEAR_FEEDBACK })}
      />
    </>
  );
}

export default FindAthletes;
