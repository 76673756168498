function client(endpoint, { body, ...customConfig } = {}) {
  const headers = {
    "content-type": "application/json",
    accept: "application/json",
  };

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }
  return window.fetch("/api/v2x" + endpoint, config).then(async (response) => {
    if (!response.ok) {
      return Promise.reject(await response.json());
    }

    //204 has no data, used for Delete, for example
    if (response.status !== 204) {
      const text = await response.text();
      return text ? JSON.parse(text) : {};
    }
  });
}

export default client;
