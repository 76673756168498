import styled from "styled-components";
import { TextField } from "components/MaterialComponents";

export const StyledTextField = styled(TextField)`
  ${({ theme }) => `{
    width: 100%;
    ${theme.breakpoints.down("sm")} {
      width: 80%;
    }
  `}
`;
