import React from "react";

import { RespondToEmailPopover as Popover } from "./style";

function RespondToEmailPopover({ open, onClose, anchorEl, children }) {
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {children}
    </Popover>
  );
}

export default RespondToEmailPopover;
