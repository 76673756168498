import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal";
import { Formik, Form } from "formik";
import * as yup from "yup";
import CustomTextField from "components/CustomTextField";
import { Typography } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import SelectInput from "components/Select";
import { INPUT_TYPES } from "constants/sports";
import { getValInt } from "helpers/getSportStatIntValue";
import isEmpty from "lodash.isempty";

import { FormContainer, FormModalHeader, ButtonsBlock } from "../../style";

const validationSchema = yup.object().shape({
  name: yup.string().required("Field is required"),
});

function getInputTypesSelectOptions() {
  return INPUT_TYPES.map((type) => ({ label: type, value: type }));
}

function getSportPositionOptions(sportPositions) {
  return sportPositions.map((position) => ({
    value: position.id,
    label: position.name,
  }));
}

function getSportPositionsDefaultOptions(sportPositions, statSportPositions) {
  if (isEmpty(statSportPositions)) {
    return [];
  }

  return statSportPositions.map((positionId) => {
    const sportPosition = sportPositions.find(
      (position) => position.id === positionId,
    );
    if (!sportPosition) {
      return { value: "Not Valid", label: "Not Valid" };
    }

    return {
      value: sportPosition.id,
      label: sportPosition.name,
    };
  });
}

function StatCreateEditePopUp({
  open,
  onClose,
  item,
  onCreateStat,
  onEditeStat,
  positions,
}) {
  const [inputType, setInputType] = useState(item?.inputType);

  const handleFormSubmit = useCallback(
    async (values) => {
      const inputTypeValue = values.inputType ? values.inputType.value : null;
      const body = {
        ...values,
        inputType: inputTypeValue,
        maxInt: getValInt(values.max, inputTypeValue),
        minInt: getValInt(values.min, inputTypeValue),
        sportPositions: isEmpty(values.sportPositions)
          ? []
          : values.sportPositions.map((position) => position.value),
      };
      !item && (await onCreateStat(body));
      item && (await onEditeStat(body, item.id));
      onClose();
    },
    [item, onCreateStat, onEditeStat, onClose],
  );

  const handleSetFieldValue = useCallback(
    (name, value, setFieldValue) => {
      const valueInt = getValInt(value, inputType);
      if (value && valueInt === null) {
        return;
      }
      setFieldValue(name, value);
    },
    [inputType],
  );

  const headerText = item ? "EDIT STAT DATA" : "ADD NEW STAT";

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <FormModalHeader>
          <Typography variant="h4">{headerText}</Typography>
        </FormModalHeader>
        <Formik
          validateOnChange
          initialValues={{
            name: item ? item.name : "",
            prompt: item ? item.prompt : "",
            sportPositions: item
              ? getSportPositionsDefaultOptions(positions, item.sportPositions)
              : [],
            inputType:
              item && item.inputType
                ? { value: item.inputType, label: item.inputType }
                : null,
            inputUnits: item ? item.inputUnits : "",
            qualifierDescription: item ? item.qualifierDescription : "",
            storageUnits: item ? item.storageUnits : "",
            min: item ? String(item.min) : "",
            max: item ? item.max : "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ setFieldValue, submitForm, values, errors }) => (
            <Form>
              <FormContainer>
                <CustomTextField label="Name" name="name" type="text" />
                <CustomTextField
                  label="Prompt"
                  name="prompt"
                  type="text"
                  disabled={!inputType}
                  setFieldValue={(name, value) => {
                    handleSetFieldValue(name, value, setFieldValue);
                  }}
                />
                <Box marginBottom="30px">
                  <SelectInput
                    placeholder="Sport Positions"
                    name="sportPositions"
                    options={getSportPositionOptions(positions)}
                    setFieldValue={setFieldValue}
                    values={values}
                    isMulti={true}
                    errors={errors}
                  />
                </Box>
                <Box marginBottom="30px">
                  <SelectInput
                    placeholder="Input Type"
                    name="inputType"
                    options={getInputTypesSelectOptions()}
                    setFieldValue={(name, option) => {
                      setFieldValue(name, option);
                      setInputType(option?.value);
                    }}
                    values={values}
                  />
                </Box>
                <CustomTextField
                  label="Input Units"
                  name="inputUnits"
                  type="text"
                />
                <CustomTextField
                  label="Qualifier Description"
                  name="qualifierDescription"
                  type="text"
                />
                <CustomTextField
                  label="Storage Units"
                  name="storageUnits"
                  type="text"
                />
                <CustomTextField
                  label="Min"
                  name="min"
                  type="text"
                  disabled={!inputType}
                  setFieldValue={(name, value) => {
                    handleSetFieldValue(name, value, setFieldValue);
                  }}
                />
                <CustomTextField
                  label="Max"
                  name="max"
                  type="text"
                  disabled={!inputType}
                  setFieldValue={(name, value) => {
                    handleSetFieldValue(name, value, setFieldValue);
                  }}
                />
                <ButtonsBlock>
                  <Button
                    onClick={submitForm}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={onClose}
                    variant="outlined"
                    color="secondary"
                    size="large"
                  >
                    Cancel
                  </Button>
                </ButtonsBlock>
              </FormContainer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

StatCreateEditePopUp.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCreateStat: PropTypes.func,
  onEditeStat: PropTypes.func,
};

export default StatCreateEditePopUp;
