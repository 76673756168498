import React from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { components } from "react-select";
import { RegularText } from "components/Typography";
import { Box } from "components/MaterialComponents";
import * as colors from "theme/colors";
import { useField } from "formik";

import { ErrorMessage } from "./style";

function SelectInput({
  values = {},
  setFieldValue,
  name,
  loadOptions,
  placeholder,
  label,
  async,
  options,
  value,
  onChange,
  isDisabled,
  children,
  isMulti,
  errors = {},
}) {
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : "";

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {children}
        </components.DropdownIndicator>
      )
    );
  };

  const handleOnChange = (option) => {
    setFieldValue(name, option);
  };

  const isValid = !Boolean(errorText);

  const renderBoxShadow = (isFocused, isValid) => {
    if (!isValid) {
      return `0 0 0 1px red`;
    }
    return isFocused ? `0 0 0 1px ${colors.Allports}` : "none";
  };

  const renderBorderColor = (isFocused, isValid) => {
    if (!isValid) {
      return `red`;
    }
    return isFocused ? colors.Allports : colors.Geyser;
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      fontSize: "16px",
      fontWeight: 500,
      textTransform: "none",
      opacity: 0.7,
    }),
    control: (provided, state) => ({
      display: "flex",
      justifyContent: "space-between",
      padding: "5px",
      fontSize: "14px",
      fontWeight: 600,
      border: "1px solid",
      borderColor: renderBorderColor(
        state.isFocused,
        state.selectProps.isValid,
      ),
      boxShadow: renderBoxShadow(state.isFocused, state.selectProps.isValid),
      borderRadius: "5px",
      textTransform: "uppercase",
      backgroundColor: state.isDisabled ? colors.AthensGray : "none",
    }),
    option: (provided) => ({
      ...provided,
      fontWeight: 600,
      fontSize: "14px",
      textTransform: "uppercase",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
  };

  return (
    <Box fontFamily="'Open Sans', Arial, sans-serif">
      <RegularText>{label}</RegularText>
      {async ? (
        <Box data-testid="async-select">
          <AsyncSelect
            name={name}
            loadOptions={loadOptions}
            value={value || values[name] || null}
            onChange={onChange || handleOnChange}
            isClearable={true}
            styles={customStyles}
            noOptionsMessage={() => null}
            placeholder={placeholder}
            components={{ DropdownIndicator }}
            isDisabled={isDisabled}
            isValid={isValid}
          />
        </Box>
      ) : (
        <Box data-testid="common-select">
          <Select
            options={options}
            value={value || values[name] || null}
            onChange={onChange || handleOnChange}
            isClearable={true}
            isDisabled={isDisabled}
            components={{ DropdownIndicator }}
            styles={customStyles}
            noOptionsMessage={() => null}
            placeholder={placeholder}
            isMulti={isMulti}
            isValid={isValid}
          />
        </Box>
      )}
      {!isValid && <ErrorMessage>{errorText}</ErrorMessage>}
    </Box>
  );
}

export default SelectInput;
