import React from "react";
import { Button } from "components/MaterialComponents";
import { IconButton } from "components/MaterialComponents";
import { Reply as ReplyIcon } from "components/MaterialIcons";
import { Send as SendIcon } from "components/MaterialIcons";
import styled from "styled-components";

export const SuccessButton = styled((props) => (
  <Button variant="contained" color="primary" {...props} />
))`
  width: 159px;
  align-self: flex-end;
  padding: 16px 32px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
`;

export const CancelButton = styled((props) => (
  <Button color="secondary" {...props} />
))`
  align-self: flex-end;
  padding: 12px 32px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
`;

export const SendButton = styled((props) => (
  <Button
    variant="contained"
    color="primary"
    endIcon={<SendIcon />}
    {...props}
  />
))`
  align-self: flex-end;
  padding: 10px 32px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  text-transform: uppercase;
`;

export const ReplyButton = styled((props) => (
  <Button variant="contained" color="primary" {...props} />
))`
  width: 159px;
  align-self: flex-end;
  padding: 16px 32px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
`;

export const MobileButton = styled((props) => <Button {...props} />)`
  padding: 5px 30px;
  margin-right: 12px;
  text-transform: uppercase;
`;

export const GoTopButton = styled((props) => (
  <IconButton color="primary" {...props} />
))`
  ${({ theme }) => `
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 9;
    background-color: ${theme.palette.common.athensGray};
  `}
`;

export const RespondButton = styled((props) => (
  <Button
    variant="outlined"
    color="default"
    startIcon={<ReplyIcon />}
    {...props}
  />
))`
  ${({ theme }) => `
    background-color: ${theme.palette.common.athensGray};
  `}
`;

export const AddButton = styled((props) => (
  <IconButton color="primary" {...props} />
))`
  ${({ theme }) => `
    background-color: ${theme.palette.common.allports};
    width: 50px;
    height: 50px;
    color: ${theme.palette.common.athensGray};
    padding:0;

    :hover {
      color: ${theme.palette.common.allports};
    }
  `}
`;

export const AddButtonWithText = styled((props) => (
  <Button color="primary" {...props} />
))`
  ${({ theme }) => `
    background-color: ${theme.palette.common.allports};
    color: ${theme.palette.common.athensGray};
    padding: 5px 15px;

    :hover {
      color: ${theme.palette.common.allports};
    }
  `}
`;
