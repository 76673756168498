/*
  There are 2 types of sidebar links - with children and without.
  ParentId and nodeId should not be the same.
*/

export const athleteRoutes = [
  // Parent id - 1
  {
    link: "/profile/personal-information",
    nodeId: "2",
    parentId: "1",
    label: "Personal Information",
  },
  {
    link: "/profile/athletic-performance",
    nodeId: "3",
    parentId: "1",
    label: "Athletic performance",
  },
  {
    link: "/profile/athletic-history",
    nodeId: "4",
    parentId: "1",
    label: "Athletic history",
  },
  {
    link: "/profile/highlight-reel",
    nodeId: "5",
    parentId: "1",
    label: "Highlight reel",
  },
  {
    link: "/profile/contact-information",
    nodeId: "6",
    parentId: "1",
    label: "Contact information",
  },
  {
    link: "/profile/academic-information",
    nodeId: "7",
    parentId: "1",
    label: "Academic information",
  },
  // Parent id - 8
  {
    link: "/colleges",
    nodeId: "9",
    parentId: "8",
    label: "All Schools",
  },
  {
    link: "/colleges/reach",
    nodeId: "10",
    parentId: "8",
    label: "Reach Schools",
  },
  {
    link: "/colleges/target",
    nodeId: "11",
    parentId: "8",
    label: "Target Schools",
  },
  {
    link: "/colleges/safety",
    nodeId: "12",
    parentId: "8",
    label: "Safety Schools",
  },
  // No parent id
  {
    link: "/contact-activity",
    nodeId: "13",
  },
  {
    link: "/notes",
    nodeId: "14",
  },
  {
    link: "/admin",
    nodeId: "15",
  },
];
