import React, { useCallback, useState, useMemo } from "react";
import { TreeView } from "components/MaterialComponents";
import { ExpandMore as ExpandMoreIcon } from "components/MaterialIcons";
import { ChevronRight as ChevronRightIcon } from "components/MaterialIcons";
import { Checkbox } from "components/MaterialComponents";
import getArrayOfMajors from "helpers/getArrayOfMajors";
import { MajorItemContainer, StyledTreeItem } from "./style";

function MajorsFilter({ filter, onChange, textQuery, majors: majorsOptions }) {
  const [expanded, setExpanded] = useState([]);
  const { majors = "" } = filter;
  const majorsArray = majors ? majors.split(",") : [];

  const majorsForTextFilter = useMemo(() => getArrayOfMajors(majorsOptions), [
    majorsOptions,
  ]);

  const filterMajors = useCallback(
    (major) => {
      return major.toLowerCase().includes(textQuery.trim().toLowerCase());
    },
    [textQuery],
  );

  const calculatedMajors = textQuery
    ? majorsForTextFilter.filter((item) => filterMajors(item.major))
    : majorsOptions;

  const handleOnSelect = useCallback(
    (e) => {
      const clickedItemId = e.target
        .closest(".MuiTreeItem-root")
        .getAttribute("dataid");

      const isInSearch = e.target
        .closest(".MuiTreeItem-root")
        .getAttribute("datasearch");

      if (clickedItemId.indexOf("_") === 0) {
        /* The item clicked was a group. It should not be selected */
        return;
      }

      if (isInSearch === "false") {
        return;
      }

      let newMajorsArray = null;

      if (!majorsArray.includes(clickedItemId))
        newMajorsArray = [...majorsArray, clickedItemId];
      else newMajorsArray = majorsArray.filter((id) => id !== clickedItemId);

      onChange({
        ...filter,
        majors: newMajorsArray.join(","),
      });
    },
    [onChange, filter, majorsArray],
  );
  const handleOnToggle = useCallback(
    (e, expanded) => {
      setExpanded(expanded);
    },
    [setExpanded],
  );
  if (!majorsOptions) return null;
  return (
    <>
      <TreeView
        defaultExpandIcon={<ChevronRightIcon />}
        defaultCollapseIcon={<ExpandMoreIcon />}
        multiSelect
        onNodeSelect={handleOnSelect}
        onNodeToggle={handleOnToggle}
        selected={majorsArray}
        expanded={expanded}
      >
        {calculatedMajors.map((item) => (
          <MajorItem
            selected={majorsArray}
            key={item.id || item.cip_code}
            {...item}
          />
        ))}
      </TreeView>
    </>
  );
}

function MajorItem({
  id,
  cip_code,
  group_name,
  major,
  children,
  majors,
  selected,
  isPresentInSearch,
}) {
  const isGroup = !cip_code;
  const calculatedId = isGroup ? `_${id}` : `${cip_code}`;
  const calculatedName = group_name || major;
  const calculatedChildren =
    Array.isArray(children) && children.length > 0 ? children : majors;

  const isSelected = selected.indexOf(calculatedId) > -1;
  return (
    <MajorItemContainer>
      <StyledTreeItem
        nodeId={calculatedId}
        label={calculatedName}
        endIcon={
          <Checkbox disabled={!isPresentInSearch} checked={isSelected} />
        }
        dataid={calculatedId}
        datasearch={
          isPresentInSearch !== undefined
            ? isPresentInSearch.toString()
            : "false"
        }
        $isPresentInSearch={isPresentInSearch}
      >
        {calculatedChildren &&
          calculatedChildren.map((item) => (
            <MajorItem
              key={item.id || item.cip_code}
              selected={selected}
              {...item}
            />
          ))}
      </StyledTreeItem>
    </MajorItemContainer>
  );
}

export default MajorsFilter;
