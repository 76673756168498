import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Table } from "components/MaterialComponents";
import { TableBody } from "components/MaterialComponents";
import { TableContainer } from "components/MaterialComponents";
import { TableRow } from "components/MaterialComponents";
import { TableCell } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { makeStyles } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import PositionCreateEditePopUp from "./PositionCreateEditePopUp";
import SportPositionsTableRow from "./SportPositionsTableRow";
import * as sportsDomain from "api/domains/sports";
import { StyledTableHead, SportTableHeadContainer } from "../../style";

const useStyles = makeStyles({
  table: {
    width: 1100,
  },
});

function SportPositionsList({ positions, sportId, refetchSportsData }) {
  const [isPositionModalOpen, setPositionModalOpen] = useState(false);
  const classes = useStyles();

  const positionModalOpen = useCallback(() => {
    setPositionModalOpen(true);
  }, []);

  const positionModalClose = useCallback(() => {
    setPositionModalOpen(false);
  }, []);

  const handleCreatePosition = useCallback(
    async (values) => {
      const lastPosition = positions[positions.length - 1];
      const ordinal = lastPosition ? lastPosition.ordinal + 1 : 1;
      const body = {
        name: values.name,
        ordinal,
        sportId,
      };
      await sportsDomain.createSportPosition(body);
      await refetchSportsData();
    },
    [positions, sportId, refetchSportsData],
  );

  const handleEditePosition = useCallback(
    async (values, id) => {
      const body = {
        name: values.name,
        sportId,
      };
      await sportsDomain.updateSportPosition(body, id);
      await refetchSportsData();
    },
    [sportId, refetchSportsData],
  );

  const handleDeletePosition = useCallback(
    async (id) => {
      await sportsDomain.deleteSportPosition(id);
      await refetchSportsData();
    },
    [refetchSportsData],
  );

  return (
    <>
      <SportTableHeadContainer>
        <Box marginRight="10px" marginLeft="10px">
          <Typography variant="h5">Sport Positions</Typography>
        </Box>
        <Button color="primary" onClick={positionModalOpen}>
          Add new
        </Button>
      </SportTableHeadContainer>
      <TableContainer className={classes.table}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell>ORDINAL</TableCell>
              <TableCell>NAME</TableCell>
              <TableCell>EDIT</TableCell>
              <TableCell>DELETE</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {positions.map((position) => (
              <SportPositionsTableRow
                key={position.id}
                position={position}
                handleEditePosition={handleEditePosition}
                handleDeletePosition={handleDeletePosition}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PositionCreateEditePopUp
        open={isPositionModalOpen}
        onClose={positionModalClose}
        onCreatePosition={handleCreatePosition}
      />
    </>
  );
}

SportPositionsList.propTypes = {
  positions: PropTypes.array,
  sportId: PropTypes.number,
  refetchSportsData: PropTypes.func,
};

export default SportPositionsList;
