import * as actionTypes from "./actionTypes";

const initialState = {
  profile: null,
  loading: true,
  updating: false,
  error: null,
  updateError: null,
  feedback: undefined,
};

function recruitingProfileReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCHING: {
      return { ...state, loading: true };
    }
    case actionTypes.UPDATING: {
      return { ...state, updating: true };
    }
    case actionTypes.SUCCESS: {
      return {
        profile: action.data,
        loading: false,
        updating: false,
        error: null,
      };
    }
    case actionTypes.RESET_RECRUITING_PROFILE: {
      return initialState;
    }
    case actionTypes.UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        updating: false,
        updateError: action.error,
        feedback: action.message,
      };
    }
    case actionTypes.ERROR: {
      return {
        profile: null,
        loading: false,
        updating: false,
        error: action.error,
      };
    }
    case actionTypes.SET_FEEDBACK: {
      return { ...state, feedback: action.data };
    }
    case actionTypes.CLEAR_FEEDBACK: {
      return {
        ...state,
        feedback: undefined,
        updateError: undefined,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default recruitingProfileReducer;

export { initialState };
