import React from "react";
import styled from "styled-components";
import { Box } from "components/MaterialComponents";
import { FormControl } from "components/MaterialComponents";

export const ContactInfoFormContainer = styled(Box)`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
  margin: 2em;
  width: 75%;
  ${theme.breakpoints.down("sm")} {
    width: inherit;
  }
  `}
`;

export const ContactMethodFormControl = styled((props) => (
  <FormControl variant="outlined" {...props} />
))`
  margin-bottom: 2em;
`;
