import { usePost } from "components/useFetch";
import {
  transformToArray,
  transformToArraySelect,
} from "helpers/athletsFilterTransformers";

function useSearchResult(
  {
    page = 1,
    query,
    sportId,
    sportPositions,
    states,
    sportStats,
    sportStatsSelect,
  },
  params,
  { removed, modified } = {},
) {
  const queryStringDictionary = { pageSize: 30, page };
  const queryString = new URLSearchParams(queryStringDictionary).toString();

  let body = {};

  if (removed) {
    body["removed"] = removed;
  }

  if (modified) {
    body["modified"] = modified;
  }

  if (query) {
    body["athletesName"] = query;
  }

  if (params.groupId) {
    body["groupId"] = params.groupId;
  }

  if (sportId) {
    body["sportId"] = sportId;
  }

  if (sportPositions) {
    body["sportPositions"] = sportPositions.split(",");
  }

  if (states) {
    body["states"] = states.split(",");
  }

  if (sportStats) {
    body["sportStats"] = transformToArray(sportStats);
  }

  if (sportStatsSelect) {
    body["sportStatsSelect"] = transformToArraySelect(sportStatsSelect);
  }

  return usePost(`/profile/athletes?${queryString}`, body);
}

export default useSearchResult;
