import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "components/MaterialIcons";
import { ArrowForwardIos as ArrowForwardIosIcon } from "components/MaterialIcons";
import { Search as SearchIcon } from "components/MaterialIcons";
import { useMediaQuery } from "components/MaterialComponents";
import { useTheme } from "components/MaterialComponents";
import MessageItem from "./MessageItem";
import MessagesSearch from "./MessagesSearch";
import useOnScreen from "customHooks/useOnScreen";
import Tooltip from "components/Tooltip";
import {
  updateUnreadEmailsForAthlete,
  useMessagesDispatch,
} from "components/useMessages";
import isempty from "lodash.isempty";
import last from "lodash.last";

import {
  MessagesListContainer,
  GoBottomButtonContainer,
  StyledIconButton,
  SearchMenuContainer,
  SearchButtonContainer,
} from "./style";

function MessagesList({
  messages = [],
  sendEmail,
  containerRef,
  collegeId,
  currentCoachId,
  listRef,
  setListref,
  withSummary = false,
}) {
  const messagesRefs = useRef([]);
  const [isSearchMenuVisible, setSearchMenuVisible] = useState(false);

  const messagesDispatch = useMessagesDispatch();

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [currentMessagesRefs, setCurrentMessagesRefs] = useState([]);

  const isLastMessageVisible = useOnScreen(last(currentMessagesRefs));

  const handleGoBottom = useCallback(() => {
    last(currentMessagesRefs).scrollIntoView({ behavior: "smooth" });
  }, [currentMessagesRefs]);

  useEffect(() => {
    const refs = messagesRefs.current.filter((el) => el !== null);
    setCurrentMessagesRefs(refs);

    const newMessagesIds = messages
      .filter((message) => !Boolean(message.messageRead))
      .map((message) => message.id);

    if (!isempty(newMessagesIds)) {
      updateUnreadEmailsForAthlete(messagesDispatch, {
        ids: newMessagesIds,
        collegeId: collegeId,
      });
    }
  }, [currentCoachId]);

  useEffect(() => {
    if (!listRef) {
      return;
    }

    listRef.scrollTop = listRef.scrollHeight;
  }, [listRef, currentCoachId]);

  const toggleSearchMenu = useCallback(() => {
    setSearchMenuVisible(!isSearchMenuVisible);
  }, [isSearchMenuVisible]);

  return (
    <MessagesListContainer ref={setListref} $withSummary={withSummary}>
      {!matchesSm && (
        <>
          {isSearchMenuVisible ? (
            <>
              <SearchMenuContainer>
                <MessagesSearch messagesRefs={currentMessagesRefs} />
              </SearchMenuContainer>
              <SearchButtonContainer>
                <Tooltip title={"Close search"} arrow={true} placement="top">
                  <StyledIconButton onClick={toggleSearchMenu}>
                    <ArrowForwardIosIcon />
                  </StyledIconButton>
                </Tooltip>
              </SearchButtonContainer>
            </>
          ) : (
            <SearchButtonContainer>
              <Tooltip
                title={"Search in messages"}
                arrow={true}
                placement="top"
              >
                <StyledIconButton onClick={toggleSearchMenu}>
                  <SearchIcon />
                </StyledIconButton>
              </Tooltip>
            </SearchButtonContainer>
          )}
        </>
      )}
      {messages.map((message, i) => (
        <MessageItem
          key={message.id}
          message={message}
          containerRef={containerRef}
          sendEmail={sendEmail}
          messageRef={(el) => {
            messagesRefs.current[i] = el;
          }}
        />
      ))}
      {!isLastMessageVisible && (
        <GoBottomButtonContainer>
          <StyledIconButton onClick={handleGoBottom}>
            <KeyboardArrowDownIcon />
          </StyledIconButton>
        </GoBottomButtonContainer>
      )}
    </MessagesListContainer>
  );
}

MessagesList.propTypes = {
  messages: PropTypes.array,
  containerRef: PropTypes.object,
  sendEmail: PropTypes.func,
};

export default MessagesList;
