import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import useMessages, { updateUnsortedEmails } from "components/useMessages";
import HorizontalLine from "components/HorizontalLine";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import UnsortedEmailsForm from "./UnsortedEmailsForm";
import formatDateTime from "helpers/formatDateTime";
import { Button } from "components/MaterialComponents";
import Feedbacker from "components/Feedbacker";
import { CLEAR_FEEDBACK } from "components/useMessages/actionTypes";
import { messagesFeedbacks } from "components/useMessages/messagesFeedbacks";

import {
  BackButton,
  StyledHeadline,
  UnsortedEmailsContainer,
  UnsortedEmailItem,
  UnsortedEmaiHeaderContainer,
  UnsortedEmaiBodyContainer,
} from "./style";

function UnsortedContactDetails() {
  const [{ unsortedEmails, feedback, error }, dispatch] = useMessages();
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const history = useHistory();

  const toggleModalOpen = useCallback(() => {
    setIsFormModalOpen(!isFormModalOpen);
  }, [isFormModalOpen]);

  const handleUpdateEmail = useCallback(
    async (emailId, body) => {
      await updateUnsortedEmails(dispatch, emailId, body);
      toggleModalOpen();
    },
    [dispatch, toggleModalOpen],
  );

  const handleGoBackClick = useCallback(() => {
    history.push("/contact-activity");
  }, [history]);

  return (
    <Box>
      <Box margin="10px">
        <StyledHeadline>Contact Activity</StyledHeadline>
        <Box display="flex" alignItems="baseline">
          <BackButton onClick={handleGoBackClick}>&#8592; Back</BackButton>
        </Box>
      </Box>
      <HorizontalLine />
      <Box display="flex" flexDirection="column" alignItems="center">
        <UnsortedEmailsContainer>
          {unsortedEmails.map((email) => (
            <UnsortedEmailItem key="email.id">
              <UnsortedEmaiHeaderContainer>
                <Typography variant="h6">{email.emailFrom}</Typography>
                <Typography variant="h6">
                  {formatDateTime(email.dateTime, "MMMM Do YYYY, h:mm:ss a")}
                </Typography>
              </UnsortedEmaiHeaderContainer>
              <Box display="flex" flexDirection="column">
                <Box marginBottom="10px">
                  <Typography variant="caption">{email.subject}</Typography>
                </Box>
                <UnsortedEmaiBodyContainer>
                  <Typography variant="subtitle2">{email.body}</Typography>
                </UnsortedEmaiBodyContainer>
              </Box>
              <Box display="flex" alignItems="center" marginTop="10px">
                <Box marginRight="10px">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={toggleModalOpen}
                  >
                    Add
                  </Button>
                </Box>
                <Typography variant="h6">
                  To reply for this message, plese add it to the college group
                </Typography>
              </Box>
              <UnsortedEmailsForm
                open={isFormModalOpen}
                onClose={toggleModalOpen}
                updateEmail={handleUpdateEmail}
                emailId={email.id}
              />
            </UnsortedEmailItem>
          ))}
        </UnsortedEmailsContainer>
      </Box>
      <Feedbacker
        open={feedback === messagesFeedbacks.MessageUpdated}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage={messagesFeedbacks.MessageUpdated}
        clearFeedback={() => dispatch({ type: CLEAR_FEEDBACK })}
      />
      <Feedbacker
        open={error}
        autoHideDuration={3000}
        severity="error"
        feedbackMessage={messagesFeedbacks.MessagesUpdateFailure}
        clearFeedback={() => dispatch({ type: CLEAR_FEEDBACK })}
      />
    </Box>
  );
}

export default UnsortedContactDetails;
