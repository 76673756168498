import React, { useState, useCallback } from "react";
import { Box } from "components/MaterialComponents";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import CustomTextField from "components/CustomTextField";
import Modal from "components/Modal";

import { ModalButton, ModalFormContainer, ModalHeader } from "./style";
import {
  INCORRECT_PASSWORD,
  VALIDATION_FAILED,
} from "../AuthenticationDrawer/backendErrors";

const validationSchema = yup.object().shape({
  email: yup.string().email("Please enter a valid email"),
  password: yup.string().required("Please enter your password"),
});

function EmailNotActivatedModal({
  open,
  handleClose,
  email,
  handleEmailUpdate,
  handleSendEmail,
  handleError,
}) {
  const [update, setUpdate] = useState(false);

  const saveEmail = useCallback(
    async (values, { setFieldError }) => {
      try {
        await handleEmailUpdate(values);

        handleClose();
      } catch (error) {
        switch (error.errorCode) {
          case VALIDATION_FAILED:
            setFieldError("email", error.message);
            break;
          case INCORRECT_PASSWORD:
            setFieldError("password", error.message);
            break;
          default:
            handleError(error);
            break;
        }
      }
    },
    [handleClose, handleEmailUpdate, handleError],
  );

  return (
    <Modal onClose={handleClose} open={open}>
      {update ? (
        <ModalHeader>Update your email address</ModalHeader>
      ) : (
        <ModalHeader data-testid="email-not-activated-modal-header">
          It seems your email address was not verified. You could send the
          verification email again or update your existing address.
        </ModalHeader>
      )}
      {update ? (
        <Formik
          initialValues={{ email: email, password: "" }}
          validateOnChange
          onSubmit={saveEmail}
          validationSchema={validationSchema}
        >
          {
            <Form>
              <ModalFormContainer>
                <CustomTextField label="Email" name="email" value="email" />
                <CustomTextField
                  label="Password"
                  name="password"
                  type="password"
                />
                <Box display="flex" justifyContent="space-around">
                  <ModalButton
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Save
                  </ModalButton>
                  <ModalButton
                    variant="outlined"
                    color="primary"
                    onClick={() => setUpdate(false)}
                  >
                    Cancel
                  </ModalButton>
                </Box>
              </ModalFormContainer>
            </Form>
          }
        </Formik>
      ) : (
        <ModalFormContainer>
          <Box display="flex" justifyContent="space-evenly">
            <ModalButton
              variant="outlined"
              color="primary"
              onClick={handleSendEmail}
            >
              Send email
            </ModalButton>
            <ModalButton
              variant="outlined"
              color="primary"
              onClick={() => setUpdate(!update)}
            >
              Update address
            </ModalButton>
          </Box>
        </ModalFormContainer>
      )}
    </Modal>
  );
}

EmailNotActivatedModal.propTypes = {
  email: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleEmailUpdate: PropTypes.func.isRequired,
  handleSendEmail: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
};

export default EmailNotActivatedModal;
