import styled from "styled-components";
import { IconButton } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Delete as DeleteIcon } from "components/MaterialIcons";
import * as colors from "theme/colors";

export const SaveIconButton = styled(IconButton)``;

export const FormContainer = styled.div`
  display: flex;
`;

export const SaveButton = styled(Button)`
  font-size: 12px;
  width: 40px;
  height: 30px;
`;

export const SaveFilterContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  :hover {
    color: ${colors.Red};
  }
`;

export const StyledButton = styled.div`
  margin-right: 10px;
  font-size: 12px;
  cursor: pointer;
`;
