import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Formik, Form } from "formik";
import * as yup from "yup";
import * as validationHelper from "helpers/validationHelper";
import SelectInput from "components/Select";
import useSports from "components/useSports";

export const getOptionsFromSports = (sportList = [], collegeSports) => {
  const filterSports = (sport) =>
    !collegeSports.find((collegeSport) => collegeSport.id === sport.id);
  return sportList.filter(filterSports).map((sport) => ({
    value: sport.id,
    label: `${sport.name} (${sport.gender})`,
  }));
};

const validationSchema = yup.object().shape({
  sports: validationHelper.sports,
});

function AddSportForm({ onAddSports, collegeId, collegeSports }) {
  const { data: sportList, loading: sportsLoading } = useSports();

  const handleFormSubmit = useCallback(
    async (values, props) => {
      const body = {
        sportIds: values.sports.map((sport) => sport.value),
      };
      props.resetForm();
      await onAddSports(collegeId, body);
    },
    [onAddSports, collegeId],
  );

  return (
    <Box width="100%">
      <Formik
        validateOnChange
        initialValues={{
          sports: [],
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <Box display="flex" marginBottom="20px">
              <Box width="50%" marginRight="10px">
                <SelectInput
                  placeholder="Sports..."
                  name="sports"
                  options={getOptionsFromSports(sportList, collegeSports)}
                  setFieldValue={setFieldValue}
                  values={values}
                  isMulti={true}
                  errors={errors}
                  isDisabled={sportsLoading}
                />
              </Box>
              <Box height="50px">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Add
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

AddSportForm.propTypes = {
  onAddSports: PropTypes.func.isRequired,
};

export default AddSportForm;
