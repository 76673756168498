import client from "../adapter";

function createHistory(body) {
  return client("/profile/athletic-history", { body });
}

function getUserHistory() {
  return client(`/profile/athletic-history`);
}

function updateHistory(id, body) {
  return client(`/profile/athletic-history/${id}`, { body, method: "PATCH" });
}

function deleteHistory(id) {
  return client(`/profile/athletic-history/${id}`, { method: "DELETE" });
}

export { createHistory, getUserHistory, updateHistory, deleteHistory };
