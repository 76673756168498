export function getOptionsFromSearchCity(items = []) {
  return items.map((item) => ({ value: item.city, label: item.city }));
}

export function getSchoolValues(school) {
  return {
    school: school ? { value: school.schoolId, label: school.name } : null,
    city: school ? { value: school.city, label: school.city } : null,
    state: school ? { value: school.state, label: school.stateName } : null,
  };
}

export function getOptionsFromStates(states = {}) {
  return Object.keys(states).map((key) => ({ value: key, label: states[key] }));
}

export function getOptionsFromSearchSchool(items = []) {
  return items.map((item) => ({ value: item.schoolId, label: item.name }));
}
