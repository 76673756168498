import React, { useCallback } from "react";
import CheckboxOptionsList, {
  ItemSkeleton,
} from "components/CheckboxOptionsList";
import useSports from "components/useSports";

function WomensSportsFilter({ filter, onChange }) {
  const { womensSports: womensSportsOptions, loading } = useSports();
  const { womensSports = "" } = filter;
  const womensSportsArray = womensSports ? womensSports.split(",") : [];
  const handleOnChange = useCallback(
    (newWomensSportsArray) => {
      onChange({
        ...filter,
        womensSports: newWomensSportsArray.join(","),
      });
    },
    [onChange, filter],
  );

  if (loading)
    return (
      <>
        <ItemSkeleton textWidth={140} />
        <ItemSkeleton textWidth={110} />
      </>
    );

  return (
    <CheckboxOptionsList
      value={womensSportsArray}
      onChange={handleOnChange}
      options={womensSportsOptions}
    />
  );
}

export default WomensSportsFilter;
