import React, { useMemo } from "react";
import Context from "./Context";
import { useGet } from "../useFetch";

function MajorsProvider({ children, testData }) {
  const [data, loading, error] = useGet("/colleges/majors");

  const value = useMemo(
    () => ({
      data,
      loading,
      error,
    }),
    [data, loading, error],
  );
  return (
    <Context.Provider value={testData ? testData : value}>
      {children}
    </Context.Provider>
  );
}

export default MajorsProvider;
