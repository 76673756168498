import React from "react";
import { render } from "react-dom";

import App from "./App";
import Reset from "components/Reset";
import * as serviceWorker from "./serviceWorker";

const rootElement = document.getElementById("root");
render(
  <React.StrictMode>
    <Reset />
    <App />
  </React.StrictMode>,
  rootElement,
);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(<NextApp />, rootElement);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
