import React from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import GeneralCard from "components/GeneralCard";
import { Link } from "components/MaterialComponents";

import { StyledMailIcon, StyledPhoneIcon } from "./style";

function Contacts({ data }) {
  return (
    <GeneralCard header="Contact Information">
      <Box display="flex" justifyContent="space-between" width="60%" mt={2}>
        <Box>
          <Typography variant="h5">
            {data.firstParentFirstName} {data.firstParentLastName}
          </Typography>
          <Box mt=".8em">
            {data.firstParentEmail && (
              <Box mb=".6em">
                <Link href={`mailto:${data.firstParentEmail}`}>
                  <StyledMailIcon />
                  {data.firstParentEmail}
                </Link>
              </Box>
            )}
            {data.firstParentPhone && (
              <Box mb=".6em">
                <Typography variant="body2">
                  <StyledPhoneIcon />
                  {data.firstParentPhone}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <Typography variant="h5">
            {data.secondParentFirstName} {data.secondParentLastName}
          </Typography>
          <Box mt=".8em">
            {data.secondParentEmail && (
              <Box mb=".6em">
                <Link href={`mailto:${data.secondParentEmail}`}>
                  <StyledMailIcon />
                  {data.secondParentEmail}
                </Link>
              </Box>
            )}
            {data.secondParentPhone && (
              <Box mb=".6em">
                <Typography variant="body2">
                  <StyledPhoneIcon />
                  {data.secondParentPhone}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </GeneralCard>
  );
}

Contacts.propTypes = {
  data: PropTypes.array,
};

export default Contacts;
