import React from "react";
import PropTypes from "prop-types";

import { StyledAvatar } from "./style";

function LargeProfileAvatar({ name, image, withBorder = false }) {
  return (
    <StyledAvatar
      src={image}
      $withBorder={withBorder}
      alt="profile avatar"
      variant="circle"
    >
      {name}
    </StyledAvatar>
  );
}

LargeProfileAvatar.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  withBorder: PropTypes.bool,
};

export default LargeProfileAvatar;
