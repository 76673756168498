import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { Draggable } from "react-beautiful-dnd";

import videoFormStateEnum from "./videoFormStateEnum";
import { useRecruitingProfileState } from "components/useRecruitingProfile";
import { RegularText, Subtitle1 } from "components/Typography";
import {
  VideoItemActionsContainer,
  IncludeSportChip,
  VideoPreviewImg,
  VideoItemContainer,
  VideoDescription,
  StyledYoutubeIcon,
  StyledHudlIcon,
  DragAndDropIcon,
  ChipsContainer,
  DeleteIcon,
} from "./style";

function VideoItem({
  title,
  description,
  id,
  index,
  img,
  type,
  sportIds,
  updating,
  handleVideoPreviewClick,
  handleOpenDeleteModal,
  handleIncludeSport,
}) {
  const recruitingProfile = useRecruitingProfileState();
  const [sports, setSports] = useState([]);

  useEffect(
    function () {
      setSports(Object.values(recruitingProfile.profile.sports));
    },
    [recruitingProfile, setSports],
  );

  const youtubeType = type === videoFormStateEnum.youtube;
  const hudlType = type === videoFormStateEnum.hudl;

  const sportIdExist = ({ id }) => sportIds.includes(id);

  return (
    <Draggable
      isDragDisabled={updating}
      draggableId={id.toString()}
      index={index}
    >
      {(provided) => (
        <VideoItemContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <VideoPreviewImg
            variant="square"
            onClick={handleVideoPreviewClick}
            src={img}
          />
          {youtubeType && <StyledYoutubeIcon />}
          {hudlType && <StyledHudlIcon />}
          <Box>
            <Subtitle1>{title}</Subtitle1>
            <VideoDescription>
              <RegularText>{description || "(No Description)"}</RegularText>
            </VideoDescription>
            <ChipsContainer>
              {!!sports.length && (
                <RegularText mr=".5em">Incude on:</RegularText>
              )}
              {sports.map((sport) => (
                <IncludeSportChip
                  key={sport.id}
                  label={sport.name}
                  color={sportIdExist(sport) ? "primary" : "default"}
                  onDelete={
                    sportIdExist(sport)
                      ? () => handleIncludeSport(id, sport.id, sportIds)
                      : undefined
                  }
                  onClick={() => handleIncludeSport(id, sport.id, sportIds)}
                />
              ))}
            </ChipsContainer>
          </Box>
          <VideoItemActionsContainer>
            <Box {...provided.dragHandleProps}>
              <DragAndDropIcon />
            </Box>
            <DeleteIcon onClick={handleOpenDeleteModal} />
          </VideoItemActionsContainer>
        </VideoItemContainer>
      )}
    </Draggable>
  );
}

VideoItem.propTypes = {
  id: PropTypes.number,
  index: PropTypes.number,
  updating: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  type: PropTypes.string,
  sportIds: PropTypes.array,
  handleOpenDeleteModal: PropTypes.func,
  handleVideoPreviewClick: PropTypes.func,
  handleIncludeSport: PropTypes.func,
};

export default VideoItem;
