import client from "../adapter";

function createNote(body) {
  return client("/profile/note", { body });
}

function getUserNotes() {
  return client(`/profile/note`);
}

function updateNote(id, body) {
  return client(`/profile/note/${id}`, { body, method: "PATCH" });
}

function deleteNote(id) {
  return client(`/profile/note/${id}`, { method: "DELETE" });
}

export { createNote, getUserNotes, updateNote, deleteNote };
