function getSportStatsWithAnswers(
  sportPositions,
  sportStatsAnswers,
  sportStatsDefinitions,
) {
  const sportPositonIds = sportPositions.map(({ id }) => id);
  const sportStatsDefinitionsWithAnswers = sportStatsDefinitions
    .filter(
      (definition) =>
        definition.sportPositions.length === 0 ||
        sportPositonIds.some((id) => definition.sportPositions.includes(id)),
    )
    .map((definition) => {
      const emptyAnswer = {
        sportStatsDefinitionId: definition.id,
        valueText: null,
        valueInt: null,
        valueDate: null,
        verifiedBy: null,
        verifiedDate: null,
      };
      const answer = sportStatsAnswers.find(
        (answer) => answer.sportStatsDefinitionId === definition.id,
      );
      return answer
        ? { ...definition, answer }
        : { ...definition, answer: emptyAnswer };
    });

  return sportStatsDefinitionsWithAnswers;
}

export default getSportStatsWithAnswers;
