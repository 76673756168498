import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Box } from "components/MaterialComponents";
import { CircularProgress } from "components/MaterialComponents";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Modal from "components/Modal";
import CustomTextField from "components/CustomTextField";
import * as validationHelper from "helpers/validationHelper";
import SelectInput from "components/Select";
import { Search } from "components/MaterialIcons";
import {
  getOptionsFromSports,
  loadOptionsCollege,
} from "helpers/coachProfileSelectHelpers";
import useSports from "components/useSports";

import { ModalHeader, ModalButton, ModalFormContainer } from "./style";

const validationSchema = yup.object().shape({
  firstName: validationHelper.firstName.required("First Name is required"),
  lastName: validationHelper.lastName.required("Last Name is required"),
  college: validationHelper.college,
  sports: validationHelper.sports,
  phoneNumber: yup.string().nullable(),
  contactEmail: yup.string().nullable(),
  coachTitle: yup.string().nullable(),
});

function NoCoachProfileModal({
  open,
  handleClose,
  handleCreateCoachProfile,
  displayName,
  loading,
}) {
  const displayNameArray = displayName.split(" ");
  const firstName = displayNameArray[0];
  const lastName = displayNameArray[1] || null;

  const { data: sportList, loading: sportsLoading } = useSports();

  const handleFormSubmit = useCallback(
    async (values) => {
      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        collegeId: values.college.value,
        sportIds: values.sports.map((sport) => sport.value),
        phoneNumber: values.phoneNumber,
        email: values.collegeEmail,
        contactEmail: values.contactEmail,
        coachTitle: values.coachTitle,
      };
      await handleCreateCoachProfile(body);
    },
    [handleCreateCoachProfile],
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader data-testid="no-coach-profile-modal-header">
        We noticed you don't have a profile yet. Press Create to continue
      </ModalHeader>
      <Box width="80%">
        <Formik
          validateOnChange
          initialValues={{
            firstName,
            lastName,
            college: null,
            sports: [],
            contactEmail: "",
            phoneNumber: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              <ModalFormContainer>
                <CustomTextField label="First Name" name="firstName" />
                <CustomTextField label="Last Name" name="lastName" />
                <Box marginBottom="30px">
                  <SelectInput
                    placeholder="Type your college name..."
                    name="college"
                    loadOptions={loadOptionsCollege}
                    async={true}
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                  >
                    <Search />
                  </SelectInput>
                </Box>
                <Box marginBottom="30px">
                  <SelectInput
                    placeholder="Sports..."
                    name="sports"
                    options={getOptionsFromSports(sportList)}
                    setFieldValue={setFieldValue}
                    values={values}
                    isMulti={true}
                    errors={errors}
                    isDisabled={sportsLoading}
                  />
                </Box>
                <CustomTextField label="Coach Title" name="coachTitle" />
                <CustomTextField label="Contact Email" name="contactEmail" />
                <CustomTextField label="Phone Number" name="phoneNumber" />
                <Box display="flex" justifyContent="space-around">
                  <ModalButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={loading && <CircularProgress size={20} />}
                    disabled={loading}
                  >
                    Create
                  </ModalButton>
                  <ModalButton
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                  >
                    Cancel
                  </ModalButton>
                </Box>
              </ModalFormContainer>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

NoCoachProfileModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreateCoachProfile: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  displayName: PropTypes.string,
};

export default NoCoachProfileModal;
