import React from "react";
import PropTypes from "prop-types";
import { InputLabel } from "components/MaterialComponents";
import { Select } from "components/MaterialComponents";
import { MenuItem } from "components/MaterialComponents";

import { StyledFormControl } from "./style";

function GenderSelect({ gender, handleChange }) {
  return (
    <StyledFormControl>
      <InputLabel id="gender-label">Gender</InputLabel>
      <Select
        labelId="gender-label"
        value={gender}
        onChange={handleChange}
        label="Gender"
      >
        <MenuItem key="m" value="m">
          Male
        </MenuItem>
        <MenuItem key="f" value="f">
          Female
        </MenuItem>
      </Select>
    </StyledFormControl>
  );
}

GenderSelect.propTypes = {
  gender: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

export default GenderSelect;
