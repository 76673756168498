import { useContext } from "react";

import MessagesProvider from "./MessagesProvider";
import { MessagesStateContext, MessagesDispatchContext } from "./Context";
import {
  setCurrentThread,
  sendEmail,
  getContactsSummary,
  setLoading,
  setCurrentThreadForCoach,
  sendEmailToAthlete,
  sendEmailToCoachNotInDB,
  updateUnsortedEmails,
  updateUnreadEmailsForAthlete,
  updateUnreadEmailsForCoach,
} from "./actions";

function useMessagesState() {
  const context = useContext(MessagesStateContext);
  if (context === undefined) {
    throw new Error("useMessagesState must be used within a MessagesProvider");
  }
  return context;
}

function useMessagesDispatch() {
  const context = useContext(MessagesDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useMessagesDispatch must be used within a MessagesProvider",
    );
  }
  return context;
}

function useMessages() {
  return [useMessagesState(), useMessagesDispatch()];
}

export default useMessages;

export {
  MessagesProvider,
  useMessagesState,
  useMessagesDispatch,
  setCurrentThread,
  sendEmail,
  getContactsSummary,
  setLoading,
  setCurrentThreadForCoach,
  sendEmailToAthlete,
  sendEmailToCoachNotInDB,
  updateUnsortedEmails,
  MessagesStateContext,
  MessagesDispatchContext,
  updateUnreadEmailsForAthlete,
  updateUnreadEmailsForCoach,
};
