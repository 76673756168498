import React from "react";
import PropTypes from "prop-types";
import { TextField } from "components/MaterialComponents";
import { StyledTextFieldContainer, StyledSubtitle2 } from "./style";

function AthleticTextArea({ value, handleChange }) {
  return (
    <StyledTextFieldContainer>
      <StyledSubtitle2>Achievements</StyledSubtitle2>
      <TextField
        fullWidth
        multiline
        rows={3}
        variant="outlined"
        name="achivements"
        type="text"
        value={value || ""}
        onChange={handleChange}
      />
    </StyledTextFieldContainer>
  );
}

AthleticTextArea.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
};

export default AthleticTextArea;
