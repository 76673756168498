import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import CoachLogo from "./CoachLogo";
import getShortName from "helpers/getShortName";
import { useRecruitingProfileState } from "components/useRecruitingProfile";
import formatDateTime from "helpers/formatDateTime";
import { RespondButton } from "components/Buttons";
import { Box } from "components/MaterialComponents";
import ReplyEmailPopUp from "./ReplyEmailPopUp";
import { parseEmailBody } from "helpers/parseEmailBody";

import {
  StyledMessageBody,
  MessageContainer,
  StyledAvatar,
  StyledMessageCard,
  MessageSubtitles,
  StyledCaption,
  RespondButtonContainer,
} from "./style";

function MessageItem({ message, containerRef, sendEmail, messageRef }) {
  const { profile } = useRecruitingProfileState();
  const [isReplyWindowOpen, setReplyWindowOpen] = useState(false);
  const isFromCoach = message.emailFromConverted.includes("coaches");

  const toggleReplyWindow = useCallback(() => {
    setReplyWindowOpen(!isReplyWindowOpen);
  }, [isReplyWindowOpen]);

  const renderLogo = () => {
    return (
      <>
        {!isFromCoach ? (
          <StyledAvatar src={profile?.profilePhotoMediaItem}>
            {getShortName(profile?.firstName)}
          </StyledAvatar>
        ) : (
          <CoachLogo sport={message.sport} small={true} />
        )}
      </>
    );
  };

  return (
    <>
      <MessageContainer $sent={!isFromCoach}>
        {isFromCoach && renderLogo()}
        <StyledMessageCard>
          <MessageSubtitles>
            <Box marginRight="10px">
              <StyledCaption>{message.subject}</StyledCaption>
            </Box>
            <StyledCaption>
              {formatDateTime(message.dateTime, "MMMM Do YYYY, h:mm:ss a")}
            </StyledCaption>
          </MessageSubtitles>
          <StyledMessageBody
            ref={messageRef}
            unread={isFromCoach && !message.messageRead}
          >
            {parseEmailBody(message.body)}
          </StyledMessageBody>
        </StyledMessageCard>
        {!isFromCoach && renderLogo()}
      </MessageContainer>
      {isFromCoach && (
        <RespondButtonContainer>
          <RespondButton onClick={toggleReplyWindow}>Reply</RespondButton>
        </RespondButtonContainer>
      )}
      <ReplyEmailPopUp
        open={isReplyWindowOpen}
        onClose={toggleReplyWindow}
        subject={message.subject}
        containerRef={containerRef}
        onSendEmail={sendEmail}
      />
    </>
  );
}

MessageItem.propTypes = {
  message: PropTypes.object,
  containerRef: PropTypes.object,
  sendEmail: PropTypes.func,
};

export default MessageItem;
