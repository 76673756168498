import React from "react";
import { Box } from "components/MaterialComponents";
import { Skeleton } from "components/MaterialComponents";
import { Formik, Form, FieldArray } from "formik";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { useGet } from "components/useFetch";
import Feedbacker from "components/Feedbacker";
import PositionSelect from "./PositionSelect";
import SportStatWithAnswer from "./SportStatWithAnswer";
import getSportStatsWithAnswers from "./getSportStatsWithAnswers";
import useRecruitingProfile, {
  updateRecruitingProfile,
  recruitingProfileFeedbacks,
} from "components/useRecruitingProfile";
import { SuccessButton } from "components/Buttons";
import {
  getSportTitle,
  getSportButtonText,
} from "helpers/getSportSpecificFormText";
import useClearFeedback from "customHooks/useClearFeedback";
import {
  AthleticPerformanceFormContainer,
  AddPositionButton,
  StyledAddIcon,
  Title,
} from "./style";

const validationSchema = yup.object().shape({
  sportPositions: yup.array().of(
    yup.object({
      name: yup.string().required(),
    }),
  ),
  sportStatsDefinitions: yup.array().of(
    yup.object({
      answer: yup.object({
        valueText: yup.string().nullable(),
        valueInt: yup.number().nullable(),
        verifiedBy: yup.string().nullable(),
        verifiedDate: yup.date().nullable(),
      }),
    }),
  ),
});

function DynamicForm() {
  const { id } = useParams();
  const [{ profile, feedback }, dispatch] = useRecruitingProfile();
  const [sportData, loading] = useGet(`/colleges/sports/${id}`);
  useClearFeedback(dispatch);

  let userSportPositions = [];
  let userSportStatsAnswers = [];

  if (loading) {
    return (
      <Skeleton
        variant="rect"
        width="100%"
        height="100vh"
        style={{ marginTop: "6em" }}
      />
    );
  }

  const handleFormSubmit = async (values, { setSubmitting }) => {
    const {
      sportPositions: formSportPositions,
      sportStatsWithAnswers,
    } = values;

    const newSportPositions = getNewSportPositions(formSportPositions);
    const newSportStatsAnswers = getNewSportStatsAnswers(sportStatsWithAnswers);

    const dataToSend = {
      ...profile,
      sportPositions: newSportPositions,
      sportStatsAnswers: newSportStatsAnswers,
    };
    await updateRecruitingProfile(dispatch, profile.userId, dataToSend);
    setSubmitting(false);
  };

  const getNewSportPositions = (formSportPositions) => {
    const otherSportPositions = Object.values(profile.sports)
      .filter((sport) => Number(sport.id) !== Number(id))
      .flatMap((sport) => sport.sportPositions);

    return [...formSportPositions, ...otherSportPositions];
  };

  const getNewSportStatsAnswers = (sportStatsWithAnswers) => {
    let otherSportStatsAnswers = [];

    const filterNullValuesCb = ({ valueText, valueInt, valueDate }) =>
      valueText || valueInt || valueDate;

    Object.values(profile.sports).forEach((sport) => {
      if (
        sport.id !== +id &&
        sport.sportStatsDefinitions &&
        sport.sportStatsDefinitions.length > 0
      ) {
        sport.sportStatsDefinitions.forEach((stat) => {
          otherSportStatsAnswers.push(stat.sportStatsAnswers);
        });
      }
    });

    const formSportStatsAnswers = sportStatsWithAnswers
      .map(({ answer }) => answer)
      .filter(filterNullValuesCb);

    return [...formSportStatsAnswers, ...otherSportStatsAnswers];
  };

  const {
    sportPositions: currentTabSportPositions,
    sportStatsDefinitions,
  } = sportData;
  const currentTabSport = Object.values(profile.sports).find(
    (sport) => sport.id === +id,
  );
  const buttonText = getSportButtonText(currentTabSport.name);

  //temp to destructure from json
  let sportStatsAnswers = [];

  Object.values(profile.sports).forEach((sport) => {
    if (sport.id === +id) {
      userSportPositions = sport.sportPositions ? sport.sportPositions : [];
      sportStatsAnswers = sport.sportStatsDefinitions
        ? sport.sportStatsDefinitions
        : [];
    }
  });

  sportStatsAnswers.forEach((statDefinitions) => {
    if (statDefinitions.sportStatsAnswers)
      userSportStatsAnswers.push(statDefinitions.sportStatsAnswers);
  });

  const userSportStatsDefinitionsWithAnswers = getSportStatsWithAnswers(
    userSportPositions,
    userSportStatsAnswers,
    sportStatsDefinitions,
  );

  const canAddAnotherPosition = (selectedPositionsCount) => {
    return (
      selectedPositionsCount < 3 &&
      selectedPositionsCount < currentTabSportPositions.length
    );
  };

  return (
    <Box mt="4em">
      <Formik
        validateOnChange
        initialValues={{
          sportPositions: userSportPositions,
          sportStatsWithAnswers: userSportStatsDefinitionsWithAnswers,
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, values, errors, setValues, setFieldValue }) => (
          <Form>
            <AthleticPerformanceFormContainer>
              <Title mb="1.5em">{getSportTitle(currentTabSport.name)}</Title>
              <FieldArray name="sportPositions">
                {({ push }) => (
                  <>
                    {values.sportPositions.map((position, index) => (
                      <Box marginBottom="2em">
                        <PositionSelect
                          key={position.id}
                          position={position}
                          index={index}
                          errors={errors.sportPositions}
                          currentTabSportPositions={currentTabSportPositions}
                          sportPositions={values.sportPositions}
                          sportStatsWithAnswers={values.sportStatsWithAnswers}
                          sportStatsDefinitions={sportStatsDefinitions}
                          setValues={setValues}
                          currentSportName={currentTabSport.name}
                        />
                      </Box>
                    ))}
                    {canAddAnotherPosition(values.sportPositions.length) && (
                      <AddPositionButton
                        startIcon={<StyledAddIcon />}
                        disableRipple
                        onClick={() => push({ id: "" + Math.random() })}
                      >
                        {values.sportPositions.length
                          ? `Add another ${buttonText}`
                          : `Add ${buttonText}`}
                      </AddPositionButton>
                    )}
                  </>
                )}
              </FieldArray>
              <Title mb="1.5em">Performance Stats:</Title>
              {values.sportStatsWithAnswers.map((statWithAnswer, index) => (
                <SportStatWithAnswer
                  key={index}
                  item={statWithAnswer}
                  index={index}
                  setFieldValue={setFieldValue}
                />
              ))}
              <SuccessButton type="submit" disabled={isSubmitting}>
                Save
              </SuccessButton>
            </AthleticPerformanceFormContainer>
          </Form>
        )}
      </Formik>
      <Feedbacker
        open={feedback === recruitingProfileFeedbacks.Updated}
        autoHideDuration={3000}
        severity="success"
        feedbackMessage={feedback}
        clearFeedback={() => dispatch({ type: "clear_feedback" })}
      />
    </Box>
  );
}

export default DynamicForm;
