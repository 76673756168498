import * as parentDomain from "api/domains/parent";
import * as actionTypes from "./actionTypes";
import { recruitingProfileFeedbacks } from "./recruitingProfileFeedbacks";

async function loadParentProfile(dispatch) {
  try {
    const res = await parentDomain.loadParentProfile();
    dispatch({ type: actionTypes.SUCCESS, data: res });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.errorCode });
  }
}

async function createParentProfile(dispatch, data) {
  try {
    dispatch({ type: actionTypes.FETCHING });
    const res = await parentDomain.createParentProfile(data);
    dispatch({ type: actionTypes.SUCCESS, data: res });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: recruitingProfileFeedbacks.ActivationEmailSent,
    });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.errorCode });
  }
}

async function updateParentProfile(dispatch, profileId, data) {
  try {
    dispatch({ type: actionTypes.UPDATING });
    const res = await parentDomain.updateParentProfile(profileId, data);
    dispatch({ type: actionTypes.SUCCESS, data: res });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: recruitingProfileFeedbacks.Updated,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_ERROR,
      error: error.errorCode,
      message: error.message,
    });
  }
}

async function uploadParentProfilePhoto(dispatch, profileData, image) {
  try {
    dispatch({ type: actionTypes.UPDATING });
    const url = await parentDomain.uploadParentProfilePhoto(image);
    dispatch({
      type: actionTypes.SUCCESS,
      data: { ...profileData, profilePhotoMediaItem: url },
    });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: recruitingProfileFeedbacks.Updated,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_ERROR,
      error: error.errorCode,
      message: error.message,
    });
  }
}

async function deleteParentProfilePhoto(dispatch, profileData) {
  try {
    dispatch({ type: actionTypes.UPDATING });
    await parentDomain.deleteParentProfilePhoto();
    dispatch({
      type: actionTypes.SUCCESS,
      data: { ...profileData, profilePhotoMediaItem: null },
    });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: recruitingProfileFeedbacks.Updated,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_ERROR,
      error: error.errorCode,
      message: error.message,
    });
  }
}

async function addChildrenToParentProfile(dispatch, email) {
  try {
    dispatch({ type: actionTypes.UPDATING });
    const res = await parentDomain.addChildrenToParentProfile(email);
    dispatch({ type: actionTypes.SUCCESS, data: res });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: recruitingProfileFeedbacks.Updated,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_ERROR,
      error: error,
      message: error.message,
    });
  }
}

async function removeChildrenFromParentProfile(dispatch, childId) {
  try {
    dispatch({ type: actionTypes.UPDATING });
    const res = await parentDomain.removeChildrenFromParentProfile(childId);
    dispatch({ type: actionTypes.SUCCESS, data: res });
    dispatch({
      type: actionTypes.SET_FEEDBACK,
      data: recruitingProfileFeedbacks.Updated,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_ERROR,
      error: error,
      message: error.message,
    });
  }
}

export {
  loadParentProfile,
  createParentProfile,
  updateParentProfile,
  uploadParentProfilePhoto,
  deleteParentProfilePhoto,
  addChildrenToParentProfile,
  removeChildrenFromParentProfile,
};
