import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { IconButton } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import { TextField } from "components/MaterialComponents";
import { Box } from "components/MaterialComponents";
import isempty from "lodash.isempty";
import { ArrowUpward as ArrowUpwardIcon } from "components/MaterialIcons";
import { ArrowDownward as ArrowDownwardIcon } from "components/MaterialIcons";

import * as colors from "theme/colors";

import { SearchContainer } from "./style";

function MessagesSearch({ messagesRefs }) {
  const [searchString, setSearchString] = useState("");
  const [filteredRefs, setFilteredRefs] = useState([]);

  const [refsInnerHtml, setRefsInnerHtml] = useState();

  const [currentRefIndex, setCurrentRefIndex] = useState(0);

  useEffect(() => {
    const refsHtml = messagesRefs.map((ref) => ref.innerHTML);
    setRefsInnerHtml(refsHtml);
    setSearchString("");
  }, [messagesRefs]);

  useEffect(() => {
    if (!isempty(filteredRefs) && currentRefIndex) {
      filteredRefs[currentRefIndex - 1].scrollIntoView();
    }
  }, [currentRefIndex]);

  const handleInputChange = useCallback((event) => {
    setSearchString(event.target.value);
  }, []);

  const handleGoUp = useCallback(() => {
    if (currentRefIndex >= 2) {
      setCurrentRefIndex(currentRefIndex - 1);
    }
  }, [currentRefIndex]);

  const handleGoDown = useCallback(() => {
    if (currentRefIndex < filteredRefs.length) {
      setCurrentRefIndex(currentRefIndex + 1);
    }
  }, [currentRefIndex, filteredRefs]);

  const revertRefsToPrevStyles = useCallback(() => {
    if (!isempty(refsInnerHtml)) {
      messagesRefs.forEach((ref, i) => {
        const refHTML = refsInnerHtml[i];
        ref.innerHTML = refHTML;
        return ref;
      });
    }
  }, [messagesRefs, refsInnerHtml]);

  const addStylesToFilteredRefs = useCallback(
    (filtered) => {
      return filtered.map((ref) => {
        const refHTML = ref.innerHTML.replaceAll(
          searchString,
          `<span style="background-color:${colors.Orange}">${searchString}</span>`,
        );
        ref.innerHTML = refHTML;
        return ref;
      });
    },
    [searchString],
  );

  useEffect(() => {
    const filtered = messagesRefs.filter((ref) =>
      ref.innerText.includes(searchString),
    );

    revertRefsToPrevStyles();

    if (searchString) {
      const coloredRefs = addStylesToFilteredRefs(filtered);
      setFilteredRefs(coloredRefs);
      setCurrentRefIndex(filtered.length);
    }

    if (!searchString) {
      setFilteredRefs([]);
      setCurrentRefIndex(0);
    }

    return () => {
      revertRefsToPrevStyles();
    };
  }, [searchString]);

  return (
    <SearchContainer>
      <TextField
        value={searchString}
        onChange={handleInputChange}
        placeholder="Search in messages"
      />
      <Box marginLeft="5px" marginRight="5px">
        {currentRefIndex ? (
          <Typography>
            {currentRefIndex} of {filteredRefs.length}
          </Typography>
        ) : (
          <Typography>No results</Typography>
        )}
      </Box>
      <IconButton onClick={handleGoUp}>
        <ArrowUpwardIcon />
      </IconButton>
      <IconButton onClick={handleGoDown}>
        <ArrowDownwardIcon />
      </IconButton>
    </SearchContainer>
  );
}

MessagesSearch.propTypes = {
  messagesRefs: PropTypes.array,
};

export default MessagesSearch;
