import React from "react";
import { Box } from "components/MaterialComponents";
import { Button } from "components/MaterialComponents";
import { Typography } from "components/MaterialComponents";
import PropTypes from "prop-types";

import Modal from "../Modal";

function ConfirmationModal({
  open,
  disabled = false,
  handleClose,
  handleConfirm,
}) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Typography variant="h3">Are you sure?</Typography>
      <Box
        width="50%"
        marginTop="1em"
        display="flex"
        justifyContent="space-between"
      >
        <Button
          onClick={handleConfirm}
          disabled={disabled}
          variant="outlined"
          color="secondary"
        >
          Confirm
        </Button>
        <Button onClick={handleClose} variant="contained" color="primary">
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default ConfirmationModal;
